import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import logo from './../../assets/logo.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from "react-secure-storage";
import { LOGIN_URL } from "../../constant/constant";
import jwtDecode from "jwt-decode";
import { Audio } from 'react-loader-spinner'
import { MdOutlineVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import useBacktestStore from "../../store/backtestStore";
import Section1 from "./Section1";

const Login = () => {
  const navigate = useNavigate();
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [rememberMe, setRememberMe] = useState(false);
  const [wait, setWait] = useState(false)
  const [visible, setVisible] = useState(false)
  const {updateCoin}=useBacktestStore()

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handlesubmit = () => {
    if (!validateEmail(email)) {
      toast('Please Enter Valid Email');
      setWait(false);
      return;
    }
    const data = { "email": email.toLowerCase(), "password": password }
    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: LOGIN_URL,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    if (email !== "" && password !== "") {
      axios.request(config)
        .then((response) => {
          const result = response?.data;
          console.log("result at login", result);
          secureLocalStorage.setItem('token', result.token)
          secureLocalStorage.setItem('email', result.email)
          secureLocalStorage.setItem('name', result.name)
          secureLocalStorage.setItem('id', result.id)
          secureLocalStorage.setItem('role', result.role)
                    secureLocalStorage.setItem('coin',result.coin)
                    secureLocalStorage.setItem('phone',result.contact)
          secureLocalStorage.setItem('activePlan', result.activePlan)
          secureLocalStorage.setItem('expire', result.expire)
          updateCoin(result.coin)
          if (result?.profilePic != null) {
            secureLocalStorage.setItem('profilePic', result?.profilePic);
          }
          secureLocalStorage.setItem('active', result.active)
          if (rememberMe) {
            secureLocalStorage.setItem('password', password);
            localStorage.setItem('remember', "true");
          } else {
            secureLocalStorage.removeItem('password');
            localStorage.setItem('remember', "false");
          }
          setemail("")
          setpassword("")
          setWait(false)
          navigate('/backtest/backtest');
        }
        )
        .catch((error) => {
                    if(error?.response?.data?.message){
            toast(error.response.data.message);
            setWait(false);
            return;
                    }else{
            toast('Try Again Later');
            setWait(false);
            return;
          }
        });
    } else {
      toast("Please enter both your email and password.");
      setWait(false);
      return;
    }
  }
  const checkTokenValidity = async () => {
    try {
      const token = await secureLocalStorage.getItem('token'); // Replace with your actual token key
      if (token) {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

        if (decodedToken.exp < currentTime) {
          // Token has expired
          navigate('/backtest/login'); // Assuming you want to redirect to the login page
        } else {
          // Token is valid, navigate to dashboard
          navigate('/backtest/backtest');
        }
      }
    } catch (error) {
      // Handle any errors that might occur during token retrieval
      console.error("Error while checking token validity:", error);
    }
  };

  useEffect(() => {
    if (wait === true) {
      handlesubmit();
      setTimeout(() => {
        setWait(false);
      }, 10000);
    }
  }, [wait])

  useEffect(() => {
    const remember = localStorage.getItem('remember');
    if (remember === "true") {
      setRememberMe(true);
      const password12 = secureLocalStorage.getItem('password');
            if(password12) setpassword(password12);
      const email1 = secureLocalStorage.getItem('email');
            if(email1) setemail(email1);
    }
    checkTokenValidity();
  }, [])

  return (
    <>
      <ToastContainer />
      <div className="flex flex-row justify-center text-center min-h-screen bg-[#DAECF2]">
        <div className="hidden lg:block relative mt-10 mb-10 ml-10">
          <Section1 />
        </div>
        <div className="flex flex-col bg-white shadow-lg p-6 lg:p-5 my-6 lg:my-10 max-w-md lg:max-w-lg mx-auto lg:mx-0">
          <div className="flex justify-start mb-6">
            <img src={logo} alt="logo" className="h-24 w-24" />
          </div>
          <h1 className="text-[#3F4547] text-3xl text-left font-bold mb-2">Account Login</h1>
          <p className="text-[#8692A6] text-left mb-4">If you have already registered, you can login with your username and password.</p>
          <div className="text-left font-semibold mb-4">Hello! Let's get started..</div>
          <hr className="my-4" />
          <label className="block text-[#494949] text-left mb-2">Email</label>
          <input
            type="email"
            placeholder="Enter Email"
            className="w-full p-3 border border-[#1565D8] rounded-lg text-[#494949] mb-4"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
          <label className="block text-[#494949] mb-2 text-left">Password</label>
          <div className="relative mb-4">
            <input
              type={visible ? "text" : "password"}
              placeholder="Enter Your Password"
              className="w-full p-3 border border-[#1565D8] rounded-lg text-[#494949]"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
            />
            {visible ? (
              <MdOutlineVisibility className="absolute right-3 top-1/2 -mt-3 cursor-pointer text-2xl font-semibold"
                onClick={() => setVisible(false)}
              />
            ) : (
              <MdVisibilityOff className="absolute right-3 top-1/2 -mt-3 cursor-pointer text-2xl font-semibold"
                onClick={() => setVisible(true)}
              />
            )}
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="mr-2"
              />
              <span className="text-[#3F4547]">Remember Me</span>
            </div>
            <Link to='/backtest/forget_password'>
              <span className="text-[#2C73EB] cursor-pointer">Forgot Password?</span>
            </Link>
          </div>
          <div className="text-center mb-4">
            {!wait ? (
              <button
                className="bg-[#2C73EB] text-white py-3 px-6 rounded-md font-bold w-full"
                onClick={() => setWait(true)}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) setWait(true);
                }}
              >
                Log In
              </button>
            ) : (
              <Audio height="40" width="120" color='lightblue' ariaLabel='three-dots-loading' />
            )}
          </div>
          <div className="text-center">
            <span className="text-[#3F4547]">Don't have an account? </span>
            <Link to='/backtest/signup'>
              <span className="text-[#2C73EB] underline cursor-pointer">Sign up here</span>
            </Link>
          </div>
        </div>
      </div>

    </>
  );
};

export default Login;

