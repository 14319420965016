export const pointnifty = [
    "ATM-1000", "ATM-950", "ATM-900", "ATM-850", "ATM-800", "ATM-750", "ATM-700", "ATM-650", 
    "ATM-600", "ATM-550", "ATM-500", "ATM-450", "ATM-400", "ATM-350", "ATM-300", "ATM-250", "ATM-200", 
    "ATM-150","ATM-100", "ATM-50", "ATM", "ATM+50", "ATM+100", "ATM+150", "ATM+200", "ATM+250", "ATM+300", "ATM+350", "ATM+400", "ATM+450", 
    "ATM+500", "ATM+550", "ATM+600", "ATM+650", "ATM+700", "ATM+750", "ATM+800", "ATM+850", "ATM+900", "ATM+950", 
    "ATM+1000"
  ];
  
export const pointbanknifty = [
    "ATM-2000", "ATM-1900", "ATM-1800", "ATM-1700", "ATM-1600", "ATM-1500", "ATM-1400", "ATM-1300", "ATM-1200", "ATM-1100", 
    "ATM-1000", "ATM-900", "ATM-800", "ATM-700", "ATM-600", "ATM-500", "ATM-400", "ATM-300", "ATM-200", "ATM-100", "ATM", 
    "ATM+100", "ATM+200", "ATM+300", "ATM+400", "ATM+500", "ATM+600", "ATM+700", "ATM+800", "ATM+900", "ATM+1000", 
    "ATM+1100", "ATM+1200", "ATM+1300", "ATM+1400", "ATM+1500", "ATM+1600", "ATM+1700", "ATM+1800", "ATM+1900", "ATM+2000"
  ];
// export const percentarray = Array.from({ length: 21 }, (_, i) => `ATM${i - 10 >= 0 ? `+${i - 10.5}` : i - 10.5}%`);4
export const percentarray = ["ATM-10%","ATM-9.5%","ATM-9%","ATM-8.5%","ATM-8%","ATM-7.5%","ATM-7%","ATM-6.5%","ATM-6%","ATM-5.5%","ATM-5%","ATM-4.5%","ATM-4%","ATM-3.5%","ATM-3%","ATM-2.5%","ATM-2%","ATM-1.5%","ATM-1%","ATM-0.5%","ATM","ATM+0.5%","ATM+1%","ATM+1.5%","ATM+2%","ATM+2.5%","ATM+3%","ATM+3.5%","ATM+4%","ATM+4.5%","ATM+5%","ATM+5.5%","ATM+6%","ATM+6.5%","ATM+7%","ATM+7.5%","ATM+8%","ATM+8.5%","ATM+9%","ATM+9.5%","ATM+10%"]
export const premium = [ "5%SP", "10%SP", "15%SP", "20%SP", "25%SP", "30%SP", "35%SP", "40%SP", "45%SP", "50%SP", "55%SP", "60%SP"];