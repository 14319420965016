export const nonDirection=[{
  "_id": {
    "$oid": "662ce3939f6403cd2438023d"
  },
  "name": "Short Straddle",
  "url":"ShortStraddle",
  "array": {
    "leg": 2,
    "segmentType": [
      "option",
      "option"
    ],
    "lotarray1": [
      50,
      50
    ],
    "legarray": [
      "SP",
      "SC"
    ],
    "darray": [
      "0",
      "0"
    ],
    "intraday": "Yes",
    "tradesymbol": "NIFTY",
    "gap": 50,
    "stoploss": 1,
    "maxprofit": -1,
    "starttime": "09:30",
    "endtime": "15:25",
    "startdate": "2022-01-03",
    "enddate": "2022-02-03",
    "startDay": 4,
    "endDay": 0,
    "atm": [
      "ATM",
      "ATM"
    ],
    "squareoff": "one",
    "waitTrade": false,
    "waitTradeType": [
      "Point ++",
      "Point ++"
    ],
    "waitTradeValue": [
      0,
      0
    ],
    "slToCost": false,
    "reEntry": false,
    "protectType": "Lock & Trail Profit",
    "profitIncrease": "1000",
    "lockMinimumProfit": "0",
    "profitReaches": "1000",
    "trailProfit": "300",
    "legsStoplossType": [
      "SL%",
      "SL%"
    ],
    "legsStoploss": [
      "-1",
      "-1"
    ],
    "legsTargetProfit": [
      "-1",
      "-1"
    ],
    "legsTargetProfitType": [
      "TP%",
      "TP%"
    ],
    "reEntryValue": [
      "-1",
      "-1"
    ],
    "reEntryChoice": [
      "Re-Entry",
      "Re-Entry"
    ],
    "legSymbol": [
      "NIFTY",
      "NIFTY"
    ],
    "legExpiryType": [
      "Weekly",
      "Weekly"
    ],
    "atmChoice": [
      "point",
      "point"
    ],
    "closestPremiumChoice": [
      "CP~",
      "CP~"
    ],
    "closestPremiumValue": [
      100,
      100
    ],
    "legsReEntry": [
      false,
      false
    ],
    "protectProfit": false,
    "reEntryType": [
      "SL",
      "SL"
    ],
    "trailStoploss": [
      false,
      false
    ],
    "trailStoplossLoss": [
      "",
      ""
    ],
    "trailStoplossProfit": [
      "",
      ""
    ],
    "trailStoplossType": [
      "point",
      "point"
    ],
    "showRangeBreakout": false,
    "rangeStartTime": "09:20",
    "rangeEndTime": "09:30",
    "lotarray": [
      1,
      1
    ],
    "optionBuySell": [
      "Sell",
      "Sell"
    ],
    "optionCallPut": [
      "Put",
      "Call"
    ]
  }
},
{
  "_id": {
    "$oid": "662ce3cb9f6403cd2438023e"
  },
  "name": "Long Straddle",
  "url":"LongStraddle",
  "array": {
    "leg": 2,
    "segmentType": [
      "option",
      "option"
    ],
    "lotarray1": [
      50,
      50
    ],
    "legarray": [
      "BP",
      "BC"
    ],
    "darray": [
      "0",
      "0"
    ],
    "intraday": "Yes",
    "tradesymbol": "NIFTY",
    "gap": 50,
    "stoploss": 1,
    "maxprofit": -1,
    "starttime": "09:30",
    "endtime": "15:25",
    "startdate": "2022-01-03",
    "enddate": "2022-02-03",
    "startDay": 4,
    "endDay": 0,
    "atm": [
      "ATM",
      "ATM"
    ],
    "squareoff": "one",
    "waitTrade": false,
    "waitTradeType": [
      "Point ++",
      "Point ++"
    ],
    "waitTradeValue": [
      0,
      0
    ],
    "slToCost": false,
    "reEntry": false,
    "protectType": "Lock & Trail Profit",
    "profitIncrease": "1000",
    "lockMinimumProfit": "0",
    "profitReaches": "1000",
    "trailProfit": "300",
    "legsStoplossType": [
      "SL%",
      "SL%"
    ],
    "legsStoploss": [
      "-1",
      "-1"
    ],
    "legsTargetProfit": [
      "-1",
      "-1"
    ],
    "legsTargetProfitType": [
      "TP%",
      "TP%"
    ],
    "reEntryValue": [
      "-1",
      "-1"
    ],
    "reEntryChoice": [
      "Re-Entry",
      "Re-Entry"
    ],
    "legSymbol": [
      "NIFTY",
      "NIFTY"
    ],
    "legExpiryType": [
      "Weekly",
      "Weekly"
    ],
    "atmChoice": [
      "point",
      "point"
    ],
    "closestPremiumChoice": [
      "CP~",
      "CP~"
    ],
    "closestPremiumValue": [
      100,
      100
    ],
    "legsReEntry": [
      false,
      false
    ],
    "protectProfit": false,
    "reEntryType": [
      "SL",
      "SL"
    ],
    "trailStoploss": [
      false,
      false
    ],
    "trailStoplossLoss": [
      "",
      ""
    ],
    "trailStoplossProfit": [
      "",
      ""
    ],
    "trailStoplossType": [
      "point",
      "point"
    ],
    "showRangeBreakout": false,
    "rangeStartTime": "09:20",
    "rangeEndTime": "09:30",
    "lotarray": [
      1,
      1
    ],
    "optionBuySell": [
      "Buy",
      "Buy"
    ],
    "optionCallPut": [
      "Put",
      "Call"
    ]
  }
},
{
  "_id": {
    "$oid": "662ce432694ea15d1ed36ad9"
  },
  "name": "Short Strangle",
  "url":"ShortStrangle",
  "array": {
    "leg": 2,
    "segmentType": [
      "option",
      "option"
    ],
    "lotarray1": [
      50,
      50
    ],
    "legarray": [
      "SP",
      "SC"
    ],
    "darray": [
      "-200",
      "+200"
    ],
    "intraday": "Yes",
    "tradesymbol": "NIFTY",
    "gap": 50,
    "stoploss": 1,
    "maxprofit": -1,
    "starttime": "09:30",
    "endtime": "15:25",
    "startdate": "2022-01-03",
    "enddate": "2022-02-03",
    "startDay": 4,
    "endDay": 0,
    "atm": [
      "ATM-200",
      "ATM+200"
    ],
    "squareoff": "one",
    "waitTrade": false,
    "waitTradeType": [
      "Point ++",
      "Point ++"
    ],
    "waitTradeValue": [
      0,
      0
    ],
    "slToCost": false,
    "reEntry": false,
    "protectType": "Lock & Trail Profit",
    "profitIncrease": "1000",
    "lockMinimumProfit": "0",
    "profitReaches": "1000",
    "trailProfit": "300",
    "legsStoplossType": [
      "SL%",
      "SL%"
    ],
    "legsStoploss": [
      "-1",
      "-1"
    ],
    "legsTargetProfit": [
      "-1",
      "-1"
    ],
    "legsTargetProfitType": [
      "TP%",
      "TP%"
    ],
    "reEntryValue": [
      "-1",
      "-1"
    ],
    "reEntryChoice": [
      "Re-Entry",
      "Re-Entry"
    ],
    "legSymbol": [
      "NIFTY",
      "NIFTY"
    ],
    "legExpiryType": [
      "Weekly",
      "Weekly"
    ],
    "atmChoice": [
      "point",
      "point"
    ],
    "closestPremiumChoice": [
      "CP~",
      "CP~"
    ],
    "closestPremiumValue": [
      100,
      100
    ],
    "legsReEntry": [
      false,
      false
    ],
    "protectProfit": false,
    "reEntryType": [
      "SL",
      "SL"
    ],
    "trailStoploss": [
      false,
      false
    ],
    "trailStoplossLoss": [
      "",
      ""
    ],
    "trailStoplossProfit": [
      "",
      ""
    ],
    "trailStoplossType": [
      "point",
      "point"
    ],
    "showRangeBreakout": false,
    "rangeStartTime": "09:20",
    "rangeEndTime": "09:30",
    "lotarray": [
      1,
      1
    ],
    "optionBuySell": [
      "Sell",
      "Sell"
    ],
    "optionCallPut": [
      "Put",
      "Call"
    ]
  }
},
{
  "_id": {
    "$oid": "662ce465694ea15d1ed36ada"
  },
  "name": "Long Strangle",
  "url":"LongStrangle",
  "array": {
    "leg": 2,
    "segmentType": [
      "option",
      "option"
    ],
    "lotarray1": [
      50,
      50
    ],
    "legarray": [
      "BC",
      "BP"
    ],
    "darray": [
      "-200",
      "+200"
    ],
    "intraday": "Yes",
    "tradesymbol": "NIFTY",
    "gap": 50,
    "stoploss": 1,
    "maxprofit": -1,
    "starttime": "09:30",
    "endtime": "15:25",
    "startdate": "2022-01-03",
    "enddate": "2022-02-03",
    "startDay": 4,
    "endDay": 0,
    "atm": [
      "ATM-200",
      "ATM+200"
    ],
    "squareoff": "one",
    "waitTrade": false,
    "waitTradeType": [
      "Point ++",
      "Point ++"
    ],
    "waitTradeValue": [
      0,
      0
    ],
    "slToCost": false,
    "reEntry": false,
    "protectType": "Lock & Trail Profit",
    "profitIncrease": "1000",
    "lockMinimumProfit": "0",
    "profitReaches": "1000",
    "trailProfit": "300",
    "legsStoplossType": [
      "SL%",
      "SL%"
    ],
    "legsStoploss": [
      "-1",
      "-1"
    ],
    "legsTargetProfit": [
      "-1",
      "-1"
    ],
    "legsTargetProfitType": [
      "TP%",
      "TP%"
    ],
    "reEntryValue": [
      "-1",
      "-1"
    ],
    "reEntryChoice": [
      "Re-Entry",
      "Re-Entry"
    ],
    "legSymbol": [
      "NIFTY",
      "NIFTY"
    ],
    "legExpiryType": [
      "Weekly",
      "Weekly"
    ],
    "atmChoice": [
      "point",
      "point"
    ],
    "closestPremiumChoice": [
      "CP~",
      "CP~"
    ],
    "closestPremiumValue": [
      100,
      100
    ],
    "legsReEntry": [
      false,
      false
    ],
    "protectProfit": false,
    "reEntryType": [
      "SL",
      "SL"
    ],
    "trailStoploss": [
      false,
      false
    ],
    "trailStoplossLoss": [
      "",
      ""
    ],
    "trailStoplossProfit": [
      "",
      ""
    ],
    "trailStoplossType": [
      "point",
      "point"
    ],
    "showRangeBreakout": false,
    "rangeStartTime": "09:20",
    "rangeEndTime": "09:30",
    "lotarray": [
      1,
      1
    ],
    "optionBuySell": [
      "Buy",
      "Buy"
    ],
    "optionCallPut": [
      "Call",
      "Put"
    ]
  }
},
{
  "_id": {
    "$oid": "662ce4de694ea15d1ed36adb"
  },
  "name": "Put Butterfly",
  "url":"ShortIronFly",
  "array": {
    "leg": 4,
    "segmentType": [
      "option",
      "option",
      "option",
      "option"
    ],
    "lotarray1": [
      50,
      50,
      50,
      50
    ],
    "legarray": [
      "BP",
      "SC",
      "SP",
      "BC"
    ],
    "darray": [
      "-200",
      "0",
      "0",
      "+200"
    ],
    "intraday": "Yes",
    "tradesymbol": "NIFTY",
    "gap": 50,
    "stoploss": 1,
    "maxprofit": -1,
    "starttime": "09:30",
    "endtime": "15:25",
    "startdate": "2022-01-03",
    "enddate": "2022-02-03",
    "startDay": 4,
    "endDay": 0,
    "atm": [
      "ATM-200",
      "ATM",
      "ATM",
      "ATM+200"
    ],
    "squareoff": "one",
    "waitTrade": false,
    "waitTradeType": [
      "Point ++",
      "Point ++",
      "Point ++",
      "Point ++"
    ],
    "waitTradeValue": [
      0,
      0,
      0,
      0
    ],
    "slToCost": false,
    "reEntry": false,
    "protectType": "Lock & Trail Profit",
    "profitIncrease": "1000",
    "lockMinimumProfit": "0",
    "profitReaches": "1000",
    "trailProfit": "300",
    "legsStoplossType": [
      "SL%",
      "SL%",
      "SL%",
      "SL%"
    ],
    "legsStoploss": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "legsTargetProfit": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "legsTargetProfitType": [
      "TP%",
      "TP%",
      "TP%",
      "TP%"
    ],
    "reEntryValue": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "reEntryChoice": [
      "Re-Entry",
      "Re-Entry",
      "Re-Entry",
      "Re-Entry"
    ],
    "legSymbol": [
      "NIFTY",
      "NIFTY",
      "NIFTY",
      "NIFTY"
    ],
    "legExpiryType": [
      "Weekly",
      "Weekly",
      "Weekly",
      "Weekly"
    ],
    "atmChoice": [
      "point",
      "point",
      "point",
      "point"
    ],
    "closestPremiumChoice": [
      "CP~",
      "CP~",
      "CP~",
      "CP~"
    ],
    "closestPremiumValue": [
      100,
      100,
      100,
      100
    ],
    "legsReEntry": [
      false,
      false,
      false,
      false
    ],
    "protectProfit": false,
    "reEntryType": [
      "SL",
      "SL",
      "SL",
      "SL"
    ],
    "trailStoploss": [
      false,
      false,
      false,
      false
    ],
    "trailStoplossLoss": [
      "",
      "",
      "",
      ""
    ],
    "trailStoplossProfit": [
      "",
      "",
      "",
      ""
    ],
    "trailStoplossType": [
      "point",
      "point",
      "point",
      "point"
    ],
    "showRangeBreakout": false,
    "rangeStartTime": "09:20",
    "rangeEndTime": "09:30",
    "lotarray": [
      1,
      1,
      1,
      1
    ],
    "optionBuySell": [
      "Buy",
      "Sell",
      "Sell",
      "Buy"
    ],
    "optionCallPut": [
      "Put",
      "Call",
      "Put",
      "Call"
    ]
  }
},
{
  "_id": {
    "$oid": "662ce4de694ea15d1ed36adb"
  },
  "name": "Call Butterfly",
  "url":"ShortIronFly",
  "array": {
    "leg": 4,
    "segmentType": [
      "option",
      "option",
      "option",
      "option"
    ],
    "lotarray1": [
      50,
      50,
      50,
      50
    ],
    "legarray": [
      "BP",
      "SC",
      "SP",
      "BC"
    ],
    "darray": [
      "-200",
      "0",
      "0",
      "+200"
    ],
    "intraday": "Yes",
    "tradesymbol": "NIFTY",
    "gap": 50,
    "stoploss": 1,
    "maxprofit": -1,
    "starttime": "09:30",
    "endtime": "15:25",
    "startdate": "2022-01-03",
    "enddate": "2022-02-03",
    "startDay": 4,
    "endDay": 0,
    "atm": [
      "ATM-200",
      "ATM",
      "ATM",
      "ATM+200"
    ],
    "squareoff": "one",
    "waitTrade": false,
    "waitTradeType": [
      "Point ++",
      "Point ++",
      "Point ++",
      "Point ++"
    ],
    "waitTradeValue": [
      0,
      0,
      0,
      0
    ],
    "slToCost": false,
    "reEntry": false,
    "protectType": "Lock & Trail Profit",
    "profitIncrease": "1000",
    "lockMinimumProfit": "0",
    "profitReaches": "1000",
    "trailProfit": "300",
    "legsStoplossType": [
      "SL%",
      "SL%",
      "SL%",
      "SL%"
    ],
    "legsStoploss": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "legsTargetProfit": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "legsTargetProfitType": [
      "TP%",
      "TP%",
      "TP%",
      "TP%"
    ],
    "reEntryValue": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "reEntryChoice": [
      "Re-Entry",
      "Re-Entry",
      "Re-Entry",
      "Re-Entry"
    ],
    "legSymbol": [
      "NIFTY",
      "NIFTY",
      "NIFTY",
      "NIFTY"
    ],
    "legExpiryType": [
      "Weekly",
      "Weekly",
      "Weekly",
      "Weekly"
    ],
    "atmChoice": [
      "point",
      "point",
      "point",
      "point"
    ],
    "closestPremiumChoice": [
      "CP~",
      "CP~",
      "CP~",
      "CP~"
    ],
    "closestPremiumValue": [
      100,
      100,
      100,
      100
    ],
    "legsReEntry": [
      false,
      false,
      false,
      false
    ],
    "protectProfit": false,
    "reEntryType": [
      "SL",
      "SL",
      "SL",
      "SL"
    ],
    "trailStoploss": [
      false,
      false,
      false,
      false
    ],
    "trailStoplossLoss": [
      "",
      "",
      "",
      ""
    ],
    "trailStoplossProfit": [
      "",
      "",
      "",
      ""
    ],
    "trailStoplossType": [
      "point",
      "point",
      "point",
      "point"
    ],
    "showRangeBreakout": false,
    "rangeStartTime": "09:20",
    "rangeEndTime": "09:30",
    "lotarray": [
      1,
      1,
      1,
      1
    ],
    "optionBuySell": [
      "Buy",
      "Sell",
      "Sell",
      "Buy"
    ],
    "optionCallPut": [
      "Put",
      "Call",
      "Put",
      "Call"
    ]
  }
},
{
  "_id": {
    "$oid": "662ce51d694ea15d1ed36adc"
  },
  "name": "Long Iron Condor",
  "url":"LongIronCondor",
  "array": {
    "leg": 4,
    "segmentType": [
      "option",
      "option",
      "option",
      "option"
    ],
    "lotarray1": [
      50,
      50,
      50,
      50
    ],
    "legarray": [
      "BP",
      "SP",
      "SC",
      "BC"
    ],
    "darray": [
      "-200",
      "-100",
      "+100",
      "+200"
    ],
    "intraday": "Yes",
    "tradesymbol": "NIFTY",
    "gap": 50,
    "stoploss": 1,
    "maxprofit": -1,
    "starttime": "09:30",
    "endtime": "15:25",
    "startdate": "2022-01-03",
    "enddate": "2022-02-03",
    "startDay": 4,
    "endDay": 0,
    "atm": [
      "ATM-200",
      "ATM-100",
      "ATM+100",
      "ATM+200"
    ],
    "squareoff": "one",
    "waitTrade": false,
    "waitTradeType": [
      "Point ++",
      "Point ++",
      "Point ++",
      "Point ++"
    ],
    "waitTradeValue": [
      0,
      0,
      0,
      0
    ],
    "slToCost": false,
    "reEntry": false,
    "protectType": "Lock & Trail Profit",
    "profitIncrease": "1000",
    "lockMinimumProfit": "0",
    "profitReaches": "1000",
    "trailProfit": "300",
    "legsStoplossType": [
      "SL%",
      "SL%",
      "SL%",
      "SL%"
    ],
    "legsStoploss": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "legsTargetProfit": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "legsTargetProfitType": [
      "TP%",
      "TP%",
      "TP%",
      "TP%"
    ],
    "reEntryValue": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "reEntryChoice": [
      "Re-Entry",
      "Re-Entry",
      "Re-Entry",
      "Re-Entry"
    ],
    "legSymbol": [
      "NIFTY",
      "NIFTY",
      "NIFTY",
      "NIFTY"
    ],
    "legExpiryType": [
      "Weekly",
      "Weekly",
      "Weekly",
      "Weekly"
    ],
    "atmChoice": [
      "point",
      "point",
      "point",
      "point"
    ],
    "closestPremiumChoice": [
      "CP~",
      "CP~",
      "CP~",
      "CP~"
    ],
    "closestPremiumValue": [
      100,
      100,
      100,
      100
    ],
    "legsReEntry": [
      false,
      false,
      false,
      false
    ],
    "protectProfit": false,
    "reEntryType": [
      "SL",
      "SL",
      "SL",
      "SL"
    ],
    "trailStoploss": [
      false,
      false,
      false,
      false
    ],
    "trailStoplossLoss": [
      "",
      "",
      "",
      ""
    ],
    "trailStoplossProfit": [
      "",
      "",
      "",
      ""
    ],
    "trailStoplossType": [
      "point",
      "point",
      "point",
      "point"
    ],
    "showRangeBreakout": false,
    "rangeStartTime": "09:20",
    "rangeEndTime": "09:30",
    "lotarray": [
      1,
      1,
      1,
      1
    ],
    "optionBuySell": [
      "Buy",
      "Sell",
      "Sell",
      "Buy"
    ],
    "optionCallPut": [
      "Put",
      "Put",
      "Call",
      "Call"
    ]
  }
},
{
  "_id": {
    "$oid": "662ce51d694ea15d1ed36adc"
  },
  "name": "Short Iron Condor",
  "url":"ShortIronCondor",
  "array": {
    "leg": 4,
    "segmentType": [
      "option",
      "option",
      "option",
      "option"
    ],
    "lotarray1": [
      50,
      50,
      50,
      50
    ],
    "legarray": [
      "BP",
      "SP",
      "SC",
      "BC"
    ],
    "darray": [
      "-200",
      "-100",
      "+100",
      "+200"
    ],
    "intraday": "Yes",
    "tradesymbol": "NIFTY",
    "gap": 50,
    "stoploss": 1,
    "maxprofit": -1,
    "starttime": "09:30",
    "endtime": "15:25",
    "startdate": "2022-01-03",
    "enddate": "2022-02-03",
    "startDay": 4,
    "endDay": 0,
    "atm": [
      "ATM-200",
      "ATM-100",
      "ATM+100",
      "ATM+200"
    ],
    "squareoff": "one",
    "waitTrade": false,
    "waitTradeType": [
      "Point ++",
      "Point ++",
      "Point ++",
      "Point ++"
    ],
    "waitTradeValue": [
      0,
      0,
      0,
      0
    ],
    "slToCost": false,
    "reEntry": false,
    "protectType": "Lock & Trail Profit",
    "profitIncrease": "1000",
    "lockMinimumProfit": "0",
    "profitReaches": "1000",
    "trailProfit": "300",
    "legsStoplossType": [
      "SL%",
      "SL%",
      "SL%",
      "SL%"
    ],
    "legsStoploss": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "legsTargetProfit": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "legsTargetProfitType": [
      "TP%",
      "TP%",
      "TP%",
      "TP%"
    ],
    "reEntryValue": [
      "-1",
      "-1",
      "-1",
      "-1"
    ],
    "reEntryChoice": [
      "Re-Entry",
      "Re-Entry",
      "Re-Entry",
      "Re-Entry"
    ],
    "legSymbol": [
      "NIFTY",
      "NIFTY",
      "NIFTY",
      "NIFTY"
    ],
    "legExpiryType": [
      "Weekly",
      "Weekly",
      "Weekly",
      "Weekly"
    ],
    "atmChoice": [
      "point",
      "point",
      "point",
      "point"
    ],
    "closestPremiumChoice": [
      "CP~",
      "CP~",
      "CP~",
      "CP~"
    ],
    "closestPremiumValue": [
      100,
      100,
      100,
      100
    ],
    "legsReEntry": [
      false,
      false,
      false,
      false
    ],
    "protectProfit": false,
    "reEntryType": [
      "SL",
      "SL",
      "SL",
      "SL"
    ],
    "trailStoploss": [
      false,
      false,
      false,
      false
    ],
    "trailStoplossLoss": [
      "",
      "",
      "",
      ""
    ],
    "trailStoplossProfit": [
      "",
      "",
      "",
      ""
    ],
    "trailStoplossType": [
      "point",
      "point",
      "point",
      "point"
    ],
    "showRangeBreakout": false,
    "rangeStartTime": "09:20",
    "rangeEndTime": "09:30",
    "lotarray": [
      1,
      1,
      1,
      1
    ],
    "optionBuySell": [
      "Buy",
      "Sell",
      "Sell",
      "Buy"
    ],
    "optionCallPut": [
      "Put",
      "Put",
      "Call",
      "Call"
    ]
  }
},
]