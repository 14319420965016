import React, { useRef, useState, useEffect } from 'react';
import { PROFILE_IMAGE_UPLOAD, GET_PROFILE_IMAGE } from '../../constant/constant'
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import Person3Icon from '@mui/icons-material/Person3';
import { toast } from "react-toastify";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { IoPersonSharp } from "react-icons/io5";

const ProfileUpload = (props) => {
  const inputRef = useRef(null);
  const [image, setImage] = useState(null);

  const handleClickImage = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleProfileImage = async (event) => {
    const file = event.target.files?.[0];
    const token = secureLocalStorage.getItem('token');
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImage(e.target?.result);
      };
      reader.readAsDataURL(file);

      // Upload the image
      const userId = secureLocalStorage.getItem('id');
      const formData = new FormData();
      formData.append('file', file);
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${PROFILE_IMAGE_UPLOAD}/${userId}`,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          "Authorization": `Bearer ${token}`, // Use correct header field name
        }
      };
      try {
        const response = await axios(config);
        secureLocalStorage.setItem('profilePic', response?.data?.profilePic);
        props.setProfilePic(response?.data?.profilePic);
        Download_Profile_Image();
        console.log('File uploaded successfully');
      } catch (error) {
        toast(error.response.data.message);
        console.error('Error uploading file:', error);
      }
    }
  }
  const Download_Profile_Image = async () => {

    const profilePic = secureLocalStorage.getItem('profilePic');
    if(profilePic){
    const imgUrl = `${GET_PROFILE_IMAGE}/${profilePic}`;
    setImage(imgUrl);
    }
  }
  useEffect(() => {
    Download_Profile_Image();
  }, [])

  return (
    <>
      <div className="flex flex-col relative" onClick={handleClickImage}>
        <div className='absolute top-20 right-0 bg-[#1814F3] text-white rounded-full'><MdDriveFileRenameOutline/></div>
        {image ? (  <img src={image.toString()} alt="profile" className="rounded-full w-[130px] h-[130px] mb-4 border-2 border-black" />) : (
          <IoPersonSharp className="rounded-full border-2 mb-4" style={{ height: "130px", width: "130px" }} />)}
        <input type="file" ref={inputRef} className="mt-10" style={{ display: 'none' }} onChange={handleProfileImage}/>
      </div>
    </>
  );
};

export default ProfileUpload;
