import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './protected/ProtectedRoute';
import CircularProgress from '@mui/material/CircularProgress';
import './App.css'

import Login from '../src/Page/Layout/Login';
import Signup from '../src/Page/Layout/Signup';
import ResetPassword from '../src/Page/Layout/Reset_Password';
import ForgetPassword from '../src/Page/Layout/ForgetPassword';
import Profile from '../src/Page/Profile/Profile';
import Page_404 from '../src/Page/Layout/Page_404';
import AdminPortal from './Page/Admin/AdminPortal.jsx';
import Backtest from './Page/Backtest/Features/Backtest';
import Home from './Page/Bucket/Home';
import Notes from './Page/Layout/Notes';
import Plan from './Page/Plan/Plan.jsx';
import SuccessPaymentPage from './Page/Plan/SuccessPaymentPage';
import FailurePaymentPage from './Page/Plan/FailurePaymentPage';
import Main from './Page/Layout/Home/Index.jsx';
import Gold from './Page/Gold/Gold.jsx';
import Series from './Page/Series/Series';
import TradingProfile from './Page/Profile/TradingProfile';
import Share from './Page/Share/Share';
import About from './About/About.jsx'
import Contact from './Page/Layout/Contact.jsx';

// Lazy-loaded components
// const Login = lazy(() => import('../src/Page/Layout/Login'));
// const Signup = lazy(() => import('../src/Page/Layout/Signup'));
// const ResetPassword = lazy(() => import('../src/Page/Layout/Reset_Password'));
// const ForgetPassword = lazy(() => import('../src/Page/Layout/ForgetPassword'));
// const Profile = lazy(() => import('../src/Page/Profile/Profile'));
// const Page_404 = lazy(() => import('../src/Page/Layout/Page_404'));
// const AdminPortal = lazy(() => import('./Page/Admin/AdminPortal'));
// const Backtest = lazy(() => import('./Page/Backtest/Features/Backtest'));
// const Home = lazy(() => import('./Page/Bucket/Home'));
// const Notes = lazy(() => import('./Page/Layout/Notes'));
// const Plan = lazy(() => import('./Page/Admin/Plan.jsx'));
// const SuccessPaymentPage = lazy(() => import('./Page/Plan/SuccessPaymentPage'));
// const FailurePaymentPage = lazy(() => import('./Page/Plan/FailurePaymentPage'));
// const Margin = lazy(() => import('./Page/Margin/Margin'));
// const Main = lazy(() => import('./Page/Layout/Home/Index.jsx'));
// const Gold = lazy(() => import('./Page/Gold/Gold.jsx'));
// const Input = lazy(()=> import('./Page/LiveTrading/Input/Input.jsx'))
// const Series = lazy(() => import('./Page/Series/Series'));
// const Dashboard = lazy(() => import('./Page/LiveTrading/Dashboard/Dashboard'));
// const TradingProfile = lazy(() => import('./Page/Profile/TradingProfile'));
// const Share = lazy(() => import('./Page/Share/Share'));

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Suspense fallback={<div className='pt-3 text-center'><CircularProgress /></div>}>
          <Routes>
            <Route path='/backtest/backtest' element={<Backtest />} />
            <Route path='/backtest/login' element={<Login />} />
            <Route path='/backtest/signup' element={<Signup />} />
            <Route path='/backtest/profile' element={<Profile />} />
            <Route path='/backtest/forget_password' element={<ForgetPassword />} />
            <Route path='/backtest/reset_password/:email/:token' element={<ResetPassword />} />
            <Route path='/backtest/plan' element={<Plan/>} />
            <Route path='/backtest/admin' element={<AdminPortal />} />
            <Route path='/backtest/stratscompare' element={<Home />} />
            <Route path='/backtest/note' element={<ProtectedRoute component={Notes} />} />
            <Route path='/backtest/success' element={<SuccessPaymentPage />} />
            <Route path='/backtest/failure' element={<FailurePaymentPage />} />
            <Route path='/*' element={<Page_404 />} />
            <Route path='/' element={<Main/>} />
            <Route path='/backtest' element={<Main/>} />
            <Route path='/backtest/series' element={<ProtectedRoute component={Series} />} />
            <Route path='/backtest/gold' element={<ProtectedRoute component={Gold} />} />
            <Route path='/backtest/about' element={<About/>} />
            <Route path='/backtest/contact' element={<Contact/>} />


            {/* For Live Trading */}
            <Route path='/backtest/tradingProfile' element={<TradingProfile />} />
            <Route path='/backtest/share/:id' element={<Share />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
};

export default App;
