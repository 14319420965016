
const Why = () => {
  return (
    <div className="p-4 sm:p-6 md:p-8 bg-white rounded-lg shadow-lg">
      {/* Title Section */}
      <div className="text-2xl sm:text-3xl md:text-4xl font-semibold text-center text-black mb-6">
        Fprognos?
      </div>

      {/* Introduction Section */}
      <p className="text-sm sm:text-base md:text-xl text-black leading-relaxed mb-8">
        Fprognos is more than just a name; it represents our mission and our values.
        We chose this name to reflect the two most powerful concepts in both language and finance.
        <span className="font-semibold"> "F" </span>
        stands for the foresight and future-focused nature of our platform, while
        <span className="font-semibold"> "prognos" </span>
        comes from the Greek word for "knowledge" or "prediction."
        Together, Fprognos symbolizes the critical juncture where your trading knowledge meets the future,
        accelerating you to a new level of growth and financial freedom.
      </p>

      {/* Key Features Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-6">
        <div className="bg-gray-100 p-3 sm:p-4 rounded-lg shadow-md">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-indigo-600 mb-2">Innovative Technology</h3>
          <p className="text-gray-700 text-sm sm:text-base">Our platforms are built with the latest technology, ensuring high performance and reliability.</p>
        </div>
        <div className="bg-gray-100 p-3 sm:p-4 rounded-lg shadow-md">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-indigo-600 mb-2">Comprehensive Tools and Resources</h3>
          <p className="text-gray-700 text-sm sm:text-base">Access a wide range of tools and resources designed to enhance your trading strategies and decisions.</p>
        </div>
        <div className="bg-gray-100 p-3 sm:p-4 rounded-lg shadow-md">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-indigo-600 mb-2">User-Centric Design</h3>
          <p className="text-gray-700 text-sm sm:text-base">Our platforms feature an intuitive design, tailored to provide a seamless user experience.</p>
        </div>
        <div className="bg-gray-100 p-3 sm:p-4 rounded-lg shadow-md">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-indigo-600 mb-2">Unmatched Support and Community</h3>
          <p className="text-gray-700 text-sm sm:text-base">Benefit from 24/7 support and join a vibrant community of traders.</p>
        </div>
        <div className="bg-gray-100 p-3 sm:p-4 rounded-lg shadow-md">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-indigo-600 mb-2">Commitment to Security</h3>
          <p className="text-gray-700 text-sm sm:text-base">We prioritize the security of your data with advanced encryption and security protocols.</p>
        </div>
        <div className="bg-gray-100 p-3 sm:p-4 rounded-lg shadow-md">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-indigo-600 mb-2">Proven Track Record</h3>
          <p className="text-gray-700 text-sm sm:text-base">Our success stories and industry recognitions speak to our effectiveness and reliability.</p>
        </div>
        <div className="bg-gray-100 p-3 sm:p-4 rounded-lg shadow-md">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-indigo-600 mb-2">Cost-Effective Solutions</h3>
          <p className="text-gray-700 text-sm sm:text-base">We offer competitive pricing that provides excellent value for your investment.</p>
        </div>
        <div className="bg-gray-100 p-3 sm:p-4 rounded-lg shadow-md">
          <h3 className="text-lg sm:text-xl md:text-2xl font-semibold text-indigo-600 mb-2">Forward-Thinking Vision</h3>
          <p className="text-gray-700 text-sm sm:text-base">We continuously innovate to ensure our solutions meet the evolving needs of the trading landscape.</p>
        </div>
      </div>
    </div>
  );
};

export default Why;
