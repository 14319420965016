import React,{useEffect, useState} from 'react'
import { CONTACT ,USER_BILLING_DETAIL } from '../../constant/constant';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { ToastContainer,toast } from 'react-toastify';
import { MdDeleteForever } from "react-icons/md";

const Contact = () => {
  const [contactList,setContactList]=useState([])
  const [email,setEmail]=useState("");
  const [userBillingHistory,setUserBillingHistory]=useState([])
 
  const getContact=async()=>{
    const token = secureLocalStorage.getItem('token');
    const config = {
      method: 'get',
      url: CONTACT,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`},
    };
    try {
      const response=await axios(config);
      console.log(JSON.stringify(response),'response')
      setContactList(response?.data);
    } catch (error) {
      if (error?.response?.data?.detail) toast(error.response.data.detail);
      else toast('Try Again Later');
    }
  }
  const deleteContact=async(id)=>{
    const token = secureLocalStorage.getItem('token');
    const config = {
      method: 'delete',
      url: `${CONTACT}/${id}`,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
    };
    try {
      await axios(config);
      setContactList(contactList.filter((item)=> (item._id!==id)))
      toast.success('Successfully Deleted')
    } catch (error) {
      if (error?.response?.data?.detail) toast(error.response.data.detail);
      else toast('Try Again Later');
    }
  }
  const getUserBillingDetail=async()=>{
    const token = secureLocalStorage.getItem('token');
    const data = { "email": email }
    const config = {
      method: 'post',
      url: USER_BILLING_DETAIL,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, },
      data: data
    };
    try {
      const response=await axios(config);
      console.log(JSON.stringify(response),'user billing history')
      setUserBillingHistory(response?.data?.data);
    } catch (error) {
      if (error?.response?.data?.detail) toast(error.response.data.detail);
      else toast('Try Again Later');
    }
  }
  useEffect(()=>{
    getContact();
  },[])
  return (
    <>
    <ToastContainer/>
    <div className='grid grid-cols-2'>
      {/* --------------contact list----------- */}
      <div className='flex flex-col rounded-lg m-2 bg-white min-h-[400px]'>
        <div className='text-xl font-xl text-center justify-center font-semibold'>List of Contact Details</div>
        {
          contactList && contactList.map((item,key)=>{
            return(
              <div className='border-[1px] rounded-lg p-2 m-1' key={`contact-${key}`}>
                <div className='grid grid-cols-2'>
                  <div className='font-semibold'>S.No</div>
                  <div className='border-[1px] p-[2px]'>{key}</div>
                </div>
                <div className='grid grid-cols-2 mt-1'>
                  <div className='font-semibold'>Email:</div>
                  <div className='border-[1px] p-[2px]'>{item.email}</div>
                </div>
                <div className='grid grid-cols-2 mt-1'>
                  <div className='font-semibold'>Mobile Number:</div>
                  <div className='border-[1px] p-[2px]'>{item.phone}</div>
                </div>
                <div className='grid grid-cols-2 mt-1'>
                  <div className='font-semibold'>Message:</div>
                  <div className='border-[1px] p-[2px]'>{item.message}</div>
                </div>
                <div className='float-right'>
                <MdDeleteForever className="text-red-500 cursor-pointer text-2xl" onClick={()=>{deleteContact(item._id);}}/>
                </div>
              </div>
            )
          })
        }
      </div>
      {/* ----------------Find the detail of coin history -------------- */}
      <div className='flex flex-col rounded-lg m-2 bg-white min-h-[400px]'>
      <input type="text" placeholder='Please Enter User Email Address' className='border-[1px] outline-none p-1 m-2' value={email} onChange={(e)=>{setEmail(e.target.value);}}/>
      <div className='text-white bg-blue-500 text-center justify-center w-32 rounded-md m-2 cursor-pointer' onClick={()=>{getUserBillingDetail();}}>submit</div>
      <hr className='text-xl bg-blue-600'></hr>
      {
        userBillingHistory.map((item,key)=>{
          return(
            <div className='flex flex-col border-[1px] rounded-md p-2 m-1'>
              <div className=''><span className='font-semibold text-l'>S.No. :</span>{key+1}</div>
              <div className=''><span className='font-semibold text-l'>Coin :</span> {item.Coin} </div>
              <div className=''><span className='font-semibold'>Reason :</span> {item.Reason} </div>
              <div className=''><span className='font-semibold'>Timestamp :</span> {item.Date}&nbsp;&nbsp;{item.Time} </div>
            </div>
          )
        })
      }
      </div>
    </div>
    </>
  )
}

export default Contact