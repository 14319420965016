import { create } from 'zustand';
import {devtools, persist} from 'zustand/middleware'

const bucketStore = (set) => ({
    activeBasketName:'',
    sidebarVisible:false,
    boxShow:false,
    bucketName:'',
    savedstrategies:[],
    activeBasket:[],
    basketList:[],
    bucketStartDate:"2020-01-03",
    bucketEndDate:"2020-02-03",
    strategyChecked:[],
    include:[],
    strategyMultiplier:[],
    // result of bucket
    fullMonth:[],
    fullWeek:[],
    originalResult:[],

    // For Updating active BasketName
    updateActiveBasketName:(name) => { 
        set((state) => (
           { activeBasketName:name }
        ))
    },
    updateSidebarVisible:(data)=>{
        set((state)=>(
            { sidebarVisible:data}
        ))
    },
    updateBoxShow:(data)=>{
        set((state)=>(
            {boxShow:data}
        ))
    },
    updateSavedStrategies:(data)=>{
        set((state)=>(
            {savedstrategies:data}
        ))
    },
    updateDayOptionStrategyOnActiveBasket: (data, index) => {
       set((state) => ({
        activeBasket: {
          ...state.activeBasket,
          strategy: state.activeBasket.strategy.map((item, i) =>
            i === index ? { ...item, dayoption: data } : item
          )
        }
      }));
      },     
      updateDayVariantStrategyOnActiveBasket: (data, index) => {
        set((state) => ({
          activeBasket: {
            ...state.activeBasket,
            strategy: state.activeBasket.strategy.map((item, i) =>
              i === index ? { ...item, variant: data } : item
            )
          }
        }));
      },
    deleteStrategiesOnActiveBasket: (data) => {
        set((state) => ({
          activeBasket: {
            ...state.activeBasket,
            strategy:data
          }
        }))
    },
    addStrategiesOnActiveBasket: (data) => {
        set((state) => ({
          activeBasket: {
            ...state.activeBasket,
            strategy: [...state.activeBasket.strategy, data]
          }
        }))
      },
    updateActiveBasket:(data)=>{
        set((state)=>(
            {activeBasket:data}
    ))
    },
    updateBasketList:(data)=>{
        set((state)=>(
            {basketList:data}
        ))
    },
    updateBucketStartDate:(data)=>{
        set((state)=>({
            bucketStartDate:data
        }))
    },
    updateBucketEndDate:(data)=>{
        set((state)=>({
            bucketEndDate:data
        }))
    },
    updateStrategyChecked:(data)=>{
      set((state)=>(
        {strategyChecked:data}
      ))
    },
    updateStatsInclude:(data)=>{
      set((state)=>(
        {include:data}
      ))
    },
   
  updateFullMonth: (data) => {
    set({ fullMonth: data });
  },
  updateFullWeek: (data) => {
    set({ fullWeek: data });
  },
    updateOriginalResult:(data)=>{
      set({originalResult:data})
    },
    updateStatsMultiplier:(data)=>{
      set(()=>(
        {strategyMultiplier:data}
      ))
    }
   
})

// Now,We will make a above code as a store and perform action in another component to perform it.
const useBucketStore = create(
    devtools(
        persist(bucketStore, {
            name: "bucket",
        })
    )
)


export default useBucketStore;