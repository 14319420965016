import React, { useState, useEffect } from 'react'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage';
import { toast } from 'react-toastify'
import {FETCH_BASKET_NAME } from '../../constant/constant';
import useBucketStore from '../../store/BucketStore';
import {useNavigate} from 'react-router-dom'
import { IoMdAdd } from "react-icons/io";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";

const Top = (props) => {
  // use of zustand to Store Management
  const {basketList,updateBasketList}=useBucketStore();
  const {sidebarVisible,updateSidebarVisible}=useBucketStore();
  const {activeBasketName,updateActiveBasketName}=useBucketStore();
  const {activeBasket,updateActiveBasket}=useBucketStore();
  const {boxShow,updateBoxShow}=useBucketStore();
  const [visibleBasket,setVisibleBasket]=useState(false);
  const [visibleRange, setVisibleRange] = useState(false);
  const [activeRange,setActiveRange]=useState(1);
  const navigate=useNavigate();


  const fetchBasketName = () => {
    const id = secureLocalStorage.getItem("id");
    const token=secureLocalStorage.getItem('token');
    let config = { method: "get", maxBodyLength: Infinity, url: `${FETCH_BASKET_NAME}/${id}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` } };
    axios
      .request(config)
      .then((response) => {
        updateBasketList(response?.data?.result);
        if(response?.data?.result.length>0)
        {
          updateActiveBasket(response?.data?.result[0]);
          updateActiveBasketName(response?.data?.result[0].bucket_name);
        }
      })
      .catch((error) => {
        if(error?.response?.status===401){
           navigate('/backtest/login');
        }else if(error?.response?.data?.message){
          toast.error(error.response.data.message)
        }else{
          toast.error('Network Issue');
        }
        console.log(error,'error at sidebar')
      });
  }

  function updateDate(numMonths) {
    // Get current date
    let currentDate = new Date();
    props.setBucketEndDate(formatDate(currentDate));
    // Subtract months from current date
    currentDate.setMonth(currentDate.getMonth() - numMonths);
    currentDate.setDate(currentDate.getDate()-1)
    // Format current date
    let formattedCurrentDate = formatDate(currentDate);
    props.setBucketStartDate(formattedCurrentDate);  
  }

  function formatDate(date) {
    // Get year, month, and day from date
    let year = date.getFullYear();
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    let day = String(date.getDate()).padStart(2, '0');
    // Return formatted date
    return `${year}-${month}-${day}`;
  }

  // const updateDate = (date, numMonthsToAdd) => {
  //   var inputDate = new Date(date);
  //   // Calculate the new date
  //   inputDate.setMonth(inputDate.getMonth() + numMonthsToAdd);
  //   // Format the new date as 'YYYY-MM-DD'
  //   var year = inputDate.getFullYear();
  //   var month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
  //   var day = String(inputDate.getDate()).padStart(2, '0');

  //   var newDate = `${year}-${month}-${day}`;
  //   return newDate;
  // }
  useEffect(() => {
    fetchBasketName();
  }, [JSON.stringify(props.BasketList)])
  

  
  return (
    <>
    <div className="w-full bg-white border-t-2 min-h-[32px] flex flex-wrap text-center justify-center gap-2">
      <hr></hr>
      <div className='text-black flex flex-row relative h-7 pl-1 pr-1 text-center justify-center' onMouseLeave={() => { setVisibleBasket(false); }} onMouseEnter={() => { setVisibleBasket(true); }}>
      <div className='font-[500] font-[CircularStd] hover:underline'>Strats</div>
      {visibleBasket && <FaArrowDown className='text-xl font-semibold'/>}
      {!visibleBasket && <FaArrowUp className='text-xl font-semibold'/>}
      {visibleBasket && <div className="flex flex-col absolute top-7 overflow-auto h-[360px] w-[180px] bg-white rounded-lg">
        {
          basketList.length>0 && basketList.map((item, key) => {
            return (
              <div className="font-serif text-[16px] text-black border-[1px] hover:font-[600] justify-center p-1 rounded cursor-pointer" key={`basketlist-${key}`} style={{backgroundColor:activeBasketName===item.bucket_name?'#2D5BFF':'white',color:activeBasketName===item.bucket_name?'white':'black'}} onClick={() => { updateActiveBasket(item); updateActiveBasketName(item.bucket_name);}}>
                {item.bucket_name}
              </div>
            )
          })
        }
        {
           basketList.length==0 && <div className='text-[18px] rounded-lg border-2 h-[360px] flex justify-center text-center font-[400] pt-10'>No Saved Strats</div>
        }
      </div>}
      </div>
      <div className='text-black flex flex-row relative h-7 pl-1 pr-1 text-center justify-center' onMouseLeave={() => { setVisibleRange(false); }} onMouseEnter={() => { setVisibleRange(true); }} >
          <div className="font-[500] font-[CircularStd] hover:underline">Period</div>
          {visibleRange && <FaArrowDown className='text-xl font-semibold'/>}
          {!visibleRange && <FaArrowUp className='text-xl font-semibold'/>}
          {
          visibleRange && <div className='absolute top-7 '>
          <div className="flex flex-col text-l  bg-white text-black w-[160px] border-[1px] rounded-lg">
            <div className="cursor-pointer text-[16px] font-[400] hover:font-[600] p-1 text-left border-t-[1px] rounded-lg flex flex-row" style={{backgroundColor:activeRange=='1'?'#2D5BFF':'white',color:activeRange=='1'?'white':'black'}} onClick={() => { updateDate(1); setActiveRange('1') }}><MdDateRange  className='text-2xl'/>  1 Month</div>
            <div className="cursor-pointer text-[16px] font-[400] hover:font-[600] p-1 text-left border-t-[1px] rounded-lg flex flex-row" style={{backgroundColor:activeRange=='2'?'#2D5BFF':'white',color:activeRange=='2'?'white':'black'}} onClick={() => { updateDate(3); setActiveRange('2') }}><MdDateRange  className='text-2xl'/>  3 Month</div>
            <div className="cursor-pointer text-[16px] font-[400] hover:font-[600] p-1 text-left border-t-[1px] rounded-lg flex flex-row" style={{backgroundColor:activeRange=='3'?'#2D5BFF':'white',color:activeRange=='3'?'white':'black'}} onClick={() => { updateDate(6); setActiveRange('3') }}><MdDateRange  className='text-2xl'/>  6 Month</div>
            <div className="cursor-pointer text-[16px] font-[400] hover:font-[600] p-1 text-left border-t-[1px] rounded-lg flex flex-row" style={{backgroundColor:activeRange=='4'?'#2D5BFF':'white',color:activeRange=='4'?'white':'black'}} onClick={() => { updateDate(9); setActiveRange('4') }}><MdDateRange  className='text-2xl'/>  9 Month</div>
            <div className="cursor-pointer text-[16px] font-[400] hover:font-[600] p-1 text-left border-t-[1px] rounded-lg flex flex-row" style={{backgroundColor:activeRange=='5'?'#2D5BFF':'white',color:activeRange=='5'?'white':'black'}} onClick={() => { updateDate(12); setActiveRange('5') }}><MdDateRange  className='text-2xl'/>  1 Year</div>
            <div className="cursor-pointer text-[16px] font-[400] hover:font-[600] p-1 text-left border-t-[1px] rounded-lg flex flex-row" style={{backgroundColor:activeRange=='6'?'#2D5BFF':'white',color:activeRange=='6'?'white':'black'}} onClick={() => { updateDate(24); setActiveRange('6') }}><MdDateRange  className='text-2xl'/>  2 Year</div>
            <div className="cursor-pointer text-[16px] font-[400] hover:font-[600] p-1 text-left border-t-[1px] rounded-lg flex flex-row" style={{backgroundColor:activeRange=='7'?'#2D5BFF':'white',color:activeRange=='7'?'white':'black'}} onClick={() => { updateDate(36); setActiveRange('7') }}><MdDateRange  className='text-2xl'/>  3 Year</div>
            <div className="cursor-pointer text-[16px] font-[400] hover:font-[600] p-1 text-left border-t-[1px] rounded-lg flex flex-row" style={{backgroundColor:activeRange=='8'?'#2D5BFF':'white',color:activeRange=='8'?'white':'black'}} onClick={() => { updateDate(48); setActiveRange('8') }}><MdDateRange  className='text-2xl'/>  4 Year</div>
          </div></div>
          }
        </div>
        <div className='text-black pl-1 pr-1 h-7 w-28 cursor-pointer font-[CircularStd] hover:underline flex flex-row' onClick={()=>{updateBoxShow(true);}}><IoMdAdd className='font-semibold text-2xl'/>Add&nbsp;Strats</div>
        {/* <div className='text-black pl-1 pr-1 h-7 w-36 cursor-pointer font-[500] font-[CircularStd]' onClick={()=>{updateBoxShow(true);}}><DeleteIcon style={{height:"18px"}}/>Delete&nbsp;Basket</div> */}
        <div className='text-black pl-1 pr-1 h-7 text-center justify-center hidden md:block'>
          <div className='font-[500] font-[CircularStd]'>Start Date: {props.bucketStartDate} &nbsp;&nbsp;End Date:&nbsp; {props.bucketEndDate}</div>
        </div>
    </div>
    </>
  )
}

export default Top;