import React, { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip';
import Joyride from 'react-joyride';
import { IoMdAdd } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { IoMdHelpCircle } from "react-icons/io";
import { IoMdSettings } from "react-icons/io";


const Main = (props) => {
  const BN_QUANT = 25;
  const [stopLossVisible, setStoplossVisible] = useState(false);
  const [maxProfitVisible, setMaxProfitVisible] = useState(false);

  const handleNumber = (event, update) => {
    const inputValue = event.target.value;
    // Regular expression to match numbers with optional decimal point
    const regex = /^(\d+\.?\d*|\.\d+)$/;
    if (regex.test(inputValue) || inputValue === '') {
      update(inputValue);
    }
  };


  return (
    <div>
      {props.runTour && <Joyride
        steps={props.tourSteps}
        run={props.runTour}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        disableOverlayClose={true}
        callback={props.handleJoyrideCallback}
      />}
      <div className="m-2 mt-[110px] sm:mt-[120px] pt-4 border-blue-400 grid grid-cols-1 lg:grid-cols-2 gap-2 sm:m-2">
        <div className='text-[white] text-xl lg:col-span-2 bg-blue-500 rounded-tl-lg rounded-tr-lg text-center'>Strategical Instrument</div>
        <div className="rounded-xl pb-5 flex flex-col gap-2 bg-white">
          <div className='feature-5 grid grid-cols-1 sm:grid-cols-2 p-2 rounded-t-xl bg-gray-100'>
            {/* Handle Trade Symbol here.. */}
            <div className="flex flex-row">
              <div className="mt-3 w-32">Symbols</div>
              <div className="grid grid-cols-2 text-center justify-center mr-2 ml-2 sm:mr-5 mt-2  w-[200px] rounded-xl border-[1px] border-blue-500">
                <div style={{ backgroundColor: props.tradesymbol === "NIFTY" ? "#DAECF2" : "#FAF9F6" }} className="h-8 p-1 rounded-l-xl text-center cursor-pointer" onClick={() => { props.settradesymbol("NIFTY"); props.setgap(50); props.setLotQuantity(50); }}>
                  Nifty
                </div>
                <div style={{ backgroundColor: props.tradesymbol === "BANKNIFTY" ? "#DAECF2" : "#FAF9F6" }} className="h-8 p-1 rounded-r-xl text-center cursor-pointer" onClick={() => { props.settradesymbol("BANKNIFTY"); props.setgap(100); props.setLotQuantity(BN_QUANT); }}>
                  Banknifty
                </div>
              </div>
            </div>

            {/* Handle Intraday  */}
            <div className="flex flex-row">
              <div className="mt-3 w-32 ">Intraday</div>
              <div className="grid grid-cols-2 text-center justify-center mr-2 ml-2 sm:mr-5 mt-2 w-[200px] rounded-xl  border-[1px] border-blue-500">
                <div style={{ backgroundColor: props.intraday == 'Yes' ? "#DAECF2" : "#FAF9F6" }} className="h-8 p-1 rounded-l-xl cursor-pointer"
                  onClick={() => { props.setintraday("Yes"); }}>Yes</div>
                <div style={{ backgroundColor: props.intraday == 'No' ? "#DAECF2" : "#FAF9F6" }} className="h-8 p-1 rounded-r-xl cursor-pointer"
                  onClick={() => { props.setintraday("No"); }}>No</div>
              </div>
            </div>

           
          </div>
          <div className='feature-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 p-2 bg-gray-100 h-full'>
            <div className="flex flex-row mt-3 mr-2 "><div className='flex flex-row w-40 '>Use Spot as ATM</div>
              <input type="checkbox" className="" checked={props.atmprice === "spot"} value="spot"
                onChange={(e) => { props.setatmprice(props.atmprice === "spot" ? "future" : "spot"); }} ></input> </div>

            <div className="flex flex-row  mt-3 mr-2 "> <div className='flex flex-row w-40 '>Use Future as ATM</div>
              <input type="checkbox" className="" checked={props.atmprice === "future"} onChange={(e) => { props.setatmprice(props.atmprice === "spot" ? "future" : "spot"); }}
              ></input> </div>

            <div className="flex flex-row  mt-3 mr-2 "><div className='flex flex-row w-40 '>Square Off One Leg <div data-tooltip-id="my-tooltip" data-tooltip-content="If either the Stop Loss or Target Profit conditions are met, only that specific position will be automatically closed.."><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
              <input type="checkbox" className="" checked={props.squareoff === "one"} onChange={(e) => {
                props.setsquareoff(props.squareoff === "one" ? "all" : "one");
              }} ></input> </div>

            <div className="flex flex-row  mt-3 mr-2 "><div className='flex flex-row w-40 '>Square Off All Leg <div data-tooltip-id="my-tooltip" data-tooltip-content="If either the Stop Loss or Target Profit conditions for any position are met, the entire strategy will be automatically closed"><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
              <input type="checkbox" className="" checked={props.squareoff === "all"} onChange={(e) => {
                props.setsquareoff(props.squareoff === "one" ? "all" : "one"); props.squareoff == 'one' && props.setSlToCost(false);
              }} ></input> </div>

            <div className="flex flex-row  mt-3 mr-2 ">
              <div className='flex flex-row w-44 '>Wait For Momentum <div data-tooltip-id="my-tooltip" data-tooltip-content="After the entry time, the position will wait for the premium to either increase or decrease by a specific percentage or point before entering, as specified in the notes"><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div>
              </div><input type="checkbox" className="" checked={props.waitTrade} onChange={(e) => {
                props.setwaitTrade(e.target.checked);
                props.setLegsStoplossType(Array(props.leg).fill('SL%'));
              }} ></input> </div>

            {props.intraday == 'Yes' && <div className="flex flex-row  mt-3 mr-2 "><div className='flex flex-row w-40 '>RangeBreakOut  <div data-tooltip-id="my-tooltip" data-tooltip-content="Sell order will be entered once low of the Range breaks and Buy Orders will be entered once High of the Range breaks."> <IoMdHelpCircle className="text-[#0096FF] text-2xl"/> </div></div>
              <input type="checkbox" className="" checked={props.showRangeBreakout} onChange={(e) => {
                props.setShowRangeBreakout(!props.showRangeBreakout); 
              }} ></input> </div>}

          </div>
        </div>

        <div className="flex flex-col border-[1px] gap-2 bg-white rounded-xl">
          <div className="feature-8  p-3 flex text-center justify-center text-xl rounded-t-xl bg-gray-100 text-blue-500 cursor-pointer" onClick={() => { props.setSetting(true); }}><IoMdSettings className='mt-1' /> Lot Size Setting</div>
          <div className="feature-10 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 p-2 bg-gray-100">

            {props.squareoff == 'one' && <div className='flex flex-row mt-3'>
              <input type="checkbox" className='flex justify-end mr-4' checked={props.reEntry} onChange={(e) => { props.setReEntry(e.target.checked); }}></input>
              <div className='flex flex-row w-44 '>Re-Entry/ReExecute<div data-tooltip-id="my-tooltip" data-tooltip-content="This will trigger a Re-Entry/Re-Execution opportunity for each position. Please refer to the notes for further clarification"><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
            </div>}

            {props.squareoff == 'all' && <div className='flex flex-row mt-3'><input type="checkbox" className='flex justify-end mr-4' checked={props.reEntry} onChange={(e) => { props.setReEntry(e.target.checked); }}
            ></input>Re-Execu<div className='flex flex-row'>
                te
                <select className='w-10 border-[1px] rounded-md outline-none' value={props.reExecuteTimes} onChange={(e) => { props.setReExecuteTimes(parseInt(e.target.value)); }}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select><div data-tooltip-id="my-tooltip" data-tooltip-content="This will trigger a Re-Entry/Re-Execution opportunity for each position. Please refer to the notes for further clarification"><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
            </div>}

            {props.squareoff == 'one' && <div className="flex flex-row mt-3" >
              <input type="checkbox" className='flex justify-end mr-4' disabled={props.disableMoveSlToCost} checked={props.slToCost} onChange={(e) => { props.setSlToCost(e.target.checked); }}></input>
              <div className='flex flex-row w-44 '>Move Sl To Cost<div data-tooltip-id="my-tooltip" data-tooltip-content="In the strategy, each leg must be either a buy or a sell, and the 'Square Off All Legs' option must not be selected."> <IoMdHelpCircle className="text-[#0096FF] text-2xl" /> </div> </div>
            </div>}

            <div className="flex flex-row">
              <div className="mt-3 w-36">Segment</div>
              <div className="grid grid-cols-2 text-center justify-center text-black mt-2 mr-2 ml-2 sm:mr-5 sm:ml-5 w-36 border-[1px] rounded-xl border-blue-500">
                <div style={{ backgroundColor: props.segment === "option" ? "#DAECF2" : "#FAF9F6" }} className="h-8 p-1 rounded-l-xl text-center cursor-pointer" onClick={() => { props.setSegment("option") }}> Option </div>
                <div style={{ backgroundColor: props.segment === "future" ? "#DAECF2" : "#FAF9F6" }} className="h-8 p-1 rounded-r-xl text-center cursor-pointer" onClick={() => { props.setSegment("future") }}> Future </div>
              </div>
            </div>

          </div>

          {/* Here,We will handle some more stuff as per demand*/}
          <div className='feature-7 grid grid-cols-1 sm:grid-cols-2 gap-2'>
            <div className="grid grid-cols-2 bg-gray-100 p-2 m-1">
              <div className="mt-2 w-32 mr-2 text-l ">Strategy Profit</div>
              <div className="mt-2">
                {maxProfitVisible && <div className='flex flex-row flex-grow'>
                  <input type="text" value={props.maxprofit} onChange={(e) => { handleNumber(e, props.setmaxprofit); }} className="h-8 bg-[#E3F6FC] border-[1px] rounded-lg outline-0 border-blue-500 w-full text-center"></input>
                  <MdClose className='mt-1 cursor-pointer text-3xl text-red-600' onClick={() => { props.setmaxprofit(-1); setMaxProfitVisible(false); }} /></div>
                }
                {!maxProfitVisible && <div className="w-full text-blue-600 flex text-center justify-center cursor-pointer border-[1px] flex-grow rounded-md border-blue-500"><IoMdAdd className='mt-1 text-2xl font-semibold' onClick={() => { props.setmaxprofit(1000); setMaxProfitVisible(true); }} /></div>}
              </div>
            </div>
            <div className="grid grid-cols-2 font-medium bg-gray-100 p-2 m-1">
              <div className="mt-2 w-full mr-2 sm:ml-2 "> Strategy&nbsp;StopLoss</div>
              <div className="mt-2">
                {stopLossVisible && <div className='flex flex-row'>
                  <input type="text" value={props.stoploss} onChange={(e) => { handleNumber(e, props.setstoploss); }} className="h-8 bg-[#E3F6FC] border-[1px] rounded-lg outline-0 border-blue-500 w-full text-center"></input>
                  <MdClose className='mt-1 cursor-pointer text-3xl text-red-600' onClick={() => { props.setstoploss(-1); setStoplossVisible(false); }} /></div>
                }
                {!stopLossVisible && <div className="w-full text-blue-600 flex text-center justify-center cursor-pointer border-[1px] flex-grow rounded-md border-blue-500"><IoMdAdd className='mt-1 text-2xl font-semibold' sx={{ marginLeft: "20px", marginTop: "4px" }} onClick={() => { props.setstoploss(1000); setStoplossVisible(true); }} /></div>}
              </div>
            </div>
          </div>

        </div>
      </div>

      {/* Add LEG */}
      <div className="ml-2 mb-8 flex items-center space-x-4">
        <select
          className="px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition-colors duration-200"
          value={props.segment}
          onChange={(e) => props.setSegment(e.target.value)}
        >
          <option value="option">Option</option>
          <option value="future">Future</option>
        </select>

        <button
          className="text-white bg-blue-500 hover:bg-blue-600 px-5 py-2 rounded-lg font-medium shadow-md transition-transform duration-200 transform hover:scale-105"
          onClick={props.addleg}
        >
          Add Leg
        </button>
      </div>





      <Tooltip id="my-tooltip" style={{ width: '300px', borderRadius: '10px', backgroundColor: 'rgb(147 197 253)', color: 'black', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)' }} />
      {/* Now to handle the maximum profit loss and maximum profit upper*/}
    </div>
  )
}

export default Main