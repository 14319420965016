import React from 'react';
import Dashboard from './../Page/Layout/Home/assets/Dashboard.webp';
import office from './office.jpg';

const Name = () => {
  return (
    <div className="grid grid-cols-1 gap-4">
      {/* Background Section */}
      <div
        className='flex flex-col justify-center items-center h-[300px] lg:h-[400px] bg-cover bg-center rounded-lg shadow-lg'
        style={{ backgroundImage: `url(${Dashboard})` }}
      >
        <div className='text-2xl lg:text-4xl text-center font-bold text-white px-4'>
          "Helping traders unlock their full potential with cutting-edge financial tools and strategies."
        </div>
        <div className='text-lg lg:text-2xl text-center font-bold text-white mt-4 px-4'>
          At Fprognos, we envision a world where every trader is empowered to take control of their financial future.
        </div>
      </div>

      {/* Image Section */}
      <div className='flex justify-center -mt-12 lg:-mt-20'>
        <img 
          src={office} 
          className='w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-2xl rounded-lg shadow-lg object-cover' 
          alt="Office" 
        />
      </div>
    </div>
  );
};

export default Name;
