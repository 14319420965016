import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { ToastContainer, toast } from 'react-toastify';
import Joyride from 'react-joyride';
// import component
import NavbarVersion2 from "../../Layout/Navbar";
import ProtectProfit from "./ProtectProfit";
import Leg from "./Leg";
import Result from '../../Result/Result'
import Login1 from './../../Layout/Login1'
// constant
import { ADD_STRATEGIES, GET_STRATEGIES, UPDATE_OLD_STRATEGIES, UPDATE_STRATEGY_ARRAY, BACKTEST, NIFTY_LOT_SIZE, BANKNIFTY_LOT_SIZE, CHECK_USER_EXIST, SAVE_LIVE_TRADING, SHARE_ADD_STRATEGY, SHARE_GET_STRATEGY } from "../../../constant/constant";
// icon
import CloseIcon from '@mui/icons-material/Close';
import Top from "./Top";
import Main from "./Main";
import SettingsIcon from '@mui/icons-material/Settings';
import useBacktestStore from "../../../store/backtestStore";
// for download pdf
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useLocation } from "react-router-dom";
import DateTime from "./DateTime";


const Backtest = () => {
  const navigate = useNavigate();
  // share strategy
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('id');

  const pdfRef = useRef();
  // share strategy
  const [loading, setLoading] = useState(true);

  const name = secureLocalStorage.getItem("backtest_name");
  // Choose Option or Future
  const [segmentType, setSegmentType] = useState([]);
  const [segment, setSegment] = useState('option');
  // State for Main
  const [leg, setleg] = useState(0);
  const [intraday, setintraday] = useState("Yes");
  const [tradesymbol, settradesymbol] = useState("NIFTY");
  const [starttime, setStartTime] = useState("09:30");
  const [endtime, setEndTime] = useState("15:20");
  const [startdate, setstartdate] = useState("2022-01-01");
  const [enddate, setEndDate] = useState("2022-02-01");
  const [rate, setRate] = useState(0.5);
  const [loader, setloader] = useState(false);
  const [expirytype, setexpirytype] = useState("Weekly");
  const [atmprice, setatmprice] = useState("spot")
  const [atm, setatm] = useState([]);
  const [atmtype, setatmtype] = useState("point");
  const [squareoff, setsquareoff] = useState("one");
  const [selectedCheckbox, setSelectedCheckbox] = useState("point");
  const [LotQuantity, setLotQuantity] = useState(50);
  const [point, setpoint] = useState([]);
  const [gap, setgap] = useState(50);

  // State for Strategy
  const [strategies, setStrategies] = useState({});
  const [savedstrategies, setsavedstrategies] = useState([]);
  const [strategyName, setstrategyName] = useState("");
  const [showstrategy, setshowstrategy] = useState(false);
  const [showupdatestrategy, setshowupdatestrategy] = useState(false);
  const [activeindex, setactiveindex] = useState("");
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [showstrategy1, setshowstrategy1] = useState(false);
  const [visibleDeleteStrategy, setVisibleDeleteStrategy] = useState(false);
  const [activeStrategy, setActiveStrategy] = useState('');

  // State for Strategy Profit
  const [stoploss, setstoploss] = useState(1);
  const [maxprofit, setmaxprofit] = useState(-1);
  // State for leg included or not for backtest
  const [includeLeg, setIncludeLeg] = useState([])
  // State for leg
  const [lotarray, setlotarray] = useState([]);
  const [lotarray1, setlotarray1] = useState([]);
  const [legarray, setlegarray] = useState([]);
  const [darray, setdarray] = useState([]);
  const [legSymbol, setLegsymbol] = useState([]);
  const [legExpiryType, setLegExpiryType] = useState([])
  const [optionBuySell, setOptionBuySell] = useState([])
  const [optionCallPut, setOptionCallPut] = useState([])
  const [atmChoice, setAtmChoice] = useState([])
  // State for Positional
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(4);
  // Variable for profit
  const [legsTargetProfitType, setLegsTargetProfitType] = useState([])
  const [legsTargetProfit, setLegsTargetProfit] = useState([])
  // Variable for Stoploss
  const [legsStoplossType, setLegsStoplossType] = useState([])
  const [legsStoploss, setLegsStoploss] = useState([])
  // State for reEntry
  const [reEntry, setReEntry] = useState(false);
  const [reEntryValue, setReEntryValue] = useState([])
  const [reEntryChoice, setReEntryChoice] = useState([])
  const [legsReEntry, setLegReEntry] = useState([])
  const [reEntryType, setReEntryType] = useState([]);
  const [reExecuteTimes, setReExecuteTimes] = useState(3);
  // State for Wait Trade
  const [waitTrade, setwaitTrade] = useState(false);
  const [waitTradeType, setWaitTradeType] = useState([])
  const [waitTradeValue, setWaitTradeValue] = useState([])
  // State for Protect Profit
  const [protectProfit, setProtectProfit] = useState(false)
  const [protectType, setProtectType] = useState("Lock & Trail Profit");
  const [profitReaches, setProfitReaches] = useState("1000")
  const [lockMinimumProfit, setLockMinimumProfit] = useState("0")
  const [profitIncrease, setProfitIncrease] = useState("1000")
  const [trailProfit, setTrailProfit] = useState("300")
  // State for Move Sl to Cost
  const [slToCost, setSlToCost] = useState(false);
  const [disableMoveSlToCost, setDisableMoveSlToCost] = useState(false);
  // Variable for Closest Premium Choice
  const [closestPremiumValue, setClosestPremiumValue] = useState([])
  const [closestPremiumChoice, setClosestPremiumChoice] = useState([])
  // Variable for Trail Stoploss
  const [trailStoploss, setTrailStoploss] = useState([false]);
  const [trailStoplossProfit, setTrailStoplossProfit] = useState([])
  const [trailStoplossLoss, setTrailStoplossLoss] = useState([])
  const [trailStoplossType, setTrailStoplossType] = useState([])
  // Variable for Range Breakout
  const [showRangeBreakout, setShowRangeBreakout] = useState(false);
  const [rangeStartTime, setRangeStartTime] = useState("09:20")
  const [rangeEndTime, setRangeEndTime] = useState("09:30")
  const [startHour, setStartHour] = useState("09");
  const [endHour, setEndHour] = useState("15");
  const [startMinute, setStartMinute] = useState("30");
  const [endMinute, setEndMinute] = useState("20");
  const [rangeEndHour, setRangeEndHour] = useState("09");
  const [rangeEndMinute, setRangeEndMinute] = useState("40");
  // variable for margin
  const [margin, setMargin] = useState(1000000)
  // Variable for Result
  const [a, seta] = useState([]);
  const [b, setb] = useState([]);
  const [c, setc] = useState([]);
  const [show, setshow] = useState(false);    //It indicate show result
  const [resultCount, setResultCount] = useState(0)
  const [prevResultCount, setPrevResultCount] = useState(0)
  const [resultData, setResultData] = useState({})
  const [slippage, setSlippage] = useState(0);
  // change setting
  const [setting, setSetting] = useState(false);
  const [settingDetail, setSettingDetail] = useState({ "bankniftylotsize": 15, "niftylotsize": 25, "slippage": 0 });
  const slippageList = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9]
  // coin related issue
  const { coin, updateCoin } = useBacktestStore()
  // next day(btst/stbt)
  const [nextDay, setNextDay] = useState(false);
  // login view 
  const [loginView, setLoginView] = useState(false);
  // Live Trading
  const [showLiveTrading, setShowLiveTrading] = useState(false);
  //  share Strategy
  const [shareUrl, setShareUrl] = useState("");
  const [showShareStrategy, setShowShareStrategy] = useState(false);
  // set the lot size of banknify and nifty.Also set nifty and banknifty.
  // const handleSetting = () => {
  //   const banknifty_lot_size = BankniftyLotSize.current.value;
  //   const nifty_lot_size = NiftyLotSize.current.value;
  //   const inbuilt_slippage = inbuiltSlippage.current.value;
  //   const settingData = { "banknifty": banknifty_lot_size, "nifty": nifty_lot_size, "slippage": inbuilt_slippage };
  //   console.log(settingData, 'settingData');
  // }

  // All the function that present in this component
  // a)handlelogout()  -->For Logout
  // b)startBacktest()---> uses helper() for do backtest
  // c)onShowStrategy()---> that help to show saved strategy
  // d)AddStrategy()---> It help to add strategy.
  // e)onGettingStrategy() --->It help to get all saved strategy
  // f)updateSavedStrategy() --->It help to update saved strategy
  // g)onHandleDeleteStrategy()  -->It help to delete saved strategy
  // h)addleg(),copyleg(),deleteLeg() ---> It help to handle add,copy and remove leg from strategy.

  const helper = () => {
    let segmentType1 = [...segmentType];
    let legarray1 = [...legarray];
    let lotarray1 = [...lotarray];
    let inputtrade = [];
    let lotarray11 = tradesymbol === "NIFTY" ?
      lotarray.map((item, key) => { inputtrade.push(item * settingDetail.niftylotsize); return item * settingDetail.niftylotsize; }) :
      lotarray.map((item, key) => { inputtrade.push(item * settingDetail.bankniftylotsize); return item * settingDetail.bankniftylotsize; });

    let legSymbol1 = [...legSymbol];
    let darray1 = [...darray];

    let atm1 = [...atm];
    let closestPremiumChoice1 = [...closestPremiumChoice]
    let closestPremiumValue1 = [...closestPremiumValue]

    let legsTargetProfit1 = [...legsTargetProfit];
    let legsTargetProfitType1 = [...legsTargetProfitType]

    let legsStoploss1 = [...legsStoploss];
    let legsStoplossType1 = [...legsStoplossType];

    let waitTradeType1 = [...waitTradeType];
    let waitTradeValue1 = [...waitTradeValue];

    let reEntryValue1 = [...reEntryValue];
    let reEntryChoice1 = [...reEntryChoice];
    let reEntryType1 = [...reEntryType];
    let legsReEntry1 = [...legsReEntry];

    let trailStoploss1 = [...trailStoploss];
    let trailStoplossType1 = [...trailStoplossType];
    let trailStoplossLoss1 = [...trailStoplossLoss];
    let trailStoplossProfit1 = [...trailStoplossProfit];

    let legExpiryType1 = [...legExpiryType];

    let optionBuySell1 = [...optionBuySell];
    let optionCallPut1 = [...optionCallPut];

    let atmChoice1 = [...atmChoice];
    let cnt = 0;

    for (let i = 0; i < includeLeg.length; i++) {
      if (includeLeg[i]) {
        cnt++;
        continue;
      }
      segmentType1.splice(i, 1);
      legarray1.splice(i, 1);
      lotarray1.splice(i, 1);
      lotarray11.splice(i, 1);
      legSymbol1.splice(i, 1);
      darray1.splice(i, 1);

      atm1.splice(i, 1);
      closestPremiumChoice1.splice(i, 1);
      closestPremiumValue1.splice(i, 1);

      legsTargetProfit1.splice(i, 1);
      legsTargetProfitType1.splice(i, 1);

      legsStoploss1.splice(i, 1);
      legsStoplossType1.splice(i, 1);

      waitTradeType1.splice(i, 1);
      waitTradeValue1.splice(i, 1);

      reEntryValue1.splice(i, 1);
      reEntryChoice1.splice(i, 1);
      reEntryType1.splice(i, 1);
      legsReEntry1.splice(i, 1);

      trailStoploss1.splice(i, 1);
      trailStoplossType1.splice(i, 1);
      trailStoplossLoss1.splice(i, 1);
      trailStoplossProfit1.splice(i, 1);

      legExpiryType1.splice(i, 1);

      optionBuySell1.splice(i, 1);
      optionCallPut1.splice(i, 1);
      atmChoice1.splice(i, 1);
    }

    const data = {
      'leg': cnt, 'segmentType': segmentType1, 'lotarray1': lotarray11, 'legarray': legarray1, 'darray': darray1, 'intraday': intraday, 'tradesymbol': tradesymbol, 'gap': gap, 'stoploss': stoploss,
      'maxprofit': maxprofit, 'starttime': `${startHour}:${startMinute}`, 'endtime': `${endHour}:${endMinute}`, 'startdate': startdate, 'enddate': enddate,
      'startDay': startDay, 'endDay': endDay, 'atm': atm1, 'atmprice': atmprice, 'squareoff': squareoff, 'waitTrade': waitTrade,
      'waitTradeType': waitTradeType1, 'waitTradeValue': waitTradeValue1, 'slToCost': slToCost, 'reEntry': reEntry, 'protectType': protectType, 'profitIncrease': profitIncrease, 'lockMinimumProfit': lockMinimumProfit,
      'profitReaches': profitReaches, 'trailProfit': trailProfit, 'legsStoplossType': legsStoplossType1, 'legsStoploss': legsStoploss1, 'legsTargetProfit': legsTargetProfit1, 'legsTargetProfitType': legsTargetProfitType1, 'reEntry': reEntry,
      'reEntryValue': reEntryValue1, 'reEntryChoice': reEntryChoice1, 'legSymbol': legSymbol1, 'legExpiryType': legExpiryType1, 'atmChoice': atmChoice1, 'closestPremiumChoice': closestPremiumChoice1, 'closestPremiumValue': closestPremiumValue1,
      'legsReEntry': legsReEntry1, 'protectProfit': protectProfit, 'reEntryType': reEntryType1, "trailStoploss": trailStoploss1, "trailStoplossLoss": trailStoplossLoss1, "trailStoplossProfit": trailStoplossProfit1, "trailStoplossType": trailStoplossType1,
      "showRangeBreakout": showRangeBreakout, "rangeStartTime": `${startHour}:${startMinute}`, "rangeEndTime": `${rangeEndHour}:${rangeEndMinute}`, "lotarray": lotarray1, "optionBuySell": optionBuySell1, "optionCallPut": optionCallPut1, "settingDetail": settingDetail
    };
    return data;
  }
  // function to help at submit time
  const helper_function = () => {
    const inputtrade = [];
    const optionArray = [];
    const inputdata = [];
    if (squareoff == 'all') {
      setLegReEntry(Array(leg).fill(true));
      setReEntryValue(Array(leg).fill(reExecuteTimes));
      setReEntryChoice(Array(leg).fill("ReExecute"));
    }
    // setStartTime(`${startHour}:${startMinute}`);
    // setRangeStartTime(`${startHour}:${startMinute}`);
    // setRangeEndTime(`${rangeEndHour}:${rangeEndMinute}`);
    // setEndTime(`${endHour}:${endMinute}`);
    // lotSize of NIFTY or BANKNIFTY
    tradesymbol === "NIFTY" ? setLotQuantity(settingDetail.niftylotsize) : setLotQuantity(settingDetail.bankniftylotsize);
    // slippage
    !isNaN(settingDetail + 0.5) && setSlippage(parseFloat(settingDetail + 0.5));
    // square off leg --> setReExecuteTimes

    // set a lot with respect to quantity

    // set a BuyCall to BC , BuyPut to BP ,SellCall to SC ,SellPut to SP
    for (let i = 0; i < leg; i++) { optionArray.push(optionBuySell[i][0] + optionCallPut[i][0]); }
    // 
    atm.map((item, key) => {
      let s1 = item.substr(3, item.length);
      if (s1 === "") s1 = "0";
      inputdata.push(s1);
      return item.substr(3, item.length);
    });
    setlegarray(optionArray);
    // setlotarray1(inputtrade);
    setdarray(inputdata);
  };

  // Method to check filled value is valid or not.
  const validation = () => {
    // console.log(typeof (starttime), typeof (endtime), typeof (rangeStartTime), typeof (rangeEndTime), starttime, endtime, rangeEndTime, rangeStartTime)
    let rangeEndTime=rangeEndHour + rangeEndMinute;
    let startTime = startHour + startMinute;
    let endTime = endHour + endMinute;

    if (startTime > endTime) {
      toast.error('Start Time must be less than Exit Time')
      return false
    }

    if (startTime >= rangeEndTime) {
      toast.error('Range Start Time must be less than range end time.')
      return false
    }

    if (rangeEndTime >= endTime) {
      toast.error('Range End Time must be less than end time.')
      return false
    }

    if (startdate < "2022-01-01") {
      toast.error('Please perform a backtest for dates greater than 2022-01-01');
      return false;
    }
    if (startdate > enddate) {
      toast.error('The start date must always be less than or equal to the end date and valid date also.');
      return false;
    }
    if (intraday == 'No' && endDay > startDay) {
      toast.error('Entry or Exit day is invalid at positional.')
      return false;
    }
    
    if (protectProfit && (protectType === "Lock Minimum Profit" || protectType === "Lock & Trail Profit") && profitReaches < 500) {
      toast.error('profit Reaches must be greater than or equal to 500.!!')
      return false;
    }
    if (protectProfit && (protectType === "Lock Minimum Profit" || protectType === "Lock & Trail Profit") && lockMinimumProfit > (profitReaches > 2000 ? profitReaches - 1000 : profitReaches / 2)) {
      toast.error(`Lock Minimum Profit should be maximum ${(profitReaches > 2000 ? profitReaches - 1000 : profitReaches / 2)}`)
      return false;
    }
    if (protectProfit && (protectType === "Trail Profit" || protectType === "Lock & Trail Profit") && profitIncrease < 500) {
      toast.error('Increase Profit value should be greater than or equal to 500.!!')
      return false;
    }
    if (protectProfit && (protectType === "Trail Profit" || protectType === "Lock & Trail Profit") && parseInt(trailProfit) > parseInt(profitIncrease)) {
      toast.error('Trail Profit should be less than Increase Profit.!!')
      return false;
    }
    

    // for leg stoploss and profit
    for (let i = 0; i < leg; i++) {
      if (legsStoploss[i] === '') {
        toast.error('please fill leg stoploss value!!.');
        return false;
      }
      if (legsTargetProfit[i] === '') {
        toast.error('please fill leg target profit value!!.');
        return false;
      }
      if (waitTrade && waitTradeValue[i] === '') {
        toast.error('Please insert proper value of wait trade.')
        return false;
      }
    }
    return true;
  }

  // stable function
  // function convertToStrings(arr) {
  //   return arr.map(subArray => subArray.map(item => String(item)));
  // }

  // new function
  function convertToStrings(arr) {
    return arr.map(subArray =>
      subArray.map(item =>
        typeof item === 'object' && item !== null ? item : String(item)
      )
    );
  }

  //  Method to do start backtesting!!.
  const startBacktest = async (e) => {
    e.preventDefault();
    if (parseInt(leg) < 1) {
      toast.warn("Minimum 1 leg required!");
      return;
    }
    setshow(false);
    // At new backtest slippage value must be zero.
    setSlippage(0);
    setLegsymbol(Array(parseInt(leg)).fill(tradesymbol));
    // condition to check all value filled by user is validate or not.
    if (!validation())
      return;
    if (coin < 1) {
      toast("not sufficient coin to do backtesting");
      return;
    }
    helper_function();


    // For handle disable move sl to cost
    if (disableMoveSlToCost)
      setSlToCost(false);

    setloader(true);
    const data = helper();
    // const data = {
    //   'leg': leg, 'segmentType': segmentType, 'lotarray1': lotarray1, 'legarray': legarray, 'darray': darray, 'intraday': intraday, 'tradesymbol': tradesymbol, 'gap': gap, 'stoploss': stoploss,
    //   'maxprofit': maxprofit, 'starttime': `${startHour}:${startMinute}`, 'endtime': `${endHour}:${endMinute}`, 'startdate': startdate, 'enddate': enddate,
    //   'startDay': startDay, 'endDay': endDay, 'atm':atm, 'atmprice': atmprice, 'squareoff': squareoff, 'waitTrade': waitTrade,
    //   'waitTradeType': waitTradeType, 'waitTradeValue': waitTradeValue, 'slToCost': slToCost, 'reEntry': reEntry, 'protectType': protectType, 'profitIncrease': profitIncrease, 'lockMinimumProfit': lockMinimumProfit,
    //   'profitReaches': profitReaches, 'trailProfit': trailProfit, 'legsStoplossType': legsStoplossType, 'legsStoploss': legsStoploss, 'legsTargetProfit': legsTargetProfit, 'legsTargetProfitType': legsTargetProfitType, 'reEntry': reEntry,
    //   'reEntryValue': reEntryValue, 'reEntryChoice': reEntryChoice, 'legSymbol': legSymbol, 'legExpiryType': legExpiryType, 'atmChoice': atmChoice, 'closestPremiumChoice': closestPremiumChoice, 'closestPremiumValue': closestPremiumValue,
    //   'legsReEntry': legsReEntry, 'protectProfit': protectProfit, 'reEntryType': reEntryType, "trailStoploss": trailStoploss, "trailStoplossLoss": trailStoplossLoss, "trailStoplossProfit": trailStoplossProfit, "trailStoplossType": trailStoplossType,
    //   "showRangeBreakout": showRangeBreakout, "rangeStartTime": `${startHour}:${startMinute}`, "rangeEndTime": `${rangeEndHour}:${rangeEndMinute}`, "lotarray": lotarray, "optionBuySell": optionBuySell, "optionCallPut": optionCallPut,"settingDetail":settingDetail
    // };
    // console.log('input data', data);
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }
    var config = {
      method: "post", maxBodyLength: Infinity, url: BACKTEST, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      withCredentials: true, data: data
    };
    console.log(config, 'config');
    try {
      const res = await axios(config); const response = res.data;
      if (response) {
        seta(JSON.parse(response.a));
        let val = JSON.parse(response.b);
        if (val.length == 0) { toast.error('No Trading Day'); setloader(false); return; }
        setb(convertToStrings(val));
        setc(JSON.parse(response.c)); setMargin(response.margin); updateCoin(response.coin)
      }
      setResultData({ "legs": data.leg, "atm": data.atm, "investment": 10000000, "startDate": startdate, "endDate": enddate, "legarray": data.legarray, "lotarray": data.lotarray, "data": data, "reEntry": data.legsReEntry, "reEntryChoice": data.reEntry });
      setResultCount(resultCount + 1); setPrevResultCount(resultCount); setloader(false); setshow(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        setloader(false);
        setLoginView(true)
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
        setloader(false);
      } else {
        toast.error('Network Issue');
        setloader(false);
      }
      console.log(error, 'error at start backtesting.')
    }
  };


  // Method to show saved strategy at screen
  const onshowstrategies = (data) => {
    setleg(data.leg); setSegmentType(data.segmentType); setlotarray1(data.lotarray1); setlegarray(data.legarray); setdarray(data.darray); setintraday(data.intraday); settradesymbol(data.tradesymbol); setgap(data.gap);
    setstoploss(data.stoploss); setmaxprofit(data.maxprofit); setStartTime(data.starttime); setEndTime(data.endtime); setstartdate(data.startdate); setEndDate(data.enddate);
    setStartDay(data.startDay); setEndDay(data.endDay); setatm(data.atm); setsquareoff(data.squareoff); setwaitTrade(data.waitTrade); setWaitTradeType(data.waitTradeType); setWaitTradeValue(data.waitTradeValue); setSlToCost(data.slToCost); setReEntry(data.reEntry);
    setProtectType(data.protectType); setProfitIncrease(data.profitIncrease); setLockMinimumProfit(data.lockMinimumProfit); setProfitReaches(data.profitReaches); setTrailProfit(data.trailProfit); setLegsStoplossType(data.legsStoplossType); setLegsStoploss(data.legsStoploss); setLegsTargetProfit(data.legsTargetProfit); setLegsTargetProfitType(data.legsTargetProfitType);
    setReEntry(data.reEntry); setReEntryValue(data.reEntryValue); setReEntryChoice(data.reEntryChoice); setOptionBuySell(data.optionBuySell); setOptionCallPut(data.optionCallPut); setLegsymbol(data.legSymbol); setLegExpiryType(data.legExpiryType); setAtmChoice(data.atmChoice);
    setClosestPremiumChoice(data.closestPremiumChoice); setClosestPremiumValue(data.closestPremiumValue); setlotarray(data.lotarray); setLegReEntry(data.legsReEntry); setProtectProfit(data.protectProfit);
    setOptionBuySell(data.optionBuySell); setOptionCallPut(data.optionCallPut); setTrailStoploss(data.trailStoploss); setTrailStoplossType(data.trailStoplossType); setTrailStoplossLoss(data.trailStoplossLoss); setTrailStoplossProfit(data.trailStoplossProfit);
    setWaitTradeType(data.waitTradeType); setWaitTradeValue(data.waitTradeValue); setReEntryType(data.reEntryType);
    setIncludeLeg(Array(data.leg).fill(true));
    // this below line is for set time
    console.log(data.starttime, 'start time at on show strategyies')
    setStartMinute(data.starttime[3] + data.starttime[4]); setStartHour(data.starttime[0] + data.starttime[1]); setEndHour(data.endtime[0] + data.endtime[1]); setEndMinute(data.endtime[3] + data.endtime[4]);
  };

  // Method to save strategy
  const addStrategy = async () => {
    if (strategyName == "") {
      toast.error('Please give a strategy name upto minimum 1 character.');
      return;
    }
    const inputdata = {
      name: strategyName,
      array: {
        'leg': leg, 'segmentType': segmentType, 'lotarray1': lotarray1, 'legarray': legarray, 'darray': darray, 'intraday': intraday, 'tradesymbol': tradesymbol, 'gap': gap, 'stoploss': stoploss,
        'maxprofit': maxprofit, 'starttime': `${startHour}:${startMinute}`, 'endtime': `${endHour}:${endMinute}`, 'startdate': startdate, 'enddate': enddate,
        'startDay': startDay, 'endDay': endDay, 'atm': atm, 'squareoff': squareoff, 'waitTrade': waitTrade,
        'waitTradeType': waitTradeType, 'waitTradeValue': waitTradeValue, 'slToCost': slToCost, 'reEntry': reEntry, 'protectType': protectType, 'profitIncrease': profitIncrease, 'lockMinimumProfit': lockMinimumProfit,
        'profitReaches': profitReaches, 'trailProfit': trailProfit, 'legsStoplossType': legsStoplossType, 'legsStoploss': legsStoploss, 'legsTargetProfit': legsTargetProfit, 'legsTargetProfitType': legsTargetProfitType, 'reEntry': reEntry,
        'reEntryValue': reEntryValue, 'reEntryChoice': reEntryChoice, 'legSymbol': legSymbol, 'legExpiryType': legExpiryType, 'atmChoice': atmChoice, 'closestPremiumChoice': closestPremiumChoice, 'closestPremiumValue': closestPremiumValue,
        'legsReEntry': legsReEntry, 'protectProfit': protectProfit, 'reEntryType': reEntryType, "trailStoploss": trailStoploss, "trailStoplossLoss": trailStoplossLoss, "trailStoplossProfit": trailStoplossProfit, "trailStoplossType": trailStoplossType,
        "showRangeBreakout": showRangeBreakout, "rangeStartTime": `${startHour}:${startMinute}`, "rangeEndTime": `${rangeEndHour}:${rangeEndMinute}`, "lotarray": lotarray, "optionBuySell": optionBuySell, "optionCallPut": optionCallPut
      }
    };

    const data = { id: secureLocalStorage.getItem("backtest_id"), array: inputdata };
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }
    let config = { method: "post", maxBodyLength: Infinity, url: ADD_STRATEGIES, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data };
    try {
      const response = await axios.request(config);
      setstrategyName("");
      onGettingStrategies();
      // setsavedstrategies(response?.data?.data);
      toast.success('strategy has been successfully incorporated into your list.')
      setshowstrategy(false);
    } catch (error) {
      console.log(error, 'erroir')
      if (error?.response?.status === 401) {
        setLoginView(true);
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Network Issue');
      }
      console.log(error, 'error at add strategy')
      setshowstrategy(false);
    }
  };
  const handleCopy = async () => {
    try {
      await window.navigator.clipboard.writeText(shareUrl);
      toast.success("Copy Url!");
    } catch (err) {
      toast.error("Copy Url Failed.");
    }
  };

  // method to share strategy
  const shareStrategy = async () => {
    const inputdata = {
      'leg': leg, 'segmentType': segmentType, 'lotarray1': lotarray1, 'legarray': legarray, 'darray': darray, 'intraday': intraday, 'tradesymbol': tradesymbol, 'gap': gap, 'stoploss': stoploss,
      'maxprofit': maxprofit, 'starttime': `${startHour}:${startMinute}`, 'endtime': `${endHour}:${endMinute}`, 'startdate': startdate, 'enddate': enddate,
      'startDay': startDay, 'endDay': endDay, 'atm': atm, 'squareoff': squareoff, 'waitTrade': waitTrade,
      'waitTradeType': waitTradeType, 'waitTradeValue': waitTradeValue, 'slToCost': slToCost, 'reEntry': reEntry, 'protectType': protectType, 'profitIncrease': profitIncrease, 'lockMinimumProfit': lockMinimumProfit,
      'profitReaches': profitReaches, 'trailProfit': trailProfit, 'legsStoplossType': legsStoplossType, 'legsStoploss': legsStoploss, 'legsTargetProfit': legsTargetProfit, 'legsTargetProfitType': legsTargetProfitType, 'reEntry': reEntry,
      'reEntryValue': reEntryValue, 'reEntryChoice': reEntryChoice, 'legSymbol': legSymbol, 'legExpiryType': legExpiryType, 'atmChoice': atmChoice, 'closestPremiumChoice': closestPremiumChoice, 'closestPremiumValue': closestPremiumValue,
      'legsReEntry': legsReEntry, 'protectProfit': protectProfit, 'reEntryType': reEntryType, "trailStoploss": trailStoploss, "trailStoplossLoss": trailStoplossLoss, "trailStoplossProfit": trailStoplossProfit, "trailStoplossType": trailStoplossType,
      "showRangeBreakout": showRangeBreakout, "rangeStartTime": `${startHour}:${startMinute}`, "rangeEndTime": `${rangeEndHour}:${rangeEndMinute}`, "lotarray": lotarray, "optionBuySell": optionBuySell, "optionCallPut": optionCallPut
    };

    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }
    let config = { method: "post", maxBodyLength: Infinity, url: SHARE_ADD_STRATEGY, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: inputdata };
    try {
      const response = await axios.request(config);
      console.log(response, 'response');
      setShareUrl(response?.data?.url);
      setShowShareStrategy(true);
    } catch (error) {
      console.log(error, 'erroir')
      if (error?.response?.status === 401) {
        setLoginView(true);
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Network Issue');
      }
      console.log(error, 'error at add strategy')
    }
  }
  // find all saved strategies from an array
  const onGettingStrategies = () => {
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id") });
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }
    let config = { method: "post", maxBodyLength: Infinity, url: GET_STRATEGIES, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
    axios
      .request(config)
      .then((response) => {
        // console.log(response.data.data[0].array,'saved strategy')
        response?.data?.data[0]?.array && setsavedstrategies(response?.data?.data[0]?.array);
        // console.log(savedstrategies);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setLoginView(true);
        } else if (error?.response?.data?.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Network Issue');
        }
        console.log(error, 'error at get saved strategy')
      });
  };

  // For add New Strategy
  const updateSavedStrategy = () => {
    setshowupdatestrategy(false);
    const index = activeindex;
    const inputdata = {
      name: strategyName,
      array: {
        'leg': leg, 'segmentType': segmentType, 'lotarray1': lotarray1, 'legarray': legarray, 'darray': darray, 'intraday': intraday, 'tradesymbol': tradesymbol, 'gap': gap, 'stoploss': stoploss,
        'maxprofit': maxprofit, 'starttime': starttime, 'endtime': endtime, 'startdate': startdate, 'enddate': enddate,
        'startDay': startDay, 'endDay': endDay, 'atm': atm, 'squareoff': squareoff, 'waitTrade': waitTrade,
        'waitTradeType': waitTradeType, 'waitTradeValue': waitTradeValue, 'slToCost': slToCost, 'reEntry': reEntry, 'protectType': protectType, 'profitIncrease': profitIncrease, 'lockMinimumProfit': lockMinimumProfit,
        'profitReaches': profitReaches, 'trailProfit': trailProfit, 'legsStoplossType': legsStoplossType, 'legsStoploss': legsStoploss, 'legsTargetProfit': legsTargetProfit, 'legsTargetProfitType': legsTargetProfitType, 'reEntry': reEntry,
        'reEntryValue': reEntryValue, 'reEntryChoice': reEntryChoice, 'legSymbol': legSymbol, 'legExpiryType': legExpiryType, 'atmChoice': atmChoice, 'closestPremiumChoice': closestPremiumChoice, 'closestPremiumValue': closestPremiumValue,
        'legsReEntry': legsReEntry, 'protectProfit': protectProfit, 'reEntryType': reEntryType, "trailStoploss": trailStoploss, "trailStoplossLoss": trailStoplossLoss, "trailStoplossProfit": trailStoplossProfit, "trailStoplossType": trailStoplossType,
        "showRangeBreakout": showRangeBreakout, "rangeStartTime": rangeStartTime, "rangeEndTime": rangeEndTime, "lotarray": lotarray, "optionBuySell": optionBuySell, "optionCallPut": optionCallPut
      }
    };
    const data = { id: secureLocalStorage.getItem("backtest_id"), index: index, array: inputdata, };
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }
    let config = { method: "post", maxBodyLength: Infinity, url: UPDATE_OLD_STRATEGIES, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
    axios
      .request(config)
      .then((response) => {
        const input = [...savedstrategies]; input[index] = inputdata;
        setsavedstrategies(input);
        setActiveStrategy(strategyName);
        toast.success('Strategy has been updated successfully.')
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          setLoginView(true);
        } else if (error?.response?.data?.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Network Issue');
        }
        console.log(error, 'error at update saved strategy')
      });
  };

  // For Delete a particular strategies
  const onHandleDeleteStrategies = () => {
    const index = activeindex[0]; const inputdata = [...savedstrategies]; inputdata.splice(index, 1);
    setsavedstrategies(inputdata);
    setTimeout(() => {
      const data = { id: secureLocalStorage.getItem("backtest_id"), array: inputdata };
      // Get the token from secureLocalStorage
      let token = secureLocalStorage.getItem('backtest_token');

      // Remove the 'backtest_' prefix if it exists
      if (token && token.startsWith('backtest_')) {
        token = token.replace('backtest_', '');
      } else {
        navigate('/backtest/login');
      }
      let config = { method: "post", maxBodyLength: Infinity, url: UPDATE_STRATEGY_ARRAY, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
      axios
        .request(config)
        .then((response) => {
          onGettingStrategies();
          toast.success('Strategy has been deleted successfully.');
          setactiveindex('');
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            setLoginView(true);
          } else if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('Network Issue');
          }
          console.log(error, 'error at on handle delete strategy')
        });
    }, 2000);
  };


  // Method to add a leg
  const addleg = () => {
    setleg(parseInt(leg) + 1);
    setSegmentType([...segmentType, segment]);
    setlegarray([...legarray, "SC"]);
    setClosestPremiumChoice([...closestPremiumChoice, 'CP~'])
    selectedCheckbox == 'premium' ? setClosestPremiumValue([...closestPremiumValue, 100]) : setClosestPremiumValue([...closestPremiumValue, '5%SP']);
    setlotarray([...lotarray, 1]);
    setatm([...atm, "ATM"]);
    setpoint([...point, "true"]);
    setdarray([...darray, "100"]);
    setlotarray1([...lotarray1, 100]);
    setLegsTargetProfit([...legsTargetProfit, "-1"]);
    setLegsTargetProfitType([...legsTargetProfitType, "TP%"])
    setLegsStoploss([...legsStoploss, "-1"]);
    setLegsStoplossType([...legsStoplossType, "SL%"])
    setReEntryChoice([...reEntryChoice, "Re-Entry"])
    setReEntryValue([...reEntryValue, "1"])
    setLegReEntry([...legsReEntry, false])
    setLegsymbol([...legSymbol, tradesymbol])
    segment == 'future' ? setLegExpiryType([...legExpiryType, "Monthly"]) : setLegExpiryType([...legExpiryType, "Weekly"]);
    setOptionBuySell([...optionBuySell, "Buy"])
    setOptionCallPut([...optionCallPut, "Call"]);
    setAtmChoice([...atmChoice, selectedCheckbox])
    setLegReEntry([...legsReEntry, false]);
    setWaitTradeType([...waitTradeType, 'Point ++'])
    setWaitTradeValue([...waitTradeValue, ''])
    setReEntryType([...reEntryType, 'SL'])
    setTrailStoploss([...trailStoploss, false]);
    setTrailStoplossLoss([...trailStoplossLoss, '']);
    setTrailStoplossProfit([...trailStoplossProfit, '']);
    setTrailStoplossType([...trailStoplossType, 'point']);
    setIncludeLeg([...includeLeg, true]);
  };

  // Method to copy leg
  const copyleg = (index) => {
    setleg(parseInt(leg) + 1);
    setSegmentType([...segmentType, segmentType[index]])
    setlegarray([...legarray, legarray[index]]);
    setlotarray([...lotarray, lotarray[index]]);
    setatm([...atm, atm[index]]);
    setpoint([...point, point[index]]);
    setdarray([...darray, darray[index]]);
    setlotarray1([...lotarray1, lotarray1[index]]);
    setLegsTargetProfit([...legsTargetProfit, legsTargetProfit[index]]);
    setLegsTargetProfitType([...legsTargetProfitType, legsTargetProfitType[index]]);
    setLegsStoploss([...legsStoploss, legsStoploss[index]]);
    setLegsStoplossType([...legsStoplossType, legsStoplossType[index]]);
    setReEntryChoice([...reEntryChoice, reEntryChoice[index]]);
    setReEntryValue([...reEntryValue, reEntryValue[index]]);
    setLegsymbol([...legSymbol, legSymbol[index]]);
    setLegExpiryType([...legExpiryType, legExpiryType[index]]);
    setOptionBuySell([...optionBuySell, optionBuySell[index]]);
    setOptionCallPut([...optionCallPut, optionCallPut[index]]);
    setAtmChoice([...atmChoice, atmChoice[index]]);
    setLegReEntry([...legsReEntry, legsReEntry[index]]);
    setClosestPremiumChoice([...closestPremiumChoice, closestPremiumChoice[index]]);
    setClosestPremiumValue([...closestPremiumValue, closestPremiumValue[index]]);
    setLegReEntry([...legsReEntry, legsReEntry[index]]);
    setWaitTradeType([...waitTradeType, waitTradeType[index]]);
    setWaitTradeValue([...waitTradeValue, waitTradeValue[index]]);
    setReEntryType([...reEntryType, reEntryType[index]]);
    setTrailStoploss([...trailStoploss, trailStoploss[index]]);
    setTrailStoplossLoss([...trailStoplossLoss, trailStoplossLoss[index]]);
    setTrailStoplossProfit([...trailStoplossProfit, trailStoplossProfit[index]]);
    setTrailStoplossType([...trailStoplossType, trailStoplossType[index]]);
    setIncludeLeg([...includeLeg, true]);
  };

  // Method to delete a leg
  const DeleteLeg = (index) => {
    setleg(parseInt(leg) - 1); // Update the leg count
    // Create new arrays excluding the item at the specified index
    setSegmentType(segmentType.filter((_, i) => i !== index))
    setlegarray(legarray.filter((_, i) => i !== index));
    setlotarray(lotarray.filter((_, i) => i !== index));
    setatm(atm.filter((_, i) => i !== index));
    setpoint(point.filter((_, i) => i !== index));
    setdarray(darray.filter((_, i) => i !== index));
    setlotarray1(lotarray1.filter((_, i) => i !== index));
    setLegsTargetProfit(legsTargetProfit.filter((_, i) => i !== index));
    setLegsTargetProfitType(legsTargetProfitType.filter((_, i) => i !== index));
    setLegsStoploss(legsStoploss.filter((_, i) => i !== index));
    setLegsStoplossType(legsStoplossType.filter((_, i) => i !== index));
    setReEntryChoice(reEntryChoice.filter((_, i) => i !== index));
    setReEntryValue(reEntryValue.filter((_, i) => i !== index));
    setLegsymbol(legSymbol.filter((_, i) => i !== index));
    setLegExpiryType(legExpiryType.filter((_, i) => i !== index));
    setOptionBuySell(optionBuySell.filter((_, i) => i !== index));
    setOptionCallPut(optionCallPut.filter((_, i) => i !== index));
    setAtmChoice(atmChoice.filter((_, i) => i !== index));
    setLegReEntry(legsReEntry.filter((_, i) => i !== index));
    setClosestPremiumChoice(closestPremiumChoice.filter((_, i) => i !== index));
    setClosestPremiumValue(closestPremiumValue.filter((_, i) => i !== index));
    setWaitTradeType(waitTradeType.filter((_, i) => i !== index));
    setWaitTradeValue(waitTradeValue.filter((_, i) => i !== index));
    setReEntryType(reEntryType.filter((_, i) => i !== index));
    setTrailStoploss(trailStoploss.filter((_, i) => i !== index));
    setTrailStoplossLoss(trailStoplossLoss.filter((_, i) => i !== index));
    setTrailStoplossProfit(trailStoplossProfit.filter((_, i) => i !== index));
    setTrailStoplossType(trailStoplossType.filter((_, i) => i !== index));
    setIncludeLeg(includeLeg.filter((_, i) => i !== index));
  }

  // wait trade function
  const waittrade = () => {
    setWaitTradeType(Array(parseInt(leg)).fill("Point ++"));;
    setWaitTradeValue(Array(parseInt(leg)).fill(0));
  }

  const checkUserExit = () => {
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }
    const email = secureLocalStorage.getItem('backtest_email');
    console.log(email, 'email', CHECK_USER_EXIST)
    let config = { method: "get", maxBodyLength: Infinity, url: `${CHECK_USER_EXIST}/${email}`, headers: { "Content-Type": "application/json" } };
    console.log(config, 'check user exits')
    axios.request(config).then((response) => {
      console.log(response)
      if (response?.data?.userExist && response?.data?.profileCompleted) { setShowLiveTrading(true); }
    })
      .catch((error) => {
        console.log(error, 'error at on check user exist or not')
      });
  }
  useEffect(() => {
    checkUserExit();
  }, [])
  const saveStrategyForLiveTrading = async () => {
    let res = {};

    let wt_trade = {
      "Point ++": 'WT_POINT_INCREMENT',
      "Point --": "WT_POINT_DECREMENT",
      "% ++": "WT_PERCENT_INCREMENT",
      "% --": "WT_PERCENT_DECREMENT"
    }
    res['client'] = {
      "id": "3244d828-4379-4b62-b537-1045c8422647",
      "client_id": "JFJ261"
    };
    res['productType'] = intraday == "Yes" ? "MIS" : "NRML";
    res['startTime'] = `${startHour}:${startMinute}`;
    res['endTime'] = `${endHour}:${endMinute}`;
    res['startDate'] = startdate;
    res['endDate'] = enddate;
    res['script'] = tradesymbol == 'NIFTY' ? { "id": 1, "value": "NIFTY", "strikeDifference": 50, "lotSize": 50, 'expiryWeek': 2 } : { "id": 1, "value": "NIFTY", "strikeDifference": 100, "lotSize": 15, 'expiryWeek': 3 };
    res['expiryType'] = 'WEEKLY';
    res['exchange'] = { "id": 1, "value": "NSE" };
    res['orderType'] = { "id": 1, "value": "" };
    res['variety'] = { "id": 1, "value": "" };
    res['placed'] = false;
    res['status'] = "Unplaced";
    res['mutation'] = false;
    res['strategyChoice'] = squareoff === 'one' ? "LEG" : "STRATEGY";
    res['validity'] = 'IOC';
    res['rangeBreakOut'] = showRangeBreakout;
    res['rangeStartTime'] = `${startHour}:${startMinute}`;
    res['rangeEndTime'] = `${rangeEndHour}:${rangeEndMinute}`;
    if (stoploss !== 1) {
      res['stoplossChoice'] = true;
      res['stoploss'] = stoploss;
    }
    if (maxprofit !== -1) {
      res['targetChoice'] = true;
      res['target'] = maxprofit;
    }
    res['reEntryExecute'] = reEntry;
    if (protectProfit) {
      res['protectProfit'] = protectProfit;
      res['protectProfitChoice'] = protectType == 'Lock Minimum Profit' ? 'lockMinimumProfit' : protectType == 'Trail Profit' ? 'trailProfit' : 'lockAndTrailProfit';
      if (protectType === 'Lock Minimum Profit') {
        res['profitReaches'] = profitReaches;
        res['lockMinimumProfit'] = lockMinimumProfit;
      } else if (protectType === 'Trail Profit') {
        res['increaseProfit'] = profitIncrease;
        res['trailProfit'] = trailProfit;
      }
      else {
        res['profitReaches'] = profitReaches;
        res['lockMinimumProfit'] = lockMinimumProfit;
        res['increaseProfit'] = profitIncrease;
        res['trailProfit'] = trailProfit;
      }
    }

    res['waitTrade'] = waitTrade;
    res['moveSlToCost'] = slToCost;
    let legArray = [];
    for (let i = 0; i < leg; i++) {
      let temp = {};
      temp['lotSize'] = lotarray[i];
      temp['entryChoice'] = atmChoice[i] == 'point' ? "ATM_POINT" : atmChoice[i] == 'percent' ? "ATM_PERCENTAGE" : atmChoice[i] == 'premium' ? 'CP' : 'CP_STRADDLE';
      temp['atmPoint'] = atmChoice[i] == 'point' ? atm[i].slice(3) == '' ? 0 : atm[i].slice(3) : 'NA';
      temp['atmPercent'] = atmChoice[i] == 'percent' ? atm[i].slice(3) : 'NA';
      temp['closestPremium'] = atmChoice[i] == 'premium' ? closestPremiumValue[i] : 'NA';
      temp['closestPremiumChoice'] = (atmChoice[i] == 'premium' || atmChoice[i] == 'straddlePremium') ? closestPremiumChoice[i].slice(2) : 'NA'
      temp['closestPremiumOnStraddle'] = atmChoice[i] == 'straddlePremium' ? closestPremiumValue[i] : 'NA';
      temp['actionType'] = optionBuySell[i] == 'Buy' ? 'BUY' : 'SELL';
      temp['optionType'] = optionCallPut[i] == 'Call' ? 'CE' : 'PE';
      temp['script'] = tradesymbol == 'NIFTY' ? { "id": 1, "value": "NIFTY", "strikeDifference": 50, "lotSize": 25 } : { "id": 1, "value": "NIFTY", "strikeDifference": 100, "lotSize": 15 };
      temp['expiryType'] = legExpiryType[i] == 'Weekly' ? 'WEEKLY' : 'MONTHLY';
      temp['stopLossChoice'] = legsStoplossType[i] == 'SL Point' ? 'SL_POINT' : 'SL_PERCENT';
      temp['stopLossValue'] = parseInt(legsStoploss[i]);
      temp['targetType'] = legsTargetProfitType[i] == 'TP Point' ? "POINT" : "PERCENT";
      temp['target'] = legsTargetProfit[i];
      temp['trailLossChoice'] = trailStoploss[i];
      temp['trailLossType'] = trailStoplossType[i] == 'point' ? 'POINT' : 'PERCENT';
      temp['trailProfit'] = trailStoplossProfit[i];
      temp['trailStoploss'] = trailStoplossLoss[i];
      // For Wait & Trade
      if (waitTrade) {
        temp['waitTradeType'] = wt_trade[waitTradeType[i]];
        temp['waitTrade'] = waitTradeValue[i];
      }
      // For ReEntry
      if (squareoff === 'one') {
        temp['reEntryExecuteChoice'] = legsReEntry[i] ? reEntryChoice[i] == 'Re-Entry' ? "RE_ENTRY" : "RE_EXECUTE" : "NA";
        temp['reEntryDependency'] = legsReEntry[i] ? reEntryType[i] == 'SL' ? 'SL' : reEntryType[i] == 'TP' ? 'TP' : 'SL_TP' : "NA";
        temp['reEntryExecuteTimes'] = legsReEntry[i] ? reEntryValue[i] : -1;
      } else {
        temp['reEntryExecuteChoice'] = legsReEntry[i] ? "RE_EXECUTE" : "NA";
        temp['reEntryDependency'] = legsReEntry[i] ? 'SL_TP' : "NA";
        temp['reEntryExecuteTimes'] = legsReEntry[i] ? reEntryValue[i] : -1;
      }

      temp['variation'] = 1;
      temp['segmentType'] = segmentType == 'option' ? { "id": 1, "value": "FUTURE" } : { "id": 2, "value": "OPTION" };
      legArray.push(temp);
    }
    res['legs'] = legArray;
    res['userEmail'] = secureLocalStorage.getItem('backtest_email');
    res['name'] = strategyName;

    if (strategyName == "") {
      toast.error('Please give a strategy name upto minimum 1 character.');
      return;
    }

    const data = res;
    console.log(data, 'data')
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    }
    // console.log(token,'token')
    let config = { method: "post", maxBodyLength: Infinity, url: SAVE_LIVE_TRADING, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data };
    try {
      const response = await axios.request(config);
      setstrategyName("");
      // setsavedstrategies(response?.data?.data);
      toast.success('strategy has been successfully incorporated into your list.')
      setshowstrategy1(false);
    } catch (error) {
      console.log(error, 'erroir')
      if (error?.response?.status === 401) {
        setLoginView(true);
      } else if (error?.response?.data?.message) {
        toast.error(error.response.data.message)
      } else {
        toast.error('Network Issue');
      }
      console.log(error, 'error at add strategy')
      setshowstrategy1(false);
    }
  }
  // Function to format date as "YYYY-MM-DD"
  function formatDate() {
    const date = new Date();
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // Adding leading zero
    var day = ("0" + date.getDate()).slice(-2); // Adding leading zero
    return year + "-" + month + "-" + day;
  }

  // at initialisation
  // useEffect(() => {
  //   if (leg === "") {
  //   } else {
  //     setleg(1);
  //     setSegmentType(['option'])
  //     setlegarray(["BP"]);
  //     setatm(["ATM"]);
  //     setdarray(["100"]);
  //     setlotarray([1]);
  //     setSelectedCheckbox(selectedCheckbox);
  //     setLegsTargetProfit(Array(parseInt(leg)).fill("-1"));
  //     setLegsTargetProfitType(Array(parseInt(leg)).fill("TP%"))
  //     setLegsStoploss(Array(parseInt(leg)).fill("-1"));
  //     setLegsStoplossType(Array(parseInt(leg)).fill("SL%"))
  //     setReEntryChoice(Array(parseInt(leg)).fill("Re-Entry"));
  //     setReEntryValue(Array(parseInt(leg)).fill("-1"));
  //     setLegsymbol(Array(parseInt(leg)).fill(tradesymbol));
  //     setLegExpiryType(Array(parseInt(leg)).fill("Weekly"));
  //     setOptionBuySell(["Buy"]);
  //     setOptionCallPut(["Put"]);
  //     setAtmChoice(Array(parseInt(leg)).fill(selectedCheckbox))
  //     setClosestPremiumChoice(Array(parseInt(leg)).fill("CP~"))
  //     setClosestPremiumValue(Array(parseInt(leg)).fill(100))
  //     setLegReEntry(Array(parseInt(leg)).fill(false));
  //     setWaitTradeType(Array(parseInt(leg)).fill('Point ++'))
  //     setWaitTradeValue(Array(parseInt(leg)).fill(0));
  //     setReEntryType(Array(parseInt(leg)).fill('SL'));
  //     setTrailStoploss(Array(parseInt(leg)).fill(false))
  //     setTrailStoplossLoss(Array(parseInt(leg)).fill(''))
  //     setTrailStoplossProfit(Array(parseInt(leg)).fill(''));
  //     setTrailStoplossType(Array(parseInt(leg)).fill('point'));
  //     setIncludeLeg(Array(parseInt(leg)).fill(true));
  //   }
  //   onGettingStrategies();
  //   const setting=localStorage.getItem('setting');  
  //   setstartdate("2022-01-01");
  //   setEndDate(formatDate());
  //   // setSettingDetail(setting);
  // }, []);
  // Share Strategy 
  const getShareStrategy = async () => {
    try {
      // Get the token from secureLocalStorage
      let token = secureLocalStorage.getItem('backtest_token');

      // Remove the 'backtest_' prefix if it exists
      if (token && token.startsWith('backtest_')) {
        token = token.replace('backtest_', '');
      } else {
        navigate('/backtest/login');
      }
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${SHARE_GET_STRATEGY}/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.request(config);
      console.log(response, 'response at share get strategy');
      onshowstrategies(response?.data?.data);
      // Remove the query parameter without reloading the component
      const newUrl = window.location.pathname;
      window.history.replaceState(null, '', newUrl);
    } catch (error) {
      console.error(error, 'error at get saved strategy');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getShareStrategy();
    } else {
      setLoading(false);
    }
  }, [id]);
  // all saved strategies
  useEffect(() => {
    strategies.leg && onshowstrategies(strategies);
    onGettingStrategies();
    setstartdate("2022-01-01");
    setEndDate(formatDate());
  }, [strategies]);

  useEffect(() => {
    waittrade();
  }, [])
  // helper function to update
  useEffect(() => {
    helper_function();
  }, [atm, lotarray, optionBuySell, optionCallPut]);


  // Only For Checking it's Follow the Move SL To Cost Condition or Not
  useEffect(() => {
    if (slToCost) {
      let flag = 0;
      for (let k = 1; k < leg; k++) { if (optionBuySell[k] != optionBuySell[k - 1]) { setDisableMoveSlToCost(true); flag++; break; } }
      if (flag == 0) setDisableMoveSlToCost(false);
    }
  }, [JSON.stringify(optionBuySell)])

  // rangeStartTime must be matched with start time
  useEffect(() => {
    setStartTime(rangeStartTime);
  }, [rangeStartTime])


  // Add an event listener when the component mounts
  useEffect(() => {
    window.addEventListener('resize', () => { setSidebarVisible(false); });
    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', () => { setSidebarVisible(false); });
    }
  }, []);

  const tourSteps = [
    {
      target: '.feature-1',
      content: 'This feature contain default strategy like as short straddle,iron butterfly and many more.',
    },
    {
      target: '.feature-2',
      content: 'This feature contains a saved strategy that is saved by you, and you can execute it at any time. ',
    }, {
      target: '.feature-3',
      content: 'This feature contain a time period in which your strategy will run.',
    }, {
      target: '.feature-4',
      content: 'This feature start date and end date of current strategy.',
    },
    {
      target: '.feature-5',
      content: 'It contain initialising of trade like as symbol,start date,time and trade type.',
    },
    {
      target: '.feature-6',
      content: 'Choice Future/Spot as ATM,WaitTrade on legs,SquareOffOneLeg.',
    },
    {
      target: '.feature-7',
      content: 'Overall Strategy Profit and Stoploss.',
    },
    {
      target: '.feature-8',
      content: 'Setting of LotSize.',
    },
    {
      target: '.feature-9',
      content: 'RangeBreakOut feature follow trend of market contain range start time and range end time.',
    },
    {
      target: '.feature-10',
      content: 'It contain feature like reEntry/ReExecute,MoveSlToCost.Entry Choice of Leg like as ATMPoint/Percent/CP/CPBasedOnStraddle.',
    },
    {
      target: '.feature-11',
      content: 'This feature tell about expiry of leg..',
    },
    {
      target: '.feature-12',
      content: 'This Feature contain detail of Leg',
    },
    {
      target: '.feature-13',
      content: 'This Feature contain protect profit where we can lock minimum profit and applicable only for square off all leg.',
    },
    {
      target: '.feature-14',
      content: 'Intraday or Positional which you want.',
    },
    {
      target: '.feature-15',
      content: 'OnClick this button,you can save the current strategy..',
    },
    {
      target: '.feature-16',
      content: 'OnClick this button, you can run current strategy.Result will be shown below.',
    },

    // Add more tour steps for other features
  ];
  const [runTour, setRunTour] = useState(false);


  const handleJoyrideCallback = (data) => {
    // Mark the tour as completed when finished
    console.log(data, 'data of jhhandle')
    if (data.status == 'finished') {
      setRunTour(false);
    }
  };
  // Function to handle pdf
  // const handleDownloadPDF = () => {
  //   const input = pdfRef.current;
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new jsPDF('l', 'mm', 'a4', true);
  //     const pdfWidth = pdf.internal.pageSize.getWidth();
  //     const pdfHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
  //     const imgY = 30;
  //     pdf.addImage(
  //       imgData,
  //       'PNG',
  //       imgX,
  //       imgY,
  //       imgWidth * ratio,
  //       imgHeight * ratio,
  //     );
  //     pdf.save('invoice.pdf');
  //   });
  // };
  const handleDownloadPDF = () => {
    const input = pdfRef.current;
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('l', 'cm', 'a4', true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = pdfWidth / imgWidth;
      const pageHeight = pdfHeight / 2;
      let position = 0;

      while (position < imgHeight) {
        const canvasFragment = document.createElement('canvas');
        const context = canvasFragment.getContext('2d');
        canvasFragment.width = imgWidth;
        canvasFragment.height = pageHeight * imgWidth / pdfWidth;
        context.drawImage(canvas, 0, -position, imgWidth, imgHeight);
        const imgFragmentData = canvasFragment.toDataURL('image/png');

        pdf.addImage(
          imgFragmentData,
          'PNG',
          0,
          0,
          pdfWidth,
          pdfHeight
        );

        position += pageHeight * imgWidth / pdfWidth;
        if (position < imgHeight) {
          pdf.addPage();
        }
      }

      pdf.save('backtest_fprognos.pdf');
    });
  };


  return (
    <>
      <div ref={pdfRef} className="flex flex-col min-h-screen bg-[#DAECF2]">
        <ToastContainer />
        {/* Joyride component */}
        {runTour && <Joyride
          steps={tourSteps}
          run={runTour}
          continuous={true}
          scrollToFirstStep={true}
          showProgress={true}
          disableOverlayClose={true}
          callback={handleJoyrideCallback}
        />}
        <div className="fixed top-0 w-full z-20 flex-col"><NavbarVersion2 setleg={setleg} activeindex={activeindex} setactiveindex={setactiveindex} setStrategies={setStrategies} />
          <Top tourSteps={tourSteps} runTour={runTour} setRunTour={setRunTour} handleJoyrideCallback={handleJoyrideCallback} leg={leg} setleg={setleg} setStrategies={setStrategies} setactiveindex={setactiveindex} activeindex={activeindex} savedstrategies={savedstrategies}
            onshowstrategies={onshowstrategies} setEndDate={setEndDate} endDate={enddate} startDate={startdate} setStartDate={setstartdate} activeStrategy={activeStrategy} setActiveStrategy={setActiveStrategy} />
        </div>
        {/* Handle Strategies */}
        <div className="bg-[#DAECF2] flex-grow">
          <Main tourSteps={tourSteps} runTour={runTour} handleJoyrideCallback={handleJoyrideCallback} settradesymbol={settradesymbol} tradesymbol={tradesymbol} intraday={intraday} gap={gap} expirytype={expirytype} setgap={setgap} setexpirytype={setexpirytype} setintraday={setintraday}
            leg={leg} setleg={setleg} rate={rate} setRate={setRate} starttime={starttime} setStartTime={setStartTime} endtime={endtime} setEndTime={setEndTime} startdate={startdate} setstartdate={setstartdate}
            enddate={enddate} setEndDate={setEndDate} squareoff={squareoff} setsquareoff={setsquareoff} atmprice={atmprice} setatmprice={setatmprice}
            stoploss={stoploss} maxprofit={maxprofit} setstoploss={setstoploss} setmaxprofit={setmaxprofit} waitTrade={waitTrade} setWaitTradeType={setWaitTradeType} setWaitTradeValue={setWaitTradeValue} setwaitTrade={setwaitTrade} waitTradeType={waitTradeType}
            waitTradeValue={waitTradeValue} reEntry={reEntry} setReEntry={setReEntry} setLotQuantity={setLotQuantity} slToCost={slToCost} setSlToCost={setSlToCost} disableMoveSlToCost={disableMoveSlToCost} setDisableMoveSlToCost={setDisableMoveSlToCost}
            selectedCheckbox={selectedCheckbox} setSelectedCheckbox={setSelectedCheckbox} segment={segment} setSegment={setSegment} addleg={addleg} setSetting={setSetting}
            showRangeBreakout={showRangeBreakout} setShowRangeBreakout={setShowRangeBreakout} rangeStartTime={rangeStartTime} setRangeStartTime={setRangeStartTime}
            rangeEndTime={rangeEndTime} setRangeEndTime={setRangeEndTime} endDate={enddate} startDate={startdate} setLegsStoplossType={setLegsStoplossType}
            // range start time
            reExecuteTimes={reExecuteTimes} setReExecuteTimes={setReExecuteTimes} />

          {/* All Detail filled and handle in this component */}
          <Leg tourSteps={tourSteps} includeLeg={includeLeg} setIncludeLeg={setIncludeLeg} runTour={runTour} handleJoyrideCallback={handleJoyrideCallback} leg={leg} setleg={setleg} addleg={addleg} segmentType={segmentType} setSegmentType={setSegmentType} segment={segment} setSegment={setSegment} selectedCheckbox={selectedCheckbox} setSelectedCheckbox={setSelectedCheckbox} legarray={legarray} lotarray={lotarray} setlotarray={setlotarray} setlegarray={setlegarray} atm={atm} setatm={setatm} tradesymbol={tradesymbol}
            atmtype={atmtype} setatmtype={setatmtype} legsTargetProfitType={legsTargetProfitType} setLegsTargetProfitType={setLegsTargetProfitType}
            legsTargetProfit={legsTargetProfit} legsStoplossType={legsStoplossType} setLegsStoplossType={setLegsStoplossType} legsStoploss={legsStoploss} setLegsStoploss={setLegsStoploss} waitTradeType={waitTradeType} waitTrade={waitTrade} waitTradeValue={waitTradeValue} setWaitTradeValue={setWaitTradeValue} expirytype={expirytype}
            setLegsTargetProfit={setLegsTargetProfit} legSymbol={legSymbol} setLegsymbol={setLegsymbol} setLegExpiryType={setLegExpiryType} legExpiryType={legExpiryType}
            optionBuySell={optionBuySell} setOptionBuySell={setOptionBuySell} optionCallPut={optionCallPut} setOptionCallPut={setOptionCallPut} atmChoice={atmChoice} setAtmChoice={setAtmChoice} copyleg={copyleg} setWaitTradeType={setWaitTradeType}
            reEntryChoice={reEntryChoice} reEntry={reEntry} reEntryValue={reEntryValue} setReEntry={setReEntry} setReEntryChoice={setReEntryChoice} setReEntryValue={setReEntryValue} legsReEntry={legsReEntry} setLegReEntry={setLegReEntry} closestPremiumChoice={closestPremiumChoice}
            setClosestPremiumChoice={setClosestPremiumChoice} closestPremiumValue={closestPremiumValue} setClosestPremiumValue={setClosestPremiumValue} DeleteLeg={DeleteLeg} settradesymbol={settradesymbol} setLotQuantity={setLotQuantity} reEntryType={reEntryType} setReEntryType={setReEntryType} trailStoploss={trailStoploss} setTrailStoploss={setTrailStoploss} trailStoplossProfit={trailStoplossProfit}
            setTrailStoplossProfit={setTrailStoplossProfit} trailStoplossLoss={trailStoplossLoss} setTrailStoplossLoss={setTrailStoplossLoss} trailStoplossType={trailStoplossType} setTrailStoplossType={setTrailStoplossType} squareoff={squareoff} />

          {leg > 0 && <div className="feature-13 border-2 m-2 sm:m-2 mt-2 rounded-md bg-white">
            <div className="text-xl font-semibold text-[#333B69] m-2 text-left">Advanced Setting</div>
            {/* protect profit component to handle protect profit */}
            <ProtectProfit protectType={protectType} setProtectType={setProtectType} profitReaches={profitReaches} setProfitReaches={setProfitReaches} lockMinimumProfit={lockMinimumProfit} setLockMinimumProfit={setLockMinimumProfit} profitIncrease={profitIncrease} setProfitIncrease={setProfitIncrease} trailProfit={trailProfit} setTrailProfit={setTrailProfit}
              protectProfit={protectProfit} setProtectProfit={setProtectProfit} intraday={intraday}
              startDay={startDay} endDay={endDay} setStartDay={setStartDay} setEndDay={setEndDay} legExpiryType={legExpiryType} leg={leg} />

          </div>}
          {leg > 0 && <DateTime startHour={startHour} endHour={endHour} startMinute={startMinute} endMinute={endMinute} rangeEndHour={rangeEndHour} rangeEndMinute={rangeEndMinute} setStartHour={setStartHour}
            setEndHour={setEndHour} setStartMinute={setStartMinute} setEndMinute={setEndMinute} setRangeEndHour={setRangeEndHour} setRangeEndMinute={setRangeEndMinute} showRangeBreakout={showRangeBreakout} setShowRangeBreakout={setShowRangeBreakout} startTime={starttime} setStartTime={setStartTime} rangeEndTime={rangeEndTime} setRangeEndTime={setRangeEndTime} setEndDate={setEndDate} enddate={enddate} startdate={startdate} setstartdate={setstartdate} intraday={intraday} />}

          {/* Here,we are handling add leg by one and save strategy name */}
          {leg > 0 && <div className="sm:ml-2 flex flex-row flex-wrap">
            <div className="feature-14 flex flex-col mt-2">
              <div className="grid grid-cols-2 text-center h-8 text-l font-thin justify-center mr-2  rounded bg-blue-500 text-white w-48">
                <div style={{ backgroundColor: intraday == "Yes" ? "#2563EB" : "white", color: intraday == "Yes" ? "white" : "black" }} className="rounded-l h-8 border-solid p-1 cursor-pointer" onClick={() => { setintraday("Yes"); }} >
                  Intraday
                </div>
                <div style={{ backgroundColor: intraday == "No" ? "#2563EB" : "white", color: intraday == "No" ? "white" : "black" }} className="rounded-r h-8 border-solid p-1 cursor-pointer" onClick={() => { setintraday("No"); showRangeBreakout && setShowRangeBreakout(false); }} >
                  Positional
                </div>
              </div>
            </div>
            {/* {intraday=="Yes" && 
          <div className="grid grid-cols-2 text-center h-8 text-l font-thin justify-center mr-2 mt-2  rounded bg-blue-500 text-white w-48">
              <div style={{ backgroundColor: !nextDay? "#2563EB" : "white", color: !nextDay ? "white" : "black" }} className="rounded-l h-8 border-solid p-1 cursor-pointer" onClick={() => { setNextDay(!nextDay); }} >
                SameDay
              </div>
              <div style={{ backgroundColor: nextDay ? "#2563EB" : "white", color: nextDay ? "white" : "black" }} className="rounded-r h-8 border-solid p-1 cursor-pointer" onClick={() => { setNextDay(!nextDay); }} >
                NextDay
              </div>
            </div>
          } */}

            <div className="feature-15 rounded h-8 border-solid p-1 text-center text-l font-thin m-2 cursor-pointer bg-white text-[#2D5BFF] w-48" onClick={() => { setshowstrategy(true); }} >
              Save Strategy
            </div>
            {showLiveTrading && <div className="feature-15 rounded h-8 border-solid p-1 text-center text-l font-thin m-2 cursor-pointer bg-green-400 text-white w-48" onClick={() => { setshowstrategy1(true); }} >
              <span className="flex flex-row text-center justify-center "> Save For Algo</span>
            </div>}
            {showstrategy && (
              <Dialog open={showstrategy} onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                  setshowstrategy(false)
                }
              }} sx={{ borderRadius: "10px" }}>
                <div className="flex flex-row">
                  <div className="text-[#181818] text-[18px] p-2 w-[480px]">Do you want to save strategy?. </div>
                  <div className="h-[24px] w-[24px] text-[#181818] flex justify-end font-semibold p-2 cursor-pointer"><CloseIcon onClick={() => { setshowstrategy(false); }} /></div>
                </div>
                <input type="text" placeholder="Strategy Name" value={strategyName} onChange={(e) => { setstrategyName(e.target.value); }} className=" m-1 mt-5 rounded-md bg-[#ECF0FF] h-8 justify-center pl-5 outline-0"   ></input>
                <div className="flex justify-end gap-2 p-1">
                  <button className="p-1 w-36 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { addStrategy(); }}>Save Strategy</button>
                  <button className="p-1 w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setshowstrategy(false); }}>Cancel</button>
                </div>
              </Dialog>
            )}
            {showstrategy1 && (
              <Dialog open={showstrategy1} onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                  setshowstrategy1(false)
                }
              }} sx={{ borderRadius: "10px" }}>
                <div className="flex flex-row">
                  <div className="text-[#181818] text-[18px] p-2 w-[480px]">Do you want to save strategy on Algo-Trading Account?. </div>
                  <div className="h-[24px] w-[24px] text-[#181818] flex justify-end font-semibold p-2 cursor-pointer"><CloseIcon onClick={() => { setshowstrategy1(false); }} /></div>
                </div>
                <input type="text" placeholder="Strategy Name" value={strategyName} onChange={(e) => { setstrategyName(e.target.value); }} className=" m-1 mt-5 rounded-md bg-[#ECF0FF] h-8 justify-center pl-5 outline-0"   ></input>
                <div className="flex justify-end gap-2 p-1">
                  <button className="p-1 w-36 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { saveStrategyForLiveTrading(); }}>Save Strategy</button>
                  <button className="p-1 w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setshowstrategy1(false); }}>Cancel</button>
                </div>
              </Dialog>
            )}
            {activeindex !== '' && (
              <div className="rounded h-8 border-solid p-1  text-center text-l font-thin m-2 cursor-pointer bg-white text-[#2D5BFF] w-48" onClick={() => { setstrategyName(activeStrategy); setshowupdatestrategy(true); }}>
                Update Strategy
              </div>
            )}
            {activeindex !== '' && (
              <div className="rounded h-8 border-solid p-1 bg-[#FF3030]  text-center text-l font-thin m-2 cursor-pointer text-white w-48" onClick={() => { setVisibleDeleteStrategy(true); }}>
                Remove Strategy
              </div>
            )}
            <div className="rounded h-8 border-solid p-1 bg-blue-400  text-center text-l font-thin m-2 cursor-pointer text-white w-48" onClick={() => { shareStrategy(); }}>
              Share Strategy
            </div>
            {activeindex !== '' && showupdatestrategy && (
              <Dialog open={showupdatestrategy} onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                  setshowupdatestrategy(false);
                }
              }} sx={{ borderRadius: "10px" }}>
                <div className="flex flex-row border-b-2">
                  <div className="text-[#232323] text-xl p-2 w-96">Update Strategy!!</div>
                  <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><CloseIcon onClick={() => { setshowupdatestrategy(false); }} /></div>
                </div>
                <input type="text" placeholder="Strategy Name" value={strategyName} onChange={(e) => { setstrategyName(e.target.value); }} className="mt-5 m-1 text-left pl-5 bg-[#ECF0FF] rounded-md h-8 border-2 outline-0"></input>
                <hr className="mt-2 bg-blue-500"></hr>
                <div className="flex justify-end gap-2 p-1">
                  <button className="p-1 w-36 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { updateSavedStrategy(); }}>Update</button>
                  <button className="p-1 w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setshowupdatestrategy(false); }}>Cancel</button>
                </div>
              </Dialog>
            )}
            <Dialog open={showShareStrategy} onClose={(event, reason) => {
              if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                setShowShareStrategy(false);
              }
            }} sx={{ borderRadius: "10px" }}>
              <div className="flex flex-row border-b-2">
                <div className="text-[#232323] text-xl p-2 w-96">Share Strategy</div>
                <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><CloseIcon onClick={() => { setShowShareStrategy(false); }} /></div>
              </div>
              <input type="text" value={shareUrl} readOnly={true} className="mt-5 m-1 text-left pl-5 bg-[#ECF0FF] rounded-md h-8 border-2 outline-0"></input>
              <div className="flex text-center justify-center hover:underline text-blue-400 cursor-pointer" onClick={() => { handleCopy(); }}>click to copy url</div>
              <hr className="mt-2 bg-blue-500"></hr>
              <div className="flex justify-end gap-2 p-1">
                <button className="p-1 w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setShowShareStrategy(false); }}>Cancel</button>
              </div>
            </Dialog>
            {/* Loader is run till the time taken by backtesting */}
            {!loader && (<div className="feature-16 rounded h-8 border-solid p-1 text-center text-l font-thin m-2 cursor-pointer bg-[#2D5BFF] text-white w-48" onClick={startBacktest}>
              Run Backtest
            </div>
            )}

            {loader && <div className="bouncing-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>}
          </div>}

          {/* Confirmation to Delete saved Strategy */}
          <Dialog className="border-2 border-t-8 border-[#2D5BFF] rounded-xl" onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleDeleteStrategy(false); }
          }} open={visibleDeleteStrategy}>
            <div className="flex flex-row border-b-2">
              <div className="text-[#232323] text-xl p-2 w-96">Alert!!!</div>
              <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><CloseIcon onClick={() => { setVisibleDeleteStrategy(false); }} /></div>
            </div>
            <div className="flex flex-col text-center justify-center mt-2 p-1">
              <div className="text-l text-left">Are you sure you want to delete the {activeStrategy} strategy?</div>
              <hr className="mt-2 bg-blue-400"></hr>
              <div className="flex justify-end gap-2 p-1">
                <button className="w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setVisibleDeleteStrategy(false); }}>Cancel</button>
                <button className="w-36 bg-[#FF3030] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { onHandleDeleteStrategies(); setVisibleDeleteStrategy(false); }}>Delete</button>
              </div>
            </div>
          </Dialog>
          {/* change default setting */}
          <Dialog className="border-2 border-t-8 border-[#2D5BFF] rounded-xl" onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setSetting(false); }
          }} open={setting}>
            <div className="flex flex-row border-b-2">
              <div className="text-[#232323] text-xl font-medium p-2 flex flex-grow"><SettingsIcon />Setting</div>
              <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><CloseIcon onClick={() => { setSetting(false); }} /></div>
            </div>
            <div className="flex flex-col mt-2 p-2">
              <div className="">Banknifty Lot Size</div>
              <select className="bg-[#DAECF2] outline-0 rounded" value={settingDetail.bankniftylotsize} onChange={(e) => { setSettingDetail(prevState => ({ ...prevState, bankniftylotsize: e.target.value })); }}>
                {
                  BANKNIFTY_LOT_SIZE.map((item, key) => {
                    return (
                      <option value={item} key={`banknifty-${key}`}>{item}</option>
                    )
                  })
                }
              </select>
              <div className="text-xs">*From 21 Jul'23 ,Banknifty Lot Size is considered 15</div>
              <div className="">Nifty Lot Size</div>
              <select className="bg-[#DAECF2] outline-0 rounded" value={settingDetail.niftylotsize} onChange={(e) => { setSettingDetail(prevState => ({ ...prevState, niftylotsize: e.target.value })); }}>
                {
                  NIFTY_LOT_SIZE.map((item, key) => {
                    return (
                      <option value={item} key={`nifty-${key}`}>{item}</option>
                    )
                  })
                }
              </select>
              <div className="text-xs">* From 23 Jul'23, Nifty Lot Size is considered 50</div>
              <div className="">Inbuilt Slippages</div>
              <select className="bg-[#DAECF2] outline-0 rounded mb-2" value={slippage} onChange={(e) => { setSettingDetail(prevState => ({ ...prevState, slippage: e.target.value })); setSlippage(e.target.value); }}>
                {
                  slippageList.map((item, key) => {
                    return (
                      <option value={item} key={`slippage-${key}`}>{item}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className="flex justify-end gap-2 mt-2 border-t-2 p-1">
              <button className="border-2 p-1 w-24 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { secureLocalStorage.setItem(settingDetail); setSetting(false); }}>Cancel</button>
              <button className="p-1 w-24 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setSetting(false); }}>Save</button>
            </div>
          </Dialog>
          {/* Result will show when your backtesting donw  */}
          {show && resultCount != prevResultCount &&
            <Result handleDownloadPDF={handleDownloadPDF} slippage={slippage} setSlippage={setSlippage} csv={b} margin={margin} resultData={resultData} resultCount={resultCount} strategyName={strategies} name={name} re_entry_result={c} intraday={intraday} LotQuantity={LotQuantity}
              legsStoploss={legsStoploss} legsStoplossType={legsStoplossType} legsTargetProfit={legsTargetProfit} legsTargetProfitType={legsTargetProfitType} strategyProfit={maxprofit} strategyStoploss={stoploss} />
          }

        </div>

        {/* Footer  */}
        <div
          className="text-center mt-1 pb-2 text-l bg-[#043873] text-white"          >
          {new Date().getFullYear() - 1}-{new Date().getFullYear()} Copyright © All
          rights reserved || For Contact{" "}
          <span className="italic">
            support.dftl@damyant.com
          </span>
        </div>

        {/* Login Dialog Box */}
        <Dialog className="border-2 border-t-8 border-[#2D5BFF] rounded-xl" onClose={(event, reason) => {
          if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setLoginView(false); }
        }} open={loginView}>
          <Login1 setLoginView={setLoginView} />
        </Dialog>
      </div>
    </>
  );
};
export default Backtest;
