import React from 'react';
import { saveAs } from 'file-saver';
import { FaDownload } from "react-icons/fa";
const ExcelJS = require('exceljs');

const Excel = (props) => {

  const generateExcel1 = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet('Strategy');
    const worksheet2 = workbook.addWorksheet('Result');
    const worksheet3 = workbook.addWorksheet('Periodical Result');

    // Add border to the cell
     const borderStyle = { style: 'medium', color: { argb: '000000' }}; 
    // ---------------------------------------Trade Shown Result---------------------------------------------------
    // Example: Adding data to worksheet2
    props.b.unshift(['LIST OF TRADES']);
    console.log(props.b,'props b')
    worksheet2.addRows(props.b);
    let char='A'
    let No_Of_Leg=props.b[2].length;

    let k1 = String.fromCharCode(char.charCodeAt(0) + No_Of_Leg-1);
    worksheet2.mergeCells(`A1:${k1}1`);
    worksheet2.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#2D5BFF' }};
    worksheet2.getCell('A1').font = { size: 10, name: 'serif', bold: true, color: { argb: 'FFFFFF' }};
    
    // Giving width of 30
    const worksheet = workbook.getWorksheet(2); // Index is 1-based
    // Set the width for each column
    worksheet.columns.forEach(column => {
      column.width = 30; // Set the width as needed (in characters)
    });

    worksheet2.eachRow((row) => {
      row.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
      });
    });
    
    
    for (let i = 0; i < No_Of_Leg; i++) {
      let char = 'A';
      let k = String.fromCharCode(char.charCodeAt(0) + i);
      worksheet2.getCell(`${k}2`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DBEAFE' }
      };
      worksheet2.getCell(`${k}2`).font = {
        size: 11, // Set font size to 16 (adjust as needed)
        name: 'serif', // Set font family (e.g., Arial)
        color: { argb: '964B00' }
      };
      worksheet2.getCell(`${k}2}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    }
    // -----------------------------------Trade Show Result----------------------------------------------------------------

    // --------------------------------------Weekly and Monthly Result---------------------------------------------------------
    // Example: Adding data to worksheet3
    const weekly = [['Day Wise Breakup'], ["Serial", "Mon", "Tue", "Wed", "Thu", "Fri"]]
    const monthly = [['No.', "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec","Total","MDD","ROI"]];
    worksheet3.addRows(weekly);
    worksheet3.getCell('A1').alignment = { horizontal:'center'}
    worksheet3.getCell('A1').font = { size: 11, name: 'calibri', bold: true, color: { argb: 'FFFFFF' }};
    worksheet3.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#ADD8E6' } };
    worksheet3.mergeCells('A1:F1');

    let week_cnt=0
    for(const key in props.week){
      worksheet3.getCell(`A${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet3.getCell(`A${week_cnt+3}`).value =key
      worksheet3.getCell(`B${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet3.getCell(`B${week_cnt+3}`).value =props.week[key][0]
      worksheet3.getCell(`C${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet3.getCell(`C${week_cnt+3}`).value =props.week[key][1]
      worksheet3.getCell(`D${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet3.getCell(`D${week_cnt+3}`).value =props.week[key][2]
      worksheet3.getCell(`E${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet3.getCell(`E${week_cnt+3}`).value =props.week[key][3]
      worksheet3.getCell(`F${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet3.getCell(`F${week_cnt+3}`).value =props.week[key][4]
      week_cnt+=1
    }
    
    
    // Monthly Style
    worksheet3.getCell('A9').value = 'Monthly Breakup';
    worksheet3.getCell('A9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet3.getCell('A9').alignment = { horizontal: 'center' }
    worksheet3.getCell('A9').fill = { type: 'pattern', pattern: 'solid',}; // fgColor: { argb: '#ADD8E6' }
    worksheet3.getCell('A9').font = { size: 11, name: 'calibri', bold: true, color: { argb: 'FFFFFF' } };
    worksheet3.mergeCells('A9:P9');

    worksheet3.addRows(monthly);
    
    let month_cnt=0
    for(const key in props.month){
          worksheet3.getCell(`A${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`A${month_cnt+11}`).value = key
          worksheet3.getCell(`B${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`B${month_cnt+11}`).value =props.month[key][1]
          worksheet3.getCell(`C${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`C${month_cnt+11}`).value =props.month[key][2]
          worksheet3.getCell(`D${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`D${month_cnt+11}`).value =props.month[key][3]
          worksheet3.getCell(`E${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`E${month_cnt+11}`).value =props.month[key][4]
          worksheet3.getCell(`F${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`F${month_cnt+11}`).value =props.month[key][5]
          worksheet3.getCell(`G${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`G${month_cnt+11}`).value =props.month[key][6]
          worksheet3.getCell(`H${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`H${month_cnt+11}`).value =props.month[key][7]
          worksheet3.getCell(`I${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`I${month_cnt+11}`).value =props.month[key][8]
          worksheet3.getCell(`J${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`J${month_cnt+11}`).value =props.month[key][9]
          worksheet3.getCell(`K${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`K${month_cnt+11}`).value =props.month[key][10]
          worksheet3.getCell(`L${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`L${month_cnt+11}`).value =props.month[key][11]
          worksheet3.getCell(`M${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`M${month_cnt+11}`).value =props.month[key][12]
          worksheet3.getCell(`N${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`N${month_cnt+11}`).value = props.month[key][13]   
          worksheet3.getCell(`O${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`O${month_cnt+11}`).value =props.month[key][14]
          worksheet3.getCell(`P${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
          worksheet3.getCell(`P${month_cnt+11}`).value = props.month[key][15]
          month_cnt+=1
    }
 
   

    // Giving the style 
    // Define an array of cell addresses where you want to apply the formatting
    // const cellAddresses1 = ['A10', 'B10', 'C10', 'D10', 'E10', 'F10', 'G10', 'H10', 'I10', 'J10', 'K10', 'L10', 'M10','N10','O10','P10','A2', 'B2', 'C2', 'D2', 'E2', 'F2'];
    // // Apply formatting to the specified cells
    // const formattingOptions1 = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' }};
    // cellAddresses1.forEach(address => {
    //   const cell = worksheet3.getCell(address);
    //   cell.fill = formattingOptions1;
    //   cell.font = { size: 11, name: 'serif', color: { argb: '964B00' }};
    //   cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
    // });

    const cellAddresses2 = ['A11', 'B11', 'C11', 'D11', 'E11', 'F11', 'G11', 'H11', 'I11', 'J11', 'K11', 'L11', 'M11','N11','O11','P11','A3', 'B3', 'C3', 'D3', 'E3', 'F3','A12', 'B12', 'C12', 'D12', 'E12', 'F12', 'G12', 'H12', 'I12', 'J12', 'K12', 'L12', 'M12', 'A4', 'B4', 'C4', 'D4', 'E4', 'F4','A10', 'B10', 'C10', 'D10', 'E10', 'F10', 'G10', 'H10', 'I10', 'J10', 'K10', 'L10', 'M10','N10','O10','P10', 'A2', 'B2', 'C2', 'D2', 'E2', 'F2']
    cellAddresses2.forEach(address => {
      const cell = worksheet3.getCell(address);
      cell.border = { top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle };
    });
    
    
    
    // ---------------------------------------Weekly and Monthly End -----------------------------------
    
    // --------------------------------------------Statistical Result ----------------------------------------
    // Example: Applying a background color to a cell in worksheet1
    const cellA1 = worksheet1.getCell('A1');
    worksheet1.mergeCells('A1:J1');
    cellA1.value = 'FPROGNOS';
    cellA1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '#2D5BFF' }
    };
    worksheet1.getCell('A1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('A1').alignment = { horizontal: 'center' }
    worksheet1.getCell('A1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};


    const cellA2 = worksheet1.getCell('K1');
    worksheet1.mergeCells('K1:S1');
    cellA2.value = props.name;
    cellA2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('K1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('K1').alignment = { horizontal: 'center' }
    worksheet1.getCell('K1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};



    // Adding data to worksheet1
    const cellD1 = worksheet1.getCell('B3');
    worksheet1.mergeCells('B3:S3');
    cellD1.value = 'POSITION';
    cellD1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('B3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B3').alignment = { horizontal: 'center' }

    worksheet1.getCell('F5').value = 'ATM';
    worksheet1.mergeCells('F5:G5');
    worksheet1.getCell('F6').value = 'SquareOff';
    worksheet1.mergeCells('F6:G6');
    worksheet1.getCell('F7').value = 'Wait & Trade';
    worksheet1.mergeCells('F7:G7');
    worksheet1.getCell('F8').value = 'Re-Entry/ReExecute';
    worksheet1.mergeCells('F8:G8');
    worksheet1.getCell('F9').value = 'MoveSlToCost';
    worksheet1.mergeCells('F9:G9');
    
    
    worksheet1.getCell('H5').value = 'Future';
    worksheet1.getCell('H5').alignment = { horizontal: 'center' }
    worksheet1.getCell('H6').value = props.f['squareoff']=='one'?"Leg":"All";
    worksheet1.getCell('H6').alignment = { horizontal: 'center' }
    worksheet1.getCell('H7').value = props.f['waitTrade'];
    worksheet1.getCell('H7').alignment = { horizontal: 'center' }
    worksheet1.getCell('H8').value = props.f['reEntry'];
    worksheet1.getCell('H8').alignment = { horizontal: 'center' }
    worksheet1.getCell('H9').value = props.f['slToCost'];
    worksheet1.getCell('H9').alignment = { horizontal: 'center' }


    worksheet1.getCell('B11').value = 'Include';
    worksheet1.getCell('B11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('C11').value = 'Total Lot';
    worksheet1.getCell('C11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D11').value = 'ATM';
    worksheet1.getCell('D11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.mergeCells('D11:E11');
    worksheet1.getCell('F11').value = 'Action';
    worksheet1.getCell('F11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('G11').value = 'Option';
    worksheet1.getCell('G11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H11').value = 'Wait & Trade';
    worksheet1.getCell('H11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.mergeCells('H11:I11');
    worksheet1.getCell('J11').value = 'Target Profit';
    worksheet1.getCell('J11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.mergeCells('J11:K11');
    worksheet1.getCell('L11').value = 'Target Stoploss';
    worksheet1.getCell('L11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.mergeCells('L11:M11');
    worksheet1.getCell('N11').value = 'Trail Stoploss';
    worksheet1.getCell('N11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.mergeCells('N11:O11');
    worksheet1.getCell('P11').value = 'Re-Entry';
    worksheet1.getCell('P11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.mergeCells('P11:Q11');
    worksheet1.getCell('R11').value = 'Index';
    worksheet1.getCell('R11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('S11').value = 'Expiry';
    worksheet1.getCell('S11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    const formattingOptions = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#2D5BFF' }};

    // Define an array of cell addresses where you want to apply the formatting
    const cellAddresses = ['B11','C11','D11', 'F11','G11','H11','J11','L11','N11','P11','R11','S11'];

    // Apply formatting to the specified cells
    cellAddresses.forEach(address => {
      const cell = worksheet1.getCell(address);
      cell.fill = formattingOptions;
      cell.font = {
        color: { argb: 'FFFFFF' }
      }
    });


    for (let i = 0; i < props.f['leg']; i++) {
      worksheet1.getCell(`B${i + 12}`).value = true;
      worksheet1.getCell(`B${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`C${i + 12}`).value = `${props.f['lotarray'][i]} lot`
      worksheet1.getCell(`C${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`D${i + 12}`).value = `${props.f['atm'][i]} `
      worksheet1.getCell(`D${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.mergeCells(`D${i + 12}:E${i + 12}`);
      worksheet1.getCell(`F${i + 12}`).value = props.f['legarray'][i][0]=='B'?'BUY':"SELL";
      worksheet1.getCell(`F${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`G${i + 12}`).value = props.f['legarray'][i][1]=='C'?'CALL':"PUT";
      worksheet1.getCell(`G${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`H${i + 12}`).value = props.f['waitTrade']?(props.f['waitTradeType'][i] +" "+ props.f['waitTradeValue'][i]):' ';
      worksheet1.getCell(`H${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.mergeCells(`H${i + 12}:I${i + 12}`);
      worksheet1.getCell(`J${i + 12}`).value = props.f['legsTargetProfit'][i] == '-1' ? '' :(props.f['legsTargetProfitType'][i] + " " + props.f['legsTargetProfit'][i]);
      worksheet1.getCell(`J${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.mergeCells(`J${i + 12}:K${i + 12}`);
      worksheet1.getCell(`L${i + 12}`).value = props.f['legsStoploss'][i] == '-1' ? '' : (props.f['legsStoplossType'][i] +" " + props.f['legsStoploss'][i]);
      worksheet1.getCell(`L${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.mergeCells(`L${i + 12}:M${i + 12}`);
      worksheet1.getCell(`N${i + 12}`).value = props.f['trailStoploss'][i] ? `Tsl ${props.f['trailStoplossType'][i]} : ${props.f['trailStoplossLoss'][i]},${props.f['trailStoplossLoss'][i]}`: "";
      worksheet1.getCell(`N${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.mergeCells(`N${i + 12}:O${i + 12}`);
      worksheet1.getCell(`P${i + 12}`).value = props.f['reEntry']?(props.f['reEntryChoice'][i] + " " + props.f['reEntryValue'][i]):'';
      worksheet1.mergeCells(`P${i + 12}:Q${i + 12}`);
      worksheet1.getCell(`P${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`R${i + 12}`).value = props.f['tradesymbol'];
      worksheet1.getCell(`R${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`S${i + 12}`).value = props.f['legExpiryType'][i];
      worksheet1.getCell(`S${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    }

  //  Entry and Exit Time
    worksheet1.getCell('B22').value = 'Entry Time';
    worksheet1.mergeCells('B22:C22');
    worksheet1.getCell('D22').value = props.f['starttime'];
    worksheet1.mergeCells('D22:E22');
    worksheet1.getCell('H22').value = 'Exit Time';
    worksheet1.mergeCells('H22:I22');
    worksheet1.getCell('J22').value = props.f['endtime'];
    worksheet1.mergeCells('J22:K22');
  
  //  Start Date and Exit Date
    worksheet1.getCell('B24').value = 'From Date';
    worksheet1.mergeCells('B24:C24');
    worksheet1.getCell('D24').value = props.f['startdate'];
    worksheet1.mergeCells('D24:E24');
    worksheet1.getCell('H24').value = 'To Date';
    worksheet1.mergeCells('H24:I24');
    worksheet1.getCell('J24').value = props.f['enddate'];
    worksheet1.mergeCells('J24:K24');


   // strategy Profit and Strategy Stoploss
    worksheet1.getCell('B26').value = 'Target Profit';
    worksheet1.mergeCells('B26:C26');
    worksheet1.getCell('D26').value = props.f['maxprofit']==-1?"":props.f['maxprofit'];
    worksheet1.getCell('D26').alignment = { horizontal: 'left' }
    worksheet1.mergeCells('D26:E26');
    worksheet1.getCell('H26').value = 'Stoploss';
    worksheet1.mergeCells('H26:I26');
    worksheet1.getCell('J26').value = props.f['stoploss']==1?"":props.f['stoploss'];
    worksheet1.mergeCells('J26:K26');
    worksheet1.getCell('J26').alignment = { horizontal: 'left' }

    // Protect Profit
    worksheet1.getCell('B28').value = 'Protect Profit';
    worksheet1.mergeCells('B28:C28');
    worksheet1.getCell('D28').value = props.f['protectProfit']? props.f['protectType'] + "" + "lockMinimumProfit" +  props.f['lockMinimumProfit'] + " trailProfit" + props.f['trailProfit'] + "increaseProfit" + props.f['profitIncrease'] + "profitReaches" + props.f['profitReaches']:"";
    worksheet1.getCell('D28').alignment = { horizontal: 'left' }
    worksheet1.mergeCells('D28:J28');

    // Range Break Out
    worksheet1.getCell('B30').value = 'Range StartTime';
    worksheet1.mergeCells('B30:C30');
    worksheet1.getCell('D30').value = props.f['showRangeBreakOut']?props.f['rangeStartTime']:"";
    worksheet1.getCell('D30').alignment = { horizontal: 'left' }
    worksheet1.mergeCells('D30:E30');
    worksheet1.getCell('H30').value = 'Range End Time';
    worksheet1.mergeCells('H30:I30');
    worksheet1.getCell('H30').value = props.f['showRangeBreakOut']?props.f['rangeEndTime']:"";
    worksheet1.getCell('H30').alignment = { horizontal: 'left' }

    // Intraday or Positional
    worksheet1.getCell('B32').value = 'Trade Type';
    worksheet1.mergeCells('B32:C32');
    worksheet1.getCell('D32').value = props.f['intraday']=="Yes"?"Intraday":"Positional";
    worksheet1.getCell('D32').alignment = { horizontal: 'left' }
    worksheet1.mergeCells('D32:H32');


    
    worksheet1.mergeCells('B34:S34');
    worksheet1.getCell('B34').value = 'STATISTICAL RESULT';
    worksheet1.getCell('B34').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#2D5BFF' } };
    worksheet1.getCell('B34').font = {color: { argb: 'FFFFFF' } }
    worksheet1.getCell('B34').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B34').alignment = { horizontal: 'center' }
    
    let cnt1=36
    // Slippages
    worksheet1.getCell(`B${cnt1+0}`).value = `Slippages`;
    worksheet1.mergeCells(`B${cnt1+0}:D${cnt1+0}`);
    worksheet1.getCell(`B${cnt1+0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    
    worksheet1.getCell(`B${cnt1+1}`).value = `Vix`;
    worksheet1.mergeCells(`B${cnt1+1}:D${cnt1+1}`);
    worksheet1.getCell(`B${cnt1+1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    
    worksheet1.getCell(`E${cnt1+0}`).value = props.slippage;
    worksheet1.getCell(`E${cnt1+0}`).alignment = { horizontal: `center` }
    worksheet1.mergeCells(`E${cnt1+0}:F${cnt1+0}`);
    worksheet1.getCell(`E${cnt1+0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    
    worksheet1.getCell(`E${cnt1+1}`).value = `${props.vixStart} to ${props.vixEnd}`
    worksheet1.mergeCells(`E${cnt1+1}:F${cnt1+1}`);
    worksheet1.getCell(`E${cnt1+1}`).alignment = { horizontal: `center` }
    worksheet1.getCell(`E${cnt1+1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    
    worksheet1.getCell(`G${cnt1+0}`).value = `(0.5% Slippages are already included for options strategies)`
    worksheet1.mergeCells(`G${cnt1+0}:M${cnt1+0}`);
    worksheet1.getCell(`G${cnt1+0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    let cnt=40
    worksheet1.getCell(`B${cnt+0}`).value = 'Total Margin';
    worksheet1.mergeCells(`B${cnt+0}:D${cnt+0}`);
    worksheet1.getCell(`B${cnt+0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+1}`).value = `Total Profit`;
    worksheet1.mergeCells(`B${cnt+1}:D${cnt+1}`);
    worksheet1.getCell(`B${cnt+1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+2}`).value = `Maximum Drawdown`;
    worksheet1.mergeCells(`B${cnt+2}:D${cnt+2}`);
    worksheet1.getCell(`B${cnt+2}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+3}`).value = `Max Profit`;
    worksheet1.mergeCells(`B${cnt+3}:D${cnt+3}`);
    worksheet1.getCell(`B${cnt+3}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+4}`).value = `Max Loss`;
    worksheet1.mergeCells(`B${cnt+4}:D${cnt+4}`);
    worksheet1.getCell(`B${cnt+4}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+5}`).value = `Win Days`;
    worksheet1.mergeCells(`B${cnt+5}:D${cnt+5}`);
    worksheet1.getCell(`B${cnt+5}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+6}`).value = `Loss Days`;
    worksheet1.mergeCells(`B${cnt+6}:D${cnt+6}`);
    worksheet1.getCell(`B${cnt+6}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+7}`).value = `Average Profit on Win Days`;
    worksheet1.mergeCells(`B${cnt+7}:D${cnt+7}`);
    worksheet1.getCell(`B${cnt+7}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+8}`).value = `Average Loss on Loss Days`;
    worksheet1.mergeCells(`B${cnt+8}:D${cnt+8}`);
    worksheet1.getCell(`B${cnt+8}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+9}`).value = `Investment Days`;
    worksheet1.mergeCells(`B${cnt+9}:D${cnt+9}`);
    worksheet1.getCell(`B${cnt+9}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+10}`).value = `Expectancy`;
    worksheet1.mergeCells(`B${cnt+10}:D${cnt+10}`);
    worksheet1.getCell(`B${cnt+10}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+11}`).value = `Max Continuous Profit Streak`;
    worksheet1.mergeCells(`B${cnt+11}:D${cnt+11}`);
    worksheet1.getCell(`B${cnt+11}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+12}`).value = `Max Continuuous Loss Streak`;
    worksheet1.mergeCells(`B${cnt+12}:D${cnt+12}`);
    worksheet1.getCell(`B${cnt+12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+13}`).value = `Leg in Strategies`;
    worksheet1.mergeCells(`B${cnt+13}:D${cnt+13}`);
    worksheet1.getCell(`B${cnt+13}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+14}`).value = `MDD (Recovery period)`;
    worksheet1.mergeCells(`B${cnt+14}:D${cnt+14}`);
    worksheet1.getCell(`B${cnt+14}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`B${cnt+15}`).value = `Return to MDD Ratio`;
    worksheet1.mergeCells(`B${cnt+15}:D${cnt+15}`);
    worksheet1.getCell(`B${cnt+15}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};


    for (let k = 0; k < props.StatisticalResult.length; k++) {
      worksheet1.getCell(`E${k + cnt}`).value = typeof props.StatisticalResult[k] == Number ? props.StatisticalResult[k].toFixed(2) : props.StatisticalResult[k];
      worksheet1.mergeCells(`E${k + cnt}:F${k + cnt}`);
      worksheet1.getCell(`E${k + cnt}`).alignment = { horizontal: 'center' }
      worksheet1.getCell(`E${k + cnt}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    }
    worksheet1.getCell(`E${cnt-7+20}`).value = props.f[`leg`]
    worksheet1.getCell(`E${cnt-7+20}`).alignment = { horizontal: `center`}
    worksheet1.mergeCells(`E${cnt-7+20}:F${cnt-7+20}`);
    worksheet1.getCell(`E${cnt-7+20}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell(`E${cnt-7+21}`).value = `----------`
    worksheet1.mergeCells(`E${cnt-7+21}:F${cnt-7+21}`);
    worksheet1.getCell(`E${cnt-7+21}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`E${cnt-7+21}`).alignment = { horizontal: `center`}


    worksheet1.getCell(`E${cnt-7+22}`).value = `----------`
    worksheet1.mergeCells(`E${cnt-7+22}:F${cnt-7+22}`);
    worksheet1.getCell(`E${cnt-7+22}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell(`E${cnt-7+22}`).alignment = { horizontal: `center`}




    


    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(data, `backtest_fprognos.xlsx`);
    });
  };

  return (
    <div className='w-full'>
      <button className="bg-green-500 hover:bg-green-600 flex text-center justify-center text-white p-1 text-[16px] rounded font-serif w-full gap-1" onClick={generateExcel1}>Download Excel<FaDownload className='mt-1'/></button>
    </div>
  );
};

export default Excel;
