import React from 'react'
import { MdOutlineCalendarMonth } from "react-icons/md";

const Month = (props) => {

    return (
        <>
        <div className="flex flex-col overflow-auto bg-white m-2 rounded-md">
        <h3 className='bg-gradient-to-r from-[#718EBF] to-[#4A6DA3] flex text-xl text-center justify-start pl-5 text-white p-1'><MdOutlineCalendarMonth className='mt-1 mr-2'/> MONTHWISE PROFIT</h3>
        <div className='flex flex-row justify-around border-[1px] min-w-[1380px] text-center border-t-blue-500 bg-[#EFEFEF] border-t-2'>
                <div className="p-2" style={{width:"80px"}}>Month</div>
                <div className="p-2" style={{width:"80px"}}>Jan</div>
                <div className="p-2" style={{width:"80px"}}>Feb</div>
                <div className="p-2" style={{width:"80px"}}>Mar</div>
                <div className="p-2" style={{width:"80px"}}>Apr</div>
                <div className="p-2" style={{width:"80px"}}>May</div>
                <div className="p-2" style={{width:"80px"}}>Jun</div>
                <div className="p-2" style={{width:"80px"}}>Jul</div>
                <div className="p-2" style={{width:"80px"}}>Aug</div>
                <div className="p-2" style={{width:"80px"}}>Sep</div>
                <div className="p-2" style={{width:"80px"}}>Oct</div>
                <div className="p-2" style={{width:"80px"}}>Nov</div>
                <div className="p-2" style={{width:"80px"}}>Dec</div>
                <div className="p-2" style={{width:"80px"}}>Total</div>
                <div className="p-2" style={{width:"80px"}}>MDD</div>
                <div className="p-2" style={{width:"80px"}}>ROI</div>
            </div>
            {
                props.month && Object.entries(props.month).map(([key, value]) => (
                    <div className=" bg-white flex flex-row justify-around text-center border-[1px] min-w-[1380px]" style={{backgroundColor:key=='Total'?"#EFEFEF":""}} key={key}>
                        <div className="p-2 text-black" style={{width:"80px"}}>{key}</div>
                        <div className="p-2" style={{ color: parseFloat(value[1]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[1]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[1]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[2]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[2]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[2]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[3]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[3]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[3]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[4]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[4]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[4]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[5]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[5]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[5]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[6]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[6]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[6]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[7]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[7]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[7]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[8]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[8]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[8]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[9]) >= 0 ? "green" : "red",width:"80px" }} >{parseFloat(value[9]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[9]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[10]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[10]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[10]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[11]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[11]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[11]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[12]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[12]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[12]*100)/props.margin).toFixed(2)}%)`}</div>
                        <div className="p-2" style={{ color: parseFloat(value[13]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[13]).toFixed(0)}</div>
                        <div className="p-2" style={{ color: parseFloat(value[14]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[14]).toFixed(0)}</div>
                        <div className="p-2" style={{ color: parseFloat(value[15]) >= 0 ? "green" : "red",width:"80px" }}>{parseFloat(value[15]).toFixed(0)}</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Month