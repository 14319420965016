import React from 'react'
import Platform from './Platform';
import Strength from './Strength';
import Main from './Main';
import Navbar from '../Page/Layout/Navbar';
import Name from './Name';
import FooterVersion2 from '../Page/Layout/Home/Footer';
import Why from './Why';

const About = () => {
  return (
    <div>
        <Navbar/>
        <Name/>
        <Why/>
        <Main/>
        <Platform/>
        <Strength/>
        <FooterVersion2/>
    </div>
  )
}

export default About