import React, { useState, useEffect } from 'react'
import Vector from './assets/Vector.webp'
import axios from 'axios'
import { MdCurrencyRupee } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { MdFavorite } from "react-icons/md";
// constant
import { PLAN_LIST_API } from './../../../constant/constant'
// images
import silver from './../../../assets/silver.webp'
import gold from './../../../assets/gold.webp'
import platinum from './../../../assets/platinum.webp'


const Plan = () => {
  const [plans, setPlans] = useState([]);
  const [showPlan, setShowPlan] = useState(false);

  const get_plan_detail = async () => {
    let config = {
      method: 'get',
      url: PLAN_LIST_API,
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);
        setPlans(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

  }

  useEffect(() => {
    get_plan_detail();
  }, [])
  return (
    <>
      <div className="text-center justify-center flex bg-white text-black">
        <div className='flex flex-col mt-16'>
          <div className='text-6xl font-sans font-bold'>
            Simple pricing for all your
          </div>
          <div className='text-6xl font-sans font-bold' style={{ backgroundImage: `url(${Vector})`, backgroundRepeat: "no-repeat", backgroundPositionX: "right" }}>
            financial needs
          </div>
          <div className='text-2xl font-serif flex flex-row text-center justify-center'>start for free,upgrade when you <MdFavorite className="text-red-500 text-2xl font-semibold" /> it!.</div>
        </div>
      </div>
      <div className='flex text-center justify-center bg-white text-black'>
        <div className="flex flex-row text-center justify-center flex-wrap gap-6 p-6">
          {
            plans.map((item, index) => {
              var imgName = '';
              if (item.name === 'Silver Plan') {
                imgName = silver;
              } else if (item.name === 'Gold Plan') {
                imgName = gold;
              } else if (item.name === 'Platinum Plan') {
                imgName = platinum;
              }
              return (
                <div key={`plan-00${index}`} className="bg-white mt-10 border-t-8 border-blue-500 rounded-xl shadow-lg flex flex-col">
                  {/* Plan Header */}
                  <div className="flex justify-center mt-5">
                    <h3 className="text-xl font-medium text-blue-800 flex items-center">
                      <img src={imgName} alt="plan-logo" className="h-10 w-10 mr-3" />
                      {item.name}
                    </h3>
                  </div>

                  <hr className="my-2 border-gray-300" />

                  {/* Plan Price */}
                  <div className="text-center text-black font-semibold">
                    <p className="text-4xl flex items-center justify-center">
                      <MdCurrencyRupee className="text-5xl mr-1" /> {item.total}
                    </p>
                    <p className="text-sm text-gray-500">(Incl.18% GST)</p>
                  </div>

                  <hr className="mt-3 h-1 bg-black" />

                  {/* Plan Features */}
                  <div className="p-5">
                    <div className="font-semibold text-lg mb-3">Features/Benefits</div>

                    <div className="flex items-center mb-2 text-gray-700">
                      <FaCheckCircle className="text-blue-600 mr-2" />
                      Maximum <span className="font-semibold text-blue-800 ml-1">{item.coins}</span> coins
                    </div>

                    <div className="flex items-center mb-2 text-gray-700">
                      <FaCheckCircle className="text-blue-600 mr-2" />
                      <span className="font-semibold text-blue-800">{item.validity}</span> day validation
                    </div>

                    <div className="flex items-center mb-2 text-gray-700">
                      <FaCheckCircle className="text-blue-600 mr-2" />
                      <span className="font-semibold text-blue-800">{item.rsPerCoin}</span> Rupee Per Coin
                    </div>

                    {item.availableFeatures.map((feature, ix) => (
                      <div key={`planfeature-00${index}-${ix}`} className="flex items-center mb-2 text-gray-700">
                        <FaCheckCircle className="text-blue-600 mr-2" />
                        {feature}
                      </div>
                    ))}
                  </div>
                </div>

              )
            })
          }
        </div>
      </div>
    </>
  );
};

export default Plan;
