import React from 'react';
import { saveAs } from 'file-saver';
const ExcelJS = require('exceljs');

const FinalExcel = (props) => {
   
    const generateCombineResult=(workbook)=>{
        const worksheet5 = workbook.addWorksheet(`combinedStatisticalResult`);
        const worksheet4 = workbook.addWorksheet(`combinedResult`);
        const worksheet2 = workbook.addWorksheet('combinedTradeResult');
        const inputValue=props.inputData[0];

        const daywise = [['DAYWISE PROFITABILITY'], ["Serial", "Mon", "Tue", "Wed", "Thu", "Fri"], props.combineWeek, props.combineWeek, [], [], [], [], ['MONTHLY PROFITABILITY'], ['No.', "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"], props.combineMonth, props.combineMonth];
        worksheet4.addRows(daywise);
        worksheet4.mergeCells('A1:G1');
        worksheet4.getCell('A3').value = '2020';
        worksheet4.getCell('A4').value = 'Total';
        worksheet4.getCell('A1').alignment = { horizontal: 'center' }
        worksheet4.getCell('A1').font = {
            size: 10, // Set font size to 16 (adjust as needed)
            name: 'serif', // Set font family (e.g., Arial)
            bold: true, // Set font weight to bold
            color: { argb: 'FFFFFF' }
        };
        const formattingOptions1 = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DBEAFE' }
        };
        worksheet4.getCell('A1').fill={
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4682B4' }
        };
        worksheet4.getCell('A9').font = {
            size: 10, // Set font size to 16 (adjust as needed)
            name: 'serif', // Set font family (e.g., Arial)
            bold: true, // Set font weight to bold
            color: { argb: 'FFFFFF' }
        };
        worksheet4.mergeCells('A9:G9');
        worksheet4.getCell('A9').fill={
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4682B4' }
        };

        

          // Add border to the cell
          const borderStyle = {
            style: 'medium',
            color: { argb: '000000' } // Black color
        };

        const cellA1 = worksheet5.getCell('A1');
        worksheet5.mergeCells('A1:G1');
        cellA1.value = 'FPROGNOS';
        cellA1.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4682B4' }
        };
        worksheet5.getCell('A1').font = {
            color: { argb: 'FFFFFF' }
        }
        worksheet5.getCell('A1').alignment = { horizontal: 'center' }
        worksheet5.getCell('A1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };


        const cellA2 = worksheet5.getCell('H1');
        worksheet5.mergeCells('H1:N1');
        // cellA2.value = props.name;
        cellA2.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4682B4' }
        };
        worksheet5.getCell('H1').font = {
            color: { argb: 'FFFFFF' }
        }
        worksheet5.getCell('H1').alignment = { horizontal: 'center' }
        worksheet5.getCell('H1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

        // Adding data to worksheet5
        const cellD1 = worksheet5.getCell('E3');
        worksheet5.mergeCells('E3:P3');
        cellD1.value = 'Basket';
        cellD1.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4682B4' }
        };
        worksheet5.getCell('E3').font = {
            color: { argb: 'FFFFFF' }
        }
        worksheet5.getCell('E3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('E3').alignment = { horizontal: 'center' }

        worksheet5.getCell('E5').value = 'S.No.';
        worksheet5.getCell('E5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('F5').value = 'Run';
        worksheet5.mergeCells('F5:G5');
        worksheet5.getCell('F5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('H5').value = 'Strategy';
        worksheet5.mergeCells('H5:I5');
        worksheet5.getCell('H5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('J5').value = 'Strategy Name';
        worksheet5.mergeCells('J5:K5');
        worksheet5.getCell('J5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('L5').value = 'Day';
        worksheet5.mergeCells('L5:P5');
        worksheet5.getCell('L5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };


        for(let i=0;i<props.activeBasket.strategy.length;i++)
        {
            let char = 'J';
            let k = String.fromCharCode(char.charCodeAt(0) + i);
            // console.log(k,'value at k');
            worksheet5.getCell(`E${6+i}`).value = i+1;
            worksheet5.getCell(`E${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`F${6+i}`).value = 'Run';
            worksheet5.mergeCells(`F${6+i}:G${6+i}`);
            worksheet5.getCell(`F${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`H${6+i}`).value = `#S-${i+1}`;
            worksheet5.mergeCells(`H${6+i}:I${6+i}`);
            worksheet5.getCell(`H${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`J${6+i}`).value = 'Strategy Name';
            worksheet5.getCell(`J${6+i}`).value = props.activeBasket.strategy[i].name;
            worksheet5.mergeCells(`J${6+i}:K${6+i}`);
            worksheet5.getCell(`J${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`L${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`L${6+i}`).value = 'Mon'
            worksheet5.getCell(`M${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`N${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`O${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`P${6+i}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            worksheet5.getCell(`M${6+i}`).value = 'Tue'
            worksheet5.getCell(`N${6+i}`).value = 'Wed'
            worksheet5.getCell(`O${6+i}`).value = 'Thu'
            worksheet5.getCell(`P${6+i}`).value = 'Fri'
        }
        
        const formattingOptions = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4682B4' }
        };

        // // Define an array of cell addresses where you want to apply the formatting
        // const cellAddresses = ['B15', 'C15', 'D15', 'E15', 'F15', 'H15', 'J15', 'L15', 'N15'];

        // // Apply formatting to the specified cells
        // cellAddresses.forEach(address => {
        //     const cell = worksheet5.getCell(address);
        //     cell.fill = formattingOptions;
        //     cell.font = {
        //         color: { argb: 'FFFFFF' }
        //     }
        // });


        worksheet5.getCell('B22').value = 'START TIME';
        worksheet5.mergeCells('B22:C22');
        worksheet5.getCell('B22').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('D22').value = inputValue['starttime'];
        worksheet5.mergeCells('D22:E22');
        worksheet5.getCell('D22').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('H22').value = 'END TIME';
        worksheet5.mergeCells('H22:I22');
        worksheet5.getCell('H22').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('J22').value = inputValue['endtime'];
        worksheet5.mergeCells('J22:K22');
        worksheet5.getCell('J22').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

        worksheet5.getCell('B24').alignment = { horizontal: 'center' }
        worksheet5.getCell('B25').value = 'START DATE';
        worksheet5.mergeCells('B25:C25');
        worksheet5.getCell('B25').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('D25').value = inputValue['startdate'];
        worksheet5.mergeCells('D25:E25');
        worksheet5.getCell('D25').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('H25').value = 'END DATE';
        worksheet5.mergeCells('H25:I25');
        worksheet5.getCell('H25').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('J25').value = inputValue['enddate'];
        worksheet5.mergeCells('J25:K25');
        worksheet5.getCell('J25').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };



        worksheet5.getCell('B30').value = 'STATISTICAL RESULT';
        worksheet5.mergeCells('B30:K30');
        worksheet5.getCell('B30').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '4682B4' } };
        worksheet5.getCell('B30').font = { color: { argb: 'FFFFFF' } }
        worksheet5.getCell('B30').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B30').alignment = { horizontal: 'center' }


        worksheet5.getCell('B32').value = 'Total Margin';
        worksheet5.mergeCells('B32:D32');
        worksheet5.getCell('B32').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B33').value = 'Total Profit';
        worksheet5.mergeCells('B33:D33');
        worksheet5.getCell('B33').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B34').value = 'Maximum Drawdown';
        worksheet5.mergeCells('B34:D34');
        worksheet5.getCell('B34').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B35').value = 'Max Profit';
        worksheet5.mergeCells('B35:D35');
        worksheet5.getCell('B35').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B36').value = 'Max Loss';
        worksheet5.mergeCells('B36:D36');
        worksheet5.getCell('B36').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B37').value = 'Win Days';
        worksheet5.mergeCells('B37:D37');
        worksheet5.getCell('B37').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B38').value = 'Loss Days';
        worksheet5.mergeCells('B38:D38');
        worksheet5.getCell('B38').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B39').value = 'Average Profit on Win Days';
        worksheet5.mergeCells('B39:D39');
        worksheet5.getCell('B39').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B40').value = 'Average Loss on Loss Days';
        worksheet5.mergeCells('B40:D40');
        worksheet5.getCell('B40').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B41').value = 'Investment Days';
        worksheet5.mergeCells('B41:D41');
        worksheet5.getCell('B41').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B42').value = 'Expectancy';
        worksheet5.mergeCells('B42:D42');
        worksheet5.getCell('B42').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B43').value = 'Max Continuous Profit Streak';
        worksheet5.mergeCells('B43:D43');
        worksheet5.getCell('B43').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B44').value = 'Max Continuuous Loss Streak';
        worksheet5.mergeCells('B44:D44');
        worksheet5.getCell('B44').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B45').value = 'Leg in Strategies';
        worksheet5.mergeCells('B45:D45');
        worksheet5.getCell('B45').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B46').value = 'MDD (Recovery period)';
        worksheet5.mergeCells('B46:D46');
        worksheet5.getCell('B46').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B47').value = 'Return to MDD Ratio';
        worksheet5.mergeCells('B47:D47');
        worksheet5.getCell('B47').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };


        // for (let k = 0; k < props.combineStatsResult.length; k++) {
        //     worksheet5.getCell(`E${k + 32}`).value = typeof props.combineStatsResult[k] == Number ? props.combineStatsResult[k].toFixed(2) : props.combineStatsResult[k];
        //     // worksheet5.mergeCells(`E${k + 32}:F${k + 32}`);
        //     worksheet5.getCell(`E${k + 32}`).alignment = { horizontal: 'center' }
        //     worksheet5.getCell(`E${k + 32}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        // }
        worksheet5.getCell('E45').value = inputValue['leg']
        worksheet5.getCell('E45').alignment = { horizontal: 'center' }
        worksheet5.mergeCells('E45:F45');
        worksheet5.getCell('E45').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };

        worksheet5.getCell('E46').value = '----------'
        worksheet5.mergeCells('E46:F46');
        worksheet5.getCell('E46').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('E46').alignment = { horizontal: 'center' }

        worksheet5.getCell('E47').value = '----------'
        worksheet5.mergeCells('E47:F47');
        worksheet5.getCell('E47').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('E47').alignment = { horizontal: 'center' }


        // Slippages
        worksheet5.getCell('B50').value = 'Slippages';
        worksheet5.mergeCells('B50:D50');
        worksheet5.getCell('B50').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('B51').value = 'Vix';
        worksheet5.mergeCells('B51:D51');
        worksheet5.getCell('B51').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('E50').value = 0;
        worksheet5.getCell('E50').alignment = { horizontal: 'center' }
        worksheet5.mergeCells('E50:F50');
        worksheet5.getCell('E50').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('E51').value = '-----'
        worksheet5.mergeCells('E51:F51');
        worksheet5.getCell(`E51`).alignment = { horizontal: 'center' }
        worksheet5.getCell('E51').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
        worksheet5.getCell('G50').value = '(0.5% Slippages are already included for options strategies)'
        worksheet5.mergeCells('G50:M50');
        worksheet5.getCell('G50').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
       

        // props.combineTradingResult.unshift(['Combined Trading Profit']);
        // worksheet2.addRows(props.combineTradingResult);


        worksheet2.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '4682B4' } };
        worksheet2.getCell('A1').font = {
            size: 10, name: 'serif', bold: true, color: { argb: 'FFFFFF' }
        };
        worksheet2.mergeCells('A1:G1');


        worksheet2.eachRow((row) => {
            row.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            });
        });
        const worksheet = workbook.getWorksheet(3); // Index is 1-based
        // Set the width for each column
        worksheet.columns.forEach(column => {
            column.width = 30; // Set the width as needed (in characters)
        });
        
    }
    // --------------------------------------------------------------------------------------------
    const finalResultExcel = () => {
        const workbook = new ExcelJS.Workbook();
        generateCombineResult(workbook);
        for (let i = 0; i < props.week.length; i++) {
            generateExcel1(i,workbook,props.inputData[i],props.week[i],props.month[i],props.result[i],props.StatisticalResult[i]);
            // console.log(props.week[i],props.month[i],'week and month profit')
        }
       
        // Save the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
            props.strategyName
                ? saveAs(data, `${props.strategyName}_fprognos.xlsx`)
                : saveAs(data, `backtest_fprognos.xlsx`);
        });
    }
    
    
        const generateExcel1 = (index,workbook,inputData,week,month,trade,statisticalResult) => {
            const worksheet1 = workbook.addWorksheet(`Strategy${index}`);
            const worksheet2 = workbook.addWorksheet(`Result${index}`);
            const worksheet3 = workbook.addWorksheet(`Periodical Result${index}`);
        
            // Add border to the cell
             const borderStyle = { style: 'medium', color: { argb: '000000' }}; 
            // ---------------------------------------Trade Shown Result---------------------------------------------------
            // Example: Adding data to worksheet2
            trade.unshift(['LIST OF TRADES']);
            worksheet2.addRows(trade);
            let char='A'
            let No_Of_Leg=trade[2].length;
        
            let k1 = String.fromCharCode(char.charCodeAt(0) + No_Of_Leg-1);
            worksheet2.mergeCells(`A1:${k1}1`);
            worksheet2.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#2D5BFF' }};
            worksheet2.getCell('A1').font = { size: 10, name: 'serif', bold: true, color: { argb: 'FFFFFF' }};
            
            // Giving width of 30
            const worksheet = workbook.getWorksheet(2); // Index is 1-based
            // Set the width for each column
            worksheet.columns.forEach(column => {
              column.width = 30; // Set the width as needed (in characters)
            });
        
            worksheet2.eachRow((row) => {
              row.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center' };
                cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
              });
            });
            
            // Using E Data
            // worksheet2.getCell('A1').alignment = { horizontal: 'center' }
            // for (let i = 0; i < props.e.length; i++) {
            //   worksheet2.getCell(`E${i + 3}`).value = props.e[i];
            // }
            // for (let i = 0; i < No_Of_Leg; i++) {
            //   let char = 'A';
            //   let k = String.fromCharCode(char.charCodeAt(0) + i);
            //   worksheet2.getCell(`${k}2`).fill = {
            //     type: 'pattern',
            //     pattern: 'solid',
            //     fgColor: { argb: 'DBEAFE' }
            //   };
            //   worksheet2.getCell(`${k}2`).font = {
            //     size: 11, // Set font size to 16 (adjust as needed)
            //     name: 'serif', // Set font family (e.g., Arial)
            //     color: { argb: '964B00' }
            //   };
            //   worksheet2.getCell(`${k}2}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            // }
            // -----------------------------------Trade Show Result----------------------------------------------------------------
        
            // --------------------------------------Weekly and Monthly Result---------------------------------------------------------
            // Example: Adding data to worksheet3
            const weekly = [['Day Wise Breakup'], ["Serial", "Mon", "Tue", "Wed", "Thu", "Fri"]]
            const monthly = [['No.', "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec","Total","MDD","ROI"]];
            worksheet3.addRows(weekly);
            worksheet3.getCell('A1').alignment = { horizontal:'center'}
            worksheet3.getCell('A1').font = { size: 11, name: 'calibri', bold: true, color: { argb: 'FFFFFF' }};
            worksheet3.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#ADD8E6' } };
            worksheet3.mergeCells('A1:F1');
        
            let week_cnt=0
            for(const key in week){
              worksheet3.getCell(`A${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet3.getCell(`A${week_cnt+3}`).value =key
              worksheet3.getCell(`B${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet3.getCell(`B${week_cnt+3}`).value =week[key][0]
              worksheet3.getCell(`C${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet3.getCell(`C${week_cnt+3}`).value =week[key][1]
              worksheet3.getCell(`D${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet3.getCell(`D${week_cnt+3}`).value =week[key][2]
              worksheet3.getCell(`E${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet3.getCell(`E${week_cnt+3}`).value =week[key][3]
              worksheet3.getCell(`F${week_cnt+3}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet3.getCell(`F${week_cnt+3}`).value =week[key][4]
              week_cnt+=1
            }
            
            
            // Monthly Style
            worksheet3.getCell('A9').value = 'Monthly Breakup';
            worksheet3.getCell('A9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet3.getCell('A9').alignment = { horizontal: 'center' }
            worksheet3.getCell('A9').fill = { type: 'pattern', pattern: 'solid',}; // fgColor: { argb: '#ADD8E6' }
            worksheet3.getCell('A9').font = { size: 11, name: 'calibri', bold: true, color: { argb: 'FFFFFF' } };
            worksheet3.mergeCells('A9:P9');
        
            worksheet3.addRows(monthly);
            
            let month_cnt=0
            for(const key in month){
                  worksheet3.getCell(`A${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`A${month_cnt+11}`).value = key
                  worksheet3.getCell(`B${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`B${month_cnt+11}`).value =month[key][1]
                  worksheet3.getCell(`C${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`C${month_cnt+11}`).value =month[key][2]
                  worksheet3.getCell(`D${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`D${month_cnt+11}`).value =month[key][3]
                  worksheet3.getCell(`E${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`E${month_cnt+11}`).value =month[key][4]
                  worksheet3.getCell(`F${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`F${month_cnt+11}`).value =month[key][5]
                  worksheet3.getCell(`G${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`G${month_cnt+11}`).value =month[key][6]
                  worksheet3.getCell(`H${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`H${month_cnt+11}`).value =month[key][7]
                  worksheet3.getCell(`I${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`I${month_cnt+11}`).value =month[key][8]
                  worksheet3.getCell(`J${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`J${month_cnt+11}`).value =month[key][9]
                  worksheet3.getCell(`K${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`K${month_cnt+11}`).value =month[key][10]
                  worksheet3.getCell(`L${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`L${month_cnt+11}`).value =month[key][11]
                  worksheet3.getCell(`M${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`M${month_cnt+11}`).value =month[key][12]
                  worksheet3.getCell(`N${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`N${month_cnt+11}`).value = month[key][13]   
                  worksheet3.getCell(`O${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`O${month_cnt+11}`).value =month[key][14]
                  worksheet3.getCell(`P${month_cnt+11}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
                  worksheet3.getCell(`P${month_cnt+11}`).value = month[key][15]
                  month_cnt+=1
            }
         
           
        
            // Giving the style 
            // Define an array of cell addresses where you want to apply the formatting
            // const cellAddresses1 = ['A10', 'B10', 'C10', 'D10', 'E10', 'F10', 'G10', 'H10', 'I10', 'J10', 'K10', 'L10', 'M10','N10','O10','P10','A2', 'B2', 'C2', 'D2', 'E2', 'F2'];
            // // Apply formatting to the specified cells
            // const formattingOptions1 = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' }};
            // cellAddresses1.forEach(address => {
            //   const cell = worksheet3.getCell(address);
            //   cell.fill = formattingOptions1;
            //   cell.font = { size: 11, name: 'serif', color: { argb: '964B00' }};
            //   cell.border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle };
            // });
        
            const cellAddresses2 = ['A11', 'B11', 'C11', 'D11', 'E11', 'F11', 'G11', 'H11', 'I11', 'J11', 'K11', 'L11', 'M11','N11','O11','P11','A3', 'B3', 'C3', 'D3', 'E3', 'F3','A12', 'B12', 'C12', 'D12', 'E12', 'F12', 'G12', 'H12', 'I12', 'J12', 'K12', 'L12', 'M12', 'A4', 'B4', 'C4', 'D4', 'E4', 'F4','A10', 'B10', 'C10', 'D10', 'E10', 'F10', 'G10', 'H10', 'I10', 'J10', 'K10', 'L10', 'M10','N10','O10','P10', 'A2', 'B2', 'C2', 'D2', 'E2', 'F2']
            cellAddresses2.forEach(address => {
              const cell = worksheet3.getCell(address);
              cell.border = { top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle };
            });
            
            
            
        //     // ---------------------------------------Weekly and Monthly End -----------------------------------

        //     // --------------------------------------------Statistical Result ----------------------------------------
            // Example: Applying a background color to a cell in worksheet1
            const cellA1 = worksheet1.getCell('A1');
            worksheet1.mergeCells('A1:J1');
            cellA1.value = 'FPROGNOS';
            cellA1.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '#2D5BFF' }
            };
            worksheet1.getCell('A1').font = {
              color: { argb: 'FFFFFF' }
            }
            worksheet1.getCell('A1').alignment = { horizontal: 'center' }
            worksheet1.getCell('A1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
        
        
            const cellA2 = worksheet1.getCell('K1');
            worksheet1.mergeCells('K1:S1');
            cellA2.value = props.name;
            cellA2.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '2D5BFF' }
            };
            worksheet1.getCell('K1').font = {
              color: { argb: 'FFFFFF' }
            }
            worksheet1.getCell('K1').alignment = { horizontal: 'center' }
            worksheet1.getCell('K1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
        
        
        
            // Adding data to worksheet1
            const cellD1 = worksheet1.getCell('B3');
            worksheet1.mergeCells('B3:S3');
            cellD1.value = 'POSITION';
            cellD1.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '2D5BFF' }
            };
            worksheet1.getCell('B3').font = {
              color: { argb: 'FFFFFF' }
            }
            worksheet1.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell('B3').alignment = { horizontal: 'center' }
        
            worksheet1.getCell('F5').value = 'ATM';
            worksheet1.mergeCells('F5:G5');
            worksheet1.getCell('F6').value = 'SquareOff';
            worksheet1.mergeCells('F6:G6');
            worksheet1.getCell('F7').value = 'Wait & Trade';
            worksheet1.mergeCells('F7:G7');
            worksheet1.getCell('F8').value = 'Re-Entry/ReExecute';
            worksheet1.mergeCells('F8:G8');
            worksheet1.getCell('F9').value = 'MoveSlToCost';
            worksheet1.mergeCells('F9:G9');
            
            
            worksheet1.getCell('H5').value = 'Future';
            worksheet1.getCell('H5').alignment = { horizontal: 'center' }
            worksheet1.getCell('H6').value = inputData['squareoff']=='one'?"Leg":"All";
            worksheet1.getCell('H6').alignment = { horizontal: 'center' }
            worksheet1.getCell('H7').value = inputData['waitTrade'];
            worksheet1.getCell('H7').alignment = { horizontal: 'center' }
            worksheet1.getCell('H8').value = inputData['reEntry'];
            worksheet1.getCell('H8').alignment = { horizontal: 'center' }
            worksheet1.getCell('H9').value = inputData['slToCost'];
            worksheet1.getCell('H9').alignment = { horizontal: 'center' }
        
        
            worksheet1.getCell('B11').value = 'Include';
            worksheet1.getCell('B11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell('C11').value = 'Total Lot';
            worksheet1.getCell('C11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell('D11').value = 'ATM';
            worksheet1.getCell('D11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.mergeCells('D11:E11');
            worksheet1.getCell('F11').value = 'Action';
            worksheet1.getCell('F11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell('G11').value = 'Option';
            worksheet1.getCell('G11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell('H11').value = 'Wait & Trade';
            worksheet1.getCell('H11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.mergeCells('H11:I11');
            worksheet1.getCell('J11').value = 'Target Profit';
            worksheet1.getCell('J11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.mergeCells('J11:K11');
            worksheet1.getCell('L11').value = 'Target Stoploss';
            worksheet1.getCell('L11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.mergeCells('L11:M11');
            worksheet1.getCell('N11').value = 'Trail Stoploss';
            worksheet1.getCell('N11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.mergeCells('N11:O11');
            worksheet1.getCell('P11').value = 'Re-Entry';
            worksheet1.getCell('P11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.mergeCells('P11:Q11');
            worksheet1.getCell('R11').value = 'Index';
            worksheet1.getCell('R11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell('S11').value = 'Expiry';
            worksheet1.getCell('S11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
        
            const formattingOptions = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#2D5BFF' }};
        
            // Define an array of cell addresses where you want to apply the formatting
            const cellAddresses = ['B11','C11','D11', 'F11','G11','H11','J11','L11','N11','P11','R11','S11'];
        
            // Apply formatting to the specified cells
            cellAddresses.forEach(address => {
              const cell = worksheet1.getCell(address);
              cell.fill = formattingOptions;
              cell.font = {
                color: { argb: 'FFFFFF' }
              }
            });
        
        
            for (let i = 0; i < inputData['leg']; i++) {
              worksheet1.getCell(`B${i + 12}`).value = true;
              worksheet1.getCell(`B${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.getCell(`C${i + 12}`).value = `${inputData['lotarray'][i]} lot`
              worksheet1.getCell(`C${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.getCell(`D${i + 12}`).value = `${inputData['atm'][i]} `
              worksheet1.getCell(`D${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.mergeCells(`D${i + 12}:E${i + 12}`);
              worksheet1.getCell(`F${i + 12}`).value = inputData['legarray'][i][0]=='B'?'BUY':"SELL";
              worksheet1.getCell(`F${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.getCell(`G${i + 12}`).value = inputData['legarray'][i][1]=='C'?'CALL':"PUT";
              worksheet1.getCell(`G${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.getCell(`H${i + 12}`).value = inputData['waitTrade']?(inputData['waitTradeType'][i] +" "+ inputData['waitTradeValue'][i]):' ';
              worksheet1.getCell(`H${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.mergeCells(`H${i + 12}:I${i + 12}`);
              worksheet1.getCell(`J${i + 12}`).value = inputData['legsTargetProfit'][i] == '-1' ? '' :(inputData['legsTargetProfitType'][i] + " " + inputData['legsTargetProfit'][i]);
              worksheet1.getCell(`J${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.mergeCells(`J${i + 12}:K${i + 12}`);
              worksheet1.getCell(`L${i + 12}`).value = inputData['legsStoploss'][i] == '-1' ? '' : (inputData['legsStoplossType'][i] +" " + inputData['legsStoploss'][i]);
              worksheet1.getCell(`L${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.mergeCells(`L${i + 12}:M${i + 12}`);
              worksheet1.getCell(`N${i + 12}`).value = inputData['trailStoploss'][i] ? `Tsl ${inputData['trailStoplossType'][i]} : ${inputData['trailStoplossLoss'][i]},${inputData['trailStoplossLoss'][i]}`: "";
              worksheet1.getCell(`N${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.mergeCells(`N${i + 12}:O${i + 12}`);
              worksheet1.getCell(`P${i + 12}`).value = inputData['reEntry']?(inputData['reEntryChoice'][i] + " " + inputData['reEntryValue'][i]):'';
              worksheet1.mergeCells(`P${i + 12}:Q${i + 12}`);
              worksheet1.getCell(`P${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.getCell(`R${i + 12}`).value = inputData['tradesymbol'];
              worksheet1.getCell(`R${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
              worksheet1.getCell(`S${i + 12}`).value = inputData['legExpiryType'][i];
              worksheet1.getCell(`S${i + 12}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
            }
        
          //  Entry and Exit Time
            worksheet1.getCell('B22').value = 'Entry Time';
            worksheet1.mergeCells('B22:C22');
            worksheet1.getCell('D22').value = inputData['starttime'];
            worksheet1.mergeCells('D22:E22');
            worksheet1.getCell('H22').value = 'Exit Time';
            worksheet1.mergeCells('H22:I22');
            worksheet1.getCell('J22').value = inputData['endtime'];
            worksheet1.mergeCells('J22:K22');
          
          //  Start Date and Exit Date
            worksheet1.getCell('B24').value = 'From Date';
            worksheet1.mergeCells('B24:C24');
            worksheet1.getCell('D24').value = inputData['startdate'];
            worksheet1.mergeCells('D24:E24');
            worksheet1.getCell('H24').value = 'To Date';
            worksheet1.mergeCells('H24:I24');
            worksheet1.getCell('J24').value = inputData['enddate'];
            worksheet1.mergeCells('J24:K24');
        
        
           // strategy Profit and Strategy Stoploss
            worksheet1.getCell('B26').value = 'Target Profit';
            worksheet1.mergeCells('B26:C26');
            worksheet1.getCell('D26').value = inputData['maxprofit']==-1?"":inputData['maxprofit'];
            worksheet1.getCell('D26').alignment = { horizontal: 'left'}
            worksheet1.mergeCells('D26:E26');
            worksheet1.getCell('H26').value = 'Stoploss';
            worksheet1.mergeCells('H26:I26');
            worksheet1.getCell('J26').value = inputData['stoploss']==1?"":inputData['stoploss'];
            worksheet1.mergeCells('J26:K26');
            worksheet1.getCell('J26').alignment = { horizontal: 'left'}
        
            // Protect Profit
            worksheet1.getCell('B28').value = 'Protect Profit';
            worksheet1.mergeCells('B28:C28');
            worksheet1.getCell('D28').value = inputData['protectProfit']? inputData['protectType'] + "" + "lockMinimumProfit" +  inputData['lockMinimumProfit'] + " trailProfit" + inputData['trailProfit'] + "increaseProfit" + inputData['profitIncrease'] + "profitReaches" + inputData['profitReaches']:"";
            worksheet1.getCell('D28').alignment = { horizontal: 'left' }
            worksheet1.mergeCells('D28:J28');
        
            // Range Break Out
            worksheet1.getCell('B30').value = 'Range StartTime';
            worksheet1.mergeCells('B30:C30');
            worksheet1.getCell('D30').value = inputData['showRangeBreakOut']?inputData['rangeStartTime']:"";
            worksheet1.getCell('D30').alignment = { horizontal: 'left' }
            worksheet1.mergeCells('D30:E30');
            worksheet1.getCell('H30').value = 'Range End Time';
            worksheet1.mergeCells('H30:I30');
            worksheet1.getCell('H30').value = inputData['showRangeBreakOut']?inputData['rangeEndTime']:"";
            worksheet1.getCell('H30').alignment = { horizontal: 'left' }
        
            // Intraday or Positional
            worksheet1.getCell('B32').value = 'Trade Type';
            worksheet1.mergeCells('B32:C32');
            worksheet1.getCell('D32').value = inputData['intraday']=="Yes"?"Intraday":"Positional";
            worksheet1.getCell('D32').alignment = { horizontal: 'left' }
            worksheet1.mergeCells('D32:H32');
        
        
            
            worksheet1.mergeCells('B34:S34');
            worksheet1.getCell('B34').value = 'STATISTICAL RESULT';
            worksheet1.getCell('B34').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: '#2D5BFF' } };
            worksheet1.getCell('B34').font = {color: { argb: 'FFFFFF' } }
            worksheet1.getCell('B34').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell('B34').alignment = { horizontal: 'center' }
            
            let cnt1=36
            // Slippages
            worksheet1.getCell(`B${cnt1+0}`).value = `Slippages`;
            worksheet1.mergeCells(`B${cnt1+0}:D${cnt1+0}`);
            worksheet1.getCell(`B${cnt1+0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            
            worksheet1.getCell(`B${cnt1+1}`).value = `Vix`;
            worksheet1.mergeCells(`B${cnt1+1}:D${cnt1+1}`);
            worksheet1.getCell(`B${cnt1+1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            
            worksheet1.getCell(`E${cnt1+0}`).value = props.slippage;
            worksheet1.getCell(`E${cnt1+0}`).alignment = { horizontal: `center` }
            worksheet1.mergeCells(`E${cnt1+0}:F${cnt1+0}`);
            worksheet1.getCell(`E${cnt1+0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            
            worksheet1.getCell(`E${cnt1+1}`).value = `${props.vixStart} to ${props.vixEnd}`
            worksheet1.mergeCells(`E${cnt1+1}:F${cnt1+1}`);
            worksheet1.getCell(`E${cnt1+1}`).alignment = { horizontal: `center` }
            worksheet1.getCell(`E${cnt1+1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            
            worksheet1.getCell(`G${cnt1+0}`).value = `(0.5% Slippages are already included for options strategies)`
            worksheet1.mergeCells(`G${cnt1+0}:M${cnt1+0}`);
            worksheet1.getCell(`G${cnt1+0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
        
            let cnt=40
            worksheet1.getCell(`B${cnt+0}`).value = 'Total Margin';
            worksheet1.mergeCells(`B${cnt+0}:D${cnt+0}`);
            worksheet1.getCell(`B${cnt+0}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+1}`).value = `Total Profit`;
            worksheet1.mergeCells(`B${cnt+1}:D${cnt+1}`);
            worksheet1.getCell(`B${cnt+1}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+2}`).value = `Maximum Drawdown`;
            worksheet1.mergeCells(`B${cnt+2}:D${cnt+2}`);
            worksheet1.getCell(`B${cnt+2}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+3}`).value = `Max Profit`;
            worksheet1.mergeCells(`B${cnt+3}:D${cnt+3}`);
            worksheet1.getCell(`B${cnt+3}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+4}`).value = `Max Loss`;
            worksheet1.mergeCells(`B${cnt+4}:D${cnt+4}`);
            worksheet1.getCell(`B${cnt+4}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+5}`).value = `Win Days`;
            worksheet1.mergeCells(`B${cnt+5}:D${cnt+5}`);
            worksheet1.getCell(`B${cnt+5}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+6}`).value = `Loss Days`;
            worksheet1.mergeCells(`B${cnt+6}:D${cnt+6}`);
            worksheet1.getCell(`B${cnt+6}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+7}`).value = `Average Profit on Win Days`;
            worksheet1.mergeCells(`B${cnt+7}:D${cnt+7}`);
            worksheet1.getCell(`B${cnt+7}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+8}`).value = `Average Loss on Loss Days`;
            worksheet1.mergeCells(`B${cnt+8}:D${cnt+8}`);
            worksheet1.getCell(`B${cnt+8}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+9}`).value = `Investment Days`;
            worksheet1.mergeCells(`B${cnt+9}:D${cnt+9}`);
            worksheet1.getCell(`B${cnt+9}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+10}`).value = `Expectancy`;
            worksheet1.mergeCells(`B${cnt+10}:D${cnt+10}`);
            worksheet1.getCell(`B${cnt+10}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+11}`).value = `Max Continuous Profit Streak`;
            worksheet1.mergeCells(`B${cnt+11}:D${cnt+11}`);
            worksheet1.getCell(`B${cnt+11}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+12}`).value = `Max Continuuous Loss Streak`;
            worksheet1.mergeCells(`B${cnt+12}:D${cnt+12}`);
            worksheet1.getCell(`B${cnt+12}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+13}`).value = `Leg in Strategies`;
            worksheet1.mergeCells(`B${cnt+13}:D${cnt+13}`);
            worksheet1.getCell(`B${cnt+13}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+14}`).value = `MDD (Recovery period)`;
            worksheet1.mergeCells(`B${cnt+14}:D${cnt+14}`);
            worksheet1.getCell(`B${cnt+14}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`B${cnt+15}`).value = `Return to MDD Ratio`;
            worksheet1.mergeCells(`B${cnt+15}:D${cnt+15}`);
            worksheet1.getCell(`B${cnt+15}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
        
        
            for (let k = 0; k < statisticalResult.length; k++) {
              worksheet1.getCell(`E${k + cnt}`).value = typeof statisticalResult[k] == Number ? statisticalResult[k].toFixed(2) : statisticalResult[k];
              worksheet1.mergeCells(`E${k + cnt}:F${k + cnt}`);
              worksheet1.getCell(`E${k + cnt}`).alignment = { horizontal: 'center' }
              worksheet1.getCell(`E${k + cnt}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            }
            worksheet1.getCell(`E${cnt-7+20}`).value = inputData[`leg`]
            worksheet1.getCell(`E${cnt-7+20}`).alignment = { horizontal: `center`}
            worksheet1.getCell(`E${cnt-7+20}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
        
            worksheet1.getCell(`E${cnt-7+21}`).value = `----------`
            worksheet1.getCell(`E${cnt-7+21}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`E${cnt-7+21}`).alignment = { horizontal: `center`}
        
        
            worksheet1.getCell(`E${cnt-7+22}`).value = `----------`
            worksheet1.getCell(`E${cnt-7+22}`).border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
            worksheet1.getCell(`E${cnt-7+22}`).alignment = { horizontal: `center`}
        
        };
   

    return (
        <div className='w-full'>
            <button className="bg-blue-600 text-white p-1 h-8 rounded font-serif w-full" onClick={finalResultExcel}>Download Excel</button>
        </div>
    );
};

export default FinalExcel;
