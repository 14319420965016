import React, { useState, useEffect, useRef } from "react";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import { toast } from 'react-toastify'
import { Dialog } from '@mui/material';
import { Audio, ColorRing } from 'react-loader-spinner';
// constant
import { PRE_DEFINED_STRATEGY, DELETE_BASKET, RENAME_BASKET_NAME, FETCH_BASKET_NAME, GET_STRATEGIES, BACKTEST, UPDATE_BASKET, NIFTY_LOT_SIZE, BANKNIFTY_LOT_SIZE } from "../../constant/constant";
import { useNavigate } from "react-router-dom";
import { ADD_STRATEGY_BUCKET } from "../../constant/constant";
// import component
import BucketResult from "./Result/BucketResult";
import useBucketStore from "../../store/BucketStore";
import Backtest from "./../Backtest/Features/BucketBacktest";
// icon and other stuff


import { FaCheck, FaPlus } from "react-icons/fa";
import { MdImportExport } from "react-icons/md";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { MdDeleteForever } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { MdAppRegistration } from "react-icons/md";

// empty strategy
import { emptyStrategy } from './Constant'
// error message
import { extractErrorMessage } from './../../utility/utils'
import { handleNumber } from "./Util";
import DateTime from "./DateTime";

const Bucket = (props) => {
  const navigate = useNavigate();
  // select all 
  const [selectAllDay, setSelectAllDay] = useState(true);
  const [selectAllDayList, setSelectAllDayList] = useState([true, true, true, true, true])
  const [selectAllStrategy, setSelectAllStrategy] = useState(true);

  const [visible, setVisible] = useState(false);
  const [visibleImportBasket, setVisibleImportBasket] = useState(false);
  const [visibleImportStrategy, setVisibleImportStrategy] = useState(false);
  const [visibleRename, setVisibleRename] = useState(false);
  const [result, setResult] = useState([]);
  const [bucketResultVisible, setBucketResultVisible] = useState(false);
  const [updateStrategy, setUpdateStrategy] = useState([]);
  const [update, setUpdate] = useState(false);
  const [copyStrategy, setCopyStrategy] = useState(false);
  const [wait, setWait] = useState(false);
  const [strategyChecked, setStrategyChecked] = useState([]);
  const [waitSelected, setWaitSelected] = useState(false);
  const [executedStrategy, setExecutedStrategy] = useState([]);
  const [visibleDeleteStrategy, setVisibleDeleteStrategy] = useState(false);
  const [visibleDeleteBasket, setVisibleDeleteBasket] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState();

  // use of Zustand to Store Management
  const { updateBoxShow, basketList, updateBasketList, addStrategiesOnActiveBasket } = useBucketStore();
  const { savedstrategies, updateSavedStrategies } = useBucketStore();
  const { activeBasketName, updateActiveBasketName } = useBucketStore();
  const { activeBasket, updateActiveBasket, deleteStrategiesOnActiveBasket, updateDayOptionStrategyOnActiveBasket, updateDayVariantStrategyOnActiveBasket } = useBucketStore();
  const [inputData, setInputData] = useState([]);
  const [currentBucketName, setCurrentBucketName] = useState();
  const [updateIndex, setUpdateIndex] = useState('');
  const [activeRange, setActiveRange] = useState('1');
  const { strategyMultiplier, updateStatsInclude, updateStatsMultiplier, include } = useBucketStore();
  // import Basket list and choose strategy from it.
  const [chooseBasket, setChooseBasket] = useState([]);
  const [chooseStrategy, setChooseStrategy] = useState([]);
  const [chooseAllStrategy, setChooseAllStrategy] = useState(false)
  // check to run strategy
  const [checkToRunStrategy, setCheckToRunStrategy] = useState(false);
  const [alertMessage, SetAlertMessage] = useState('Do You Really Want to Execute All Selected Strategy?')
  // handle day option
  const [dayOption, setDayOption] = useState();
  // preDefined Strategy
  const [preDefinedStrategy, setPreDefinedStrategy] = useState([])
  //handle strategy Multiplier
  // change setting
  const [setting, setSetting] = useState(false);
  const [settingDetail, setSettingDetail] = useState({ "bankniftylotsize": 15, "niftylotsize": 25, "slippage": 0 });
  const BankniftyLotSize = useRef(40);
  const NiftyLotSize = useRef(50);
  const slippageList = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9]
  const inbuiltSlippage = useRef(1);
  // advanced setting
  const [strategy, setStrategy] = useState({})
  // Slippage
  const { slippage, updateSlippage, removeSlippage, removeStrategyMultiplier, updateStrategyResultInclude, strategyResultInclude } = useBucketStore();
  // Confirmation of completely update all state when we are adding any strategy
  const [confirmUpdated, setConfirmUpdated] = useState(true);
  // Confirmation of completely run one strategy single single
  const [confirmRunStrategy, setConfirmRunStrategy] = useState(true);
  // set the lot size of banknify and nifty.Also set nifty and banknifty.
  // Filtration 
  const [resultFilter, setResultFilter] = useState([])
  const [tempFilter, setTempFilter] = useState([])
  // Default strategy
  const [addDefaultStrategyVisible, setAddDefatultStrategyVisible] = useState(false);

  const handleSetting = () => {
    const banknifty_lot_size = BankniftyLotSize.current.value;
    const nifty_lot_size = NiftyLotSize.current.value;
    const inbuilt_slippage = inbuiltSlippage.current.value;
    const settingData = { "banknifty": banknifty_lot_size, "nifty": nifty_lot_size, "slippage": inbuilt_slippage };
    // console.log(settingData, 'settingData');
  }
  // all the function of this component
  // a)Execute Particular Strategy() --> Only For Execute one Strategy
  // b)Execute Strategy() -->For Execute all Strategy
  // c)delete Basket()
  // d)RenameBasketName()
  // e)OnGettingStrategies()
  // f)addBasketOnActiveBasket()
  // g)fetchBasket()

  //  This function will execute particular strategy
  const ExecuteParticularStrategy = async (item, index) => {
    setConfirmRunStrategy(false);
    const data = item.array;
    let lotsize = []
    data.tradesymbol === "NIFTY" ?
      data.lotarray.map((item, key) => { lotsize.push(item * settingDetail.niftylotsize); return item * settingDetail.niftylotsize; }) :
      data.lotarray.map((item, key) => { lotsize.push(item * settingDetail.bankniftylotsize); return item * settingDetail.bankniftylotsize; });
    const temp = {
      'leg': data.leg, 'segmentType': data.segmentType, 'lotarray1': lotsize, 'legarray': data.legarray, 'darray': data.darray, 'intraday': data.intraday, 'tradesymbol': data.tradesymbol, 'gap': data.gap, 'stoploss': data.stoploss,
      'maxprofit': data.maxprofit, 'starttime': data.starttime, 'endtime': data.endtime, 'startdate': props.bucketStartDate, 'enddate': props.bucketEndDate,
      'timeGroups': data.timeGroups, 'startTimeArray': data.startTimeArray, 'endTimeArray': data.endTimeArray, 'startDay': data.startDay, 'endDay': data.endDay, 'atm': data.atm, 'atmprice': data.atmprice, 'squareoff': data.squareoff, 'waitTrade': data.waitTrade,
      'waitTradeType': data.waitTradeType, 'waitTradeValue': data.waitTradeValue, 'slToCost': data.slToCost, 'reEntry': data.reEntry, 'protectType': data.protectType, 'profitIncrease': data.profitIncrease, 'lockMinimumProfit': data.lockMinimumProfit,
      'profitReaches': data.profitReaches, 'trailProfit': data.trailProfit, 'legsStoplossType': data.legsStoplossType, 'legsStoploss': data.legsStoploss, 'legsTargetProfit': data.legsTargetProfit, 'legsTargetProfitType': data.legsTargetProfitType, 'reEntry': data.reEntry,
      'reEntryValue': data.reEntryValue, 'reEntryChoice': data.reEntryChoice, 'legSymbol': data.legSymbol, 'legExpiryType': data.legExpiryType, 'atmChoice': data.atmChoice, 'closestPremiumChoice': data.closestPremiumChoice, 'closestPremiumValue': data.closestPremiumValue,
      'legsReEntry': data.legsReEntry, 'protectProfit': data.protectProfit, 'reEntryType': data.reEntryType, "trailStoploss": data.trailStoploss, "trailStoplossLoss": data.trailStoplossLoss, "trailStoplossProfit": data.trailStoplossProfit, "trailStoplossType": data.trailStoplossType,
      "showRangeBreakout": data.showRangeBreakout, "rangeStartTime": data.rangeStartTime, "rangeEndTime": data.rangeEndTime
    }
    let token = secureLocalStorage.getItem('backtest_token');
    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    var config = { method: "post", maxBodyLength: Infinity, url: BACKTEST, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: temp, };
    try {
      const res = await axios(config);
      const response = res.data;
      if (response) {
        const res = { 'legarray': data.legarray, 'result': response.b, 'reEntryResult': response.c, 'margin': response.margin, 'lotarray': data.lotarray1, 'atm': data.atm, 'leg': data.leg }
        const tempRes = [...result]; tempRes[index] = res; setResult(tempRes); setBucketResultVisible(true);
        const tempInput = [...inputData];
        tempInput[index] = temp;
        setInputData(tempInput);
        const input1 = [...executedStrategy]; input1[index] = 2; setExecutedStrategy(input1);
        updateStrategyResultInclude([...strategyResultInclude, true]);
        // console.log(result, tempRes, tempInput, 'execute particular strategy')
      }
      setConfirmRunStrategy(true);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate('/backtest/login');
      } else {
        const input1 = [...executedStrategy]; input1[index] = 0; setExecutedStrategy(input1);
        toast.error(extractErrorMessage(error));
      }
      console.log(error, 'error at execute particular strategy')
      setConfirmRunStrategy(true);
    }
  }
  // This function will execute choice strategy and whole strategy
  const ExecuteStrategy = async (choice) => {
    const tempResult = Array(activeBasket.strategy.length).fill({});
    const input_data = Array(activeBasket.strategy.length).fill({});
    updateStatsInclude(Array(activeBasket.strategy.length).fill(true))
    updateStatsMultiplier(Array(activeBasket.strategy.length).fill(1))
    setBucketResultVisible(false);
    setExecutedStrategy((prev) => Array(activeBasket.strategy.length).fill(1));
    updateStrategyResultInclude(Array(activeBasket.strategy.length).fill(true));


    if (!choice) {
      setStrategyChecked(Array(activeBasket.strategy.length).fill(true))
    }
    choice ? setWaitSelected(true) : setWait(true);
    for (let i = 0; i < activeBasket.strategy.length; i++) {
      const data = activeBasket.strategy[i].array;
      if (strategyChecked[i]) {
        // set a lot with respect to quantity
        let lotsize = []
        data.tradesymbol === "NIFTY" ?
          data.lotarray.map((item, key) => { lotsize.push(item * settingDetail.niftylotsize); return item * settingDetail.niftylotsize; }) :
          data.lotarray.map((item, key) => { lotsize.push(item * settingDetail.bankniftylotsize); return item * settingDetail.bankniftylotsize; });
        const temp = {
          'leg': data.leg, 'segmentType': data.segmentType, 'lotarray': data.lotarray, 'lotarray1': lotsize, 'legarray': data.legarray, 'darray': data.darray, 'intraday': data.intraday, 'tradesymbol': data.tradesymbol, 'gap': data.gap, 'stoploss': data.stoploss,
          'maxprofit': data.maxprofit, 'starttime': data.starttime, 'endtime': data.endtime, 'startdate': props.bucketStartDate, 'enddate': props.bucketEndDate,
          'timeGroups': data.timeGroups, 'startTimeArray': data.startTimeArray, 'endTimeArray': data.endTimeArray, 'startDay': data.startDay, 'endDay': data.endDay, 'atm': data.atm, 'atmprice': data.atmprice, 'squareoff': data.squareoff, 'waitTrade': data.waitTrade,
          'waitTradeType': data.waitTradeType, 'waitTradeValue': data.waitTradeValue, 'slToCost': data.slToCost, 'reEntry': data.reEntry, 'protectType': data.protectType, 'profitIncrease': data.profitIncrease, 'lockMinimumProfit': data.lockMinimumProfit,
          'profitReaches': data.profitReaches, 'trailProfit': data.trailProfit, 'legsStoplossType': data.legsStoplossType, 'legsStoploss': data.legsStoploss, 'legsTargetProfit': data.legsTargetProfit, 'legsTargetProfitType': data.legsTargetProfitType, 'reEntry': data.reEntry,
          'reEntryValue': data.reEntryValue, 'reEntryChoice': data.reEntryChoice, 'legSymbol': data.legSymbol, 'legExpiryType': data.legExpiryType, 'atmChoice': data.atmChoice, 'closestPremiumChoice': data.closestPremiumChoice, 'closestPremiumValue': data.closestPremiumValue,
          'legsReEntry': data.legsReEntry, 'protectProfit': data.protectProfit, 'reEntryType': data.reEntryType, "trailStoploss": data.trailStoploss, "trailStoplossLoss": data.trailStoplossLoss, "trailStoplossProfit": data.trailStoplossProfit, "trailStoplossType": data.trailStoplossType,
          "showRangeBreakout": data.showRangeBreakout, "rangeStartTime": data.rangeStartTime, "rangeEndTime": data.rangeEndTime
        }

        // Get the token from secureLocalStorage
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
          token = token.replace('backtest_', '');
        } else {
          navigate('/backtest/login');
        }
        var config = { method: "post", maxBodyLength: Infinity, url: BACKTEST, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: temp, };
        try {
          const res = await axios(config); const response = res.data;
          if (response) {
            const res = { 'legarray': data.legarray, 'result': response.b, 'reEntryResult': response.c, 'margin': response.margin, 'lotarray': lotsize, 'atm': data.atm, 'leg': data.leg }
            tempResult[i] = res;
            input_data[i] = temp;
            setExecutedStrategy(prevState => {
              const input = [...prevState];
              input[i] = 2;
              return input;
            });

          }
        } catch (error) {
          if (error?.response?.status === 401) {
            const input = [...executedStrategy]; input[i] = 0; setExecutedStrategy(input);
            navigate('/backtest/login');
          } else {
            const input = [...executedStrategy]; input[i] = 0; setExecutedStrategy(input);
            toast.error(extractErrorMessage(error))
          }
          console.log(error, 'error at execute strategy')
        }
      }
    }
    setResult(tempResult);
    setInputData(input_data);
    setWait(false);
    setWaitSelected(false);
    setBucketResultVisible(true);

  }
  const deleteBasket = () => {
    if (activeBasketName == '') {
      toast.warning('Something Wrong!');
      return;
    }
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id"), 'bucket_name': activeBasketName });
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let config = { method: "post", maxBodyLength: Infinity, url: DELETE_BASKET, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data, };
    axios
      .request(config)
      .then((response) => {
        toast.success('Basket Deleted Successfully!!');
        fetchBasketName();
        setBucketResultVisible(false);
        setConfirm(false);
      })
      .catch((error) => {
        setConfirm(false);
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          toast.error(extractErrorMessage(error))
        }
        console.log(error, 'error at delete basket')
      });
  }

  const RenameBasketName = () => {
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id"), 'bucket_name': activeBasketName, 'new_name': currentBucketName });
    let config = {
      method: "post", maxBodyLength: Infinity, url: RENAME_BASKET_NAME,
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        toast.success('Strats name chages successfully.')
        setVisibleRename(false);
        updateActiveBasketName(currentBucketName);
        const temp = activeBasket;
        temp.bucket_name = currentBucketName;
        updateActiveBasket(temp);
        fetchBasketName();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          toast.error(extractErrorMessage(error));
        }
        console.log(error, 'error at rename basket name')
      });

  }

  const onGettingStrategies = () => {
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id") });
    let config = { method: "post", maxBodyLength: Infinity, url: GET_STRATEGIES, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data, };

    axios
      .request(config)
      .then((response) => {
        const response1 = response?.data?.data?.array
        const temp = response1 ? response1 : [];
        updateSavedStrategies([...temp, ...preDefinedStrategy]);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          toast.error(extractErrorMessage(error));
        }
        console.log(error, 'error at on getting strategy')
      });
  };

  const deleteStrategy = (key, confirm) => {
    const id = secureLocalStorage.getItem('backtest_id');
    const data = activeBasket;
    const temp = [...data.strategy];
    temp.splice(key, 1);
    data.strategy = temp;

    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let config = { method: "post", url: `${UPDATE_BASKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: temp };
    if (confirm) {
      axios
        .request(config)
        .then((response) => {
          toast.success('Delete Strategy Successfully');
          deleteStrategiesOnActiveBasket(data?.strategy);
          setExecutedStrategy(prevResult => { const updatedResult = [...prevResult]; updatedResult.splice(key, 1); return updatedResult; });
          setStrategyChecked(prevResult => { const updatedResult = [...prevResult]; updatedResult.splice(key, 1); return updatedResult; });
          setDayOption(prevResult => { const updatedResult = [...prevResult]; updatedResult.splice(key, 1); return updatedResult; });
          removeStrategyMultiplier(key);
          const temp1 = [...strategyMultiplier];
          temp1.splice(key, 1);
          updateStatsInclude(temp1);
          removeSlippage(key);
          setConfirm(false);
          setResult(prevResult => { const updatedResult = [...prevResult]; updatedResult.splice(key, 1); return updatedResult; });
          // console.log(executedStrategy, dayOption, strategyMultiplier, include, result);
          setVisibleDeleteStrategy(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate('/backtest/login');
          } else {
            toast.error(extractErrorMessage(error));
          }
          console.log(error, 'error at delete strategy')
          setVisibleDeleteStrategy(false);
          setConfirm(false);
        });
    }
  }
  const addBasketOnActiveBasket = () => {
    if (chooseAllStrategy) {
      const d1 = activeBasket;
      const temp = [...d1.strategy, ...chooseBasket.strategy]
      const temp1 = activeBasket;
      temp1.strategy = temp;
      updateActiveBasket(temp1);
      setStrategyChecked(Array(temp1.strategy.length).fill(true));
      const id = secureLocalStorage.getItem('backtest_id');
      // Get the token from secureLocalStorage
      let token = secureLocalStorage.getItem('backtest_token');

      // Remove the 'backtest_' prefix if it exists
      if (token && token.startsWith('backtest_')) {
        token = token.replace('backtest_', '');
      } else {
        navigate('/backtest/login');
      }
      let config = { method: "post", url: `${UPDATE_BASKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: temp1.strategy };
      axios
        .request(config)
        .then((response) => {
          toast.success('Basket Added Successfully!!!');
          setChooseAllStrategy(false);
          setVisibleImportBasket(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate('/backtest/login');
          } else {
            toast.error(extractErrorMessage(error));
          }
          console.log(error, 'error at add basket on active basket')
        });
    } else {
      const d1 = activeBasket;
      const temp = [...d1.strategy];
      for (let i = 0; chooseStrategy.strategy.length; i++) {
        chooseStrategy[i] && temp.push(chooseStrategy.strategy[i]);
      }
      const temp1 = activeBasket;
      temp1.strategy = temp;
      updateActiveBasket(temp1);
      setStrategyChecked(Array(temp1.strategy.length).fill(true));
      const id = secureLocalStorage.getItem('backtest_id');
      // Get the token from secureLocalStorage
      let token = secureLocalStorage.getItem('backtest_token');

      // Remove the 'backtest_' prefix if it exists
      if (token && token.startsWith('backtest_')) {
        token = token.replace('backtest_', '');
      } else {
        navigate('/backtest/login');
      }
      let config = { method: "post", url: `${UPDATE_BASKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: temp1.strategy };
      axios
        .request(config)
        .then((response) => {
          toast.success('Basket Added Successfully!!!');
          setChooseStrategy([]);
          setVisibleImportBasket(false);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate('/backtest/login');
          } else {
            toast.error(extractErrorMessage(error));
          }
          console.log(error, 'error at bucket(add basket on active basket)')
        });
    }
  }

  const fetchBasketName = () => {
    const id = secureLocalStorage.getItem("backtest_id");
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let config = { method: "get", maxBodyLength: Infinity, url: `${FETCH_BASKET_NAME}/${id}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, };
    axios
      .request(config)
      .then((response) => {
        if (response.data.result.length == 0) {
          updateBasketList([]); updateActiveBasket([]); updateActiveBasketName('');
        }
        else {
          updateBasketList(response?.data?.result);
          let newActiveBasket = response?.data?.result[0];
          const temp = new Array(newActiveBasket?.strategy?.length).fill(true);
          updateActiveBasket(newActiveBasket);
          updateActiveBasketName(newActiveBasket?.bucket_name);
          updateStatsMultiplier(new Array(newActiveBasket?.strategy?.length).fill(1))
          updateSlippage(new Array(newActiveBasket?.strategy?.length).fill(0))
          temp && setStrategyChecked(temp);
          setExecutedStrategy(Array(newActiveBasket?.strategy?.length).fill(0));
          console.log(response?.data?.result, 'all the basket');
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          toast.error(extractErrorMessage(error));
        }
        console.log(error, 'error at fetch basket name')
      });
  }
  const updateDate = (date, numMonthsToAdd) => {
    var inputDate = new Date(date);
    // Calculate the new date
    inputDate.setMonth(inputDate.getMonth() + numMonthsToAdd);
    // Format the new date as 'YYYY-MM-DD'
    var year = inputDate.getFullYear();
    var month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    var day = String(inputDate.getDate()).padStart(2, '0');

    var newDate = `${year}-${month}-${day}`;
    return newDate;
  }
  // method to handle when we click on select all day checkbox
  const handleSelectAllDay = () => {
    let selectedAll = Array(activeBasket.strategy.length).fill([1, 1, 1, 1, 1])
    let notSelectedAll = Array(activeBasket.strategy.length).fill([0, 0, 0, 0, 0])
    selectAllDay ? setDayOption(notSelectedAll) : setDayOption(selectedAll);
    selectAllDay ? setSelectAllDayList([false, false, false, false, false]) : setSelectAllDayList([true, true, true, true, true]);
    setSelectAllDay(!selectAllDay);
  }
  const handleSelectedAllStrategy = () => {
    selectAllStrategy ? setStrategyChecked(Array(activeBasket.strategy.length).fill(false)) : setStrategyChecked(Array(activeBasket.strategy.length).fill(true));;
    setSelectAllStrategy(!selectAllStrategy);
  }

  //  method to add strategy into basket
  const add_strategy_bucket = (strategy) => {
    const id = secureLocalStorage.getItem("backtest_id");
    const inputdata = {
      'name': strategy?.name,
      'array': strategy.array,
      'dayoption': [1, 1, 1, 1, 1],
      'variant': ['contained', 'contained', 'contained', 'contained', 'contained']
    };
    if (activeBasketName == '') {
      toast.warn('Please choose any basket from list');
      return;
    }
    // Get the token from secureLocalStorage
    let token = secureLocalStorage.getItem('backtest_token');

    // Remove the 'backtest_' prefix if it exists
    if (token && token.startsWith('backtest_')) {
      token = token.replace('backtest_', '');
    } else {
      navigate('/backtest/login');
    }
    let config = { method: "post", url: `${ADD_STRATEGY_BUCKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: inputdata };
    axios
      .request(config)
      .then((response) => {
        // update some state when we are adding strategy of active Basket
        addStrategiesOnActiveBasket(inputdata);
        setStrategyChecked([...strategyChecked, true]);
        setExecutedStrategy([...executedStrategy, 0]);
        setResult([...result, {}])
        setDayOption([...dayOption, [1, 1, 1, 1, 1]])
        updateStatsMultiplier([...strategyMultiplier, 1])
        updateStatsInclude([...include, true])
        updateSlippage([...slippage, 0]);
        toast.success('Strategy Added Successfully!!!');
        setConfirmUpdated(true);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/backtest/login');
        } else {
          toast.error(extractErrorMessage(error));
        }
        console.log(error, 'error at add strategy')
        setConfirmUpdated(true);
      });
  }
  // Advanced Setting
  const [lotSize, setLotSize] = useState(1)
  const [target, setTarget] = useState(10)
  const [stoploss, setStoploss] = useState(10);
  const [trailLoss, setTrailLoss] = useState(10);
  const [profitMoves, setProfitMoves] = useState(10);
  const [stoplossMoves, setStoplossMoves] = useState(10);
  const [waitTrade, setWaitTrade] = useState(10);
  const [selectedStrategy, setSelectedStrategy] = useState([true, true, true, false, false, false, false])
  const [targetChoice, setTargetChoice] = useState('TP%')
  const [stoplossChoice, setStoplossChoice] = useState('SL%')
  const [trailLossChoice, setTrailLossChoice] = useState('point')
  const [waitTradeChoice, setWaitTradeChoice] = useState('% --')
  const [reEntryChoice, setReEntryChoice] = useState('SL')
  const [reEntryTimes, setReEntryTimes] = useState(2)
  const [reExecuteChoice, setReExecuteChoice] = useState('SL')
  const [reExecuteTimes, setReExecuteTimes] = useState(4);


  const updateSelectedStrategy = (index) => {
    const input = [...selectedStrategy];
    input[index] = !input[index];
    setSelectedStrategy(input);
  }

  useEffect(() => {
    activeBasket != '' && setExecutedStrategy(Array(activeBasket.strategy.length).fill(0));
    activeBasket != '' && setStrategyChecked(Array(activeBasket.strategy.length).fill(true));
    activeBasket != '' && setDayOption(Array(activeBasket.strategy.length).fill([1, 1, 1, 1, 1]))
    activeBasket != '' && updateStatsMultiplier(Array(activeBasket.strategy.length).fill(1));
    activeBasket != '' && updateSlippage(Array(activeBasket.strategy.length).fill(0));
  }, [])


  useEffect(() => {
    onGettingStrategies();
  }, [visibleImportStrategy == true])

  useEffect(() => {
    if (bucketResultVisible) {
      let cnt = 0;
      for (let i = 0; i < executedStrategy.length; i++) {
        if (executedStrategy[i] == 2)
          cnt++
      }
      // console.log(executedStrategy.length,'executedstrategy')
      if (cnt == 0)
        setBucketResultVisible(false);
    }
  }, [executedStrategy])

  // advanced strategy method to add
  const handleAddCombineStrategy = () => {
    setConfirmUpdated(false);
    // console.log('combine strategy -- handleAddCombineStrategy')
    let temp_strategy = strategy; // copy of strategy
    // console.log(temp_strategy,'temp strategy ----')
    let length = temp_strategy?.array?.leg;
    for (let key = 0; key < 7; key++) {
      // for reEntry
      if (selectedStrategy[key] === true) {
        if (key === 0) {
          temp_strategy.array.lotarray = Array(length).fill(lotSize);
          temp_strategy.array.lotarray1 = temp_strategy.array.tradesymbol == 'NIFTY' ? Array(length).fill(lotSize * 50) : Array(length).fill(lotSize * 50);
        } else if (key === 1) {
          // for target
          temp_strategy.array.legsTargetProfit = new Array(length).fill(target);
          temp_strategy.array.legsTargetProfitType = new Array(length).fill(targetChoice);
        } else if (key === 2) {
          // for stoploss
          temp_strategy.array.legsStoploss = new Array(length).fill(stoploss);
          temp_strategy.array.legsStoplossType = new Array(length).fill(stoplossChoice);
        } else if (key === 3) {
          // for trailLoss
          temp_strategy.array.trailStoploss = new Array(length).fill(true);
          temp_strategy.array.trailStoplossLoss = new Array(length).fill(stoplossMoves);
          temp_strategy.array.trailStoplossProfit = new Array(length).fill(profitMoves);
          temp_strategy.array.trailStoplossType = new Array(length).fill(trailLossChoice);
        } else if (key === 4) {
          temp_strategy.array.reEntry = true;
          temp_strategy.array.reEntryValue = new Array(length).fill(reEntryTimes);
          temp_strategy.array.reEntryType = new Array(length).fill(reEntryChoice)
          temp_strategy.array.reEntryChoice = new Array(length).fill("Re-Entry");
          temp_strategy.array.legsReEntry = new Array(length).fill(true);
        } else if (key === 5) {
          // for reExecution
          // console.log(reExecuteChoice,reEntryTimes);
          temp_strategy.array.reEntry = true;
          temp_strategy.array.reEntryType = new Array(length).fill(reExecuteChoice)
          temp_strategy.array.reEntryValue = new Array(length).fill(reExecuteTimes);
          temp_strategy.array.reEntryChoice = new Array(length).fill("ReExecute");
          temp_strategy.array.legsReEntry = new Array(length).fill(true);
        } else if (key === 6) {
          // for waitTrade
          // console.log(waitTrade,waitTradeChoice,'wait trade')
          temp_strategy.array.waitTrade = true;
          temp_strategy.array.waitTradeType = new Array(length).fill(waitTradeChoice);
          temp_strategy.array.waitTradeValue = new Array(length).fill(waitTrade);
        }
      }
    }
    temp_strategy.array.atmprice = 'future';
    // console.log(temp_strategy, 'temp_strategy');
    add_strategy_bucket(temp_strategy);

  }

  useEffect(() => {
    // find all saved strategies from an array
    const onGettingPreDefinedStrategy = () => {
      let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id") });
      // Get the token from secureLocalStorage
      let token = secureLocalStorage.getItem('backtest_token');

      // Remove the 'backtest_' prefix if it exists
      if (token && token.startsWith('backtest_')) {
        token = token.replace('backtest_', '');
      } else {
        navigate('/backtest/login');
      }
      let config = { method: "get", maxBodyLength: Infinity, url: PRE_DEFINED_STRATEGY, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
      axios
        .request(config)
        .then((response) => {
          //  console.log('response',response.data)
          response?.data?.data && setPreDefinedStrategy(response?.data?.data);
          setStrategy(response?.data?.data[0]);
          // console.log(savedstrategies);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate('/backtest/login');
          } else if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('Network Issue');
          }
          console.log(error, 'error at getting strategy')
        });
    };
    onGettingPreDefinedStrategy();
    updateStrategyResultInclude([]);
  }, [])

  const updateDay = (index) => {
    const data = [...selectAllDayList];
    data[index] = !data[index];
    //----------------------
    const inputdata = [...dayOption];
    for (let i = 0; i < activeBasket.strategy.length > 0; i++) {
      inputdata[i][index] = data[index] ? 1 : 0;
    }
    setDayOption(inputdata);

    if (data[0] && data[1] && data[2] && data[3] && data[4]) {
      setSelectAllDay(true);
    } else {
      setSelectAllDay(false);
    }
    setSelectAllDayList(data);
  }

  const updateStrategyDay = (key, index) => {
    const current_status = dayOption[key][index];
    setDayOption((prevArray) => {
      // Create a new array using the spread operator
      const newArray = [...prevArray];
      // Update the value at firstIndex in the first dimension
      newArray[key] = [...prevArray[key]];
      newArray[key][index] = 1 - newArray[key][index]; // Assuming you want to update the value at index 0 in the second dimension
      return newArray;
    });
    let cnt = 0;
    let flag = false;
    for (let i = 0; i < activeBasket?.strategy?.length; i++) {
      if (key == i) {
        if (current_status == 0) {
          cnt++;
        }
      } else if (dayOption[i][index] == 1)
        cnt++;
    }
    if (cnt == activeBasket.strategy.length) {
      setSelectAllDayList((prevResult) => { const inputdata = [...prevResult]; inputdata[index] = true; return inputdata; })
      flag = true;
    }
    else if (selectAllDayList[index]) {
      setSelectAllDayList((prevResult) => { const inputdata = [...prevResult]; inputdata[index] = false; return inputdata; })
    }

    let dayCount = 0;
    for (let i = 0; i < 5; i++) {
      if (index == i && flag) {
        dayCount++;
      } else if (selectAllDayList[i] && index !== i) {
        dayCount++;
      }
    }
    if (dayCount == 5) {
      setSelectAllDay(true);
    } else if (selectAllDay) {
      setSelectAllDay(false);
    }

  }

  return (
    <div >
      <div className="flex flex-row">
        <div className="flex flex-col m-2 w-[320px] gap-4 p-4 bg-white rounded-lg shadow-lg">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={selectAllStrategy}
                onChange={handleSelectedAllStrategy}
              />
              <div className="text-sm font-medium">Select All</div>
            </div>
            <IoMdSettings
              onClick={() => setSetting(true)}
              className="text-blue-500 text-xl cursor-default hover:text-blue-700 transition-colors duration-200"
            />
          </div>

          <div className="flex justify-between items-center">
            <div className="flex items-center gap-2">
              <input
                type="checkbox"
                className="w-5 h-5"
                checked={selectAllDay}
                onChange={handleSelectAllDay}
              />
              <div className="text-sm font-medium">Select Days</div>
            </div>
            <div className="flex gap-2">
              {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day, index) => (
                <button
                  key={index}
                  className={`text-sm h-8 w-8 rounded-full flex items-center justify-center font-medium 
                      ${selectAllDayList[index] ? "bg-blue-600 text-white" : "bg-gray-200 text-gray-600"} 
                      hover:bg-blue-500 hover:text-white transition-colors duration-200`}
                  onClick={() => updateDay(index)}
                >
                  {day}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center">
              <div className="w-[90px] text-sm font-medium">From Date:</div>
              <input
                type="date"
                className="flex-grow p-1 border-2 rounded-md text-md focus:ring-2 focus:ring-blue-600 border-black"
                value={props.bucketStartDate}
                onChange={(e) => props.setBucketStartDate(e.target.value)}
              />
            </div>

            <div className="flex items-center">
              <div className="w-[90px] text-sm font-medium">End Date:</div>
              <input
                type="date"
                className="flex-grow p-1 border-2 rounded-md text-md focus:ring-2 focus:ring-blue-600 border-black"
                value={props.bucketEndDate}
                onChange={(e) => props.setBucketEndDate(e.target.value)}
              />
            </div>
          </div>

          <div className="flex flex-col gap-2 mt-2">
            <div className="w-full flex flex-col items-start sm:items-center">
              {/* Title */}
              <div className="text-xl text-left font-semibold text-gray-800">List of Strats</div>

              {/* Select Dropdown */}
              <div className="w-full">
                <select
                  className="w-full h-10 p-1 border border-gray-300 rounded-lg outline-none bg-white text-gray-700 hover:border-gray-400 focus:border-blue-500 transition-all"
                  value={activeBasketName} // Use a unique ID instead of JSON string
                  onChange={(e) => {
                    const name = e.target.value;
                    const selectedBasket = basketList.find(item => item.bucket_name === name); // Find basket by ID
                    console.log(name,'name','basketId',selectedBasket);
                    if (selectedBasket) {
                      updateActiveBasket(selectedBasket);
                      updateActiveBasketName(selectedBasket.bucket_name);
                      updateStatsInclude(Array(selectedBasket.strategy.length).fill(true));
                      updateStatsMultiplier(Array(selectedBasket.strategy.length).fill(1));
                    }
                  }}
                >
                  {basketList.length > 0 &&
                    basketList.map((item,key) => (
                      <option key={key} value={item.bucket_name}>
                        {item.bucket_name}
                      </option>
                    ))}
                </select>
              </div>

            </div>

            <button
              className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
              onClick={() => setVisible(true)}
            >
              <MdAddBox className="text-2xl font-semibold" />
              Add New Strategy
            </button>
            <button
              className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
              onClick={() => setAddDefatultStrategyVisible(true)}
            >
              <MdAddBox className="text-2xl font-semibold" aria-hidden="false" />
              Add Default Strategy
            </button>
            <button
              className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
              onClick={() => updateBoxShow(true)}
            >
              <FaPlus className="text-2xl font-semibold" />
              Add New Strats
            </button>
            <button
              className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
              onClick={() => setVisibleImportBasket(true)}
            >
              <MdImportExport className="text-2xl font-semibold" />
              Import Strats
            </button>
            <button
              className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
              onClick={() => { setCurrentBucketName(activeBasketName); setVisibleRename(true); }}
            >
              <MdDriveFileRenameOutline className="text-2xl font-semibold" />
              Rename Strats
            </button>
            <button
              className="p-2 bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-md shadow-md hover:shadow-lg transition-transform duration-300 flex items-center justify-center gap-2"
              onClick={() => setVisibleDeleteBasket(true)}
            >
              <MdDeleteOutline className="text-2xl font-semibold" />
              Remove Strats
            </button>
          </div>

          <div className="flex justify-center mt-6 relative">
            <div className="absolute -top-3 bg-white text-blue-700 font-semibold px-2 py-1 rounded-full border-2 border-blue-700">Execute</div>
          </div>

          {!waitSelected && (
            <button
              className="mt-4 p-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md shadow-md flex items-center justify-center transition-colors duration-300"
              onClick={() => { setCheckToRunStrategy(true); SetAlertMessage('Do You Really Want to Execute All Selected Strategy?.'); setChooseAllStrategy(true); }}
            >
              Run Selected Strategy
            </button>
          )}

          {waitSelected && (
            <div className="mt-4 p-2 bg-blue-600 text-white rounded-md shadow-md flex items-center justify-center">
              <Audio height="40" width="120" color='lightblue' ariaLabel='three-dots-loading' />
            </div>
          )}

          {!wait && (
            <button
              className="mt-2 p-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md shadow-md flex items-center justify-center transition-colors duration-300"
              onClick={() => { setCheckToRunStrategy(true); SetAlertMessage('Do You Really Want to Execute All Strategy?.'); setChooseAllStrategy(false); }}
            >
              Run All Strategy
            </button>
          )}

          {wait && (
            <div className="mt-4 p-2 bg-blue-600 text-white rounded-md shadow-md flex items-center justify-center">
              <Audio height="30" width="120" color='lightblue' ariaLabel='three-dots-loading' />
            </div>
          )}
        </div>

        <div className="relative flex flex-grow flex-wrap flex-col rounded bg-white m-2">
          {/* <div className="text-center justify-center bg-gradient-to-r from-blue-400 via-purple-500 to-pink-500 text-white text-2xl font-extrabold py-2 rounded-lg shadow-md">STRATEGY</div> */}
          <div className="flex flex-row flex-wrap overflow-auto gap-4 p-6">
            {activeBasket?.strategy?.length > 0 && activeBasket.strategy.map((item, key) => {
              return (
                <div className="rounded-xl p-2 flex flex-col flex-wrap gap-2 bg-white border-blue-300 border-2" key={`activeBasket-${key}`}>
                  <div className="grid grid-cols-2">
                    <div className="bg-white text-[16px] text-[#718EBF]">Strategy [#{key + 1}]</div>
                    <div className="mr-2 p-1 w-full text-center rounded-md border-[#DFEAF2] border-[1px] text-sm font-semibold overflow-hidden flex-grow">{item.name}</div>
                  </div>
                  <div className="flex flex-row gap-1">
                    <div className="w-12 flex text-center justify-center border-[1px] border-[#DFEAF2] rounded">{(strategyChecked[key] == true || strategyChecked[key] == false) && <input type="checkbox" className="ml-2 mr-2 p-1 border-2" checked={strategyChecked[key]} onChange={() => { const inputdata = [...strategyChecked]; inputdata[key] = !strategyChecked[key]; setStrategyChecked(inputdata); }} />}</div>
                    <button className="p-1 text-blue-600 bg-[lightblue] flex justify-center font-semibold text-center w-16 text-sm rounded border-[#DFEAF2] border-[1px]"
                      onClick={() => { confirmRunStrategy && ExecuteParticularStrategy(item, key); const inputData = [...executedStrategy]; inputData[key] = 1; confirmRunStrategy && setExecutedStrategy(inputData); }}>{executedStrategy[key] == 1 ? <ColorRing
                        visible={true} height="24" width="30" ariaLabel="blocks-loading" wrapperStyle={{}} wrapperClass="blocks-wrapper" colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                      /> : executedStrategy[key] == 2 ? <FaCheck className="text-2xl text-center" /> : 'RUN'}</button>
                    {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day, index) => (
                      <button key={index} className="ml-2 text-sm border-blue-500 shadow-lg h-8 w-8 rounded text-center justify-center font-medium focus:outline-none focus:border-0" style={{ backgroundColor: dayOption && dayOption[key] && dayOption[key][index] ? dayOption[key][index] == 1 ? "#89CFF0" : "lightblue" : '' }}
                        onClick={() => { updateStrategyDay(key, index); }}> {day} </button>))}
                  </div>
                  <div className="flex flex-row gap-1">
                    <div className="font-serif  mr-2 p-1 text-sm w-[30px] h-8  text-center rounded border-[#DFEAF2] border-[1px]">{item.array.tradesymbol == 'NIFTY' ? 'N' : 'BN'}</div>
                    <div className="font-serif mr-2 p-1 text-sm w-16 h-8 text-center rounded border-[#DFEAF2] border-[1px]">{item.array.intraday === 'Yes' ? 'Intraday' : 'Positional'}</div>
                    <div className="rounded flex flex-row text-center justify-center h-8 border-[#DFEAF2] border-[1px]">
                      <div className="text-l border-[#DFEAF2] border-r-[1px] text-center justify-center pl-1 pr-1 bg-[#DAECF2]">Slippage</div>
                      <input type="text" value={slippage[key] !== undefined ? slippage[key] : ''} className="w-8  border-l-[1px] border-[#DFEAF2] bg-inherit text-center justify-center hover:outline-none focus:outline-none" onChange={(e) => { const inputData = [...slippage]; inputData[key] = handleNumber(e.target.value, inputData[key]); updateSlippage(inputData); }} />
                    </div>
                    {/* Strategy Multiplier */}
                    <div className="rounded flex flex-row text-center justify-center h-8 border-[#DFEAF2] border-[1px]">
                      <div className="text-l border-[#DFEAF2] border-r-[1px] text-center justify-center pl-1 pr-1 bg-[#DAECF2]">Multiplier</div>
                      <input type="text" value={strategyMultiplier[key] && strategyMultiplier[key]} className="w-8  border-l-[1px] border-[#DFEAF2] bg-inherit text-center justify-center hover:outline-none focus:outline-none" onChange={(e) => { const inputData = [...strategyMultiplier]; inputData[key] = handleNumber(e.target.value, inputData[key]); updateStatsMultiplier(inputData); }}></input>
                    </div>
                  </div>
                  {/* Profit Week Day Wise */}
                  <div className="flex flex-row justify-end">
                    <div className="flex flex-row gap-2">
                      <div className="mt-1"><MdContentCopy className="text-2xl font-semibold cursor-pointer" onClick={() => { setVisible(true); setCopyStrategy(true); setUpdateStrategy(item); }} /></div>
                      {/* Method to delete a particular leg */}
                      <div className="mt-1"><MdDeleteForever className="text-2xl font-semibold cursor-pointer text-red-500" onClick={() => { setVisibleDeleteStrategy(true); setDeleteIndex(key); }} /></div>
                      {/* Method to update a particular leg */}
                      <div className="mt-1"><FaEdit className="text-2xl font-semibold cursor-pointer" onClick={() => { setUpdate(true); setVisible(true); setUpdateStrategy(item); setUpdateIndex(key); }} /></div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="absolute bottom-0 w-full"><DateTime bucketStartDate={props.bucketStartDate} bucketEndDate={props.bucketEndDate} setBucketStartDate={props.setBucketStartDate} setBucketEndDate={props.setBucketEndDate} /></div>
        </div>
      </div>


      {/* Dialog Box for Rename basket */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" maxWidth="xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setAddDefatultStrategyVisible(false); }
      }} open={addDefaultStrategyVisible}>
        <div className="flex flex-row border-b-2 justify-between items-center bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">Add Default Strategy</div>
          <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer">
            <MdClose className="text-2xl" onClick={() => { setAddDefatultStrategyVisible(false); }} />
          </div>
        </div>

        <div className="m-2">
          <div className="flex flex-col gap-2 overflow-auto p-2">
            <div className="grid grid-cols-8 gap-2 mr-4 min-w-[1400px]">
              <div className="flex flex-row justify-start">
                <div className=""><MdAppRegistration /> Saved&nbsp;Strategy</div>
              </div>
              <div className="flex flex-row justify-start">
                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[0]} onChange={() => { updateSelectedStrategy(0); }} />
                <div className="">Lot</div>
              </div>
              <div className="flex flex-row justify-start">
                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[1]} onChange={() => { const inputdata = [...selectedStrategy]; inputdata[1] = !selectedStrategy[1]; setSelectedStrategy(inputdata); }} />
                <div className="">Target</div>
              </div>
              <div className="flex flex-row justify-start">
                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[2]} onChange={() => { const inputdata = [...selectedStrategy]; inputdata[2] = !selectedStrategy[2]; setSelectedStrategy(inputdata); }} />
                <div className="">Stoploss</div>
              </div>
              <div className="flex flex-row justify-start">
                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[3]} onChange={() => { selectedStrategy[2] && updateSelectedStrategy(3); }} />
                <div className="">TrailStoploss</div>
              </div>
              <div className="flex flex-row justify-start">
                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[4]} onChange={() => { !selectedStrategy[4] ? selectedStrategy[2] && updateSelectedStrategy(4) : updateSelectedStrategy(4); }} />
                <div className="">ReEntry</div>
              </div>
              <div className="flex flex-row justify-start">
                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[5]} onChange={() => { !selectedStrategy[5] ? (selectedStrategy[2] || selectedStrategy[1]) && updateSelectedStrategy(5) : updateSelectedStrategy(5); }} />
                <div className="">ReExecute</div>
              </div>
              <div className="flex flex-row justify-start">
                <input type="checkbox" className="w-6 mt-1 h-4 outline-none" checked={selectedStrategy[6]} onChange={() => { updateSelectedStrategy(6); }} />
                <div className="">Wait&nbsp;Trade</div>
              </div>
            </div>
            <div className="grid grid-cols-8 gap-2  min-w-[1400px]">
              <select type="number" className="outline-none border-2 rounded p-1" onChange={(e) => { setStrategy(JSON.parse(e.target.value)); }}>
                {preDefinedStrategy.map((item, key) => {
                  return (
                    <option value={JSON.stringify(item)} key={`pre-defined-${key}`}>{item.name}</option>
                  )
                })}
              </select>
              <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[0]} value={lotSize} onChange={(e) => { setLotSize(e.target.value); }}></input>
              <div className="grid grid-cols-2">
                <select value={targetChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[1]} onChange={(e) => { setTargetChoice(e.target.value); }}>
                  <option value="TP%">TP%</option>
                  <option value="TP Point">TP Point</option>
                </select>
                <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[1]} value={target} onChange={(e) => { setTarget(e.target.value); }}></input>
              </div>
              <div className="grid grid-cols-2">
                <select value={stoplossChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[2]} onChange={(e) => { setStoplossChoice(e.target.value); }}>
                  <option value="SL%">SL%</option>
                  <option value="SL Point">SL Point</option>
                </select>
                <input type="number" className="outline-none border-2 rounded p-1  text-center" disabled={!selectedStrategy[2]} value={stoploss} onChange={(e) => { setStoploss(e.target.value); }}></input>
              </div>
              <div className="grid grid-cols-3">
                <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[3]} value={profitMoves} onChange={(e) => { setProfitMoves(e.target.value); }}></input>
                <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[3]} value={stoplossMoves} onChange={(e) => { setStoplossMoves(e.target.value); }}></input>
                <select value={trailLossChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[3]} onChange={(e) => { setTrailLossChoice(e.target.value); }}>
                  <option value="point">point</option>
                  <option value="percent">percent</option>
                </select>
              </div>
              <div className="grid grid-cols-2">
                <select value={reEntryChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[4]} onChange={(e) => { setReEntryChoice(e.target.value); }}>
                  <option value="SL">SL</option>
                </select>
                <select value={reEntryTimes} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[4]} onChange={(e) => { setReEntryTimes(e.target.value); }}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div>
              <div className="grid grid-cols-2">
                <select value={reExecuteChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[5]} onChange={(e) => { setReExecuteChoice(e.target.value); }}>
                  <option value="SL">SL</option>
                  <option value="TP">TP</option>
                </select>
                <select value={reExecuteTimes} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[5]} onChange={(e) => { setReExecuteTimes(e.target.value); }}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                </select>
              </div>
              <div className="grid grid-cols-2">
                <select value={waitTradeChoice} className="outline-none border-2 rounded p-1" disabled={!selectedStrategy[6]} onChange={(e) => { setWaitTradeChoice(e.target.value); }}>
                  <option className="text-black" value="% ++" >  % ++ </option>
                  <option className="text-black" value="% --">   % --</option>
                  <option className="text-black" value="Point ++">  Point ++ </option>
                  <option className="text-black" value="Point --">  Point -- </option>
                </select>
                <input type="number" className="outline-none border-2 rounded p-1 text-center" disabled={!selectedStrategy[6]} value={waitTrade} onChange={(e) => { setWaitTrade(e.target.value); }}></input>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 flex flex-row gap-4 bg-gray-300 p-2">
          <div className="bg-blue-600 text-white rounded p-1 w-32 text-center cursor-pointer" onClick={() => { setAddDefatultStrategyVisible(false); confirmUpdated && handleAddCombineStrategy(); }}>Add</div>
        </div>
      </Dialog>

      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl w-full" scroll={"paper"} maxWidth="xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisible(false); }
      }} open={visible}>
        <div className="">
          <Backtest setVisible={setVisible} setUpdate={setUpdate} update={update} updateStrategy={updateStrategy} updateIndex={updateIndex} setUpdateStrategy={setUpdateStrategy} copyStrategy={copyStrategy} setCopyStrategy={setCopyStrategy} strategyChecked={strategyChecked} setStrategyChecked={setStrategyChecked} executedStrategy={executedStrategy} setExecutedStrategy={setExecutedStrategy}
            result={result} setResult={setResult} dayOption={dayOption} setDayOption={setDayOption} />
        </div>
      </Dialog>

      {/* saved strategy */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleImportStrategy(false); }
      }} open={visibleImportStrategy}>
        <div className="text-center text-[#333B69] font-serif p-4 w-80">List of Strategy</div>
        <div className="flex flex-col p-2">
          {
            savedstrategies.map((item, key) => {
              return (
                <div className="text-l text-black h-8 border-2 border-t-4 border-blue-500 m-2 rounded-xl text-center p-2" key={key}>{item.name}</div>
              )
            })
          }
          <div className="text-l text-white h-8 border-2 border-t-4 border-blue-500 m-2 bg-red-600 rounded-xl p-1 text-center cursor-pointer" onClick={() => { setVisibleImportStrategy(false); }}>Cancel</div>
        </div>
      </Dialog>

      {/* Dialog Box for import Basket List */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl p-2" maxWidth="lg" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          setVisibleImportBasket(false);
        }
      }} open={visibleImportBasket}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">List of Basket</div>
          <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setVisibleImportBasket(false); }} /></div>
        </div>
        <div className="flex flex-col p-6">
          <div className="text-black">Basket List:</div>
          <select className="border-2 border-black rounded p-1" onChange={(e) => { setChooseBasket(JSON.parse(e.target.value)); setChooseStrategy(Array(JSON.parse(e.target.value).strategy.length).fill(false)) }}>
            {
              basketList.map((item, key) => {
                return (
                  <option value={JSON.stringify(item)} key={`basket-${key}`}>{item.bucket_name}</option>
                )
              })
            }
          </select>
          <hr className="mt-2"></hr>
          <div className="flex flex-col">

            <div className="text-l font-serif">Select Strategy:</div>
            {
              chooseBasket && chooseBasket.strategy && chooseBasket.strategy.length > 0 &&
              <div>
                <div className="flex flex-row gap-2"><input type="checkbox" value={chooseAllStrategy} onChange={() => { setChooseAllStrategy(!chooseAllStrategy); }}></input><div className="text-l text-blue-400">All Strategy</div></div>
              </div>
            }
            {
              chooseBasket && chooseBasket.strategy && chooseBasket.strategy.length > 0 && chooseBasket.strategy.map((item, key) => {
                return (
                  <div className="flex flex-row gap-2"><input type="checkbox" defaultChecked={chooseStrategy[key] ? chooseStrategy[key] : false} onChange={() => {
                    const inputData = [...chooseStrategy]; inputData[key] = !inputData[key]; setChooseStrategy(inputData);
                  }}></input><div className="text-l text-blue-400">{item.name}</div></div>
                )
              })
            }
          </div>
        </div>
        <div className="flex justify-end gap-2 mt-2 bg-gray-400 p-2">
          <div className="border-2 p-1 w-24 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { addBasketOnActiveBasket(); }}>Import</div>
          <div className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setVisibleImportBasket(false); }}>Cancel</div>
        </div>
      </Dialog>

      {/* Dialog Box for Rename basket */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleRename(false); }
      }} open={visibleRename}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">Rename a Basket Name</div>
          <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer"><MdClose className="text-2xl font-semibold" onClick={() => { setVisibleRename(false); }} /></div>
        </div>
        <div className="flex flex-col text-center justify-center mt-2 p-6">
          <div className="font-serif text-l text-left">Basket Name</div>
          <input type="text" className="rounded-md outline-0 border-[1px] border-black bg-[#ECF0FF] justify-center p-1" placeholder="Bucket Name" value={currentBucketName} onChange={(e) => { setCurrentBucketName(e.target.value); }} />
        </div>
        <div className="flex justify-end gap-2 p-2 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { RenameBasketName() }}>Rename</button>
          <button className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setVisibleRename(false); }}>Cancel</button>
        </div>
      </Dialog>

      {/* Check to Run Strategy or Not */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setCheckToRunStrategy(false); }
      }} open={checkToRunStrategy}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">Execute Strategy</div>
          <div className="text-xl flex justify-end pt-2 pl-4 cursor-pointer"><MdClose className="text-2xl font-semibold" onClick={() => { setCheckToRunStrategy(false); }} /></div>
        </div>
        <div className="flex flex-col p-4">
          <div className="text-lg p-2">{alertMessage}</div>
        </div>
        <div className="flex justify-end gap-2 p-1 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { setCheckToRunStrategy(false); ExecuteStrategy(chooseAllStrategy); }}>YES</button>
          <button className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setCheckToRunStrategy(false); }}>NO</button>
        </div>
      </Dialog>
      {/* Delete a strategy*/}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleDeleteStrategy(false); }
      }} open={visibleDeleteStrategy}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">Delete Strategy</div>
          <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { setVisibleDeleteStrategy(false); }} /></div>
        </div>
        <div className="flex flex-col text-center justify-center mt-2 p-4">
          <div className="font-serif text-l justify-start text-left p-4 text-xl">Are You Sure Want to Delete a Strategy?</div>
        </div>
        <div className="flex justify-end gap-2 p-1 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-blue-500 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { deleteStrategy(deleteIndex, true); }}>Delete</button>
          <button className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setVisibleDeleteStrategy(false); }}>Cancel</button>
        </div>
      </Dialog>
      {/* Confirmation to Delete a Basket */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setVisibleDeleteBasket(false); }
      }} open={visibleDeleteBasket}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl p-2 w-96">Delete CompareStrats</div>
          <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer"><MdClose className="text-2xl font-bold" onClick={() => { setVisibleDeleteBasket(false); }} /></div>
        </div>
        <div className="flex flex-col text-center justify-center mt-2 p-4">
          <div className="font-serif text-l text-center">Are You Sure Want to Delete a Basket(Basket Name:{activeBasketName})?</div>
        </div>
        <div className="flex justify-end gap-2 p-1 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-[#FF3030] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { setVisibleDeleteBasket(false); deleteBasket(true); console.log('delete basket'); }}>Delete</button>
          <button className="border-2 p-1 w-24 bg-white rounded-lg justify-center text-center text-blue-600 border-blue-600 cursor-pointer" onClick={() => { setVisibleDeleteBasket(false); }}>Cancel</button>
        </div>
      </Dialog>
      {/* change default setting */}
      <Dialog className="border-2 border-t-8 border-[#2D5BFF] rounded-xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setSetting(false); }
      }} open={setting}>
        <div className="flex flex-row border-b-2 bg-blue-400 text-white">
          <div className="text-xl font-medium p-2 flex flex-grow"><IoMdSettings className="mt-1" />Setting</div>
          <div className="text-xl  flex justify-end font-semibold p-2 cursor-pointer"><MdClose className="text-2xl" onClick={() => { setSetting(false); }} /></div>
        </div>
        <div className="flex flex-col mt-2 p-2">
          <div className="">Banknifty Lot Size</div>
          <select className="bg-[#DAECF2] outline-0 rounded p-1" value={settingDetail.bankniftylotsize} onChange={(e) => { setSettingDetail(prevState => ({ ...prevState, bankniftylotsize: e.target.value })); }}>
            {
              BANKNIFTY_LOT_SIZE.map((item, key) => {
                return (
                  <option value={item} key={`bankniftylot-${key}`}>{item}</option>
                )
              })
            }
          </select>
          <div className="text-xs">*From 21 Jul'23 ,Banknifty Lot Size is considered 15</div>
          <div className="">Nifty Lot Size</div>
          <select className="bg-[#DAECF2] outline-0 rounded p-1" value={settingDetail.niftylotsize} onChange={(e) => { setSettingDetail(prevState => ({ ...prevState, niftylotsize: e.target.value })); }}>
            {
              NIFTY_LOT_SIZE.map((item, key) => {
                return (
                  <option value={item} key={`niftylot-${key}`}>{item}</option>
                )
              })
            }
          </select>
          <div className="text-xs">* From 23 Jul'23, Nifty Lot Size is considered 50</div>
          <div className="">Inbuilt Slippages</div>
          <select className="bg-[#DAECF2] outline-0 rounded mb-2 p-1" value={slippage} onChange={(e) => { setSettingDetail(prevState => ({ ...prevState, slippage: e.target.value })); updateSlippage(e.target.value); }}>
            {
              slippageList.map((item, key) => {
                return (
                  <option value={item} key={`slippage-${key}`}>{item}</option>
                )
              })
            }
          </select>
        </div>
        <div className="flex justify-end gap-2 mt-2 border-t-2 p-1 bg-gray-400">
          <button className="border-2 p-1 w-24 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { secureLocalStorage.setItem(settingDetail); setSetting(false); }}>Cancel</button>
          <button className="p-1 w-24 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setSetting(false); }}>Save</button>
        </div>
      </Dialog>
      {/* Bucket Result */}
      {bucketResultVisible && <BucketResult resultFilter={resultFilter} setTempFilter={setTempFilter} tempFilter={tempFilter} slippage={slippage} setSlippage={updateSlippage} result={result} dayOption={dayOption} setDayOption={setDayOption} inputData={inputData} activeBasket={activeBasket} bucketStartDate={props.bucketStartDate} bucketEndDate={props.bucketEndDate} executedStrategy={executedStrategy} />}
    </div>
  );
};

export default Bucket;
