import React, { useState, useEffect } from 'react'
import Main from './Main'
import WeekProfit from './Week'
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import GraphResult from '../../Result/GraphResult'
import FinalExcel from '../../../pdf/FinalExcel';   //Download the excel for overall strategy
import useBucketStore from '../../../store/BucketStore'
import LegProfit from './LegProfit';
import CombinedResult from './CombinedResult';
import Month from './Month';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Filter from './Filter';
import CombinedStats from './CombinedStats';
import { NoOfDaysBetweenTwoDate, formatDate,daysFromToday} from './../../Result/Utility'
import {FaChartLine} from 'react-icons/fa'
import IndividualLeg from './IndividualLeg';

const BucketResult = (props) => {
    const statisticalItem = ['Margin', 'Total Profit', 'Maximum Drawdown', 'Max Profit', 'Max Loss', 'Winning Trades', 'Losing Trades', 'Avg Profit', 'Avg Loss', 'Investment Day', 'Monthly Return%', 'Expectancy', 'Max Streak', 'Loss Streak']
    const startdate = props.bucketStartDate;   //It contain startDate of bucket
    const enddate = props.bucketEndDate;       //It contain endDate of bucket
    const bucketResult = props.result;           //It contain bucketResult

    // to store the result 
    const [margin, setMargin] = useState([]);          //It contain margin of all strategy
    const [fullResult, setFullResult] = useState([]);  //It contain excel result
    const { fullMonth, updateFullMonth } = useBucketStore() //It contain Monthly profit of all strategy
    const { originalResult, updateOriginalResult } = useBucketStore() //It contain original result of all strategy
    const { fullWeek, updateFullWeek } = useBucketStore()//It contain weekly profit of all strategy
    const [finalProfitArray, setFinalProfitArray] = useState([]);  // it contain profit of all strategy
    const [finalCumulativeArray, setFinalCumulativeArray] = useState([]);  //It contain cumulative result of all strategy
    const [finalDrawdownArray, setFinalDrawdownArray] = useState([]);      //It contain drawdown that will help into graph

    // to store reEntry Result
    const [date, setDate] = useState()  //to store date
    const [premiumPrice, setPremiumPrice] = useState(0.5)  //For calculation the premium amount
    // To Store Statistical Result
    const [StatisticalResult, setStatisticalResult] = useState([])   //It store statistical result
    // To Store ReEntry Result
    const [reEntryProfit, setReEntryProfit] = useState([])   //It contain reEntry Profit
    const [excelRentryResult, setExcelRentryResult] = useState([])   //It contain Excel ReEntry Result
    const [reEntryChoice, setReEntryChoice] = useState(false);
    const [finalReEntryResult, setFinalReEntryResult] = useState([]);
    const [finalExcelReEntryResult, setFinalExcelReEntryResult] = useState([]);
    const [reEntryStatsResult, setReEntryStatsResult] = useState([])
    // combine Result
    const { activeBasket, include, strategyMultiplier, updateStatsInclude, updateStatsMultiplier } = useBucketStore();
    const [showVisibility, setShowVisibility] = useState(false);
    const [activeStrategyResult, setActiveStrategyResult] = useState(-1);
    const [processCombineResult1, setProcessCombineResult] = useState({})
    // correlation matrix
    const [correlationMatrix, setCorrelationMatrix] = useState([])

    // show vix result
    const [vixStart, setVixStart] = useState(0)
    const [vixExit, setVixExit] = useState(100)
    const [restartAgain, setRestartAgain] = useState(0)
    // Filteration Process
    const [resultStartDate, setResultStartDate] = useState("")
    const [resultEndDate, setResultEndDate] = useState("")
    // combined Margin
    const [combineMargin, setCombineMargin] = useState(0)
    // trading date
    const [tradingDate,setTradingDate] = useState([])
    // individual leg contribution
    const [individualLegContribution,setIndividualLegContribution]=useState([])
    // for handle dayOption --Not used proper

    // all the function that contain by this component
    // a)  Bucket_Result() --> Find the result of each strategy using result()
    // b)  Excel Result() --> Store the result in form excel using bucket result.
    // c)  FinalReEntryResult() --> Store the reEntryResult of bucket using reEntry_result().
    // d)  FinalExcelReEntryResult() --> Store the excel result using excelReEntryResult();
    // e)  CombineResult(),CombineTrading and CombineStats that is used for combine Result
    // f)  handleDownload is used for download the pdf of result.
    // g)  calculateCorrelationMatrix() that uses calculateCorrelation() 


    // There is a method to download a pdf
    const handleDownload = () => {
        const pdfElement = document.getElementById("screenshot");
        html2canvas(pdfElement).then((canvas) => {
            const pdf = new jsPDF();
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0,
                pdf.internal.pageSize.getWidth(),
                pdf.internal.pageSize.getHeight()
            );
            props.strategyName ? pdf.save(`${props.strategyName}_fprognos.pdf`) : pdf.save(`backtest_fprognos.pdf`);
        });
    };

    // Method to Find the Next Thursday
    const Next_Thursday = (given_date) => {
        const givenDate = new Date(given_date);

        let nextThursday = new Date(givenDate)
        const currentWeekDay = nextThursday.getDay();
        const daysUntilNextThursday = (4 - currentWeekDay + 7) % 7;
        nextThursday.setDate(nextThursday.getDate() + daysUntilNextThursday);
        const nextThursdayDay = nextThursday.getDate();
        const nextThursdayMonth = nextThursday.getMonth() + 1;
        const nextThursdayYear = nextThursday.getFullYear();
        const formattedDate = `${nextThursdayMonth}/${nextThursdayDay}/${nextThursdayYear}`;
        return formattedDate;
    }

    // Method to Calculate Coefficient
    const calculateCorrelation = (arr1, arr2) => {
        // Check if arrays are of equal length
        if (arr1.length !== arr2.length) {
            throw new Error("Arrays must have the same length");
        }

        // Calculate means
        const meanArr1 = arr1.reduce((sum, val) => sum + val, 0) / arr1.length;
        const meanArr2 = arr2.reduce((sum, val) => sum + val, 0) / arr2.length;

        // Calculate the numerator and denominators
        let numerator = 0;
        let denominatorX = 0;
        let denominatorY = 0;

        for (let i = 0; i < arr1.length; i++) {
            const diffX = arr1[i] - meanArr1;
            const diffY = arr2[i] - meanArr2;

            numerator += diffX * diffY;
            denominatorX += diffX ** 2;
            denominatorY += diffY ** 2;
        }

        // Calculate the correlation coefficient
        const correlation = numerator / Math.sqrt(denominatorX * denominatorY);

        return correlation;
    }

    // Method to Calculate Correlation for Multiple Arrays
    const calculateCorrelationMatrix = (arrays) => {
        const correlationMatrix = [];

        // Iterate through each pair of arrays
        for (let i = 0; i < arrays.length; i++) {
            const row = [];
            if (props.executedStrategy[i] == 2) {
                for (let j = 0; j < arrays.length; j++) {
                    // Calculate correlation for the pair of arrays
                    if (props.executedStrategy[j] == 2) {
                        const correlation = calculateCorrelation(arrays[i], arrays[j]);
                        row.push(correlation);
                    } else {
                        row.push(0);
                    }
                }
            } else {
                row.push(Array(arrays.length).fill(0));
            }
            correlationMatrix.push(row);
        }
        setCorrelationMatrix(correlationMatrix);
        // console.log(correlationMatrix,'correlation matrix');
        return correlationMatrix;
    };
    // Method to filter on the basis of gapup/down and prevdayHL
    const filterPass = (csv) => {
        // Applying Filter on the basis of GapUpDown or PrevDayHL
        for (let k = 0; k < props.resultFilter.length; k++) {
            let value = (csv[10]?.Open - csv[11]?.Close)
            if (props.resultFilter[k].type == 'GapUpDown') {
                if (props.resultFilter[k].sign1 == ">=") {
                    if (!(props.resultFilter[k].or && props.resultFilter[k].sign2 == ">=")) {
                        if (!(value >= props.resultFilter[k].value1 || value >= props.resultFilter[k].value2))
                            return false;
                    } else if (props.resultFilter[k].or && props.resultFilter[k].sign2 == "<=") {
                        if (!(value >= props.resultFilter[k].value1 || value <= props.resultFilter[k].value2))
                            return false;
                    } else if (!(value >= props.resultFilter[k].value1 && !props.resultFilter[k].or))
                        return false;
                } else {
                    if (props.resultFilter[k].or && props.resultFilter[k].sign2 == ">=") {
                        if (!(value <= props.resultFilter[k].value1 || value >= props.resultFilter[k].value2))
                            return false;
                    } else if (props.resultFilter[k].or && props.resultFilter[k].sign2 == ">=") {
                        if (!(value <= props.resultFilter[k].value1 || value >= props.resultFilter[k].value2))
                            return false;
                    } else if (!(value <= props.resultFilter[k].value1 && !props.resultFilter[k].value2))
                        return false;
                }
            } else
                if (props.resultFilter[k]?.type == 'PrevDayHL') {
                    if (!(props.resultFilter[k]?.sign1 == csv[csv.length - 5] || (props.resultFilter[k].or && props.resultFilter[k]?.sign2 == csv[csv.length - 5]))) {
                        return false;
                    }
                }
        }
        return true;
    }
    // Method to find the bucket result
    const BucketResult = async () => {
        const TempResult = []
        const week = []
        const month = []
        const cumulative = []
        const drawdown = []
        const profitArray = []
        const original_result = []
        const margin = []
        const date = []
        const legContribution = []
        for (let i = 0; i < bucketResult.length; i++) {
            if (props.executedStrategy[i] == 2) {
                const csv = JSON.parse(bucketResult[i].result);
                const legarray = bucketResult[i].legarray;   //It contain buycall,buyput,sellcall and sellput of particular strategy
                const lotarray = bucketResult[i].lotarray;  //It contains the lot size of each leg of the ith strategy.
                // console.log(lotarray,props.inputData,'lot array and input data')
                const atm = bucketResult[i].atm;
                const legs = bucketResult[i].leg;           //It contain how many leg present ith strategy
                const dayoption = props.dayOption[i];       //It contain include mon,tue,wed,thu,fri or not in particular strategy
                const tempMargin = bucketResult[i].margin;  //It contain margin of each strategy
                const slippageValue = props.slippage[i];
                // console.log(bucketResult,'executed strategy')
                // For handle selected strategies
                // console.log(csv, legarray, lotarray, atm, legs)
                const resp = result(csv, legarray, lotarray, atm, legs, dayoption, tempMargin, slippageValue);
                TempResult.push(resp.stats);
                week.push(resp.week);
                month.push(resp.month);
                cumulative.push(resp.cumulative);
                drawdown.push(resp.drawdown);
                profitArray.push(resp.profit);
                date.push(resp.tradingDate);
                legContribution.push(resp.individualLeg);
                
                // console.log(resp.profit,'resp profit')
                // console.log(props.result[i].margin,'margin total profit')
                margin.push(tempMargin);
                original_result.push({ "result": resp.result, 'legarray': legarray, 'legs': parseInt(legs), 'atm': atm, 'lotarray': lotarray,'date':resp.tradingDate });
            }
        }
        // console.log(week,month,original_result,'final result')
        setStatisticalResult(TempResult);
        updateFullWeek(week);
        updateFullMonth(month);
        setFinalCumulativeArray(cumulative);
        setFinalDrawdownArray(drawdown);
        setFinalProfitArray(profitArray);
        updateOriginalResult(original_result);
        setTradingDate(date);
        setMargin(margin);
        setIndividualLegContribution(legContribution);
        

        // process combine result
        processCombineResult();
    }

    const totalMonthProfit = (monthlyResult, monthlyDrawdown, maximumDrawdown) => {
        let total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let month_profit = {}
        let total_profit = 0
        let sum = 0;
        Object.keys(monthlyResult).forEach(key => {
            let value = monthlyResult[key];
            for (let i = 0; i <= 12; i++) {
                total[i] += value[i];
                for (let i = 1; i <= 12; i++) {
                    sum += value[i];
                }
                month_profit[key] = sum;

                sum = 0;
            }
            total_profit += month_profit[key];
        });

        Object.keys(monthlyResult).forEach(key => {
            monthlyResult[key].push(month_profit[key]);
            monthlyResult[key].push(monthlyDrawdown[key]);
            monthlyResult[key].push(parseFloat(props.margin / month_profit[key]).toFixed(0));
        })
        monthlyResult['Total'] = total;
        monthlyResult['Total'].push(total_profit);
        monthlyResult['Total'].push(maximumDrawdown);
        monthlyResult['Total'].push(parseFloat(props.margin / total_profit).toFixed(0));
        return monthlyResult;
    }

    const totalWeekProfit = (weekResult) => {
        let total = [0, 0, 0, 0, 0, 0]
        let week_profit = {}
        let sum = 0;
        Object.keys(weekResult).forEach(key => {
            let value = weekResult[key];
            for (let i = 0; i <= 5; i++) {
                total[i] += value[i];
                for (let i = 1; i <= 5; i++) {
                    sum += value[i];
                }
                week_profit[key] = sum;
                sum = 0;
            }
        });
        return total;
    }
    // Method to Find the Result
    let n = 0;
    const result = (csv, legarray, lotarray, atm, legs, dayoption, margin, slippage) => {
        // console.log('margin',margin)
        // console.log(dayoption,'dayoption')
        let total_profit = 0, winningtrades = 0, losingtrades = 0, maxprofit = 0, maxloss = 0, barChartDateArray = [], previous = 0, cnt = 0, premiumAmount = 0;
        n = csv.length > 0 ? csv[0].length : 0
        let cumulative = new Array(csv.length);
        let profitArray = new Array(csv.length)
        let week1 = new Array(5).fill(0)   //For Storing profit on the basis of daywise
        let month1 = {}   //For Storing profit on the basis of monthwise
        let winStreakDict = [0, 0, 0, 0];
        let lossStreakDict = [0, 0, 0];
        let net_profit = new Array(csv.length)  //For Storing profit
        // expectancy
        let profitQuantity = 0, totalProfit = 0, totalLoss = 0, lossQuantity = 0;
        let maxStreak = 0, lossStreak = 0;
        let prev = 1, count = 0;
        // drawdown
        let maxProfit = 0; // Initialize maxPrice with the first element of the array
        let MaximumDrawdown = 0;
        let monthlyDrawdown = {};
        let result = new Array(csv.length)
        // investmentDay
        let investmentDay = 0;
        // group by expiry
        const expirySet = new Set();
        let weekResult = {};
        let data = []
        // drawdown date
        let drawDownDate = csv.length > 0 ? csv[0][0] : '';
        //mdd Day
        let mddCount = 0, recoveryStartDate = "", maxLoss = 9999999, ans = { "count": 0, "startDate": "", "endDate": "", "recovery": 0, "continue": false, "status": 'Running', "recoveryDate": "" };
        let individualLeg = new Array(legs).fill().map(() => ({ "profit": 0, "count": 0 }));
        let drawdownArray = []
        let dayOpen = { "pdhProfit": 0, "pdhProfitCnt": 0, "pdhLoss": 0, "pdhLossCnt": 0, "pdlProfit": 0, "pdlLoss": 0, "pdlProfitCnt": 0, "pdlLossCnt": 0 }
        let gap = { "upProfit": 0, "upProfitCnt": 0, "upLoss": 0, "upLossCnt": 0, "downProfit": 0, "downProfitCnt": 0, "downLoss": 0, "downLossCnt": 0 }
        let vix = new Array(3).fill().map(() => ({ profit: 0, cnt: 0 }));
        //------start loop ---
        for (var i = 0; i < csv.length; i++) {
            // Method to store the data that will pass filter and condition
            data[cnt] = csv[i]

            // Applying Filter on the basis of vix , range of start date and end date
            if (csv[i][8] < vixStart || csv[i][8] > vixExit || csv[i][9] < vixStart || csv[9] > vixExit)
                continue;
            if (csv[i][0] < resultStartDate || csv[i][0] > resultEndDate)
                continue;
            // Applying the filter on the basis of GapUpDown and PrevDayHL
            if (!filterPass(csv[i])) {
                continue;
            }
            // Formula to Calculate Sum of Profit at a particular Day
            let sum = 0;
            let flag = 0; // It indicate supremacy of strategy profit or stoploss
            for (let key = 0; key < legarray.length; key++) {
                let current_sum=0;

                if (legarray[key][0] == 'S') {
                    if (csv[i][((12 + 5 * legs + key))] == 'sstoploss') {
                        sum = -1 * parseFloat(props.strategyStoploss);
                        flag = 1;
                    } else if (csv[i][((12 + 5 * legs + key))] == 'smaxprofit') {
                        sum = parseFloat(props.strategyProfit);
                        flag = 1;
                    } else if (csv[i][csv[i].length - 4] == 'Lock and Trail Profit Hit!' || csv[i][csv[i].length - 4] == 'Trail Hit!' || csv[i][csv[i].length - 4] == 'Lock Minimum Profit Hit!') {
                        sum = parseFloat(csv[i][csv[i].length - 3])
                    }
                    else if (flag == 0 && csv[i][(12 + 3 * legs + key)] != '99999999999' && csv[i][(12 + 4 * legs + key)] != '99999999999') {
                        sum = parseFloat(sum) + parseFloat((parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)]) - slippage * parseFloat(csv[i][(12 + 3 * legs + key)]) * 0.01) - (parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)]) + slippage * parseFloat(csv[i][(12 + 4 * legs + key)]) * 0.01))) * lotarray[key])
                    }
                    current_sum = parseFloat((parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)])-slippage*parseFloat(csv[i][(12 + 3 * legs + key)])*0.01) - (parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)])+ slippage*parseFloat(csv[i][(12 + 4 * legs + key)])*0.01))) * lotarray[key])
                } else {
                    if (csv[i][((12 + 5 * legs + key))] === 'sstoploss') {
                        sum = parseFloat(props.strategyStoploss);
                        flag = 1;
                    } else if (csv[i][((12 + 5 * legs + key))] === 'smaxprofit') {
                        sum = parseFloat(props.strategyProfit);
                        flag = 1;
                    } else if (csv[i][csv[i].length - 4] == 'Lock and Trail Profit Hit!' || csv[i][csv[i].length - 4] == 'Trail Hit!' || csv[i][csv[i].length - 4] == 'Lock Minimum Profit Hit!') {
                        sum = parseFloat(csv[i][csv[i].length - 3])
                    }
                    else if (flag == 0 && csv[i][(12 + 3 * legs + key)] != '99999999999' && csv[i][(12 + 4 * legs + key)] != '99999999999') {
                        sum = parseFloat(sum) + parseFloat((parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)]) - slippage * parseFloat(csv[i][(12 + 4 * legs + key)]) * 0.01) - parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)]) + slippage * parseFloat(csv[i][(12 + 3 * legs + key)]) * 0.01)) * lotarray[key])
                    }
                    current_sum=parseFloat((parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)])- slippage*parseFloat(csv[i][(12 + 4 * legs + key)])*0.01) - parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)]) + slippage*parseFloat(csv[i][(12 + 3 * legs + key)])*0.01)) * lotarray[key])
                }
                individualLeg[key].profit+=parseFloat(current_sum);
                individualLeg[key].count+=1;
            }
            net_profit[cnt] = parseFloat(sum);
            data[cnt] = csv[i]
            data[cnt][csv[i].length - 3] = parseFloat(sum)

            // Method to Sum of Profit DayWise
            if (dayoption[0] === 1 && csv[i][3] === 'Monday') {
                week1[0] += parseFloat(net_profit[i]);
                // console.log('weekResult ....',weekResult)
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][0] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][0] += parseFloat(net_profit[i]);
                }
            }
            else if (dayoption[1] === 1 && csv[i][3] === 'Tuesday') {
                week1[1] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][1] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][1] += parseFloat(net_profit[i]);
                }

            }
            else if (dayoption[2] === 1 && csv[i][3] === 'Wednesday') {
                week1[2] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][2] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][2] += (net_profit[i]);
                }

            }
            else if (dayoption[3] === 1 && csv[i][3] === 'Thursday') {
                week1[3] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][3] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][3] += parseFloat(net_profit[i]);
                }

            }
            else if (dayoption[4] === 1 && csv[i][3] === 'Friday') {
                week1[4] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][4] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][4] += parseFloat(net_profit[i]);
                }
            }
            else
                continue;

            // Method to find the MontlyWise Profit
            if (month1.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][parseInt(csv[i][0][5] + csv[i][0][6])] += parseFloat(sum)
            } else {
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(13).fill(0);
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][parseInt(csv[i][0][5] + csv[i][0][6])] += parseFloat(sum);
            }
            // Method to calculate total profit
            total_profit = week1[0] + week1[1] + week1[2] + week1[3] + week1[4];

            // Method to find the Cumulative Data
            cumulative[cnt] = previous + sum;
            previous = cumulative[cnt];

            // Method to find the Maximum Drawdown
            // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
            // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
            if (cumulative[cnt] > maxProfit) {
                // console.log(maxProfit,maxLoss,cumulative[cnt],drawDownDate,recoveryStartDate,csv[i][0],'tradeOnllllll')
                let dayCount = NoOfDaysBetweenTwoDate(drawDownDate, csv[i][0])
                if (ans.count < dayCount) {
                    ans.count = parseFloat(dayCount).toFixed(0);
                    ans.startDate = formatDate(drawDownDate);
                    ans.endDate = formatDate(csv[i][0]);
                    ans.recovery = NoOfDaysBetweenTwoDate(recoveryStartDate, csv[i][0],);
                    ans.continue = false;
                    ans.status = '';
                    ans.recoveryDate = recoveryStartDate;
                }

                maxProfit = cumulative[cnt]; // Update maxPrice if a new peak is reached
                maxLoss = maxProfit;
                drawDownDate = csv[i][0];
                recoveryStartDate = '';
            }

            if (cumulative[cnt] < maxLoss) {
                recoveryStartDate = csv[i][0];
                maxLoss = cumulative[cnt];
            }

            const currentDrawdown = (cumulative[cnt] - maxProfit);

            if (currentDrawdown < MaximumDrawdown) {
                MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
            }
            result[cnt] = MaximumDrawdown; 

            // monthlyDrawdown
            if (monthlyDrawdown.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = MaximumDrawdown
            } else {
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(13).fill(0);
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = MaximumDrawdown;
            }

            // Method to find all date
            barChartDateArray[cnt] = csv[i][0]

            // Method to find the Total Premium Amount
            premiumAmount += sum * premiumPrice;

            // Method to find the Expectancy
            // Formula to Calculate Expectancy
            // Expectancy=(WinRate × AverageWin)−(LossRate × AverageLoss)
            if (net_profit[i] >= 0) {
                profitQuantity += 1;
                totalProfit += net_profit[i];
                // Calculate streak
                if (prev > 0) {
                    count += 1
                    prev = 1;
                } else {
                    lossStreak = count > lossStreak ? count : lossStreak;
                    if (count <= 3) {
                        lossStreakDict[count - 1] += 1;
                    }
                    count = 1;
                    prev = 1;
                }
            } else {
                lossQuantity += 1;
                totalLoss += net_profit[i];
                if (prev < 0) {
                    count += 1;
                    prev = -1;
                } else {
                    maxStreak = count > maxStreak ? count : maxStreak;
                    if (count <= 4) {
                        winStreakDict[count - 1] += 1;
                    }
                    count = 1;
                    prev = -1;
                }
            }

            // Method to find the winning trades and lossing trades
            profitArray[cnt] = sum
            if (sum > 0) {
                winningtrades += 1;
            }
            else {
                losingtrades += 1;
            }
            if (sum > maxprofit) {
                maxprofit = sum;
            }
            if (sum < maxloss) {
                maxloss = sum;
            }

            // Count Number of InvestmentDay
            investmentDay += 1
            // Expiry
            let expirey_date = Next_Thursday(csv[i][0]);
            expirySet.add(expirey_date);

            // Day open profit
            if (csv[i][csv[i].length - 5] == 'High') {
                if (sum > 0) { dayOpen.pdhProfit += sum; dayOpen.pdhProfitCnt += 1; }
                else { dayOpen.pdhLoss += sum; dayOpen.pdhLossCnt += 1 }
            }
            else if (csv[i][csv[i].length - 5] == 'Low') {
                if (sum > 0) { dayOpen.pdlProfit += sum; dayOpen.pdlProfitCnt += 1; }
                else { dayOpen.pdlLoss += sum; dayOpen.pdlLossCnt += 1 }
            }

            // gap up profit
            if ((csv[i][10]?.Open - csv[i][11]?.Close) > 0) {
                if (sum >= 0) { gap.upProfit += sum; gap.upProfitCnt += 1; }
                else { gap.upLoss += sum; gap.upLossCnt += 1; }
            }
            else {
                if (sum >= 0) { gap.downProfit += sum; gap.downProfitCnt += 1; }
                else { gap.downProfit += sum; gap.downLossCnt += 1; }
            }

            // vix profit
            if (csv[i][8] <= 10) {
                vix[0].profit += sum; vix[0].cnt += 1;
            } else if (csv[i][8] <= 20) {
                vix[1].profit += sum; vix[1].cnt += 1;
            } else { vix[2].profit += sum; vix[2].cnt += 1; }

            cnt++;
        }
        //------- End Loop -----
        if (prev < 0) {
            if (count <= 3) { lossStreakDict[count - 1] += 1; }
        } else {
            if (count <= 4) { winStreakDict[count - 1] += 1; }
        }
        // daywise profitabiliry
        // console.log(weekResult,'weekResult  at the time of calculation')
        weekResult['Total'] = totalWeekProfit(weekResult);
        // monthwise profitability
        month1 = totalMonthProfit(month1, monthlyDrawdown, MaximumDrawdown);
        // Calculate Expectancy
        let Expectancy1 = 0;
        totalLoss = -1 * totalLoss;
        if (totalLoss == 0) {
            Expectancy1 = 'Infinity';
        }
        else if (totalProfit == 0) {
            Expectancy1 = 0;
        }
        else {
            if (cnt == 0) {
                Expectancy1 = 0;
            } else {
                Expectancy1 = ((profitQuantity * totalProfit) / (totalLoss * cnt)) - lossQuantity / cnt;
            }
        }
        // check again at every point
        let current_cnt=daysFromToday(drawDownDate);
        if(ans.count==0 || ans.count<=current_cnt){
            ans.count=daysFromToday(drawDownDate);
            ans.startDate=formatDate(drawDownDate);
            ans.endDate='Running';
            ans.recovery=recoveryStartDate==''?0:daysFromToday(recoveryStartDate);
            ans.continue=true;
            ans.status='Running'
        }

        const st_date = new Date(startdate);
        const end_date = new Date(enddate);
        const investmentmonth = (end_date.getFullYear() - st_date.getFullYear()) * 12 + (end_date.getMonth() - st_date.getMonth());
        return { "stats": { "margin":margin, "profit":parseFloat(total_profit).toFixed(2), "maximumDrawdown":parseFloat(MaximumDrawdown).toFixed(2), "maxProfit":parseFloat(maxprofit).toFixed(2), "maxLoss":parseFloat(maxloss).toFixed(2), "winTrade":winningtrades, "lossTrade":losingtrades, "avgProfit":profitQuantity != 0 ? (totalProfit / profitQuantity).toFixed(2) : 0, "avgLoss":lossQuantity != 0 ? (totalLoss / lossQuantity).toFixed(2) : 0,"avgProfit": (total_profit / ((investmentmonth + 1) * 100)).toFixed(2),"investmentDay":investmentDay,"maxStreak": maxStreak,"lossStreak": lossStreak,"gap":gap,"vix":vix,"dayOpen":dayOpen,"mdd":ans}, 'week': weekResult, 'month': month1, 'profit': net_profit, 'drawdown': result, 'cumulative': cumulative, "result": data ,"tradingDate":barChartDateArray,"individualLeg":individualLeg};
    }

    const combinedStatsResult = (combinedProfit, combinedResult, margin) => {
        let dayoption = new Array(6).fill(1);
        setDate(Array(parseInt(combinedProfit.length)).fill(""))
        let total_profit = 0, winningtrades = 0, losingtrades = 0, maxprofit = 0, maxloss = 0, barChartDateArray = [], previous = 0, cnt = 0, premiumAmount = 0;
        n = combinedProfit.length;
        let cumulative = new Array(combinedProfit.length);
        let profitArray = new Array(combinedProfit.length)
        let week1 = new Array(5).fill(0)   //For Storing profit on the basis of daywise
        let month1 = {}   //For Storing profit on the basis of monthwise
        let net_profit = new Array(combinedProfit.length)  //For Storing profit
        // expectancy
        let profitQuantity = 0, totalProfit = 0, totalLoss = 0, lossQuantity = 0;
        let maxStreak = 0, lossStreak = 0;
        let winStreakDict = [0, 0, 0, 0];
        let lossStreakDict = [0, 0, 0];
        let prev = 1, count = 0;
        // drawdown
        let maxProfit = 0; // Initialize maxPrice with the first element of the array
        let MaximumDrawdown = 0;
        let monthlyDrawdown = {};
        let result = new Array(combinedResult.length)
        // investmentDay
        let investmentDay = 0;
        // group by expiry
        const expirySet = new Set();
        let weekResult = {};
        // mdd day
        let mddCount = 0, recoveryStartDate = "", maxLoss = 9999999, ans = { "count": 0, "startDate": "", "endDate": "", "recovery": 0, "continue": false, "status": 'Running', "recoveryDate": "" };
        let dayOpen = { "pdhProfit": 0, "pdhProfitCnt": 0, "pdhLoss": 0, "pdhLossCnt": 0, "pdlProfit": 0, "pdlLoss": 0, "pdlProfitCnt": 0, "pdlLossCnt": 0 }
        let gap = { "upProfit": 0, "upProfitCnt": 0, "upLoss": 0, "upLossCnt": 0, "downProfit": 0, "downProfitCnt": 0, "downLoss": 0, "downLossCnt": 0 }
        let vix = new Array(3).fill().map(() => ({ profit: 0, cnt: 0 }));
        let drawDownDate = combineResult.length>0 && combineResult[0][0] ? combineResult[0][0] : ''

        //------start loop ---
        for (var i = 0; i < combinedProfit.length; i++) {
            // Formula to Calculate Sum of Profit at a particular Day
            let flag = 0; // It indicate supremacy of strategy profit or stoploss
            net_profit[i] = combinedProfit[i];
            total_profit += combinedProfit[i]

            //  Method to Sum of Profit DayWise
            if (dayoption[0] === 1 && combinedResult[i][1] === 'Mon') {
                week1[0] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][0] += parseFloat(net_profit[i]);
                } else {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][0] += parseFloat(net_profit[i]);
                }
            }
            else if (dayoption[1] === 1 && combinedResult[i][1] === 'Tue') {
                week1[1] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][1] += parseFloat(net_profit[i]);
                } else {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][1] += parseFloat(net_profit[i]);
                }

            }
            else if (dayoption[2] === 1 && combinedResult[i][1] === 'Wed') {
                week1[2] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][2] += parseFloat(net_profit[i]);
                } else {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][2] += (net_profit[i]);
                }

            }
            else if (dayoption[3] === 1 && combinedResult[i][1] === 'Thu') {
                week1[3] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][3] += parseFloat(net_profit[i]);
                } else {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][3] += parseFloat(net_profit[i]);
                }

            }
            else if (dayoption[4] === 1 && combinedResult[i][1] === 'Fri') {
                week1[4] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][4] += parseFloat(net_profit[i]);
                } else {
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(5).fill(0);
                    weekResult[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][4] += parseFloat(net_profit[i]);
                }
            }
            else
                continue;

            // Method to find the MontlyWise Profit
            if (month1.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                month1[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][parseInt(combinedResult[i][0][5] + combinedResult[i][0][6])] += combinedProfit[i]
            } else {
                month1[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(13).fill(0);
                month1[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]][parseInt(combinedResult[i][0][5] + combinedResult[i][0][6])] += combinedProfit[i];
            }

            // Method to find the Cumulative Data
            cumulative[cnt] = previous + net_profit[i];
            previous = cumulative[cnt];

            // Method to find the Maximum Drawdown
            // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
            // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
            if (cumulative[cnt] > maxProfit) {
                // console.log(maxProfit,maxLoss,cumulative[cnt],drawDownDate,recoveryStartDate,csv[i][0],'tradeOnllllll')
                let dayCount = NoOfDaysBetweenTwoDate(drawDownDate, combinedResult[i][0])
                if (ans.count < dayCount) {
                    ans.count = parseFloat(dayCount).toFixed(0);
                    ans.startDate = formatDate(drawDownDate);
                    ans.endDate = formatDate(combinedResult[i][0]);
                    ans.recovery = NoOfDaysBetweenTwoDate(recoveryStartDate, combinedResult[i][0],);
                    ans.continue = false;
                    ans.status = '';
                    ans.recoveryDate = recoveryStartDate;
                }

                maxProfit = cumulative[cnt]; // Update maxPrice if a new peak is reached
                maxLoss = maxProfit;
                drawDownDate = combinedResult[i][0];
                recoveryStartDate = '';
            }

            if (cumulative[cnt] < maxLoss) {
                recoveryStartDate = combinedResult[i][0];
                maxLoss = cumulative[cnt];
            }

            const currentDrawdown = (cumulative[cnt] - maxProfit);

            if (currentDrawdown < MaximumDrawdown) {
                MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
            }
            result[cnt] = MaximumDrawdown;

            // Method to find the MontlyWise Profit
            if (monthlyDrawdown.hasOwnProperty(combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3])) {
                monthlyDrawdown[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = MaximumDrawdown
            } else {
                monthlyDrawdown[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = new Array(13).fill(0);
                monthlyDrawdown[combinedResult[i][0][0] + combinedResult[i][0][1] + combinedResult[i][0][2] + combinedResult[i][0][3]] = MaximumDrawdown;
            }

            // Method to find all date
            barChartDateArray[cnt] = combinedResult[i][0]

            // Method to find the Total Premium Amount
            premiumAmount += net_profit[i] * premiumPrice;

            // Method to find the Expectancy
            // Formula to Calculate Expectancy
            // Expectancy=(WinRate × AverageWin)−(LossRate × AverageLoss)
            if (net_profit[i] >= 0) {
                profitQuantity += 1;
                totalProfit += net_profit[i];
                // Calculate streak
                if (prev > 0) {
                    count += 1
                    prev = 1;
                } else {
                    lossStreak = count > lossStreak ? count : lossStreak;
                    count = 1;
                    prev = 1;
                }
            } else {
                lossQuantity += 1;
                totalLoss += net_profit[i];
                if (prev < 0) {
                    count += 1;
                    prev = -1;
                } else {
                    maxStreak = count > maxStreak ? count : maxStreak;
                    count = 1;
                    prev = -1;
                }
            }

            // Method to find the winning trades and lossing trades
            profitArray[cnt] = net_profit[i]
            if (net_profit[i] > 0) {
                winningtrades += 1;
            }
            else {
                losingtrades += 1;
            }
            if (net_profit[i] > maxprofit) {
                maxprofit = net_profit[i];
            }
            if (net_profit[i] < maxloss) {
                maxloss = net_profit[i];
            }

            // Count Number of InvestmentDay
            investmentDay += 1
            cnt++;
        }
        //------- End Loop -----
        if (prev < 0) {
            if (count <= 3) { lossStreakDict[count - 1] += 1; }
        } else {
            if (count <= 4) { winStreakDict[count - 1] += 1; }
        }
        // daywise profitabiliry
        weekResult['Total'] = totalWeekProfit(weekResult);
        // monthwise profitability
        month1 = totalMonthProfit(month1, monthlyDrawdown, MaximumDrawdown);
        // Calculate Expectancy
        let Expectancy1 = 0;
        totalLoss = -1 * totalLoss;
        if (totalLoss == 0) {
            Expectancy1 = 'Infinity';
        }
        else if (totalProfit == 0) {
            Expectancy1 = 0;
        }
        else {
            if (cnt == 0) {
                Expectancy1 = 0;
            } else {
                Expectancy1 = ((profitQuantity * totalProfit) / (totalLoss * cnt)) - lossQuantity / cnt;
            }
        }
        // check again at every point
        let current_cnt=daysFromToday(drawDownDate);
        if(ans.count==0 || ans.count<=current_cnt){
            ans.count=daysFromToday(drawDownDate);
            ans.startDate=formatDate(drawDownDate);
            ans.endDate='Running';
            ans.recovery=recoveryStartDate==''?0:daysFromToday(recoveryStartDate);
            ans.continue=true;
            ans.status='Running'
        }

        const st_date = new Date(startdate);
        const end_date = new Date(enddate);
        const investmentmonth = (end_date.getFullYear() - st_date.getFullYear()) * 12 + (end_date.getMonth() - st_date.getMonth());
        return { "stats": { "margin":margin, "profit":parseFloat(total_profit).toFixed(2), "maximumDrawdown":parseFloat(MaximumDrawdown).toFixed(2), "maxProfit":parseFloat(maxprofit).toFixed(2), "maxLoss":parseFloat(maxloss).toFixed(2), "winTrade":winningtrades, "lossTrade":losingtrades, "avgProfit":profitQuantity != 0 ? (totalProfit / profitQuantity).toFixed(2) : 0, "avgLoss":lossQuantity != 0 ? (totalLoss / lossQuantity).toFixed(2) : 0,"avgProfit": (total_profit / ((investmentmonth + 1) * 100)).toFixed(2),"investmentDay":investmentDay,"maxStreak": maxStreak,"lossStreak": lossStreak,"gap":gap,"vix":vix,"dayOpen":dayOpen,"mdd":ans}, 'week': weekResult, 'month': month1, 'profit': net_profit, 'drawdown': result, 'cumulative': cumulative };
    }

    const [combineResult, setCombinedResult] = useState([]);
    const [combineProfit, setCombineProfit] = useState([]);
    const [combineStats, setCombineStats] = useState([]);

    const processCombineResult = () => {
        let result = {};
        let totalMargin = 0
        for (let i = 0; i < bucketResult.length; i++) {
            if (props.executedStrategy[i] == 2) {
                const csv = originalResult[i]?.result;
                totalMargin += margin[i];
                // console.log(totalMargin,'statistical margin')

                for (let j = 0; j < csv?.length; j++) {
                    let sum = 0;
                    if (props.inputData[i].reEntry) {
                        sum = reEntryProfit[i] && reEntryProfit[i][j] ? reEntryProfit[i][j] : 0;
                    } else {
                        sum = finalProfitArray[i] && finalProfitArray[i][j] ? finalProfitArray[i][j] : 0
                    }

                    if (result.hasOwnProperty(csv[j][0])) {
                        result[csv[j][0]][i + 1] = sum ? parseFloat(sum).toFixed(2) : 'NA'
                    } else {
                        result[csv[j][0]] = new Array(bucketResult.length + 1).fill(0)
                        result[csv[j][0]][0] = csv[j][3]
                        result[csv[j][0]][i + 1] = sum ? parseFloat(sum).toFixed(2) : 'NA'
                    }
                }
            }
        }

        // result------------
        let res = []
        let combinedProfit = []
        Object.entries(result).map(([key, value]) => {
            let sum = 0;
            for (let i = 1; i <= value.length; i++) {
                sum += parseInt(value[i]) ? parseFloat(value[i]) : 0;
            }
            let day = value[0].slice(0, 3);
            value.splice(0, 1);
            res.push([key, day, parseFloat(sum).toFixed(2), ...value]);
            combinedProfit.push(sum);
        })
        // console.log(result,res,combinedProfit,'result added')
        let temp = combinedStatsResult(combinedProfit, res, totalMargin);

        // combine vix,dayopen->pdh | pdl ,gap
        let dayOpen = { "pdhProfit": 0, "pdhProfitCnt": 0, "pdhLoss": 0, "pdhLossCnt": 0, "pdlProfit": 0, "pdlLoss": 0, "pdlProfitCnt": 0, "pdlLossCnt": 0 }
        let gap = { "upProfit": 0, "upProfitCnt": 0, "upLoss": 0, "upLossCnt": 0, "downProfit": 0, "downProfitCnt": 0, "downLoss": 0, "downLossCnt": 0 }
        let vix = new Array(3).fill().map(() => ({ profit: 0, cnt: 0 }));
        for (let i = 0; i < bucketResult.length; i++){

            dayOpen.pdhProfit += StatisticalResult[i]?.dayOpen?.pdhProfit;
            dayOpen.pdhProfitCnt += StatisticalResult[i]?.dayOpen?.pdhProfitCnt;
            dayOpen.pdhLoss += StatisticalResult[i]?.dayOpen?.pdhLoss;
            dayOpen.pdhLossCnt += StatisticalResult[i]?.dayOpen?.pdhLossCnt;

            dayOpen.pdlLoss += StatisticalResult[i]?.dayOpen?.pdlLoss;
            dayOpen.pdlLossCnt += StatisticalResult[i]?.dayOpen?.pdlLossCnt;
            dayOpen.pdlProfit += StatisticalResult[i]?.dayOpen?.pdlProfit;
            dayOpen.pdlProfitCnt += StatisticalResult[i]?.dayOpen?.pdlProfitCnt;

            gap.upProfit += StatisticalResult[i]?.gap.upProfit;
            gap.upProfitCnt += StatisticalResult[i]?.gap.upProfitCnt;
            gap.upLoss += StatisticalResult[i]?.gap?.upLoss;
            gap.upLossCnt += StatisticalResult[i]?.gap?.upLossCnt;
            
            gap.downProfit += StatisticalResult[i]?.gap?.downProfit;
            gap.downProfitCnt += StatisticalResult[i]?.gap?.downProfitCnt;
            gap.downLoss += StatisticalResult[i]?.gap?.downLoss;
            gap.downLossCnt += StatisticalResult[i]?.gap?.downLossCnt;

            vix[0].profit+=StatisticalResult[i]?.vix[0]?.profit;
            vix[0].cnt+=StatisticalResult[i]?.vix[0]?.cnt;
            vix[1].profit+=StatisticalResult[i]?.vix[1]?.profit;
            vix[1].cnt+=StatisticalResult[i]?.vix[1]?.cnt;
            vix[2].profit+=StatisticalResult[i]?.vix[2]?.profit;
            vix[2].cnt+=StatisticalResult[i]?.vix[2]?.cnt;
        }

        temp.stats.vix=vix;
        temp.stats.gap=gap;
        temp.stats.dayOpen=dayOpen;


        // console.log(temp,'stats result')
        setCombineProfit(combineProfit);
        setCombinedResult(res);
        setCombineStats(temp);
        setCombineMargin(totalMargin);
        // console.log(include,StatisticalResult,strategyMultiplier,props.executedStrategy,'include and strategy multiplier')
    }

    // Re-entry Result
    const FinalReEntryResult = () => {
        let reEntryStats = []
        let final_result = []
        let final_profit = []
        for (let i = 0; i < bucketResult.length; i++) {
            if (props.executedStrategy[i] == 2) {
                const csv = JSON.parse(bucketResult[i].result);  //It contain the normal result of ith strategy
                const legarray = bucketResult[i].legarray;       //It contain buycall,sellcall,buyput and sellput
                const lotarray = bucketResult[i].lotarray;       //It contain lot size of each leg of particular strategy
                const atm = bucketResult[i].atm;                 //It contain ATM-200,ATM+100,ATM-50 like it of particular strategy
                const legs = bucketResult[i].leg;                //It contain how many leg present in ith strategy
                const reEntryResult = bucketResult[i].reEntryResult;  //It contain reEntryResult of ith strategy
                const inputData = props.inputData[i];
                const slippageValue = props.slippage[i]
                // console.log(legarray, lotarray, atm, legs, csv, inputData,'hello world')
                let res = ReEntry_Result(reEntryResult, legarray, lotarray, atm, legs, csv, inputData, slippageValue);
                let stats = ReEntryStats(res?.profit, csv);
                reEntryStats.push(stats);
                final_result.push(res?.result);
                final_profit.push(res?.profit);
            }
        }
        setReEntryStatsResult(reEntryStats);
        setFinalReEntryResult(final_result);
        setReEntryProfit(final_profit);
        // console.log(reEntryStats,'reEntry Stats Result')
    }

    const ReEntryStats = (reEntryProfit, csv) => {
        let dayoption = new Array(5).fill(1)
        setDate(Array(parseInt(csv.length)).fill(""))
        let total_profit = 0, winningtrades = 0, losingtrades = 0, maxprofit = 0, maxloss = 0, barChartDateArray = [], previous = 0, cnt = 0, premiumAmount = 0;
        n = csv.length > 0 ? csv[0].length : 0
        let cumulative = new Array(csv.length);
        let profitArray = new Array(csv.length)
        let week1 = new Array(5).fill(0)   //For Storing profit on the basis of daywise
        let month1 = {}   //For Storing profit on the basis of monthwise
        let net_profit = new Array(csv.length)  //For Storing profit
        // expectancy
        let profitQuantity = 0, totalProfit = 0, totalLoss = 0, lossQuantity = 0;
        let maxStreak = 0, lossStreak = 0;
        let prev = 1, count = 0;
        // drawdown
        let maxProfit = 0; // Initialize maxPrice with the first element of the array
        let MaximumDrawdown = 0;
        let result = new Array(csv.length)
        // investmentDay
        let investmentDay = 0;
        // group by expiry
        const expirySet = new Set();
        let weekResult = {};
        // drawdown date
        let drawDownDate = csv.length > 0 ? csv[0][0] : '';
        //mdd Day
        let mddCount = 0, recoveryStartDate = "", maxLoss = 9999999, ans = { "count": 0, "startDate": "", "endDate": "", "recovery": 0, "continue": false, "status": 'Running', "recoveryDate": "" };
        // let individualLeg=new Array(inputData.leg).fill().map(() => ({"profit":0,"count":0}));
        let drawdownArray = []
        let dayOpen = { "pdhProfit": 0, "pdhProfitCnt": 0, "pdhLoss": 0, "pdhLossCnt": 0, "pdlProfit": 0, "pdlLoss": 0, "pdlProfitCnt": 0, "pdlLossCnt": 0 }
        let gap = { "upProfit": 0, "upProfitCnt": 0, "upLoss": 0, "upLossCnt": 0, "downProfit": 0, "downProfitCnt": 0, "downLoss": 0, "downLossCnt": 0 }
        let vix = new Array(3).fill().map(() => ({ profit: 0, cnt: 0 }));

        //------start loop ---
        for (var i = 0; i < csv.length; i++) {
            // Method to store the data
            // Formula to Calculate Sum of Profit at a particular Day
            let sum = 0;
            let flag = 0; // It indicate supremacy of strategy profit or stoploss

            net_profit[i] = reEntryProfit[i];
            sum = reEntryProfit[i];

            // Method to Sum of Profit DayWise
            if (dayoption[0] === 1 && csv[i][3] === 'Monday') {
                week1[0] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][0] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][0] += parseFloat(net_profit[i]);
                }
            }
            else if (dayoption[1] === 1 && csv[i][3] === 'Tuesday') {
                week1[1] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][1] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][1] += parseFloat(net_profit[i]);
                }

            }
            else if (dayoption[2] === 1 && csv[i][3] === 'Wednesday') {
                week1[2] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][2] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][2] += (net_profit[i]);
                }

            }
            else if (dayoption[3] === 1 && csv[i][3] === 'Thursday') {
                week1[3] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][3] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][3] += parseFloat(net_profit[i]);
                }

            }
            else if (dayoption[4] === 1 && csv[i][3] === 'Friday') {
                week1[4] += parseFloat(net_profit[i]);
                if (weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][4] += parseFloat(net_profit[i]);
                } else {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][4] += parseFloat(net_profit[i]);
                }
            }
            else
                continue;

            // Method to find the MontlyWise Profit
            if (month1.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][parseInt(csv[i][0][5] + csv[i][0][6])] += parseFloat(sum)
            } else {
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(13).fill(0);
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][parseInt(csv[i][0][5] + csv[i][0][6])] += parseFloat(sum);
            }

            // Method to calculate total profit
            total_profit = week1[0] + week1[1] + week1[2] + week1[3] + week1[4];

            // Method to find the Cumulative Data
            cumulative[cnt] = previous + sum;
            previous = cumulative[cnt];

            // Method to find the Maximum Drawdown
            // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
            // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
            if (cumulative[cnt] > maxProfit) {
                // console.log(maxProfit,maxLoss,cumulative[cnt],drawDownDate,recoveryStartDate,csv[i][0],'tradeOnllllll')
                let dayCount = NoOfDaysBetweenTwoDate(drawDownDate, csv[i][0])
                if (ans.count < dayCount) {
                    ans.count = dayCount;
                    ans.startDate = formatDate(drawDownDate);
                    ans.endDate = formatDate(csv[i][0]);
                    ans.recovery = NoOfDaysBetweenTwoDate(recoveryStartDate, csv[i][0],);
                    ans.continue = false;
                    ans.status = '';
                    ans.recoveryDate = recoveryStartDate;
                }

                maxProfit = cumulative[cnt]; // Update maxPrice if a new peak is reached
                maxLoss = maxProfit;
                drawDownDate = csv[i][0];
                recoveryStartDate = '';
            }

            if (cumulative[cnt] < maxLoss) {
                recoveryStartDate = csv[i][0];
                maxLoss = cumulative[cnt];
            }

            const currentDrawdown = (cumulative[cnt] - maxProfit);

            if (currentDrawdown < MaximumDrawdown) {
                MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
            }
            result[cnt] = MaximumDrawdown;

            // Method to find all date
            barChartDateArray[cnt] = csv[i][0]

            // Method to find the Total Premium Amount
            premiumAmount += sum * premiumPrice;

            // Method to find the Expectancy
            // Formula to Calculate Expectancy
            // Expectancy=(WinRate × AverageWin)−(LossRate × AverageLoss)
            if (net_profit[i] >= 0) {
                profitQuantity += 1;
                totalProfit += net_profit[i];
                // Calculate streak
                if (prev > 0) {
                    count += 1
                    prev = 1;
                } else {
                    lossStreak = count > lossStreak ? count : lossStreak;
                    count = 1;
                    prev = 1;
                }
            } else {
                lossQuantity += 1;
                totalLoss += net_profit[i];
                if (prev < 0) {
                    count += 1;
                    prev = -1;
                } else {
                    maxStreak = count > maxStreak ? count : maxStreak;
                    count = 1;
                    prev = -1;
                }
            }

            // Method to find the winning trades and lossing trades
            profitArray[cnt] = sum
            if (sum > 0) {
                winningtrades += 1;
            }
            else {
                losingtrades += 1;
            }
            if (sum > maxprofit) {
                maxprofit = sum;
            }
            if (sum < maxloss) {
                maxloss = sum;
            }

            // Count Number of InvestmentDay
            investmentDay += 1
            // Expiry
            let expirey_date = Next_Thursday(csv[i][0]);
            expirySet.add(expirey_date);

            // Day open profit
            if (csv[i][csv[i].length - 5] == 'High') {
                if (sum > 0) { dayOpen.pdhProfit += sum; dayOpen.pdhProfitCnt += 1; }
                else { dayOpen.pdhLoss += sum; dayOpen.pdhLossCnt += 1 }
            }
            else if (csv[i][csv[i].length - 5] == 'Low') {
                if (sum > 0) { dayOpen.pdlProfit += sum; dayOpen.pdlProfitCnt += 1; }
                else { dayOpen.pdlLoss += sum; dayOpen.pdlLossCnt += 1 }
            }

            // gap up profit
            if ((csv[i][10]?.Open - csv[i][11]?.Close) > 0) {
                if (sum >= 0) { gap.upProfit += sum; gap.upProfitCnt += 1; }
                else { gap.upLoss += sum; gap.upLossCnt += 1; }
            }
            else {
                if (sum >= 0) { gap.downProfit += sum; gap.downProfitCnt += 1; }
                else { gap.downProfit += sum; gap.downLossCnt += 1; }
            }

            // vix profit
            if (csv[i][8] <= 10) {
                vix[0].profit += sum; vix[0].cnt += 1;
            } else if (csv[i][8] <= 20) {
                vix[1].profit += sum; vix[1].cnt += 1;
            } else { vix[2].profit += sum; vix[2].cnt += 1; }

            cnt++;
        }
        //------- End Loop -----

        // Calculate Expectancy
        let Expectancy1 = 0;
        totalLoss = -1 * totalLoss;
        if (totalLoss == 0) {
            Expectancy1 = 'Infinity';
        }
        else if (totalProfit == 0) {
            Expectancy1 = 0;
        }
        else {
            if (cnt == 0) {
                Expectancy1 = 0;
            } else {
                Expectancy1 = ((profitQuantity * totalProfit) / (totalLoss * cnt)) - lossQuantity / cnt;
            }
        }

        // check again at every point
        let current_cnt=daysFromToday(drawDownDate);
        if(ans.count==0 || ans.count<=current_cnt){
            ans.count=daysFromToday(drawDownDate);
            ans.startDate=formatDate(drawDownDate);
            ans.endDate='Running';
            ans.recovery=recoveryStartDate==''?0:daysFromToday(recoveryStartDate);
            ans.continue=true;
            ans.status='Running'
        }

        const st_date = new Date(startdate);
        const end_date = new Date(enddate);
        const investmentmonth = (end_date.getFullYear() - st_date.getFullYear()) * 12 + (end_date.getMonth() - st_date.getMonth());
        return { "stats": { "margin":margin, "profit":parseFloat(total_profit).toFixed(2), "maximumDrawdown":parseFloat(MaximumDrawdown).toFixed(2), "maxProfit":parseFloat(maxprofit).toFixed(2), "maxLoss":parseFloat(maxloss).toFixed(2), "winTrade":winningtrades, "lossTrade":losingtrades, "avgProfit":profitQuantity != 0 ? (totalProfit / profitQuantity).toFixed(2) : 0, "avgLoss":lossQuantity != 0 ? (totalLoss / lossQuantity).toFixed(2) : 0,"avgProfit": (total_profit / ((investmentmonth + 1) * 100)).toFixed(2),"investmentDay":investmentDay,"maxStreak": maxStreak,"lossStreak": lossStreak,"gap":gap,"vix":vix,"dayOpen":dayOpen,"mdd":ans}, 'week': weekResult, 'month': month1, 'profit': net_profit, 'drawdown': result, 'cumulative': cumulative };
    }

    // Method to Find the ReEntry Result
    const ReEntry_Result = (reEntryResult, legarray, lotarray, atm, legs, csv, inputData, slippage) => {
        let LotQuantity = 1
        let ReEntryResult = JSON.parse(reEntryResult);
        let n = ReEntryResult.length;
        let index = 0;
        let total_leg = inputData['leg'];

        let reEntry_profit = new Array(parseInt(csv.length)).fill(0);
        let reEntry_reason = new Array(total_leg).fill(0);
        let res = new Array(total_leg).fill(0);
        ReEntryResult.sort();
        for (let i = 0; i < ReEntryResult.length; i++) {
            if (ReEntryResult[i][3] > res[ReEntryResult[i][1]])
                res[ReEntryResult[i][1]] = ReEntryResult[i][3];
        }
        let result = [];
        let temp = [];

        for (let i = 0; i < total_leg; i++) {
            let tempArray = [];
            if (inputData.legsReEntry[i]) {
                for (let x = 0; x <= inputData.reEntryValue[i]; x++) {
                    tempArray.push([ReEntryResult[0][0], 0, 0, 0, 0, 0, 0, 0, 0]);
                }
            } else {
                tempArray.push([ReEntryResult[0][0], 0, 0, 0, 0, 0, 0, 0, 0]);
            }
            temp.push(tempArray);
        }

        temp[ReEntryResult[0][1]][ReEntryResult[0][3]] = ReEntryResult[0];
        let key = ReEntryResult[0][1]
        // for buy  case
        let normal = -1 * parseFloat((parseFloat(ReEntryResult[0][4]) + slippage * parseFloat(ReEntryResult[0][4]) * 0.01 - (parseFloat(ReEntryResult[0][5]) - slippage * (ReEntryResult[0][5]) * 0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
        // for sell case
        let normal1 = parseFloat((parseFloat(ReEntryResult[0][4]) - slippage * parseFloat(ReEntryResult[0][4]) * 0.01 - (parseFloat(ReEntryResult[0][5]) + slippage * (ReEntryResult[0][5]) * 0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)

        reEntry_profit[index] = parseFloat(reEntry_profit[index]) + (ReEntryResult[0][4] != 99999999999 && ReEntryResult[0][4] != 99999999999) && legarray[ReEntryResult[0][1]][0] == 'S' ? normal1 : normal;
        // reEntry_profit[index] =((ReEntryResult[0][4]+slippage*ReEntryResult[0][4]*0.01 - ReEntryResult[0][5]-slippage*ReEntryResult[0][5]*0.01) * LotQuantity) ;
        if (ReEntryResult[0][6] != 'stoploss' && ReEntryResult[0][6] != 'maxprofit')
            reEntry_reason[index] = ReEntryResult[0][6]
        for (let i = 1; i < n; i++) {
            if (ReEntryResult[i - 1][0] === ReEntryResult[i][0]) {
                temp[ReEntryResult[i][1]][ReEntryResult[i][3]] = ReEntryResult[i];
                if (JSON.stringify(ReEntryResult[i]) != JSON.stringify(ReEntryResult[i - 1])) {
                    // Method to calculate profit at reentry
                    let key = ReEntryResult[i][1]
                    let normal = parseFloat(((parseFloat(ReEntryResult[i][4]) + slippage * parseFloat(ReEntryResult[i][4]) * 0.01) - (parseFloat(ReEntryResult[i][5]) - slippage * (ReEntryResult[i][5]) * 0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
                    let normal1 = parseFloat(((parseFloat(ReEntryResult[i][4]) - slippage * parseFloat(ReEntryResult[i][4]) * 0.01) - (parseFloat(ReEntryResult[i][5]) + slippage * (ReEntryResult[i][5]) * 0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
                    let sum = legarray[ReEntryResult[i][1]][0] == 'S' ? normal1 : -1 * normal;
                    // reEntry_profit[index] += ((ReEntryResult[i][4] - ReEntryResult[i][5]) * LotQuantity);
                    reEntry_profit[index] = parseFloat(reEntry_profit[index]) + parseFloat(sum);
                    // console.log(reEntry_profit[index],'re_entry_profit')
                    if (ReEntryResult[i][6] != 'stoploss' && ReEntryResult[i][6] != 'maxprofit')
                        reEntry_reason[index] = ReEntryResult[i][6]

                }
            } else {
                result.push(temp);
                index += 1;
                reEntry_profit[index] = 0;
                temp = [];
                for (let i = 0; i < total_leg; i++) {
                    let tempArray = [];
                    if (inputData.legsReEntry[i]) {
                        for (let x = 0; x <= inputData.reEntryValue[i]; x++) {
                            tempArray.push([ReEntryResult[0][0], 0, 0, 0, 0, 0, 0, 0, 0]);
                        }
                    } else {
                        tempArray.push([ReEntryResult[0][0], 0, 0, 0, 0, 0, 0, 0, 0]);
                    }
                    temp.push(tempArray);
                }
                temp[ReEntryResult[i][1]][ReEntryResult[i][3]] = ReEntryResult[i];
                let key = ReEntryResult[i][1]

                let normal = parseFloat(((parseFloat(ReEntryResult[i][4]) + slippage * parseFloat(ReEntryResult[i][4]) * 0.01) - (parseFloat(ReEntryResult[i][5]) - slippage * (ReEntryResult[i][5]) * 0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
                let normal1 = parseFloat(((parseFloat(ReEntryResult[i][4]) - slippage * parseFloat(ReEntryResult[i][4]) * 0.01) - (parseFloat(ReEntryResult[i][5]) + slippage * (ReEntryResult[i][5]) * 0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
                let sum = (ReEntryResult[i][4] != 99999999999 && ReEntryResult[i][4] != 99999999999) && legarray[ReEntryResult[0][1]][0] == 'S' ? normal1 : -1 * normal;
                reEntry_profit[index] = parseFloat(reEntry_profit[index]) + parseFloat(sum);
                if (ReEntryResult[i][6] != 'stoploss' && ReEntryResult[i][6] != 'maxprofit')
                    reEntry_reason[index] = ReEntryResult[i][6]
            }
        }
        if (temp.length > 0)
            result.push(temp);
        for (let i = 0; i < reEntry_profit.length; i++) {
            // console.log(reEntry_reason[i],'re entry reason')
            if (reEntry_reason[i] == 'strategy maxprofit hit!')
                reEntry_profit[i] = parseFloat(props.strategyProfit);
            else if (reEntry_reason[i] == 'strategy stoploss hit!')
                reEntry_profit[i] = -1 * parseFloat(props.strategyStoploss);
            else if (reEntry_reason[i] === 'Lock and Trail Profit Hit!' || reEntry_reason[i] === 'Trail Hit!' || reEntry_reason[i] === 'Lock Minimum Profit Hit!') {
                // Dependent on normal result
                if (parseFloat(csv[i][csv[i]?.length - 3])) {
                    reEntry_profit[i] = parseFloat(csv[i][csv[i]?.length - 3])
                }
            }
        }

        // console.log(reEntry_profit,'reEntry Profit');
        return { "profit": reEntry_profit, "result": result };
    }

    // Store the result in the form of excel
    const ExcelResult = () => {
        const tempResult = []
        for (let i = 0; i < bucketResult.length; i++) {
            if (props.executedStrategy[i] == 2) {
                const legarray = bucketResult[i].legarray;
                const csv = JSON.parse(bucketResult[i].result);
                const lotarray = bucketResult[i].lotarray;
                const atm = bucketResult[i].atm;
                const legs = bucketResult[i].leg;

                const mainResult = []; // Initialize mainResult array
                const column1 = ['EntryDate', 'Entry Time', 'Exit Time', 'Day', 'Expiry', 'Profit/Loss', 'LotSize', 'IndiaVix', 'Spot Price', 'Future Price', 'Gap Up/Down', 'Prev Day H/L', 'MaxProfit', 'MaxLoss', 'Exit Reason'];
                let column2 = legarray && legarray.map((item, key) => {
                    return (`Leg${key + 1} ${item} ${lotarray[key]}Lot ${atm[key]}`);
                });

                const mergedRes = [...column1, ...column2];
                mainResult.push(mergedRes);
                csv.map((item, index) => {
                    const legData = legarray && legarray.map((value, key) => {
                        let sum = legarray[key][0] === 'B' ? (item[(12 + 4 * legs + key)].toFixed(2) - item[(12 + 3 * legs + key)].toFixed(2)) : (item[(12 + 3 * legs + key)].toFixed(2) - item[(12 + 4 * legs + key)].toFixed(2));
                        if (legarray[key][0] === 'B') {
                            return (
                                `${item[(12 + 4 * legs + key)].toFixed(2)} - ${item[(12 + 3 * legs + key)].toFixed(2)}=${(sum * lotarray[key]).toFixed(2)} `
                            );
                        } else {
                            return (
                                `${item[(12 + 3 * legs + key)].toFixed(2)} - ${item[(12 + 4 * legs + key)].toFixed(2)}=${(sum * lotarray[key]).toFixed(2)} `
                            );
                        }
                    });
                    const newRow = [
                        item[0],
                        item[1],
                        item[2],
                        item[3],
                        item[12],
                        parseFloat(item[item.length - 3]).toFixed(2),
                        25,
                        `${parseFloat(item[8]).toFixed(2)}-${parseFloat(item[9]).toFixed(2)}=${parseFloat(item[8] - item[9]).toFixed(0)}`,
                        `${parseFloat(item[6]).toFixed(2)}-${parseFloat(item[7]).toFixed(2)}=${parseFloat(item[6] - item[7]).toFixed(0)}`,
                        `${parseFloat(item[4]).toFixed(2)}-${parseFloat(item[5]).toFixed(2)}=${parseFloat(item[4] - item[5]).toFixed(0)}`,
                        parseFloat(item[11].Open - item[10].Close).toFixed(2),
                        item[item.length - 5] == 'High' ? 'Day Open > PDH' : item[item.length - 5] == 'Low' ? 'Day Open < PDL' : '--',
                        parseFloat(item[item.length - 2]).toFixed(0),
                        parseFloat(item[item.length - 1]).toFixed(0),
                        item[item.length - 4]
                    ];

                    const mergedResult = [...newRow, ...legData];
                    mainResult.push(mergedResult);
                });
                tempResult.push(mainResult);
            }
        }
        setFullResult(tempResult);
        // console.log(tempResult,'reentry result .........')
    }

    // Method to find the ReEntry Result for Excel
    const ExcelReEntryResult = (reEntryResult, legarray, lotarray, atm, legs, csv) => {
        if (reEntryResult && reEntryResult.length == 0)
            return;
        const temp = {};
        const mainResult = []; // Initialize mainResult array
        const column1 = ['EntryDate', 'Entry Time', 'Exit Time', 'Day', 'Expiry', 'Profit/Loss', 'LotSize', 'IndiaVix', 'Spot Price', 'Future Price', 'Gap Up/Down', 'Prev Day H/L', 'MaxProfit', 'MaxLoss', 'Exit Reason'];

        let temparray = []
        legarray && legarray.map((item1, key) => {
            reEntryResult && reEntryResult[0] && reEntryResult[0][key] && reEntryResult[0][key].map((item, key1) => {
                return (
                    temparray.push(`Re${key1} Leg${key + 1} ${lotarray[key]}Lot ${atm[key]}`)
                )
            })
        })
        let column2 = temparray

        const mergedRes = [...column1, ...column2];
        mainResult.push(mergedRes);

        csv && csv.forEach((hello, index) => {
            const temparray = [];
            legarray && legarray.forEach((item1, key) => {
                reEntryResult && reEntryResult[index] && reEntryResult[index][key] && reEntryResult[index][key].forEach((item2, key1) => {
                    let sum = legarray[key][0] === 'B' ? (item2[4].toFixed(2) - item2[5].toFixed(2)) : (item2[5].toFixed(2) - item2[4].toFixed(2));

                    if (legarray[key][0] === 'B') {
                        temparray.push(
                            `${item2[4].toFixed(2)} - ${item2[5].toFixed(2)} = ${(sum * lotarray[key]).toFixed(2)}   (${item2[2]})`
                        );
                    } else {
                        temparray.push(
                            `${item2[5].toFixed(2)} - ${item2[4].toFixed(2)} = ${(sum * lotarray[key]).toFixed(2)}   (${item2[2]})`
                        );
                    }
                });
            });

            const newRow = [
                hello[0],
                hello[1],
                hello[2],
                hello[3],
                hello[12],
                parseFloat(hello[hello.length - 3]).toFixed(2),
                25,
                `${parseFloat(hello[8]).toFixed(2)}-${parseFloat(hello[9]).toFixed(2)}=${parseFloat(hello[8] - hello[9]).toFixed(0)}`,
                `${parseFloat(hello[6]).toFixed(2)}-${parseFloat(hello[7]).toFixed(2)}=${parseFloat(hello[6] - hello[7]).toFixed(0)}`,
                `${parseFloat(hello[4]).toFixed(2)}-${parseFloat(hello[5]).toFixed(2)}=${parseFloat(hello[4] - hello[5]).toFixed(0)}`,
                parseFloat(hello[11].Open - hello[10].Close).toFixed(2),
                hello[hello.length - 5] == 'High' ? 'Day Open > PDH' : hello[hello.length - 5] == 'Low' ? 'Day Open < PDL' : '--',
                parseFloat(hello[hello.length - 2]).toFixed(0),
                parseFloat(hello[hello.length - 1]).toFixed(0),
                hello[hello.length - 4]
            ];


            temp[index] = temparray;
            // console.log(temparray,'temparray');
            const mergedResult = [...newRow, ...temparray];
            mainResult.push(mergedResult);
        });
        setFinalExcelReEntryResult([...finalExcelReEntryResult, mainResult])
        setShowVisibility(true);
    }

    const FinalExcelReEntryResult = () => {
        for (let i = 0; i < bucketResult.length; i++) {
            if (props.executedStrategy[i] == 2) {
                const csv = JSON.parse(bucketResult[i].result);
                const legarray = bucketResult[i].legarray;
                const lotarray = bucketResult[i].lotarray;
                const atm = bucketResult[i].atm;
                const legs = bucketResult[i].leg;
                const reEntryResult = bucketResult[i].reEntryResult;
                setTimeout(() => {
                    ExcelReEntryResult(finalReEntryResult[i], legarray, lotarray, atm, legs, csv, true);
                }, 1000);
            }
        }
    }

    useEffect(() => {
        BucketResult();
        FinalReEntryResult();
        setTimeout(() => {
            FinalReEntryResult();
        }, 1000)
        processCombineResult();
    }, [JSON.stringify(fullResult), JSON.stringify(fullMonth), props.dayOption, activeBasket.strategy.length, JSON.stringify(bucketResult), resultStartDate, resultEndDate, vixStart, vixExit, props.resultFilter])

    useEffect(() => {
        processCombineResult();
        // console.log(originalResult,'original result')
    }, [JSON.stringify(originalResult), finalProfitArray])

    useEffect(() => {
        ExcelResult();
        FinalExcelReEntryResult();
        setResultStartDate(startdate);
        setResultEndDate(enddate);
    }, [bucketResult])


    return (
        <>
            <div className='w-full'>
                {/* Box of Main Answer of Every Value */}
                <div className="flex items-center justify-center bg-gradient-to-r from-blue-400 via-blue-500 to-blue-500 text-white text-2xl font-extrabold py-2 rounded-lg shadow-md">
                    <FaChartLine className="mr-2 text-3xl" />RESULT
                </div>


                <div id="screenshot" className='mb-10'>
                    {/* Combine Stats */}
                    <CombinedStats inputData={props.inputData} StatisticalResult={StatisticalResult} statisticalItem={statisticalItem} executedStrategy={props.executedStrategy} reEntryStatsResult={reEntryStatsResult} combineStats={combineStats} />
                    <div className='flex flex-col'>
                           <WeekProfit week={combineStats?.week} />
                           <Month month={combineStats?.month} totalProfit={combineStats?.stats && combineStats?.stats.length>0 && combineStats?.stats[1]} margin={combineStats?.stats && combineStats?.stats.length>0 && combineStats?.stats[0]} maximumDrawdown={combineStats?.stats && combineStats?.stats.length>0 && combineStats?.stats[1]} />
                    </div>
                    <div className="m-1 mt-2 rounded-lg">
                        <div className='p-4'>
                            <div className='flex flex-row flex-wrap gap-4'>
                                <div
                                    className='bg-green-500 text-white border-2 border-blue-600 rounded-md flex items-center justify-center cursor-pointer w-[140px]'
                                    style={{ backgroundColor: activeStrategyResult === -1 ? '#60A5FA' : 'white', color: activeStrategyResult === -1 ? 'white' : 'black' }}
                                    onClick={() => setActiveStrategyResult('-1')}
                                >
                                    Combine
                                </div>
                                {StatisticalResult && StatisticalResult.map((item, index) => (
                                    <div
                                        className="bg-green-500 text-white border-2 border-blue-600 rounded-md flex items-center justify-center cursor-pointer w-[140px]"
                                        key={index}
                                        style={{ backgroundColor: activeStrategyResult === index ? '#60A5FA' : 'white', color: activeStrategyResult === index ? 'white' : 'black' }}
                                        onClick={() => setActiveStrategyResult(index)}
                                    >
                                        {`##S-${index + 1}`}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="">
                            {activeStrategyResult == -1 && <CombinedResult combineResult={combineResult} />}
                            {activeStrategyResult != -1 && <div className='flex flex-col'>
                                <div className='flex flex-row flex-wrap mt-2 gap-2'>
                                    <div className='flex flex-col gap-2 bg-white ml-2'>
                                        <div className="flex text-center justify-center text-xl font-semibold h-10 text-white bg-[#718EBF] rounded-t-md ml-1 mr-1">Metrices</div>

                                        <div className="flex flex-col m-2 rounded-lg bg-gray-100 p-2 w-[440px]">
                                            <div className='grid grid-cols-2 gap-[1px]'>
                                                <div className="text-l mt-1 text-black text-left">Slippages</div>
                                                <input type="text" className="border-2 rounded text-l h-6 text-center text-black" value={props.slippage[activeStrategyResult]} onChange={(e) => { props.setSlippage((prevResult) => { const inputdata = [...prevResult]; inputdata[activeStrategyResult] = parseFloat(e.target.value); return inputdata; }) }}></input>
                                            </div>
                                            <div className="grid grid-cols-2">
                                                <div className="">vix (1-99)</div>
                                                <div className="flex flex-row gap-1">
                                                    <input type="text" value={vixStart} onChange={(e) => { setVixStart(parseFloat(e.target.value)) }} className="w-20 text-center border-[1px] rounded-lg" />
                                                    <div className="">to</div>
                                                    <input type="text" value={vixExit} onChange={(e) => { setVixExit(parseFloat(e.target.value)) }} className="w-20 text-center border-[1px] rounded-lg" />
                                                    <div className="bg-blue-500 text-white w-12 rounded-lg w flex text-center justify-center"><span className="cursor-pointer text-white" onClick={() => { setRestartAgain(restartAgain + 1) }}><RestartAltIcon /></span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col bg-gray-100 m-2 rounded-xl p-2">
                                            {/* Download Result in the form of PDF or EXCEl. */}
                                            <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 mt-1'>
                                                <button className="bg-blue-600 text-white rounded p-1 h-8" onClick={handleDownload}> Download Pdf </button>
                                                {reEntryChoice && <FinalExcel activeBasket={props.activeBasket} result={excelRentryResult} week={fullWeek} month={fullMonth} reEntryProfit={reEntryProfit} StatisticalResult={StatisticalResult} name={props.name} combineMonth={processCombineResult1.month} combineStatsResult={processCombineResult1?.stats} combineWeek={processCombineResult1.week} combineTradingResult={props.combineResult} />}
                                                {!reEntryChoice && <FinalExcel activeBasket={props.activeBasket} result={excelRentryResult} week={fullWeek} month={fullMonth} reEntryProfit={reEntryProfit} StatisticalResult={StatisticalResult} name={props.name} combineMonth={processCombineResult1.month} combineStatsResult={processCombineResult1?.stats} combineWeek={processCombineResult1.week} combineTradingResult={props.combineResult} />}
                                            </div>


                                            {/* Download Result in the form of PDF or EXCEl. */}
                                            <div className="grid grid-cols-5 gap-2 mt-1">
                                                {["Mon", "Tue", "Wed", "Thu", "Fri"].map((day, index) => (
                                                    <button key={index} className="bg-blue-300 text-black shadow-lg h-6 rounded" style={{ backgroundColor: props.dayOption[activeStrategyResult] && props.dayOption[activeStrategyResult][index] == 1 ? "rgb(147 197 253)" : "white" }}
                                                        onClick={() => {
                                                            props.setDayOption((prevArray) => {
                                                                // Create a new array using the spread operator
                                                                const newArray = [...prevArray];
                                                                // Update the value at firstIndex in the first dimension
                                                                newArray[activeStrategyResult] = [...prevArray[activeStrategyResult]];
                                                                newArray[activeStrategyResult][index] = 1 - newArray[activeStrategyResult][index]; // Assuming you want to update the value at index 0 in the second dimension
                                                                return newArray;
                                                            });
                                                        }}> {day} </button>))}
                                            </div>
                                        </div>
                                        <Filter tempFilter={props.tempFilter} setResultFilter={props.setResultFilter} setTempFilter={props.setTempFilter} />
                                    </div>
                                    <div className="flex flex-grow">
                                        {props.inputData[activeStrategyResult]['reEntry'] && <Main res={reEntryStatsResult[activeStrategyResult].stats} margin={margin[activeStrategyResult]} />}
                                        {!props.inputData[activeStrategyResult]['reEntry'] && <Main res={StatisticalResult[activeStrategyResult]} margin={margin[activeStrategyResult]} />}
                                    </div>
                                </div>
                                <WeekProfit week={fullWeek[activeStrategyResult]} month={fullMonth[activeStrategyResult]} margin={margin[activeStrategyResult]} maximumDrawdown={finalDrawdownArray[activeStrategyResult]} />
                                {props.inputData[activeStrategyResult]['reEntry'] && <Month week={reEntryStatsResult[activeStrategyResult].week} totalProfit={StatisticalResult[activeStrategyResult][1]} month={reEntryStatsResult[activeStrategyResult].month} margin={margin[activeStrategyResult]} maximumDrawdown={reEntryStatsResult[activeStrategyResult].drawdown} />}
                                {!props.inputData[activeStrategyResult]['reEntry'] && <Month week={fullWeek[activeStrategyResult]} totalProfit={StatisticalResult[activeStrategyResult][1]} month={fullMonth[activeStrategyResult]} margin={margin[activeStrategyResult]} maximumDrawdown={finalDrawdownArray[activeStrategyResult]} />}
                                {props.inputData[activeStrategyResult]['reEntry'] && <GraphResult profit={reEntryProfit[activeStrategyResult]} cumulative={reEntryStatsResult[activeStrategyResult].cumulative} date={tradingDate[activeStrategyResult]} drawdown={reEntryStatsResult[activeStrategyResult].drawdown} StatisticalResult={StatisticalResult} />}
                                {!props.inputData[activeStrategyResult]['reEntry'] && <GraphResult profit={finalProfitArray[activeStrategyResult]} cumulative={finalCumulativeArray[activeStrategyResult]} date={tradingDate[activeStrategyResult]} drawdown={finalDrawdownArray[activeStrategyResult]} StatisticalResult={StatisticalResult} />}
                                {showVisibility && <IndividualLeg individualLegContribution={individualLegContribution[activeStrategyResult]} totalProfit={StatisticalResult[activeStrategyResult]?.profit}/>}
                                {showVisibility && <LegProfit slippage={props.slippage[activeStrategyResult]} originalResult={originalResult[activeStrategyResult]} finalProfitArray={finalProfitArray[activeStrategyResult]} reEntryProfit={reEntryProfit[activeStrategyResult]} inputData={props.inputData[activeStrategyResult]} finalReEntryResult={finalReEntryResult[activeStrategyResult]} />}
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BucketResult