
import { useState, useEffect } from "react"
import axios from 'axios'
import { useNavigate } from "react-router-dom"
import 'react-toastify/dist/ReactToastify.css';
import { FETCHDATA_USERS } from "./../../constant/constant"
import secureLocalStorage from "react-secure-storage";
import ProfileUpload from "./ProfileUpload";
import { ToastContainer, toast } from 'react-toastify';
import { Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const Profile = () => {
  const [fullName, setFullName] = useState("")
  const [address, setAddress] = useState("")
  const [clientId, setClientId] = useState("")
  const [secretKey, setSecretKey] = useState("")
  const [apiKey, setApiKey] = useState("")
  const [brokerName, setBrokerName] = useState("")
  const [email, setEmail] = useState("")
  const [dob, setDOB] = useState("")
  const [totp, setTotp] = useState("")
  const [id, setId] = useState("")
  const [accountPassword, setAccountPassword] = useState("")
  const navigate = useNavigate();
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [editProfile,setEditProfile]=useState(false);


  const fetchData = async () => {
    const token = secureLocalStorage.getItem('token');
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${FETCHDATA_USERS}/${id}`,
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token, // Use correct header field name
      }
    };
    if (id != null && id) {
      try {
        const response = await axios.request(config);
        const result = JSON.parse((response?.data));
        if (result) {
          // result[0]?.totp ? setTotp(result[0]?.totp) : "";
          // result[0]?.clientId ? setClientId(result[0]?.clientId) : "";
          // result[0]?.api_key ? setApiKey(result[0]?.api_key) : "";
          // result[0]?.secret_key ? setSecretKey(result[0]?.secret_key) : ""
          // result[0]?.address ? setAddress(result[0]?.address) : ""
          // result[0]?.email ? setEmail(result[0]?.email) : ""
          // result[0]?.acc_password ? setAccountPassword(result[0]?.acc_password) : ""
          // result[0]?.broker_name ? setBrokerName(result[0]?.broker_name) : "";
          // result[0]?.dob ? setDOB(result[0]?.dob) : "";
          // result[0]?.full_name ? setFullName(result[0]?.full_name) : "";
        }

      } catch (error) {
        toast(error?.response?.data?.detail)
      }
    }
  }
//   useEffect(() => {
//     const id1 = secureLocalStorage.getItem('id');
//     if (id1 != null && id1 != undefined)
//       setId(id1);
//   }, [])

//   useEffect(() => {
//     fetchData();
//   }, [id])
//   useEffect(() => {
//     const token = secureLocalStorage.getItem('token');
//     const profilePicUrl1 = secureLocalStorage.getItem('profilePic')
//     profilePicUrl1 && setProfilePicUrl(profilePicUrl1);
//     if (!token)
//       navigate('/');
//   }, [])
  return (
    <>
      <ToastContainer />
      <div className="flex flex-row m-8 p-5 rounded bg-white">
        <div className="flex text-center m-5 justify-center cursor-pointer">
          <ProfileUpload setProfilePicUrl={setProfilePicUrl} />
        </div>
        <div className="flex flex-col flex-grow gap-2">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">Client Id</label>
              <input type="text" required className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" id="client_id"
                name="client_id" placeholder="Client Id" value={clientId} onChange={(e) => { e.target.value && setClientId(e.target.value); }}
                disabled={true}
              />
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">Broker Name</label>
              <select required className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" id="broker_name"
                name="broker_name" value={brokerName} onChange={(e) => { e.target.value && setBrokerName(e.target.value); }} disabled={true}>
                <option value="zerodha">Zerodha</option>
                <option value="angel">Angel Broking</option>
                <option value="upstox">Upstox</option>
                <option value="icici">ICICI Direct</option>
                {/* <!-- Add more options as needed --> */}
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">Secret_key</label>
              <input type="text" required className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1"
                placeholder="Secret Key" value={secretKey} onChange={(e) => { e.target.value && setSecretKey(e.target.value) }} disabled={true}/>
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">Api key</label>
              <input type="text" required className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1"
                placeholder="api key" value={apiKey} onChange={(e) => { e.target.value &&  setApiKey(e.target.value) }}
                disabled={true}/>
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">TOTP</label>
              <input type="text" required className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" placeholder="Enter your TOTP" 
              value={totp} onChange={(e) => { e.target.value && setTotp(e.target.value) }} disabled={true}/>
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">Full Name</label>
              <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1"
                type="text" required placeholder="Full name" value={fullName}
                onChange={(e) => { e.target.value && setFullName(e.target.value)  }} disabled={true} />
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">Email ID</label>
              <input type="email" required className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" placeholder="Email"
                value={email} onChange={(e) => { e.target.value && setEmail(e.target.value)  }} disabled={true} />
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">Date of Birth</label>
              <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" type="date" required value={dob}
                onChange={(e) => { e.target.value && setDOB(e.target.value) }} disabled={true} />
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif">Trading Account Password</label>
              <input type="text" required className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" placeholder="Enter Your Trading Account Password"
                value={accountPassword} onChange={(e) => { e.target.value && setAccountPassword(e.target.value)  }} disabled={true} />
            </div>
            <div className="flex flex-col">
              <label className="text-left text-[16px] font-serif ">Address</label>
              <textarea className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" placeholder="Your address..."
                value={address} onChange={(e) => { e.target.value && setAddress(e.target.value)  }} disabled={true}
              ></textarea>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2">
            <button className="rounded h-8 border-solid p-1 text-center text-l font-thin m-2 cursor-pointer bg-[#2D5BFF] text-white sm:w-48" onClick={() => {
              setEditProfile(true);
            }}>Update Profile</button>
          </div>
        </div>
      </div>
       {/* Confirmation to Delete saved Strategy */}
       <Dialog className="rounded-xl w-full" maxWidth="xl" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setEditProfile(false); }
      }} open={editProfile}>
        <div className="flex flex-row border-[1px] w-[680px]">
          <div className="text-[#232323] text-[22px] font-[600] p-2">Edit profile</div>
          <div className="text-[22px] font-[600] text-[#232323] flex-grow flex justify-end p-2 cursor-pointer"><CloseIcon onClick={() => { setEditProfile(false); }} /></div>
        </div>
        {/* <UpdateProfile setEditProfile={setEditProfile}/> */}
      </Dialog>
    </>
  )
}

export default Profile