import React, { useState, useEffect } from 'react'
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { FaArrowUp } from "react-icons/fa6";
import { FaArrowDown } from "react-icons/fa";
import { MdOutlineUnfoldMore } from "react-icons/md";


const LegProfit = (props) => {
    const [boxList, setBoxList] = useState([])
    const [data, setData] = useState([])
    // page size and number box
    const [pageSize, setPageSize] = useState(100)
    const [numberBox, setNumberBox] = useState([])
    const [activeColor, setActiveColor] = useState(0)
    const [indexNumber, setIndexNumber] = useState(0)
    const final_result = props.originalResult.result

    // console.log(props.finalReEntryResult,'final reEntry result')

    // Sorting the Data on the basis of Index
    const [legData, setLegData] = useState([]);
    const [order, setOrder] = useState(0);
    const sortLegData = (index) => {
        const sortedData = [...props.originalResult.result].sort((a, b) => {
            const itemA = a[index];
            const itemB = b[index];
            if (order == 0) {
                if (typeof itemA === 'string' && typeof itemB === 'string') {
                    setOrder(1 - order);
                    return itemA.localeCompare(itemB);
                } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                    setOrder(1 - order);
                    return itemA - itemB;
                } else {
                    setOrder(1 - order);
                    return 0; // No comparison possible, keep the order unchanged
                }
            } else {
                if (typeof itemA === 'string' && typeof itemB === 'string') {
                    setOrder(1 - order);
                    return itemB.localeCompare(itemA);
                } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                    setOrder(1 - order);
                    return itemB - itemA;
                } else {
                    setOrder(1 - order);
                    return 0; // No comparison possible, keep the order unchanged
                }
            }
        });
        setLegData(sortedData);
        // console.log(sortedData);
    };
    useEffect(() => {
        sortLegData(0);
    }, props.originalResult.result)
    useEffect(() => {
        let len = legData.length;
        // console.log(final_result,'final_result')
        setBoxList(Array(parseInt(len / parseInt(pageSize)) + 1).fill(0))
        setData(legData.slice(numberBox * pageSize, (numberBox + 1) * pageSize))
    }, [pageSize, numberBox, legData, props.originalResult.result])

    return (
        <>

            {/* List of trades */}
            <div>
                <div className="p-3 mt-5 overflow-y-none overflow-x-auto justify-center ml-2 mr-2">
                    <h1 className="text-[#333B69] -mt-3 -ml-3 -mr-3 p-1" style={{ fontWeight: "400", fontSize: "24px" }} > List Of Trades </h1>
                </div>
                <div className="overflow-x-auto overflow-y-none ml-2 mr-2 rounded-lg">
                    <table className="w-full">
                        <thead className="flex flex-row text-center border-b-2 p-2 text-white bg-[#718EBF]">
                            <tr>
                                <th className="p-2 w-[70px]  font-medium rounded sticky left-0 top-0 z-10 text-white bg-[#718EBF]">Include</th>
                                <th className="p-2 w-[170px]  font-medium rounded sticky text-left left-[70px] top-0 z-10 text-white bg-[#718EBF]] cursor-pointer" onClick={() => { sortLegData(0); }}>Entry Date<MdOutlineUnfoldMore className='cursor-pointer inline' onClick={() => { sortLegData(0); }} /></th>
                                <th className="p-2 w-[180px]  font-medium text-left " onClick={() => { sortLegData(legData[0].length - 3); }}>Profit/Loss<MdOutlineUnfoldMore className='cursor-pointer inline' onClick={() => { sortLegData(legData[0].length - 3); }} /></th>
                                <th className="p-2 w-[280px]  font-medium text-left" onClick={() => { sortLegData(9); }}>Vix/Spot/Future<MdOutlineUnfoldMore className='cursor-pointer inline' onClick={() => { sortLegData(9); }} /></th>
                                <th className="p-2 w-[160px]  font-medium text-left" onClick={() => { sortLegData(11); }}>Prev Day<MdOutlineUnfoldMore className='cursor-pointer inline' onClick={() => { sortLegData(11); }} /></th>
                                {
                                    props.inputData.legarray && props.inputData.legarray.map((item, key) => {
                                        return (
                                            <th className="p-2 w-[360px]  font-medium" key={`leg-${key}`}><abbr title={props.inputData.tradesymbol}>[{props.inputData.tradesymbol == 'NIFTY' ? "N" : "BN"}]</abbr> {'->'} Leg {key + 1}: {item[0] == 'B' ? 'BUY' : 'SELL'} {item[1] == 'C' ? 'CALL' : 'PUT'} |  Lot |
                                                {props.inputData.segmentType[key] == 'option' ? (props.inputData.atmChoice[key] == 'point' || props.inputData.atmChoice[key] == 'percent') ? props.inputData.atm[key] : `${props.inputData.closestPremiumChoice[key]} ${props.inputData.closestPremiumValue[key]}` : ''}
                                            </th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.map((item, index) => {
                                    return (
                                        <tr className="bg-white flex flex-row font-[inter] font-[400] text-center  border-b-2 text-[16px]" key={index}>
                                            {/* <td className="p-2 text-[#232323]  w-[70px] font-[600]  sticky left-0 top-0 z-10 bg-white "><input type="checkbox" checked={!props.includeTrade.hasOwnProperty(item[0])} onChange={() => {
                                                props.includeTrade.hasOwnProperty(item[0])
                                                    ? props.setIncludeTrade((prevResult) => {
                                                        const inputdata = { ...prevResult }; // Create a shallow copy of prevResult
                                                        delete inputdata[item[0]]; // Delete the property
                                                        return inputdata; // Return the updated object
                                                    })
                                                    : props.setIncludeTrade((prevResult) => {
                                                        const inputdata = { ...prevResult }; // Create a shallow copy of prevResult
                                                        inputdata[item[0]] = 1; // Add or update the property
                                                        return inputdata; // Return the updated object
                                                    });
                                            }} /></td> */}
                                            <td className="p-2 text-[#232323]  w-[70px] font-[600]  sticky left-0 top-0 z-10 bg-white "><input type="checkbox" defaultChecked={true} /></td>
                                            <td className="p-2 text-[#232323]  w-[170px] font-[500] text-left  sticky left-[70px] top-0 z-10 bg-white"><span className='font-[600]'>{item[3].slice(0, 3)},{item[0]}</span><br />
                                                {item[1]} - {item[2]} <br /> <span className="text-red-500">Exp.:({item[12]})</span><br />
                                            </td>
                                            <td className="p-1 text-[#232323] w-[160px]" style={{ border:"5px",borderColor:"black", color: props.inputData['reEntry'] ? props.reEntryProfit[index] && props.reEntryProfit[index] > 0 ? 'green' : 'red' : props.finalProfitArray[index] && props.finalProfitArray[index] > 0 ? 'green' : 'red' }} >
                                                <div className='border-2 border-blue-400 rounded-lg p-1'>
                                                {props.inputData['reEntry'] ? props.reEntryProfit[index] && parseInt(props.reEntryProfit[index]) : props.finalProfitArray[index] && parseInt(props.finalProfitArray[index])}
                                                </div>
                                                {/* Max and Min Profit */}
                                                <div className="p-1 h-10 mt-1 border-[1px] rounded-lg flex items-center justify-between font-semibold text-md bg-gray-100 shadow-sm">
                                                    <span className="mr-2">Max:</span>
                                                    <span className="text-green-500">{parseFloat(item[item.length - 2]).toFixed(0)}</span>
                                                    <span>/</span>
                                                    <span className="text-red-500">{parseFloat(item[item.length - 1]).toFixed(0)}</span>
                                                </div>
                                                {/* Reason (Target or StopLoss) */}
                                                <div
                                                    className={`p-1 h-10 rounded-lg flex items-center justify-center font-semibold text-md shadow-sm 
                                                            ${item[item.length - 4] === "" ? "" :
                                                            item[item.length - 4] === "strategy maxprofit hit!"
                                                                ? "text-green-600 bg-green-100 border-green-500"
                                                                : "text-red-600 bg-red-100 border-red-500"} transition-all duration-300 ease-in-out`}
                                                >
                                                    {item[item.length - 4] === "" ? "" :
                                                        item[item.length - 4] === "strategy maxprofit hit!" ? "Target Hit" : "StopLoss Hit"}
                                                </div>

                                            </td>


                                            {/* <td className="p-1 text-[#232323]  w-[140px] text-left">
                                                <div style={{ color: item[item.length - 3] >= 0 ? "green" : "red", borderColor: item[item.length - 3] >= 0 ? "green" : "red", width: "100px" }} className='flex text-center justify-center border-2 p-1'>{item[item.length - 3].toFixed(0)}</div>
                                                Max: <span className='text-green-500'>{parseFloat(item[item.length - 2]).toFixed(0)}</span>/<span className='text-red-500'>{parseFloat(item[item.length - 1]).toFixed(0)}</span>

                                            </td> */}

                                            <td className="p-2 text-[#232323]  w-[280px] text-left">
                                                <span className='text-blue-400'>(vix){parseFloat(item[8]).toFixed(2)}-{parseFloat(item[9]).toFixed(2)}={(parseFloat(item[9]).toFixed(2) - parseFloat(item[8]).toFixed(2)).toFixed(2)}</span><br />
                                                <span className='text-gray-600'>(fut.){parseFloat(item[6]).toFixed(2)}-{parseFloat(item[7]).toFixed(2)}={(parseFloat(item[7]).toFixed(2) - parseFloat(item[6]).toFixed(2)).toFixed(2)}</span><br />
                                                <span className=''>(spot) {parseFloat(item[4]).toFixed(2)}-{parseFloat(item[5]).toFixed(2)}={(parseFloat(item[5]).toFixed(2) - parseFloat(item[4]).toFixed(2)).toFixed(2)}</span></td>

                                            <td className="p-2 text-[#232323]  w-[160px] text-left" sx={{ color: (item[11]?.Open - item[10]?.Close) > 0 ? "green" : "red" }}>
                                                {parseFloat(item[10]?.Open - item[11]?.Close) > 0 ? parseFloat(item[10]?.Open - item[11]?.Close).toFixed(2) : parseFloat(item[10]?.Open - item[11]?.Close).toFixed(2)}{parseFloat(item[10]?.Open - item[11]?.Close) > 0 ? <FaArrowUp className='text-green-500 inline' /> : <FaArrowDown className='text-red-600 inline' />}({parseFloat((((item[10]?.Open - item[11]?.Close) / item[11].Close)) * 100).toFixed(2)}%)<br />
                                                <span className='text-blue-500'>{item[item.length - 5] == 'High' ? 'Day Open > PDH' : item[item.length - 5] == 'Low' ? 'Day Open < PDL' : '--'}</span><br />
                                            </td>

                                            {
                                                !props.inputData['reEntry'] && props.inputData.legarray && props.inputData.legarray.map((value, key) => {
                                                    return (
                                                        <React.Fragment key={`normal-${key}`}>
                                                            <td className="flex flex-col w-[360px]">
                                                                {
                                                                    parseFloat(item[(12 + 4 * props.inputData.leg + key)]) != 99999999999 &&
                                                                    <td className="p-1 w-[360px]  text-black" style={{
                                                                        backgroundColor: item[(12 + 5 * props.inputData.leg + key)] === "stoploss" ? "#FFDDDD" : item[(12 + 5 * props.inputData.leg + key)] === "sstoploss" ? "#FFD580" : item[(12 + 5 * props.inputData.leg + key)] === "maxprofit" ? "#cefad0" : item[(12 + 5 * props.inputData.leg + key)] === "smaxprofit" ? "#fdfa72" : ""
                                                                    }}>
                                                                        {
                                                                            (parseFloat(item[(12 + 4 * props.inputData.leg + key)]) !== 99999999999 && parseFloat(item[(12 + 3 * props.inputData.leg + key)]) !== 99999999999
                                                                                && (
                                                                                    props.originalResult.legarray[key][0] === 'S'
                                                                                        ? ((parseFloat(parseFloat(item[(12 + 3 * props.inputData.leg + key)]) - props.slippage * (parseFloat(item[(12 + 3 * props.inputData.leg + key)])) * 0.01) - (parseFloat(parseFloat(item[(12 + 4 * props.inputData.leg + key)]) + props.slippage * (parseFloat(item[(12 + 4 * props.inputData.leg + key)])) * 0.01))) * props.inputData.lotarray1[key]).toFixed(0)
                                                                                        : ((parseFloat(parseFloat(item[(12 + 4 * props.inputData.leg + key)]) - props.slippage * (parseFloat(item[(12 + 4 * props.inputData.leg + key)])) * 0.01) - (parseFloat(parseFloat(item[(12 + 3 * props.inputData.leg + key)]) + props.slippage * parseFloat(item[(12 + 3 * props.inputData.leg + key)]) * 0.01))) * props.inputData.lotarray1[key]).toFixed(0)
                                                                                )
                                                                            )}
                                                                        ({props.inputData.segmentType[key] == 'option' && `${item[12 + 2 * props.inputData.leg + key]}`}{props.inputData.segmentType[key] == 'option' && `${props.inputData.legarray[key][1]}E`})
                                                                        <br></br>
                                                                        {
                                                                            (parseFloat(item[(12 + 4 * props.inputData.leg + key)]) !== 99999999999 && parseFloat(item[(12 + 3 * props.inputData.leg + key)]) !== 99999999999 && props.inputData.legarray[key][0] === 'B'
                                                                                ? `(${(parseFloat(item[(12 + 3 * props.inputData.leg + key)]) + props.slippage * (parseFloat(item[(12 + 3 * props.inputData.leg + key)]) * 0.01)).toFixed(2)}(${item[(12 + 6 * props.inputData.leg + key)]}) - ${(parseFloat(item[(12 + 4 * props.inputData.leg + key)]) - props.slippage * (parseFloat(item[(12 + 4 * props.inputData.leg + key)]) * 0.01)).toFixed(2)}(${item[(12 + 7 * props.inputData.leg + key)]})=${((parseFloat(item[(12 + 4 * props.inputData.leg + key)]) - props.slippage * (parseFloat(item[(12 + 4 * props.inputData.leg + key)]) * 0.01)) - (parseFloat(item[(12 + 3 * props.inputData.leg + key)]) + props.slippage * (parseFloat(item[(12 + 3 * props.inputData.leg + key)]) * 0.01))).toFixed(2)})`
                                                                                : `(${(parseFloat(item[(12 + 3 * props.inputData.leg + key)]) - props.slippage * (parseFloat(item[(12 + 3 * props.inputData.leg + key)]) * 0.01)).toFixed(2)}(${item[(12 + 6 * props.inputData.leg + key)]}) - ${(parseFloat(item[(12 + 4 * props.inputData.leg + key)]) + props.slippage * (parseFloat(item[(12 + 4 * props.inputData.leg + key)]) * 0.01)).toFixed(2)}(${item[(12 + 7 * props.inputData.leg + key)]})=${((parseFloat(item[(12 + 3 * props.inputData.leg + key)]) - props.slippage * (parseFloat(item[(12 + 3 * props.inputData.leg + key)]) * 0.01)) - (parseFloat(item[(12 + 4 * props.inputData.leg + key)]) + props.slippage * (parseFloat(item[(12 + 4 * props.inputData.leg + key)]) * 0.01))).toFixed(2)})`)
                                                                        }
                                                                        <br />
                                                                    </td>
                                                                }
                                                                {
                                                                    (parseFloat(item[(12 + 4 * props.inputData.leg + key)]) == 99999999999 || parseFloat(item[(12 + 3 * props.inputData.leg + key)]) == 99999999999) && !props.appearNA &&
                                                                    <td className='p-1 w-[220px]  text-black'>NA</td>
                                                                }
                                                            </td>
                                                        </React.Fragment>
                                                    )
                                                })
                                            }
                                            <td>

                                                <div className="flex flex-row">
                                                    {
                                                        props.inputData['reEntry'] && props.originalResult.legarray && props.originalResult.legarray.map((item1, key) => {
                                                            return (
                                                                <div className="flex flex-col w-[360px]" key={`reEntryResult-${key}`}>
                                                                    {
                                                                        props.finalReEntryResult && props.finalReEntryResult[index + parseInt(pageSize) * indexNumber] && props.finalReEntryResult[index + parseInt(pageSize) * indexNumber][key] && props.finalReEntryResult[index + parseInt(pageSize) * indexNumber][key].map((item2, key1) => {
                                                                            return (
                                                                                <div className="flex flex-col w-[360px]" key={`reEntryResult1-${key1}-${key}`}>
                                                                                    {item2[5] != 99999999999 && item2[4] != 99999999999 && <div className="p-2 w-[360px]  text-black border-b-[1px]" style={{ backgroundColor: item2[6] == "stoploss" ? "#FFDDDD" : item2[6] == "maxprofit" ? "#90EE90" : "" }}>
                                                                                        {key1 == 0 ? "N" : `RE-${key1}`}: &nbsp;
                                                                                        {(item2[5] != 99999999999 && item2[4] != 99999999999 ?
                                                                                            (props.originalResult.legarray[key][0] == 'S' ?
                                                                                                parseFloat(parseFloat((parseFloat(item2[4]) - props.slippage * (parseFloat(item2[4]) * 0.01)) - (parseFloat(item2[5]) + props.slippage * (parseFloat(item2[5]) * 0.01))) * parseFloat(props.inputData.lotarray1[key])).toFixed(0) :
                                                                                                parseFloat(parseFloat((parseFloat(item2[5]) - props.slippage * (parseFloat(item2[5]) * 0.01)) - (parseFloat(item2[4]) + props.slippage * (parseFloat(item2[4]) * 0.01))) * (props.inputData.lotarray1[key])).toFixed(0)) : 'NA')}

                                                                                        (<span className="text-blue-600">{item2[2] == 0 ? '' : item2[2]}{props.inputData.segmentType[key] == 'option' && `${props.inputData.legarray[key][1]}E`}</span>)
                                                                                        <br />

                                                                                        {
                                                                                            item2[5] != 99999999999 && item2[4] != 99999999999 && (props.originalResult.legarray[key][0] === 'B'
                                                                                                ? `(${parseFloat(parseFloat(item2[4]) + props.slippage * (parseFloat(item2[4]) * 0.01)).toFixed(2)}(${item2[8]}) - ${parseFloat(parseFloat(item2[5]) - props.slippage * (parseFloat(item2[5]) * 0.01)).toFixed(2)}(${item2[7]})=${parseFloat((parseFloat(item2[4]) + props.slippage * (parseFloat(item2[4]) * 0.01)) - (parseFloat(item2[5]) - props.slippage * (parseFloat(item2[5]) * 0.01))).toFixed(2)})`
                                                                                                : `(${parseFloat(parseFloat(item2[4]) - props.slippage * (parseFloat(item2[4]) * 0.01)).toFixed(2)}(${item2[8]}) - ${parseFloat(parseFloat(item2[5]) + props.slippage * (parseFloat(item2[5]) * 0.01)).toFixed(2)}(${item2[7]})=${parseFloat((parseFloat(item2[4]) - props.slippage * (parseFloat(item2[4]) * 0.01)) - (parseFloat(item2[5]) + props.slippage * (parseFloat(item2[5]) * 0.01))).toFixed(2)})`
                                                                                            )}
                                                                                    </div>}
                                                                                    {
                                                                                        (item2[5] == 99999999999 || item2[4] == 99999999999) && <td className="p-2 pt-5 w-[220px] text-black text-center">NA</td>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Indexing  and PageSize*/}
            <div className="grid grid-cols-1 sm:grid-cols-3" >
                <div className="flex flex-row mt-5">
                    <div className="font-[600] ml-5 text-[16px]">Page&nbsp;Size</div>
                    <select className="ml-3 outline-0 h-6 rounded-md" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                        <option value={100}>100</option>
                    </select>
                </div>
                <div className="flex justify-center mt-5">Showing {indexNumber * pageSize + 1} to {data.length < ((indexNumber + 1) * pageSize) ? data.length : (indexNumber + 1) * pageSize} of {data.length} entries</div>
                <div className="grid ml-5 sm:justify-end  mb-10 mt-5 mr-10">
                    <div className="flex flex-row gap-2">
                        <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white mt-1'><FaArrowLeft className='m-1' /></div>
                        {
                            numberBox && numberBox.map((item, key) => {
                                return (
                                    <div className="rounded h-[32px] w-[32px] font-[Ubuntu]  text-[16px] font-[700] text-center text-[#2D5BFF] justify-center border-[1px] border-[#ECF0FF] cursor-pointer active:bg-[#ECF0FF]" key={`numberBox-${key}`} style={{ backgroundColor: activeColor === key ? "white" : "#ECF0FF" }} onClick={() => { setIndexNumber(key); setActiveColor(key); }}>{key + 1}</div>
                                )
                            })
                        }
                        <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white mt-1'><FaArrowRight className='m-1' /></div>
                    </div>
                </div>
            </div>
        </>)
}

export default LegProfit