import React,{useState} from 'react'
import Navbar from '../Layout/Navbar';
import FooterVersion2 from '../Layout/FooterVersion2';
import Normal from './Normal/Normal'
import Rollover from './Rollover/Rollover'
import Analysis from './Analysis/Analysis'
import HelpIcon from '@mui/icons-material/Help';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

const Gold = () => {
  const [visible,setVisible]=useState(true);
  return (
    <div className='bg-[#DAECF2]'>
        <div className='fixed w-full top-0'><Navbar /></div>
        <div className='flex flex-row mt-20 justify-center text-center p-2 gap-4'>
            <input type="checkbox" checked={visible==1} onChange={()=>{setVisible(1)}}/>
            <div className='flex flex-row'><GraphicEqIcon/> Normal <div data-tooltip-id="my-tooltip" data-tooltip-content="It contain the data without normal."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
            <input type="checkbox" checked={visible==2} onChange={()=>{setVisible(2)}}/>
            <div className='flex flex-row'><DataSaverOnIcon/> RollOver <div data-tooltip-id="my-tooltip" data-tooltip-content="It contain rollOver with 1% of exit price."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
            <input type="checkbox" checked={visible==3} onChange={()=>{setVisible(3)}}/>
            <div className='flex flex-row'><AutoGraphIcon/> Analysis <div data-tooltip-id="my-tooltip" data-tooltip-content="It contain analysis data."><HelpIcon sx={{ color: "#0096FF", fontSize: "20px" }} /></div></div>
        </div>
        {visible==1 && <Normal/>}
        {visible==2 && <Rollover/>}
        {visible==3 && <Analysis/>}
        <FooterVersion2/>
    </div>
  )
}

export default Gold