const BASE_URL='https://fprognos.com'
// const BASE_URL='http://localhost:5000'
// algo-trading connection
// const BASE_LIVE_URL='http://localhost:4000'
const BASE_LIVE_URL='https://fprognos.com'

export const LOGIN_URL=BASE_URL+'/api/user/login'
export const REGISTER_URL=BASE_URL+'/api/register'
export const RESET_PASSWORD_URL=BASE_URL+'/api/reset_password'
export const FORGOT_PASSWORD=BASE_URL+'/api/forget_password'
export const REFERRAL_URL=BASE_URL+'/api/refer'


// Backtesting
export const ADD_STRATEGIES=BASE_URL+'/api/user/add/strategies'
export const GET_STRATEGIES=BASE_URL+'/api/user/get_strategies'
export const UPDATE_OLD_STRATEGIES=BASE_URL+'/api/user/update/oldstrategies'
export const UPDATE_STRATEGY_ARRAY=BASE_URL+'/api/user/update/strategiesarray'
export const BACKTEST=BASE_URL+'/api/backtest/'
export const PRE_DEFINED_STRATEGY=BASE_URL + '/api/user/pre_defined_strategy'
export const SHARE_ADD_STRATEGY=BASE_URL + '/api/user/share/add/strategies'
export const SHARE_GET_STRATEGY=BASE_URL + '/api/user/share/strategy'

// profile
export const PROFILE_DETAIL=BASE_URL+'/api/user/profile'
export const UPDATE_PROFILE_DETAIL=BASE_URL+'/api/user/profile/update'
export const UPDATE_PASSWORD=BASE_URL+'/api/user/password/update'
export const PROFILE_IMAGE_UPLOAD=BASE_URL+'/api/user/upload'
export const GET_PROFILE_IMAGE=BASE_URL+'/api/static'
export const COUNTING_URL=BASE_URL+'/api/user/counting'

// admin
export const USER_ADMIN_LIST=BASE_URL+'/api/user/user_list'
export const UPDATE_USER_ACCOUNT=BASE_URL+'/api/user/user_account_active'
export const ADMIN_ADD_COIN_TO_USER_ACCOUNT=BASE_URL+'/api/add/coin'

// admin1
export const CONTACT=BASE_URL+'/api/admin/contact'
export const USER_BILLING_DETAIL=BASE_URL+'/api/admin/users_billing'

// Bucket
export const SAVE_BUCKET_NAME=BASE_URL+'/api/bucket/save_bucket_name'
export const ADD_STRATEGY_BUCKET=BASE_URL+'/api/bucket/add_strategy'
export const REMOVE_STRATEGY_BUCKET=BASE_URL+'/api/bucket/remove_strategy_bucket'
export const FETCH_BASKET_NAME=BASE_URL+'/api/bucket/fetch/bucket'
export const RENAME_BASKET_NAME=BASE_URL+'/api/bucket/rename'
export const DELETE_BASKET=BASE_URL+'/api/bucket/delete'
export const UPDATE_BASKET=BASE_URL+'/api/bucket/update'

// margin
export const MARGIN_DATA=BASE_URL+'/api/backtest/margin'
export const UPLOAD_SPAN_DATA=BASE_URL+'/api/backtest/margin/upload'
export const DELETE_MARGIN_DATA=BASE_URL+'/api/backtest/margin/delete'
export const CALCULATE_MARGIN=BASE_URL+'/api/backtest/margin/calculate'

// payment api
export const PAYMENT_DETAIL=BASE_URL+'/api/payment/payu/detail'
export const TRANSACTION_DETAIL=BASE_URL+'/api/payment/payu/transaction-detail'
export const TRANSACTION_CANCEL=BASE_URL+'/api/payment/payu/cancel'

// future api
export const SERIES_TRADING=BASE_URL+'/api/future/series/trading'
// option api
export const SERIES_OPTION_TRADING=BASE_URL+'/api/option/series/trading'
// gold api
export const GOLD_TRADING=BASE_URL+'/api/gold/trading'   //without rollover
export const GOLD_TRADING1=BASE_URL+'/api/gold/trading1' //only for rollOver
export const ANALYSIS_TRADING=BASE_URL+'/api/gold/analysis'
export const UPLOAD_GOLD_DATA=BASE_URL+'/api/gold/process_data' // for uploading latest gold data

// update a frontend
export const SAVE_LIVE_TRADING = BASE_LIVE_URL + '/fprognos/api/v1/save-strategy'
export const CHECK_USER_EXIST = BASE_LIVE_URL + '/fprognos/api/v1/auth/user-exist'

export const COIN_LIST = [
    {'price': 700, 'coin': 50, 'expire': '1 month'},
    {'price': 1500, 'coin': 150, 'expire': '2 month'},
    {'price': 3000, 'coin': 350, 'expire': '4 month'},
    {'price': 5000, 'coin': 500, 'expire': '6 month'},
    {'price': 10000, 'coin': 800, 'expire': '9 month'},
    {'price': 20000, 'coin': 2500, 'expire': '1 year'},
    {'price': 30000, 'coin': 3000, 'expire': '2 year'},
    {'price': 40000, 'coin': 4200, 'expire': '3 year'},
    {'price': 50000, 'coin': 5500, 'expire': '4 year'},
    {'price': 100000, 'coin': 110000, 'expire': '5 year'},
    {'price': 200000, 'coin': 22000, 'expire': '8 year'}
]

export const NIFTY_LOT_SIZE=[25,50,75]
export const BANKNIFTY_LOT_SIZE=[15,20,25,40]
// Live Trading::
// For Dashboard
export const GET_ORDER =BASE_URL;
export const MANUAL_SQUARE_OFF=BASE_URL;
export const TIME_DURATION=BASE_URL;

// For Input
export const SAVE_STRATEGY=BASE_URL;
export const SAVE_INPUT_STRATEGY=BASE_URL;

// For Profile
export const FETCHDATA_USERS=BASE_URL;
export const UPDATE_PROFILE_URL=BASE_URL;
export const FETCHDATA_USERS1=BASE_URL;

// For LiveTrading
export const DELETE_BACKUP_INPUT_COLLECTION=BASE_URL;
export const DELETE_BACKUP_ORDER_COLLECTION=BASE_URL;
export const DELETE_BACKUP_SESSION_FILE=BASE_URL;
export const DELETE_BACKUP_SIGNAL_FILE=BASE_URL;
export const UPDATE_USER_ADMIN_SESSION=BASE_URL;
export const USER_ADMIN_URL=BASE_URL + '/api/user/user_list'
export const FETCH_PICKLE_FILE_NAME=BASE_URL;

export const PLAN_LIST_API=BASE_URL + '/api/get-all-plans';
export const PAYMENT_INITIATE_API = BASE_URL+'/api/payment/initiate-payment'
export const PAYMENT_HISTORY_API = BASE_URL+'/api/payment/payment-history/'
export const UPDATE_PLAN = BASE_URL + '/api/payment/update-plan'
export const DELETE_PLAN = BASE_URL + '/api/payment/delete-plan'
export const INSERT_PLAN = BASE_URL + '/api/payment/insert-plan'

export const special_days=[{"id":1,"name":"Budget Days","date":["20240723",'20240201',"20230201","20220201","20210201","20190705","20190201","20180201"]},{"id":2,"name":"RBI Policy Days","date":["20240607",'20240405',"20230207","20231207","20231006","20230810","20230608","20230406","20230208"]}]