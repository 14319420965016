import { useState, useEffect } from "react";
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import logo from './../../assets/logo12.webp'
import { REGISTER_URL } from '../../constant/constant'
import { ToastContainer, toast } from "react-toastify";
import { Audio } from 'react-loader-spinner'
import { MdOutlineVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import { useLocation } from 'react-router-dom';
import Section1 from './Section1';
import { FaCheckDouble } from "react-icons/fa6";
import TermsAndConditions from './TermsAndConditions'
import { Dialog } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const Signup = () => {
    const navigate = useNavigate()
    const [name, setname] = useState("")
    const [password, setpassword] = useState("")
    const [email, setemail] = useState("")
    const [address, setaddress] = useState("")
    const [dob, setdob] = useState("")
    const [username, setUserName] = useState("")
    const [cpassword, setcpassword] = useState("")
    const [wait, setWait] = useState(false);
    const [warn, setWarn] = useState(false)
    const [checked, setChecked] = useState(false)
    const [visible, setVisible] = useState(false)
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [openTermsAndCondition, setOpenTermsAndCondition] = useState(false);

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };
    function validatepassword(str) {
        // Check for lowercase, uppercase, and digit
        const hasLowercase = /[a-z]/.test(str);
        const hasUppercase = /[A-Z]/.test(str);
        const hasDigit = /\d/.test(str);

        // Check for special characters using a regular expression
        const hasSpecialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(str);

        // Check the length (minimum 8 characters)
        const isLengthValid = str.length >= 7;

        // Check if all conditions are met
        return hasLowercase && hasUppercase && hasDigit && hasSpecialChars && isLengthValid;
    }
    const handlesubmit = () => {
        const refer = params.get('refer');
        if (name && email && username && password && address) {
            const data = { "full_name": name, "email": email.toLowerCase(), "username": username, "date_of_birth": dob, "password": password, "address": address, "refer": refer };
            if (!checked) {
                toast.error('Please Accept Terms & Conditions.')
                setWait(false);
                return;
            }
            if (!validateEmail(email)) {
                toast.error('Please Enter Correct Email Address.');
                setWait(false)
                return;
            }
            if (password != cpassword) {
                toast.error('Please Enter Both Password must be equal.');
                setWait(false)
                return;
            }
            if (!validatepassword(password)) {
                toast.error('Please enter a password with at least 8 characters, including uppercase, lowercase, numbers, and special characters.');
                setWait(false)
                return;
            }
            else {
                const config = {
                    method: 'post',
                    url: REGISTER_URL,
                    headers: { 'Content-Type': 'application/json' },
                    data: data
                };
                axios.request(config)
                    .then((response) => {
                        setname(""); setemail(""); setdob(""); setpassword(""); setaddress(""); setWait(false); alert('You are successfully registered!');
                        navigate('/backtest/login');
                    })
                    .catch((error) => {
                        if (error?.response?.data?.message) {
                            toast(error.response.data.message); setWait(false); return;
                        }
                        else { toast('Try Again Later'); setWait(false); return; }
                    });
            }
        } else {
            toast.warn("Please complete all required fields before proceeding with the sign-up process");
            setWait(false);
            return;
        }
    }
    useEffect(() => {
        validatepassword(password) ? setWarn(true) : setWarn(false);
    }, [password])
    useEffect(() => {
        if (wait === true) {
            handlesubmit();
            setTimeout(() => {
                setWait(false);
            }, 10000);
        }
    }, [wait])
    return (
        <>
            <ToastContainer />
            <div className="flex flex-row justify-center text-center min-h-screen bg-[#DAECF2]">
                {/* Section1 Component for Large Screens */}
                <div className="hidden lg:block ml-5 mt-10 mb-10">
                    <Section1 />
                </div>
                {/* Registration Form */}
                <div className="flex flex-col bg-white shadow-lg p-6 lg:p-5 my-6 lg:my-10 max-w-md lg:max-w-lg lg:min-w-fit mx-auto lg:mx-0">
                    <div className="text-[#313c40] text-3xl font-bold text-left mb-6">
                        <img src={logo} alt="logo" className="h-24 w-24" />
                    </div>
                    <h1 className="text-[#000000] text-3xl font-bold text-left mb-2">Create Account</h1>
                    <p className="text-[#000000] text-left mb-2 font-semibold">Hello! Let's get started...</p>
                    <hr className="my-1" />

                    {/* Form Inputs */}
                    <div className="space-y-4">
                        {/* Name Input */}
                        <div className="flex flex-col">
                            <input
                                type="text"
                                required
                                placeholder="Enter Your Name*"
                                className="outline-0 h-10 border border-[#1565D8] pl-4 rounded-lg w-full"
                                value={name}
                                onChange={(e) => setname(e.target.value)}
                            />
                        </div>

                        {/* Email Input */}
                        <div className="flex flex-col">
                            <input
                                type="email"
                                required
                                placeholder="Enter Email Address*"
                                className="outline-0 h-10 border border-[#1565D8] pl-4 rounded-lg w-full"
                                value={email}
                                onChange={(e) => setemail(e.target.value)}
                            />
                        </div>

                        {/* Username Input */}
                        <div className="flex flex-col">
                            <input
                                type="text"
                                required
                                placeholder="Enter Username*"
                                className="outline-0 h-10 border border-[#1565D8] pl-4 rounded-lg w-full"
                                value={username}
                                onChange={(e) => setUserName(e.target.value)}
                            />
                        </div>

                        {/* Password Input */}
                        <div className="flex flex-col mt-1">
                            {visible ? (
                                <div className="flex flex-row border border-[#1565D8] rounded-lg h-10 w-full">
                                    <input
                                        type="text"
                                        placeholder="Enter Your Password*"
                                        className="outline-0 pl-4 w-full rounded-lg border-[#1565D8]"
                                        value={password}
                                        onChange={(e) => setpassword(e.target.value)}
                                    />
                                    <MdOutlineVisibility className="cursor-pointer ml-5 mr-2 mt-2 font-semibold text-2xl"  onClick={() => setVisible(false)} />
                                </div>
                            ) : (
                                <div className="flex flex-row border border-[#1565D8] rounded-lg h-10 w-full">
                                    <input
                                        type="password"
                                        placeholder="Enter Your Password*"
                                        className="outline-0 pl-4 w-full rounded-lg border-[#1565D8]"
                                        value={password}
                                        onChange={(e) => setpassword(e.target.value)}
                                    />
                                    <MdVisibilityOff className="cursor-pointer ml-5 mr-2 mt-2 font-semibold text-2xl" onClick={() => setVisible(true)}
                                    />
                                </div>
                            )}
                            <div className="text-xs italic mt-2" style={{ color: warn ? "black" : "red" }}>
                                Password: Min 7 chars, 1 lowercase, 1 uppercase, 1 digit, 1 special required
                            </div>
                        </div>

                        {/* Confirm Password Input */}
                        <div className="flex flex-col mt-1">
                            {visible ? (
                                <div className="flex flex-row border border-[#1565D8] rounded-lg h-10 w-full">
                                    <input
                                        type="text"
                                        placeholder="Confirm Password*"
                                        className="outline-0 pl-4 w-full rounded-lg border-[#1565D8]"
                                        value={cpassword}
                                        onChange={(e) => setcpassword(e.target.value)}
                                    />
                                    <MdOutlineVisibility className="cursor-pointer ml-5 mr-2 mt-2 font-semibold text-2xl" onClick={() => setVisible(false)}/>
                                </div>
                            ) : (
                                <div className="flex flex-row border border-[#1565D8] rounded-lg h-10 w-full">
                                    <input
                                        type="password"
                                        placeholder="Confirm Password*"
                                        className="outline-0 pl-4 w-full rounded-lg border-[#1565D8]"
                                        value={cpassword}
                                        onChange={(e) => setcpassword(e.target.value)}
                                    />
                                    <MdVisibilityOff className="cursor-pointer ml-5 mr-2 mt-2 font-semibold text-2xl" onClick={() => setVisible(true)}/>
                                </div>
                            )}
                        </div>

                        {/* Address Input */}
                        <div className="flex flex-col mt-1">
                            <input
                                type="text"
                                required
                                placeholder="Enter Your Address*"
                                className="outline-0 h-10 border border-[#1565D8] pl-4 rounded-lg w-full"
                                value={address}
                                onChange={(e) => setaddress(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Terms and Conditions */}
                    <div className="text-xs ml-5 mr-5 flex flex-row mt-3 text-center justify-start">
                        {checked && <FaCheckDouble />}
                        <p className="text-center">
                            By creating an account, you agree to the <span className="italic text-blue-400 cursor-pointer" onClick={() => { setOpenTermsAndCondition(true); }}>terms of use and privacy policy</span>
                        </p>
                    </div>

                    {/* Submit Button */}
                    <div className="text-center">
                        {!wait ? (
                            <button
                                className="text-white bg-[#2C73EB] h-10 rounded-md mt-4 font-bold cursor-pointer w-full"
                                onClick={() => setWait(true)}
                            >
                                Create Account
                            </button>
                        ) : (
                            <div className="flex justify-center mt-4">
                                <Audio height="40" width="120" color='lightblue' ariaLabel='three-dots-loading' />
                            </div>
                        )}
                    </div>

                    {/* Links */}
                    <div className="grid grid-cols-1 gap-2 mt-2">
                        <div className="flex items-center justify-start">
                            <div className="text-[#3F4547]">Already Registered?</div>
                            <Link to='/backtest/login' className="text-[#0369a8] pl-1 ml-2 underline cursor-pointer">Sign In</Link>
                        </div>
                        <div className="flex items-center justify-start">
                            <div className="text-[#3F4547]">Forgot Password?</div>
                            <Link to='/backtest/forget_password' className="text-[#0369a8] pl-1 ml-2 underline cursor-pointer">Reset Password</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openTermsAndCondition} onClose={() => { setOpenTermsAndCondition(false); }} sx={{ borderRadius: "10px" }}>
                <div className="flex flex-row border-b-2">
                    <div className="text-[#181818] text-[18px] p-2">Terms & Conditions</div>
                    <div className="h-[24px] w-[24px] text-[#181818] flex-grow flex justify-end font-semibold p-2 cursor-pointer"><CloseIcon onClick={() => { setOpenTermsAndCondition(false); }} /></div>
                </div>
                <TermsAndConditions />
                <div className="flex justify-end p-2 m-4 gap-2">
                    <button className="p-1 w-28 bg-white border-[1px] border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setChecked(false);setOpenTermsAndCondition(false); }}>Decline</button>
                    <button className="p-1 w-28 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { setChecked(true); setOpenTermsAndCondition(false); }}>Accept</button>
                </div>
            </Dialog>
        </>
    );
};
export default Signup;