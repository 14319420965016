import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import logo from './../../assets/logo12.webp'
import { Audio } from 'react-loader-spinner'
import { FORGOT_PASSWORD } from '../../constant/constant'
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from "react-secure-storage";
import Section1 from "./Section1";


const ForgetPassword = () => {
    const navigate = useNavigate();
    const [emailId, setEmailId] = useState("")
    const [wait, setWait] = useState(false)

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handlesubmit = () => {
        if (emailId != "" && validateEmail(emailId)) {
            const data = { "email": emailId }
            const config = {
                method: 'post',
                url: FORGOT_PASSWORD,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    setWait(false)
                    setEmailId("");
                    alert('A password reset link has been sent to your email. Please check your inbox (and spam folder) to continue');
                    navigate('/backtest/login');
                })
                .catch((error) => {
                    if (error?.response?.data?.message) { toast(error.response.data.message); setWait(false); return; }
                    else { toast('Try Again Later'); setWait(false); return; }
                });
        } else {
            toast('Please Enter Valid Email Address.');
            setWait(false);
            return;
        }

    }
    useEffect(() => {
        if (wait === true) {
            handlesubmit(); setTimeout(() => { setWait(false); }, 20000);
        }
    }, [wait])

    useEffect(() => {
        const email = secureLocalStorage.getItem('email');
        console.log(email, 'email id')
        email && setEmailId(email);
    }, [])

    return (
        <>
            <ToastContainer />
            <div className="flex flex-row justify-center text-center min-h-screen bg-[#DAECF2]">
                <div className="hidden lg:block relative mt-10 mb-10 ml-20">
                    <Section1 />
                </div>
                <div className="flex flex-col bg-white shadow-lg p-6 lg:p-10 my-6 lg:my-10 max-w-md lg:max-w-lg mx-auto lg:mx-0">
                    <div className="flex justify-start mb-6">
                        <img src={logo} alt="logo" className="h-24 w-24" />
                    </div>
                    <div className="text-[#3F4547] text-4xl font-bold text-left mb-2">
                        Forgot Password
                    </div>
                    <div className="text-[#000000] text-xl font-medium text-left mb-4">
                        You will receive a reset password link in your official email ID.
                    </div>
                    <div className="text-left font-semibold mb-6">
                        Hello! Let's get started...
                    </div>
                    <hr className="mb-6" />
                    <div className="text-left text-[#494949] font-medium mb-2">
                        Email Address
                    </div>
                    <div className="text-center mb-4">
                        <input
                            type="text"
                            placeholder="Enter Your Email Address"
                            className="w-full h-12 border border-[#1565D8] rounded-lg px-4 outline-none focus:ring-2 focus:ring-[#2C73EB]"
                            value={emailId}
                            onChange={(e) => { setEmailId(e.target.value); }}
                        />
                    </div>
                    {!wait && (
                        <div className="text-center mb-4">
                            <button
                                className="w-full h-12 bg-[#2C73EB] text-white font-bold rounded-lg hover:bg-[#1b56b3] transition-all duration-200"
                                onClick={() => { setWait(true) }}
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) { setWait(true) }
                                }}
                            >
                                Forgot Password
                            </button>
                        </div>
                    )}
                    {wait && (
                        <div className="flex justify-center mb-4">
                            <Audio height="40" width="120" color='lightblue' ariaLabel='three-dots-loading' />
                        </div>
                    )}
                    <div className="flex justify-start mt-6">
                        <div className="text-[#3F4547]">Have an account?</div>
                        <Link to='/backtest/login' className="text-[#2C73EB] pl-1 underline">
                            Sign In here.
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ForgetPassword;