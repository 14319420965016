import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import footer from './Home/assets/Footer.webp';
import { useNavigate } from 'react-router-dom';

const FooterVersion2 = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='' style={{ backgroundImage: `url(${footer})`, color: "white" }}>
                <div className="grid grid-cols-6 pt-5 pl-5 pb-5 max-md:grid-cols-1  border-t-8 border-white"  >
                    {/* 1st Box  */}

                    {/* 2nd Box */}
                    <div className="flex flex-col col-span-3 mr-24 ml-16 max-md:col-span-1 max-md:m-0">
                        <p className="mt-10 w-[360px]"><span className="text-2xl font-semibold">Damyant Fintech</span><br/> was incorporated in December,2015. The company is headquartered in Delhi India</p>
                        <div className="flex flex-row">
                            <div style={{ borderRadius: "50%", backgroundColor: "white", color:"gray", padding: "5px", margin: "10px" }}><FaFacebookSquare /></div>
                            <div style={{ borderRadius: "50%", backgroundColor: "white", color:"gray", padding: "5px", margin: "10px" }}><FaXTwitter /></div>
                            <div style={{ borderRadius: "50%", backgroundColor: "white", color:"gray", padding: "5px", margin: "10px" }}><FaLinkedin /></div>
                            <div style={{ borderRadius: "50%", backgroundColor: "white", color:"gray", padding: "5px", margin: "10px" }}><FaGoogle /></div>
                        </div>
                    </div>

                    {/* ###### 3rd Box ##### */}
                    <div className="flex flex-col mt-10">
                        <div className="font-bold text-xl">Product</div>
                        <p className="mt-3 hover:underline cursor-pointer" onClick={()=>{navigate('/')}}>Overview</p>
                        <div className="mt-3 hover:underline cursor-pointer" onClick={()=>{navigate('/')}}>Pricing</div>
                        <div className="mt-3 hover:underline cursor-pointer" onClick={()=>{navigate('/')}}>Customer Stories</div>
                    </div>

                    {/* 4th Box */}
                    <div className="flex flex-col mt-10">
                        <div className="font-bold text-xl">Resource</div>
                        <div className="mt-3 hover:underline cursor-pointer" onClick={()=>{navigate('/')}}>Faq</div>
                        <div className="mt-3 hover:underline cursor-pointer" onClick={()=>{navigate('/')}}>Terms</div>
                        <div className="mt-3 hover:underline cursor-pointer" onClick={()=>{navigate('/')}}>Help Center</div>
                    </div>
                    {/* 5th box */}
                    <div className="flex flex-col mt-10">
                        <div className="font-bold text-xl">Company</div>
                        <div className="mt-4 hover:underline cursor-pointer" onClick={()=>{navigate('/backtest/about')}}>About</div>
                        <div className="mt-3 hover:underline cursor-pointer" onClick={()=>{navigate('/')}}>Product</div>
                        <div className="mt-3 hover:underline cursor-pointer" onClick={()=>{navigate('/backtest/contact')}}>Contact</div>
                    </div>
                </div>
                <div >
                    <hr className='bg-white h-1 ml-10 mr-10'></hr>
                    <div className="text-center mt-1 pb-2 text-l">
                         {new Date().getFullYear() - 1}-{new Date().getFullYear()} Copyright © All rights reserved || For Contact{" "} <span className="italic text-blue-600">
                         <a href="mailto:support.dftl@damyant.com" className="text-blue-500 underline"> support.dftl@damyant.com</a>. </span> 
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterVersion2