import React, { useState, useEffect } from 'react'
import { pointnifty, pointbanknifty, premium, percentarray } from "../constant"
import { IoMdAdd } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { MdClose } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Dialog } from "@mui/material";
import Joyride from 'react-joyride';

const Leg = (props) => {
  const [openTrailStoplossBox, setOpenTrailStoplossBox] = useState(false);
  const [trailProfit, setTrailProfit] = useState('');
  const [trailloss, setTrailLoss] = useState('');
  const [trailType, setTrailType] = useState('point');
  const [trailingMessage, setTrailingMessage] = useState('');
  const [currentKey,setCurrentKey]=useState(null);

  const StraddlePremiumSeries = ['5%SP', '10%SP', '15%SP', '20%SP', '25%SP', '30%SP', '35%SP', '40%SP', '45%SP', '50%SP', '55%SP', '60%SP', '65%SP', '70%SP']
  const Toast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const updateOptionBuySell = (key) => {
    const inputdata = [...props.optionBuySell];
    let temp = inputdata[key];
    inputdata[key] = (temp === 'Buy' ? 'Sell' : 'Buy');
    props.setOptionBuySell(inputdata);
  }
  const updateOptionCallPut = (key) => {
    const inputdata = [...props.optionCallPut];
    let temp = inputdata[key];
    inputdata[key] = (temp === 'Call' ? 'Put' : 'Call');
    props.setOptionCallPut(inputdata);
  }
  const onHandleTrailSubmit = (key) => {
    if (parseInt(trailloss) > parseInt(trailProfit)) {
      setTrailingMessage('Profit moves must be greater than loss movement!!.');
    }
    else if (trailloss == '' || trailProfit == '') {
      setTrailingMessage('Value must not be blank!!.');
    } else {
      const inputdata = [...props.trailStoplossProfit]; inputdata[key] = trailProfit; props.setTrailStoplossProfit(inputdata);
      const inputdata1 = [...props.trailStoplossLoss]; inputdata1[key] = trailloss; props.setTrailStoplossLoss(inputdata1);
      const inputdata2 = [...props.trailStoplossType]; inputdata2[key] = trailType; props.setTrailStoplossType(inputdata2);
      setTrailingMessage('');
      setTrailLoss('');
      setTrailProfit('');
      setOpenTrailStoplossBox(false);
    }
  }
  const removeTrailStoploss = (key) => {
    props.setTrailStoplossProfit((prev)=>{const inputdata=[...prev];inputdata[key]='';return inputdata});
    props.setTrailStoplossLoss((prev)=>{const inputdata=[...prev];inputdata[key]='';return inputdata});
    props.setTrailStoploss((prev)=>{const inputdata=[...prev];inputdata[key]=false;return inputdata})
  }

  const handleInputNumber = (event,key,value,update) => {
    const inputValue = event.target.value;
    // Regular expression to match numbers with optional decimal point
    const regex = /^(\d+\.?\d*|\.\d+)$/;
    if (regex.test(inputValue) || inputValue === '') {
      const input=[...value];
      input[key]=inputValue;
      update(input);
    }
  };
  const handleNumber = (event,update) => {
    const inputValue = event.target.value;
    // Regular expression to match numbers with optional decimal point
    const regex = /^(\d+\.?\d*|\.\d+)$/;
    if (regex.test(inputValue) || inputValue === '') {
      update(inputValue);
    }
  };


  const BN_QUANT = 25;
  useEffect(() => {
    props.squareoff == 'all' ? props.setReEntryChoice(Array(parseInt(props.leg)).fill('ReExecute')) : props.setReEntryChoice(Array(parseInt(props.leg)).fill('Re-Entry'));
  }, [props.squareoff])
  useEffect(() => {
    const inputdata = [...props.trailStoploss];
    const inputdata1 = [...props.legsReEntry];
    let cnt = 0, cnt1 = 0;
    for (let i = 0; i < props.leg; i++) {
      if (props.legsStoploss[i] == -1 && props.trailStoploss[i]) {
        inputdata[i] = false;
        cnt++;
      }
      if (props.legsStoploss[i] == -1 && props.legsReEntry[i]) {
        inputdata1[i] = false;
        cnt1++;
      }
    }
    if (cnt > 0)
      props.setTrailStoploss(inputdata);
    if (cnt1 > 0)
      props.setLegReEntry(inputdata1);
  }, [props.legsStoploss])
  return (
    <>
      <ToastContainer />
      {props.runTour && <Joyride
        steps={props.tourSteps}
        run={props.runTour}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        disableOverlayClose={true}
        callback={props.handleJoyrideCallback}
      />}
        {/* Here ,All Properties of leg and Handle it */}
        <div className="lg:-mt-5 feature-12">
          {props.legarray.map((item, key) => {
            return (
              <div className="flex flex-col bg-white rounded-md float-normal mt-2 mr-2 ml-2 sm:mr-2 sm:ml-2 overflow-auto"
                style={{ backgroundColor: key % 2 !== 0 ? '#F0F2F5' : 'white', fontSize: "medium", fontWeight: "500",opacity:props.includeLeg[key]?1:0.2 }} key={`leg-${key}`}>
                <div className=" flex flex-row overflow-x-auto overflow-y-hidden bg-inherit p-2">
                  <div className="flex text-center justify-center">
                    <input type="checkbox" className='mt-7 p-1' checked={props.includeLeg[key]} onChange={()=>{props.setIncludeLeg((prevResult)=>{const inputdata=[...prevResult];inputdata[key]=!props.includeLeg[key];console.log(inputdata,'inputdata');return inputdata})}}/>
                  </div>
                  
                  {/* Handle lot of each leg */}
                  <div className="flex flex-col">
                    <div className="mt-2 text-center text-black text-sm">Lot</div>
                    <input type="text" value={props.lotarray[key]} placeholder='0' className="text-sm h-8 mt-2 ml-2 border-black bg-inherit outline-0 w-12 pr-1 text-center border-solid rounded-xl border-[1px] "
                      onChange={(e) => { handleInputNumber(e,key,props.lotarray,props.setlotarray);}}></input>
                  </div>

                  {/* Handle ATM TYPE */}
                  {props.segmentType[key] == 'option' && <div className="flex flex-col ">
                      <div className="mt-2 ml-2 text-center text-sm ">Entry Choice</div>
                        <select value={props.atmChoice[key]} className="text-l h-8 mt-2 ml-2  border-black  text-center border-solid bg-inherit  w-[180px] border-[1px] rounded-[0.75rem]"
                          onChange={(e) => { props.setAtmChoice((prev)=>{ let input=[...prev];input[key]=e.target.value;return input;}) }}>
                          <option className="text-black" value="point">  ATM Point</option>
                          <option className="text-black" value="percent"> ATM Percent </option>
                          <option className="text-black" value="premium"> Closest Premium </option>
                          <option className="text-black" value="straddlePremium"> CP Based On Straddle </option>
                        </select>
                    </div>}
                    {props.segmentType[key]!=='option' && <div className='flex flex-col w-[180px]'></div>}
                    {props.segmentType[key]!=='option' && <div className='flex flex-col w-[170px]'></div>}
                  
                  

                  {/* Handle Leg Atm Type--Point,Percent,Premium,Straddle Premium */}
                  {props.segmentType[key] == 'option' && props.atmChoice[key] === "point" && (
                    <div className="flex flex-col ">
                      <div className="mt-2 ml-2 text-center text-sm ">ATM</div>
                      <div className="flex flex-row">
                        <div className="text-sm h-8 mt-2 ml-2 pt-1 border-black  text-center border-solid border-[1px] bg-inherit  w-[60px]"
                          style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem" }} > ATM </div>
                        <select value={props.atm[key]} className="text-sm h-8 mt-2  border-black  text-center border-solid border-[1px] bg-inherit w-[92px]"
                          style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem" }}
                          onChange={(e) => { const inputdata = [...props.atm]; inputdata[key] = e.target.value; props.setatm(inputdata); }}>

                          {props.atmChoice[key] == "point" && props.tradesymbol == "NIFTY" && pointnifty.map((item, key) => {
                            return (<option className="text-black text-l" value={item} key={key}>  {item} </option>);
                          })}

                          {props.atmChoice[key] == "point" && props.tradesymbol == "BANKNIFTY" && pointbanknifty.map((item, key) => {
                            return (<option className="text-black text-l" value={item} key={key} >  {item} </option>);
                          })}
                        </select>
                      </div>
                    </div>
                  )}

                  {props.segmentType[key] == 'option' && props.atmChoice[key] === "percent" && (
                    <div className="flex flex-col">
                      <div className="mt-2 ml-2 text-center text-sm">ATM</div>
                      <div className="flex flex-row">
                        <div className="h-8 mt-2 ml-2 pt-1 border-black text-sm text-center border-solid border-[1px] bg-inherit  w-[60px]" style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem", }} >
                          ATM%
                        </div>
                        <select value={props.atm[key]} className="h-8 mt-2  border-black text-l border-solid border-[1px] bg-inherit w-[92px]" style={{
                          borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem",
                        }} onChange={(e) => { const inputdata = [...props.atm]; inputdata[key] = e.target.value; props.setatm(inputdata); console.log(props.atm); }} >

                          {props.atmChoice[key] === "percent" && percentarray.map((item, key) => {
                            return (<option className="text-black text-sm" value={item} key={key}> {item} </option>);
                          })}
                        </select>
                      </div>
                    </div>
                  )}

                  {props.segmentType[key] == 'option' && props.atmChoice[key] === "premium" && (
                    <div className="flex flex-col">
                      <div className="mt-2 ml-2 text-center text-sm ">ATM</div>
                      <div className="flex flex-row">
                        <select value={props.closestPremiumChoice[key]} className="text-l h-8 mt-2 ml-2  border-black  text-center border-solid border-[1px] bg-inherit  w-[60px]" style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem" }}
                          onChange={(e) => { const inputData = [...props.closestPremiumChoice]; inputData[key] = e.target.value; props.setClosestPremiumChoice(inputData); }}>
                          <option className="text-black" value="CP~">  CP~ </option>
                          <option className="text-black" value="CP>="> CP&gt;= </option>
                          <option className="text-black" value="CP<="> CP&lt;= </option>
                        </select>
                        <input type="text" className="text-l h-8 mt-2 border-black  text-center border-solid border-[1px] bg-inherit w-[92px]" style={{
                          borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem"
                        }} value={props.closestPremiumValue[key]} onChange={(e) => { handleInputNumber(e,key,props.closestPremiumValue,props.setClosestPremiumValue); }} />
                      </div>
                    </div>
                  )}

                  {props.segmentType[key] == 'option' && props.atmChoice[key] === "straddlePremium" && (
                    <div className="flex flex-col ">
                      <div className="mt-2 ml-2 text-center text-sm ">ATM</div>
                      <div className="flex flex-row">
                        <select value={props.closestPremiumChoice[key]} className="text-l h-8 mt-2 ml-2  border-black  text-center border-solid border-[1px] bg-inherit  w-[60px]" style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem" }}
                          onChange={(e) => { const inputData = [...props.closestPremiumChoice]; inputData[key] = e.target.value; props.setClosestPremiumChoice(inputData); }}>
                          <option className="text-black" value="CP~">  CP~ </option>
                          <option className="text-black" value="CP>="> CP&gt;= </option>
                          <option className="text-black" value="CP<="> CP&lt;= </option>
                        </select>
                        <select value={props.closestPremiumValue[key]} className="text-l h-8 mt-2  border-black  text-center border-solid border-[1px] bg-inherit w-[92px]"
                          style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem" }}
                          onChange={(e) => { 
                          props.setClosestPremiumValue((prev)=>{const input=[...prev];prev[key]=e.target.value;return input}); }}>
                          {StraddlePremiumSeries.map((item, key) => {
                            return (<option className="text-black text-sm" value={item} key={key}>  {item} </option>);
                          })}
                        </select>
                      </div>
                    </div>
                  )}

                  {/* Handle Leg Option */}
                  <div className="flex flex-col ">
                    <div className="mt-2 ml-2 text-center text-sm">{props.segmentType[key] == 'option'?'Option':'Future'}</div>
                    <div className="flex flex-row text-center justify-center gap-1">
                      <div className={`h-8 mt-2 pt-1 ml-2 text-l cursor-pointer border-black text-center border-solid border-[1px] bg-inherit  ${props.segmentType[key] == 'option'?'w-16':'w-32'}`} style={{ borderRadius: "0.75rem",color:props.optionBuySell[key]=='Buy'?'green':'red',borderColor:props.optionBuySell[key]=='Buy'?'green':'red'}}
                        onClick={(e) => { updateOptionBuySell(key); }}>{props.optionBuySell[key]}
                      </div>
                      {props.segmentType[key] == 'option' && <div className="h-8 mt-2 pt-1 border-black  cursor-pointer text-l text-center border-solid border-[1px] bg-inherit  w-16" style={{ borderRadius: "0.75rem",color:props.optionCallPut[key]=='Call'?'green':'red',borderColor:props.optionCallPut[key]=='Call'?'green':'red'}}
                        onClick={(e) => { updateOptionCallPut(key); }}>{props.optionCallPut[key]}
                      </div>}
                    </div>
                  </div>

                  {/* Handle Leg Expiry type  */}
                  <div className="flex flex-col">
                    <div className="mt-2 ml-2 text-center text-sm"> Expiry Type </div>
                    {props.segmentType[key] == 'option' && <div className="grid grid-cols-2 text-center justify-center mt-2 ml-2 w-32 text-l  rounded-xl">
                      <div style={{ borderColor: props.legExpiryType[key] == 'Weekly' ? "#3E45DA" : "", color: props.legExpiryType[key] == 'Weekly' ? "#3E45DA" : "black" }} className="text-l rounded-l-xl h-8 border-solid border-[1px] pt-1" onClick={() => {
                        const inputdata = [...props.legExpiryType];
                        inputdata[key] = "Weekly"; props.setLegExpiryType(inputdata)
                      }}> Weekly </div>
                      <div style={{ borderColor: props.legExpiryType[key] == 'Monthly' ? "#3E45DA" : "", color: props.legExpiryType[key] == 'Monthly' ? "#3E45DA" : "black" }} className="text-l rounded-r-xl h-8 border-solid border-[1px] pt-1"
                        onClick={() => { const inputdata = [...props.legExpiryType]; inputdata[key] = "Monthly"; props.setLegExpiryType(inputdata) }}>
                        Monthly
                      </div>
                    </div>}
                    {
                      props.segmentType[key] == 'future' && <div className='h-8 pt-1 text-black ml-2 mt-2 w-32 text-l rounded-xl border-[1px] border-black text-center justify-center'>Monthly</div>
                    }
                  </div>

                  {/* Handle Leg Symbol Here.... */}
                  <div className="flex flex-col">
                    <div className="mt-2 ml-2 text-center text-sm"> Symbol </div>
                    <div className="grid grid-cols-2 text-center justify-center mt-2 ml-2 w-36  rounded-xl">
                      <div style={{ borderColor: props.tradesymbol == "NIFTY" ? "green" : '', color: props.tradesymbol == "NIFTY" ? "green" : 'black' }} className="text-l rounded-l-xl h-8 border-solid border-[1px] pt-1"
                        onClick={() => { const inputdata = [...props.legSymbol]; inputdata[key] = "NIFTY"; props.setLegsymbol(inputdata); props.settradesymbol('NIFTY'); props.setLotQuantity(50); }}>
                        Nifty
                      </div>
                      <div style={{ borderColor: props.tradesymbol == "BANKNIFTY" ? "green" : '', color: props.tradesymbol == "BANKNIFTY" ? "green" : 'black' }} className="text-l rounded-r-xl h-8 border-solid border-[1px] pt-1"
                        onClick={() => { const inputdata = [...props.legSymbol]; inputdata[key] = 'BANKNIFTY'; props.setLegsymbol(inputdata); props.settradesymbol('BANKNIFTY'); props.setLotQuantity(BN_QUANT); }}>
                        Banknifty
                      </div>
                    </div>
                  </div>

                  {/*Handle Wait Trade Here..  */}
                  {
                    props.waitTrade && <div className="flex flex-col ">
                      <div className="mt-2 ml-2 text-center text-sm">Wait For Momentum </div>
                      <div className="flex flex-row">
                        <select className="h-8 mt-2 ml-2 border-black text-sm text-center border-solid border-[1px] bg-inherit  w-20"
                          style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem" }} value={props.waitTradeType[key]}
                          onChange={(e) => { const inputdata = [...props.waitTradeType]; inputdata[key] = e.target.value; props.setWaitTradeType(inputdata); }}>
                         <option className="text-black" value="Point ++">  Point ++ </option>
                         <option className="text-black" value="Point --">  Point -- </option>
                         <option className="text-black" value="% ++" >  % ++ </option> 
                         <option className="text-black" value="% --">   % --</option>
                         <option className="text-black" value="Spot++" >  Spot ++ </option> 
                         <option className="text-black" value="Spot--">   Spot --</option>
                        </select>
                        <input type="text" placeholder='0' className="h-8 mt-2 border-black  text-center border-solid border-[1px] bg-inherit  w-12" style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem", }} value={props.waitTradeValue[key]} onChange={(e) => { handleInputNumber(e,key,props.waitTradeValue,props.setWaitTradeValue); }} />
                      </div>
                    </div>
                  }

                  {/* Handle Leg Target Profit */}
                  <div className="flex flex-col w-[160px]">
                    <div className="mt-2 ml-2 text-center text-sm"> Target Profit </div>
                    <div className="flex flex-row">

                      {props.legsTargetProfit[key] == -1 && <div className="w-32 text-blue-600 text-l mt-2 flex justify-center text-center cursor-pointer"><IoMdAdd className='ml-4 mt-1 text-2xl font-semibold' onClick={() => {
                        const inputdata = [...props.legsTargetProfit]; inputdata[key] = ""; props.setLegsTargetProfit(inputdata);
                        const inputdata1 = [...props.reEntryChoice]; inputdata1[key] = 'ReExecute'; props.legsStoploss[key] == -1 && props.setReEntryChoice(inputdata1);
                        const inputdata2 = [...props.reEntryType]; inputdata2[key] = 'TP'; props.legsStoploss[key] == -1 && props.setReEntryType(inputdata2);
                      }}></IoMdAdd></div>
                      }

                      {props.legsTargetProfit[key] != -1 &&
                        <div className="flex flex-row">
                          <select className="h-8 mt-2 ml-2 border-black  text-center text-sm border-solid border-[1px] bg-inherit  w-20"
                            style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem", }}
                            value={props.legsTargetProfitType[key]} onChange={(e) => {
                              const inputdata = [...props.legsTargetProfitType];
                              inputdata[key] = e.target.value; props.setLegsTargetProfitType(inputdata);
                            }}>
                            <option className="text-black text-sm" value="TP%"> TP% </option>
                            <option className="text-black text-sm" value="TP Point"> TP Point </option>
                          </select>
                          <input type="text" className="h-8 mt-2 border-black text-sm pl-4 border-solid border-[1px] bg-inherit  w-12"
                            style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem", }} value={props.legsTargetProfit[key]}
                            placeholder='0'
                            onChange={(e) => {
                              handleInputNumber(e,key,props.legsTargetProfit,props.setLegsTargetProfit);
                            }} />
                          <MdClose className="mt-3 cursor-pointer text-2xl text-red-500" onClick={() => {
                            const inputdata = [...props.legsTargetProfit]; inputdata[key] = "-1"; props.setLegsTargetProfit(inputdata);
                          }} />
                        </div>
                      }
                    </div>
                  </div>
                  {/* Handle Leg Stoploss */}
                  <div className="flex flex-col w-[175px]">
                    <div className="mt-2 ml-2 text-center text-sm"> StopLoss </div>
                    {
                      props.legsStoploss[key] == "-1" && <div className="w-32 text-blue-600 flex text-center justify-center mt-2 cursor-pointer"><IoMdAdd className='ml-4 mt-1 text-2xl font-semibold' onClick={() => {
                        const inputdata = [...props.legsStoploss]; inputdata[key] = ""; props.setLegsStoploss(inputdata)
                        const inputdata2 = [...props.reEntryType]; inputdata2[key] = 'SL'; props.legsTargetProfit[key] == -1 && props.setReEntryType(inputdata2);
                      }} /></div>
                    }
                    {
                      props.legsStoploss[key] != "-1" && <div className="flex flex-row">
                        <select className="h-8 mt-2 ml-2 border-black text-sm  text-center border-solid border-[1px] bg-inherit  w-24"
                          style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem", }} value={props.legsStoplossType[key]}
                          onChange={(e) => {
                            const inputdata = [...props.legsStoplossType]; inputdata[key] = e.target.value; 
                            if((e.target.value=="Spot Point" || e.target.value=="Spot Percent") && props.trailStoploss[key])
                            {
                                const inputdata = [...props.trailStoploss];inputdata[key] = false;props.setTrailStoploss(inputdata);
                            }
                            props.setLegsStoplossType(inputdata)
                          }}>
                          <option className="text-black text-sm" value="SL%"> SL% </option>
                          <option className="text-black text-sm" value="SL Point"> SL Point </option>
                          {props.segmentType[key] == 'option' && !props.waitTrade && <option className="text-black text-sm" value="Spot Point"> Spot Point </option>}
                          {props.segmentType[key] == 'option' && !props.waitTrade && <option className="text-black text-sm" value="Spot Percent"> Spot Percent </option>}
                        </select>
                        <input type="text" className="h-8 mt-2 border-black text-sm  pl-4 border-solid border-[1px] bg-inherit  w-12"
                          style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem" }}
                          placeholder='0'
                          value={props.legsStoploss[key]}
                          onChange={(e) => { handleInputNumber(e,key,props.legsStoploss,props.setLegsStoploss); }} />
                        <MdClose className="mt-3 cursor-pointer text-2xl text-red-500" onClick={() => {
                          const inputdata = [...props.legsStoploss]; inputdata[key] = "-1"; props.setLegsStoploss(inputdata);
                        }} />
                      </div>
                    }
                  </div>
                  <div className="flex flex-col w-[160px]">
                    <div className="mt-2 ml-2 text-center text-sm">Trail StopLoss </div>
                    {
                      !props.trailStoploss[key] &&
                      <div className="mt-2 text-blue-600 cursor-pointer text-sm w-32 flex text-center justify-center bg-inherit">
                        <IoMdAdd
                          className='ml-4 mt-1 text-2xl font-semibold'
                          onClick={() => {
                            if (props.legsStoploss[key] != -1 && (props.legsStoplossType[key] == 'SL%' || props.legsStoplossType[key] == 'SL Point')) {
                              const inputdata = [...props.trailStoploss];
                              inputdata[key] = true;
                              props.setTrailStoploss(inputdata);
                              setOpenTrailStoplossBox(true);
                              setCurrentKey(key);
                            } else {
                              if (!(props.legsStoplossType[key] == 'SL%' || props.legsStoplossType[key] == 'SL Point'))
                                toast.warn('trail stoploss apply only on Stoploss Percent and Point!!.');
                              else
                                toast.warn('Stoploss required!!');
                            }
                          }}
                        />
                      </div>
                    }
                    {
                        <Dialog open={openTrailStoplossBox} onClose={() => { setOpenTrailStoplossBox(false); }} disablebackdropclick={true} sx={{ borderRadius: "10px" }}>
                          <div className="flex flex-row border-b-2">
                            <div className="text-[#181818] text-[18px] p-2">Trailing Stoploss </div>
                            <div className="h-[24px] w-[24px] text-[#181818] flex-grow flex justify-end font-semibold p-2 cursor-pointer"><MdClose onClick={() => { const inputdata = [...props.trailStoploss]; inputdata[key] = false; props.setTrailStoploss(inputdata); setOpenTrailStoplossBox(false); setTrailingMessage(''); }} /></div>
                          </div>
                          <div className='grid grid-cols-2 p-1'>
                            <div className='ml-2 font-[500] text-[#232323]'>If Profit  Moves By </div>
                            <div className=''>
                              <input
                                type="text"
                                placeholder="0"
                                value={trailProfit}
                                onChange={(e) => { handleNumber(e,setTrailProfit); }}
                                className="text-center justify-center  rounded-md bg-[#DAECF2] h-8  outline-0"
                              />
                              <select className="text-center justify-center  rounded-md h-8 bg-[#DAECF2] ml-2 outline-0" value={trailType} onChange={(e) => { setTrailType(e.target.value); }}>
                                <option value="point">Pt</option>
                                <option value="percent">%</option>
                              </select>
                            </div>
                          </div>
                          <div className='grid grid-cols-2 p-1'>
                            <div className='ml-2 font-[500] text-[#232323]'>Then Stoploss Moves By  </div>
                            <div className=''>
                              <input
                                type="text"
                                placeholder="0"
                                value={trailloss}
                                onChange={(e) => { handleNumber(e,setTrailLoss); }}
                                className="text-center justify-center rounded-md h-8 bg-[#DAECF2] outline-0"
                              />
                              <select className='text-center justify-center  rounded-md h-8 bg-[#DAECF2] ml-2 outline-0' value={trailType} onChange={(e) => { setTrailType(e.target.value); }}>
                                <option value='point'>Pt</option>
                                <option value='percent'>%</option>
                              </select>
                            </div>
                          </div>
                          <div className='text-[#FF3030] text-center'>{trailingMessage}</div>
                          <div className="text-[#FF3030] m-2">
                            <p className='text-[12px]'>***Profit Moves Must Be Greater Than Stoploss Movement!!.</p>
                            <p className='text-[12px]'>***It will be valid only on Stoploss Percent and Stoploss Point!!.</p>
                            <p className='text-[12px]'>***Every time instrument moves A pts in the profit direction then move stoploss B pts in same direction.</p>
                          </div>
                          <div className="flex justify-end p-2 m-4 gap-2">
                            <button className="p-1 w-28 bg-white border-[1px] border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { const inputdata = [...props.trailStoploss]; inputdata[currentKey] = false; props.setTrailStoploss(inputdata); setOpenTrailStoplossBox(false); setTrailingMessage(''); }} sx={{ margin: "10px" }}>Cancel</button>
                            <button className="p-1 w-28 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { onHandleTrailSubmit(currentKey); }} sx={{ margin: "10px" }}>Ok</button>
                          </div>
                        </Dialog>
                    }
                    {
                      props.trailStoploss[key] && props.legsStoploss[key] != -1 && <div className="mt-2 w-40 flex flex-row bg-inherit">
                        <select className='text-center justify-center  rounded-l-xl h-8 border-[1px] border-black w-12' value={props.trailStoplossType[key]} onChange={(e) => { const inputdata = [...props.trailStoplossType]; inputdata[key] = e.target.value; props.setTrailStoplossType(inputdata); }}>
                          <option value='point'>Pt</option>
                          <option value='percent'>%</option>
                        </select>
                        <input type="text" placeholder="0" value={props.trailStoplossProfit[key]} onChange={(e) => { handleInputNumber(e,key,props.trailStoplossProfit,props.setTrailStoplossProfit); }} className="text-center justify-center  h-8 border-[1px] border-black w-12" />
                        <input type="text" placeholder="0" value={props.trailStoplossLoss[key]} onChange={(e) => { handleInputNumber(e,key,props.trailStoplossLoss,props.setTrailStoplossLoss); }} className="text-center justify-center rounded-r-xl h-8 border-[1px]  border-black w-12" />
                        <MdClose className="mt-1 cursor-pointer text-2xl text-red-500" onClick={() => { removeTrailStoploss(key); }}></MdClose>
                      </div>
                    }
                  </div>
                  
                  {/*  Handle Leg ReEntry/ReExecute*/}
                  {
                    (props.reEntry && props.squareoff == 'one') &&
                    <div className="flex flex-col w-[260px]">
                      {props.squareoff == 'one' && <div className="mt-2 ml-2 text-center text-sm "> Re-Entry/Re-Execute </div>}
                      {props.legsReEntry[key] && (props.legsStoploss[key] != -1 || props.legsTargetProfit[key] != -1) && <div className="flex flex-row">
                        <select className="h-8 mt-2 ml-2 border-black  text-center text-sm border-solid border-[1px] bg-inherit  w-28" style={{ borderTopLeftRadius: "0.75rem", borderBottomLeftRadius: "0.75rem", }} value={props.reEntryChoice[key]}
                          onChange={(e) => { const inputdata = [...props.reEntryChoice]; inputdata[key] = e.target.value; props.setReEntryChoice(inputdata); }}>
                          {props.squareoff == 'one' && props.legsStoploss[key] != -1 && <option className="text-black text-sm" value="Re-Entry"> ReEntry </option>}
                          {props.squareoff == 'one' && props.waitTrade && props.legsStoploss[key] != -1 && <option className="text-black text-sm" value="Re-EntryWithWaitTrade"> ReEntry[WT] </option>}
                          {props.squareoff == 'one' && <option className="text-black text-sm" value="ReExecute"> ReExecute </option>}
                          {props.squareoff == 'one' && props.waitTrade && <option className="text-black text-sm" value="ReExecuteWithWaitTrade"> ReExecute[WT] </option>}
                        </select>
                        {(props.reEntryChoice[key] == 'ReExecute' || props.reEntryChoice[key] == 'ReExecuteWithWaitTrade') &&
                          <select className="h-8 mt-2 border-black text-center text-sm border-solid border-[1px] bg-inherit w-16" value={props.reEntryType[key]}
                            onChange={(e) => { const inputdata = [...props.reEntryType]; inputdata[key] = e.target.value; props.setReEntryType(inputdata); }}>
                            {props.legsStoploss[key] != -1 && <option className="text-black text-sm" value="SL">SL</option>}
                            {props.legsTargetProfit[key] != -1 && <option className="text-black text-sm" value="TP">TP</option>}
                            {props.legsStoploss[key] != -1 && props.legsTargetProfit[key] != -1 && <option className="text-black text-sm" value="SL/TP">SL/TP</option>}
                          </select>
                        }
                        {(props.reEntryChoice[key] == 'Re-Entry' || props.reEntryChoice[key] == 'Re-EntryWithWaitTrade' ) &&
                          <select className="h-8 mt-2 border-black text-center text-sm border-solid border-[1px] bg-inherit w-12" value={props.reEntryType[key]}
                            onChange={(e) => { const inputdata = [...props.reEntryType]; inputdata[key] = e.target.value; props.setReEntryType(inputdata); }}>
                            <option className="text-black text-sm" value="SL">SL</option>
                          </select>
                        }
                        <select className="h-8 mt-2 border-black text-sm text-center border-solid border-[1px] bg-inherit  w-12" style={{ borderTopRightRadius: "0.75rem", borderBottomRightRadius: "0.75rem", }} value={props.reEntryValue[key]}
                          onChange={(e) => { const inputdata = [...props.reEntryValue]; inputdata[key] = e.target.value; props.setReEntryValue(inputdata); }}>
                          <option className="text-black text-sm" value="1">1</option>
                          <option className="text-black text-sm" value="2">2</option>
                          <option className="text-black text-sm" value="3">3</option>
                          <option className="text-black text-sm" value="4">4</option>
                          <option className="text-black text-sm" value="5">5</option>
                          {/* <option className="text-black text-sm" value="40">∞</option> */}
                        </select>
                        <MdClose className="mt-3 cursor-pointer text-2xl text-red-500" onClick={() => {
                          const inputdata = [...props.legsReEntry];
                          inputdata[key] = false; props.setLegReEntry(inputdata)
                        }} />
                      </div>
                      }
                      {
                        props.squareoff=='one' && props.legsReEntry[key] == false && <div className="mt-2  text-blue-600 cursor-pointer text-sm w-36 flex text-center justify-center "><IoMdAdd className='ml-4 mt-1 text-2xl font-semibold' onClick={() => {
                          if (props.legsTargetProfit[key] != '-1' || props.legsStoploss[key] != '-1') {
                            const inputdata = [...props.legsReEntry];
                            inputdata[key] = true;
                            props.setLegReEntry(inputdata)
                          } else {
                            alert('Please add leg Profit or Stoploss first!.')
                          }
                        }} /></div>
                      }
                    </div>

                  }

                  {/* Method to copy leg*/}
                  <div className="mt-10 ml-6">
                    <MdContentCopy className="cursor-pointer text-2xl" onClick={() => { props.copyleg(key); Toast('Leg Copy Successfully!') }} />
                  </div>
                  {/* Method to delete a particular leg */}
                  <div className="mt-10 ml-6">
                    <MdDeleteForever className="cursor-pointer text-red-500 text-2xl" onClick={() => { props.DeleteLeg(key); Toast('Leg Deleted Successfully!') }} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
    </>
  )
}

export default Leg