import { IoIosChatboxes } from "react-icons/io";
import { FaThumbsUp } from "react-icons/fa";
import { MdOutlineMessage } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoEyeOff } from "react-icons/io5";

const Strength = () => {

  const featureList = [
    { title: "Advanced Algorithmic Testing", description: "Supports complex strategies on large datasets." },
    { title: "High-Speed Performance", description: "Delivers rapid backtesting results with real-time simulation." },
    { title: "Detailed Analytics", description: "Provides in-depth metrics and customizable reports." },
    { title: "User-Friendly Interface", description: "Intuitive design with drag-and-drop functionality." },
    { title: "Integration", description: "Compatible with major trading platforms and data providers." },
    { title: "Risk Management", description: "Includes scenario analysis and portfolio optimization tools." },
    { title: "Continuous Updates", description: "Regularly updated with new features and community support." },
    { title: "AI-Powered Insights", description: "Utilizes AI for predictive analytics and custom strategy building." }
  ];

  return (
    <div className="mx-auto mt-10 px-4 mb-10 bg-white text-black">
      <h2 className="text-3xl font-extrabold text-gray-800 mb-6 text-center">Platform Features</h2>
      <ul className="grid grid-cols-1 md:grid-cols-3 gap-4">

          <div className='p-2 flex flex-row text-center justify-center'>
            <div className="bg-blue-100 h-[40px] w-[40px] rounded flex text-center justify-center m-2"><IoIosChatboxes className="text-blue-500 h-[20px] w-[20px] mt-[8px] "/></div>
            <div className="w-[340px] text-left ml-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">Advanced Algorithmic Testing</h3>
              <p className="text-gray-700">Supports complex strategies on large datasets.</p>
            </div>
          </div>

          <div className='p-2 flex flex-row text-center justify-center'>
          <div className="bg-blue-100 h-[40px] w-[40px] rounded flex text-center justify-center m-2"><FaThumbsUp className="text-blue-500 h-[20px] w-[20px] mt-[8px]"/></div>
            <div className="w-[340px] text-left ml-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">High-Speed Performance</h3>
              <p className="text-gray-700">Delivers rapid backtesting results with real-time simulation.</p>
            </div>
          </div>

          <div className='p-2 flex flex-row text-center justify-center'>
          <div className="bg-blue-100 h-[40px] w-[40px] rounded flex text-center justify-center m-2"><MdOutlineMessage className="text-blue-500 h-[20px] w-[20px] mt-[8px]"/></div>
            <div className="w-[340px] text-left ml-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">Detailed Analytics</h3>
              <p className="text-gray-700">Provides in-depth metrics and customizable reports.</p>
            </div>
          </div>


          <div className='p-2 flex flex-row text-center justify-center'>
          <div className="bg-blue-100 h-[40px] w-[40px] rounded flex text-center justify-center m-2"><FaEdit className="text-blue-500 h-[20px] w-[20px] mt-[8px]"/></div>
            <div className="w-[340px] text-left ml-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">User-Friendly Interface</h3>
              <p className="text-gray-700">Includes scenario analysis and portfolio optimization tools.</p>
            </div>
          </div>

          <div className='p-2 flex flex-row text-center justify-center'>
          <div className="bg-blue-100 h-[40px] w-[40px] rounded flex text-center justify-center m-2"><FaExternalLinkAlt className="text-blue-500 h-[20px] w-[20px] mt-[8px]"/></div>
            <div className="w-[340px] text-left ml-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">Integration</h3>
              <p className="text-gray-700">Compatible with major trading platforms and data providers.</p>
            </div>
          </div>

          <div className='p-2 flex flex-row text-center justify-center'>
          <div className="bg-blue-100 h-[40px] w-[40px] rounded flex text-center justify-center m-2"><IoEyeOff className="text-blue-500 h-[20px] w-[20px] mt-[8px]"/></div>
            <div className="w-[340px] text-left ml-5">
              <h3 className="text-lg font-semibold text-gray-900 mb-1">Risk Management</h3>
              <p className="text-gray-700">Includes scenario analysis and portfolio optimization tools..</p>
            </div>
          </div>
     
      </ul>
    </div>
  );
};

export default Strength;
