import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from './../../assets/logo12.webp'
import { useParams } from "react-router-dom";
import { Audio } from 'react-loader-spinner'
import { RESET_PASSWORD_URL } from "../../constant/constant";
import { MdOutlineVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";

import Section1 from './Section1'

const ResetPassword = () => {
    const navigate = useNavigate();
    const { token, email } = useParams();
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [wait, setWait] = useState(false);
    const [visible, setVisible] = useState(false)
    const [warn, setWarn] = useState(false)


    function validatepassword(str) {
        // Check for lowercase, uppercase, and digit
        const hasLowercase = /[a-z]/.test(str);
        const hasUppercase = /[A-Z]/.test(str);
        const hasDigit = /\d/.test(str);

        // Check for special characters using a regular expression
        const hasSpecialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(str);

        // Check the length (minimum 8 characters)
        const isLengthValid = str.length >= 7;

        // Check if all conditions are met
        return hasLowercase && hasUppercase && hasDigit && hasSpecialChars && isLengthValid;
    }
    const handlesubmit = () => {
        if (newPassword !== confirmPassword || !warn) {
            toast("Please enter both password same and password must be strong");
            setWait(false);
            return;
        } else {
            const data = { "password": newPassword, "token": token, "email": email }
            const config = {
                method: 'post', maxBodyLength: Infinity, url: RESET_PASSWORD_URL, headers: { 'Content-Type': 'application/json' },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    alert('Password Reset Successfully'); setWait(false); navigate('/backtest/login');
                })
                .catch((error) => {
                    if (error?.response?.data?.message) { toast(error.response.data.message); setWait(false); return; }
                    else { toast('Try Again Later'); setWait(false); return; }
                });
        }

    }
    useEffect(() => {
        validatepassword(newPassword) ? setWarn(true) : setWarn(false);
    }, [newPassword])
    useEffect(() => {
        if (wait === true) {
            handlesubmit();
            setTimeout(() => {
                setWait(false);
            }, 20000);
        }
    }, [wait])

    return (
        <>
            <ToastContainer />
            <div className="flex flex-row justify-center text-center min-h-screen bg-[#DAECF2]">
                <div className="hidden lg:block relative mt-10 mb-10 ml-20">
                    <Section1 />
                </div>
                <div className="flex flex-col bg-white shadow-lg p-6 lg:p-10 my-6 lg:my-10 max-w-md lg:max-w-lg mx-auto lg:mx-0">
                    <div className="flex justify-start mb-6">
                        <img src={logo} alt="logo" className="h-24 w-24" ></img>
                    </div>
                    <div className="text-[#000000] justify-start text-left text-3xl mt-5 ml-4" style={{ fontWeight: "700" }}>
                        <h1>Reset Password</h1>
                    </div>
                    <div className="font-semibold ml-5 text-left"> Hello! Lets get started.. </div>
                    <hr className="m-5"></hr>

                    <label className="ml-5 mt-1 text-left font-semibold">New Password</label>
                    {visible && <div className="flex flex-row border border-[#1565D8] ml-4 rounded-lg h-10" >
                        <input type="text" placeholder="Enter Your Password" className="mr-5 outline-0  pl-4 w-full rounded-lg border-[#1565D8]"
                            value={newPassword} onChange={(e) => { setNewPassword(e.target.value); }} />
                        <MdOutlineVisibility sx={{ cursor: "pointer", marginLeft: "20px", marginRight: "10px", marginTop: "6px" }} onClick={() => { setVisible(false) }} />
                    </div>
                    }

                    {!visible && <div className="flex flex-row border border-[#1565D8] rounded-lg ml-4 h-10">
                        <input type="password" placeholder="Enter Your Password" className="mr-5 outline-0   pl-4 w-full rounded-lg border-[#1565D8]"
                            value={newPassword} onChange={(e) => { setNewPassword(e.target.value); }} />
                        <MdVisibilityOff sx={{ cursor: "pointer", marginLeft: "20px", marginRight: "10px", marginTop: "6px" }} onClick={() => { setVisible(true) }} />
                    </div>}

                    <div className="text-xs italic ml-5" style={{ color: warn === true ? "black" : "red" }}>Password: Min 7 chars, 1 lowercase, 1 uppercase, 1 digit, 1 special required</div>
                    <label className="mt-2 ml-5 text-[#494949] text-left font-semibold">Confirm Password</label>

                    {visible && <div className="flex flex-row border border-[#1565D8] ml-4 rounded-lg h-10" >
                        <input type="text" placeholder="Enter Your Password" className="mr-5 outline-0  pl-4 w-full rounded-lg border-[#1565D8]"
                            value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); }} />
                        <MdOutlineVisibility sx={{ cursor: "pointer", marginLeft: "20px", marginRight: "10px", marginTop: "6px" }} onClick={() => { setVisible(false) }} />
                    </div>
                    }

                    {!visible && <div className="flex flex-row border border-[#1565D8] rounded-lg ml-4 h-10">
                        <input type="password" placeholder="Enter Your Password" className="mr-5 outline-0   pl-4 w-full rounded-lg border-[#1565D8]"
                            value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value); }} />
                        <MdVisibilityOff sx={{ cursor: "pointer", marginLeft: "20px", marginRight: "10px", marginTop: "6px" }} onClick={() => { setVisible(true) }} />
                    </div>}

                    {!wait && <div className="text-center justify-center mr-5">
                        <button className="text-[white] bg-[#2C73EB] h-10 text rounded-md mt-3 ml-4 font-bold cursor-pointer w-full" onClick={() => { setWait(true) }} onKeyDown={(e) => {
                            if (e.keyCode === 13) { setWait(true) }
                        }}> Reset Password </button> </div>}

                    {wait && <div className="flex text-center justify-center"><Audio height="40" width="120" color='lightblue' ariaLabel='three-dots-loading' /></div>}

                    <div className="grid grid-cols-1">
                        <div className="flex flex-row mt-5 ml-5">
                            <div className="text-[#3F4547]">Don't have an account?</div>
                            <Link to='/backtest/signup'><div className="text-[#2C73EB] pl-1 underline cursor-pointer">Sign up here</div></Link>
                        </div>
                        <div className="flex flex-row mt-5 ml-5">
                            <div className="text-[#3F4547]">Have an account?</div>
                            <Link to='/backtest/login'><div className="text-[#2C73EB] pl-1 underline cursor-pointer">Sign in here</div></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword;