import React from 'react';
import { saveAs } from 'file-saver';
const ExcelJS = require('exceljs');


const Excel = (props) => {

  const generateExcel1 = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet('Result');
    const worksheet2 = workbook.addWorksheet('RollOver Result');
    const worksheet3 = workbook.addWorksheet('Without RollOver Result');
    const worksheet4 = workbook.addWorksheet('Monthly Result');
    const worksheet5 = workbook.addWorksheet('Trail Roll Over Result');
  
     // Define your columns
     const columns = [
      'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
      'K', 'L', 'M', 'N', 'O', 'P','Q'
    ];

    // Set width for each column
    columns.forEach(column => {
      worksheet1.getColumn(column).width = 15;
      worksheet2.getColumn(column).width = 15;
      worksheet3.getColumn(column).width = 15;
      worksheet4.getColumn(column).width = 15;
      worksheet5.getColumn(column).width = 15;
    });
   // Add border to the cell
     const borderStyle = {
      style: 'medium',
      color: { argb: '000000' } // Black color
    };
    // worksheet.getCell('A11').value = '2020';
    // worksheet.getCell('A12').value = 'Total';
    // worksheet.getCell('A9').alignment = { horizontal: 'center' }
    // worksheet.mergeCells('A9:M9');
    // worksheet.getCell('A9').fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: '#2D5BFF' }
    // };
    // worksheet3.getCell('A9').font = {
    //   size: 10, // Set font size to 16 (adjust as needed)
    //   name: 'serif', // Set font family (e.g., Arial)
    //   bold: true, // Set font weight to bold
    //   color: { argb: 'FFFFFF' }
    // };

    // Example: Applying a background color to a cell in worksheet1
    const cellA1 = worksheet1.getCell('A1');
    worksheet1.mergeCells('A1:G1');
    cellA1.value = 'FPROGNOS';
    cellA1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '#2D5BFF' }
    };
    worksheet1.getCell('A1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('A1').alignment = { horizontal: 'center' }
    worksheet1.getCell('A1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};


    const cellA2 = worksheet1.getCell('H1');
    worksheet1.mergeCells('H1:N1');
    cellA2.value = props.name;
    cellA2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('H1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('H1').alignment = { horizontal: 'center' }
    worksheet1.getCell('H1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};



    // Adding data to worksheet1
    const cellD1 = worksheet1.getCell('B3');
    worksheet1.mergeCells('B3:E3');
    cellD1.value = 'POSITION';
    cellD1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('B3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B3').alignment = { horizontal: 'center' }

    worksheet1.getCell('B4').value = 'START TIME';
    worksheet1.mergeCells('B4:C4');
    worksheet1.getCell('B4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D4').value = '09:16';
    worksheet1.mergeCells('D4:E4');
    worksheet1.getCell('D4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B6').value = 'END TIME';
    worksheet1.mergeCells('B6:C6');
    worksheet1.getCell('B6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D6').value = '03:20';
    worksheet1.mergeCells('D6:E6');
    worksheet1.getCell('D6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B5').value = 'START DATE';
    worksheet1.mergeCells('B5:C5');
    worksheet1.getCell('B5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D5').value = '01/03/2020';
    worksheet1.mergeCells('D5:E5');
    worksheet1.getCell('D5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B7').value = 'END DATE';
    worksheet1.mergeCells('B7:C7');
    worksheet1.getCell('B7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D7').value = '01/06/2020';
    worksheet1.mergeCells('D7:E7');
    worksheet1.getCell('D7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B8').value = 'Action';
    worksheet1.mergeCells('B8:C8');
    worksheet1.getCell('B8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D8').value = props.action;
    worksheet1.mergeCells('D8:E8');
    worksheet1.getCell('D8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B9').value = 'Symbol';
    worksheet1.mergeCells('B9:C9');
    worksheet1.getCell('B9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D9').value = props.symbol;
    worksheet1.mergeCells('D9:E9');
    worksheet1.getCell('D9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B10').value = 'Target Profit';
    worksheet1.mergeCells('B10:C10');
    worksheet1.getCell('B10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D10').value = props.targetProfit;
    worksheet1.mergeCells('D10:E10');
    worksheet1.getCell('D10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B11').value = 'Entry Difference';
    worksheet1.mergeCells('B11:C11');
    worksheet1.getCell('B11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D11').value = props.targetDiff;
    worksheet1.mergeCells('D11:E11');
    worksheet1.getCell('D11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B12').value = 'Lot';
    worksheet1.mergeCells('B12:C12');
    worksheet1.getCell('B12').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D12').value = props.lot;
    worksheet1.mergeCells('D12:E12');
    worksheet1.getCell('D12').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    const formattingOptions = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '#2D5BFF' }
    };

    const cellres = worksheet1.getCell('H3');
    worksheet1.mergeCells('H3:K3');
    cellres.value = 'STATISTICAL RESULT';
    cellres.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('H3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('H3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H3').alignment = { horizontal: 'center' }
    worksheet1.getCell('H5').value = 'Total Margin';
    worksheet1.mergeCells('H5:I5');
    worksheet1.getCell('H5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H6').value = 'Total Profit';
    worksheet1.mergeCells('H6:I6');
    worksheet1.getCell('H6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H7').value = 'Maximum Drawdown';
    worksheet1.mergeCells('H7:I7');
    worksheet1.getCell('H7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H8').value = 'Montly Max Profit';
    worksheet1.mergeCells('H8:I8');
    worksheet1.getCell('H8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H9').value = 'Monthly Min Profit';
    worksheet1.mergeCells('H9:I9');
    worksheet1.getCell('H9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H10').value = 'Investment Day';
    worksheet1.mergeCells('H10:I10');
    worksheet1.getCell('H10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('J5').value = props.margin;
    worksheet1.mergeCells('J5:K5');
    worksheet1.getCell('J5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J6').value = props.totalProfit;
    worksheet1.mergeCells('J6:K6');
    worksheet1.getCell('J6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J7').value = props.maximumDrawdown;
    worksheet1.mergeCells('J7:K7');
    worksheet1.getCell('J7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J8').value = props.maximumProfit;
    worksheet1.mergeCells('J8:K8');
    worksheet1.getCell('J8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J9').value = props.minimumProfit;
    worksheet1.mergeCells('J9:K9');
    worksheet1.getCell('J9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J10').value = props.investmentDay;
    worksheet1.mergeCells('J10:K10');
    worksheet1.getCell('J10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    // // Define an array of cell addresses where you want to apply the formatting
    const cellAddresses = ['B15', 'C15', 'D15', 'E15', 'F15','G15' ,'H15','I15', 'J15','K15', 'L15','M15', 'N15','O15','P15','Q15'];

    // Apply formatting to the specified cells
    cellAddresses.forEach(address => {
      const cell = worksheet1.getCell(address);
      cell.fill = formattingOptions;
      cell.font = {
        color: { argb: 'FFFFFF' }
      }
    });

    // Trade List Result is showing here...........
    // Adding data to worksheet1
    const trade = worksheet1.getCell('B14');
    worksheet1.mergeCells('B14:Q14');
    trade.value = 'TRADE LIST';
    trade.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('B14').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('B14').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B14').alignment = { horizontal: 'center' }
    
    worksheet1.getCell('B15').value = 'Serial No.';
    worksheet1.getCell('B15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('C15').value = 'Start Time';
    worksheet1.getCell('C15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D15').value = 'Start Date';
    worksheet1.getCell('D15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('E15').value = 'End Time';
    worksheet1.getCell('E15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('F15').value = 'End Date';
    worksheet1.getCell('F15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('G15').value = 'Start Price';
    worksheet1.getCell('G15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H15').value = 'End Price';
    worksheet1.getCell('H15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('I15').value = 'Old_Price';
    worksheet1.getCell('I15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J15').value = 'RollOverPrice';
    worksheet1.getCell('J15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('K15').value = 'Profit';
    worksheet1.getCell('K15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('L15').value = 'RollOver';
    worksheet1.getCell('L15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('M15').value = 'Nifty_II';
    worksheet1.getCell('M15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('N15').value = 'Reason';
    worksheet1.getCell('N15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('O15').value = 'low';
    worksheet1.getCell('O15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('P15').value = 'high';
    worksheet1.getCell('P15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('Q15').value = 'target price';
    worksheet1.getCell('Q15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    let cnt=0;
    for(let k=0;k<props.tradeList.length;k++){
    for (let i = 0; i < props.tradeList[k].length; i++) {
      worksheet1.getCell(`B${cnt+16}`).value = i + 1;
      worksheet1.getCell(`B${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`C${cnt+16}`).value = props.tradeList[k][i][0];
      worksheet1.getCell(`C${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`D${cnt+16}`).value = props.tradeList[k][i][1];
      worksheet1.getCell(`D${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`E${cnt+16}`).value = props.tradeList[k][i][2];
      worksheet1.getCell(`E${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`F${cnt+16}`).value = props.tradeList[k][i][3];
      worksheet1.getCell(`F${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`G${cnt+16}`).value = props.tradeList[k][i][6];
      worksheet1.getCell(`G${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`H${cnt+16}`).value = props.tradeList[k][i][7];
      worksheet1.getCell(`H${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`I${cnt+16}`).value = props.tradeList[k][i][8];
      worksheet1.getCell(`I${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`J${cnt+16}`).value = props.tradeList[k][i][9]
      worksheet1.getCell(`J${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`K${cnt+16}`).value = props.tradeList[k][i][10];
      worksheet1.getCell(`K${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`L${cnt+16}`).value = props.tradeList[k][i][11];
      worksheet1.getCell(`L${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`M${cnt+16}`).value = props.tradeList[k][i][12];
      worksheet1.getCell(`M${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`N${cnt+16}`).value = props.tradeList[k][i][13];
      worksheet1.getCell(`N${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`O${cnt+16}`).value = props.tradeList[k][i][14];
      worksheet1.getCell(`O${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`P${cnt+16}`).value = props.tradeList[k][i][15]
      worksheet1.getCell(`P${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`Q${cnt+16}`).value = props.tradeList[k][i][16]
      worksheet1.getCell(`Q${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      cnt++;
    }
    cnt++;
    worksheet1.getCell(`B${cnt+16}`).fill={fgColor:{ argb: '2D5BFF' },type: 'pattern',pattern: 'solid',}
    worksheet1.getCell(`B${cnt+16}`).value = 'Month: '+ k
    worksheet1.getCell(`B${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`C${cnt+16}`).value =  'RollOverQuantity';
    worksheet1.getCell(`C${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`D${cnt+16}`).value =  props.rollOverQuantity[k]
    worksheet1.getCell(`D${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`E${cnt+16}`).value =  'BookedProfit'
    worksheet1.getCell(`E${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`F${cnt+16}`).value = props.bookedMonthProfit[k]
    worksheet1.getCell(`F${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`G${cnt+16}`).value = 'RollOverLoss'
    worksheet1.getCell(`G${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`H${cnt+16}`).value = props.monthlyLossExpiry[k]
    worksheet1.getCell(`H${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`I${cnt+16}`).value = 'NetProfit/Loss'
    worksheet1.getCell(`I${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`J${cnt+16}`).value = props.monthlyProfit[k]
    worksheet1.getCell(`J${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    cnt+=2;
  }
    //------------------------------------------------------------------------------------------------------------------
    // only RollOver Result
    const withRollOver = worksheet2.getCell('B2');
   worksheet2.mergeCells('B2:Q2');
   withRollOver.value = 'Roll Over Result';
   withRollOver.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: '2D5BFF' }
   };
   worksheet2.getCell('B2').font = {
     color: { argb: 'FFFFFF' }
   }
   worksheet2.getCell('B2').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('B2').alignment = { horizontal: 'center' }
   
   worksheet2.getCell('B3').value = 'Serial No.';
   worksheet2.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('C3').value = 'Start Time';
   worksheet2.getCell('C3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('D3').value = 'Start Date';
   worksheet2.getCell('D3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('E3').value = 'End Time';
   worksheet2.getCell('E3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('F3').value = 'End Date';
   worksheet2.getCell('F3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('G3').value = 'Start Price';
   worksheet2.getCell('G3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('H3').value = 'End Price';
   worksheet2.getCell('H3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('I3').value = 'Old_Price';
   worksheet2.getCell('I3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('J3').value = 'RollOverPrice';
   worksheet2.getCell('J3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('K3').value = 'Profit';
   worksheet2.getCell('K3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('L3').value = 'RollOver';
   worksheet2.getCell('L3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('M3').value = 'Nifty_II';
   worksheet2.getCell('M3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('N3').value = 'Reason';
   worksheet2.getCell('N3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('O3').value = 'low';
   worksheet2.getCell('O3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('P3').value = 'high';
   worksheet2.getCell('P3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet2.getCell('Q3').value = 'target price';
   worksheet2.getCell('Q3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

   let count=0;
   for(let k=0;k<props.tradeList.length;k++){
   for (let i = 0; i < props.tradeList[k].length; i++) {
    if(props.tradeList[k][i][10]=='')
       continue;
     worksheet2.getCell(`B${count+4}`).value = i + 1;
     worksheet2.getCell(`B${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`C${count+4}`).value = props.tradeList[k][i][0];
     worksheet2.getCell(`C${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`D${count+4}`).value = props.tradeList[k][i][1];
     worksheet2.getCell(`D${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`E${count+4}`).value = props.tradeList[k][i][2];
     worksheet2.getCell(`E${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`F${count+4}`).value = props.tradeList[k][i][3];
     worksheet2.getCell(`F${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`G${count+4}`).value = props.tradeList[k][i][6];
     worksheet2.getCell(`G${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`H${count+4}`).value = props.tradeList[k][i][7];
     worksheet2.getCell(`H${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`I${count+4}`).value = props.tradeList[k][i][8];
     worksheet2.getCell(`I${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`J${count+4}`).value = props.tradeList[k][i][9]
     worksheet2.getCell(`J${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`K${count+4}`).value = props.tradeList[k][i][10];
     worksheet2.getCell(`K${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`L${count+4}`).value = props.tradeList[k][i][11];
     worksheet2.getCell(`L${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`M${count+4}`).value = props.tradeList[k][i][12];
     worksheet2.getCell(`M${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`N${count+4}`).value = props.tradeList[k][i][13];
     worksheet2.getCell(`N${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`O${count+4}`).value = props.tradeList[k][i][14];
     worksheet2.getCell(`O${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`P${count+4}`).value = props.tradeList[k][i][15]
     worksheet2.getCell(`P${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet2.getCell(`Q${count+4}`).value = props.tradeList[k][i][16]
     worksheet2.getCell(`Q${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     count++;
   }
   count++;
   worksheet2.getCell(`B${count+4}`).fill={fgColor:{ argb: '2D5BFF' },type: 'pattern',pattern: 'solid',}
    worksheet2.getCell(`B${count+4}`).value = 'Month: '+ k
    worksheet2.getCell(`B${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`C${count+4}`).value =  'RollOverQuantity';
    worksheet2.getCell(`C${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`D${count+4}`).value =  props.rollOverQuantity[k]
    worksheet2.getCell(`D${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`E${count+4}`).value =  'BookedProfit'
    worksheet2.getCell(`E${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`F${count+4}`).value = props.bookedMonthProfit[k]
    worksheet2.getCell(`F${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`G${count+4}`).value = 'RollOverLoss'
    worksheet2.getCell(`G${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`H${count+4}`).value = props.monthlyLossExpiry[k]
    worksheet2.getCell(`H${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`I${count+4}`).value = 'NetProfit/Loss'
    worksheet2.getCell(`I${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`J${count+4}`).value = props.monthlyProfit[k]
    worksheet2.getCell(`J${count+4}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    count++;
    count++;
 }
   //-----------------------------------------------------------------------------------------------------------------
   // without RollOver
   const withoutRollOver = worksheet3.getCell('B3');
   worksheet3.mergeCells('B3:Q3');
   withoutRollOver.value = 'Only Booked Profit';
   withoutRollOver.fill = {
     type: 'pattern',
     pattern: 'solid',
     fgColor: { argb: '2D5BFF' }
   };
   worksheet3.getCell('B3').font = {
     color: { argb: 'FFFFFF' }
   }
   worksheet3.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('B3').alignment = { horizontal: 'center' }
   
   worksheet3.getCell('B4').value = 'Serial No.';
   worksheet3.getCell('B4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('C4').value = 'Start Time';
   worksheet3.getCell('C4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('D4').value = 'Start Date';
   worksheet3.getCell('D4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('E4').value = 'End Time';
   worksheet3.getCell('E4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('F4').value = 'End Date';
   worksheet3.getCell('F4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('G4').value = 'Start Price';
   worksheet3.getCell('G4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('H4').value = 'End Price';
   worksheet3.getCell('H4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('I4').value = 'Old_Price';
   worksheet3.getCell('I4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('J4').value = 'RollOverPrice';
   worksheet3.getCell('J4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('K4').value = 'Profit';
   worksheet3.getCell('K4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('L4').value = 'RollOver';
   worksheet3.getCell('L4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('M4').value = 'Nifty_II';
   worksheet3.getCell('M4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('N4').value = 'Reason';
   worksheet3.getCell('N4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('O4').value = 'low';
   worksheet3.getCell('O4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('P4').value = 'high';
   worksheet3.getCell('P4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
   worksheet3.getCell('Q4').value = 'target price';
   worksheet3.getCell('Q4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

   let wcnt=0;
   for(let k=0;k<props.tradeList.length;k++){
   for (let i = 0; i < props.tradeList[k].length; i++) {
    if(props.tradeList[k][i][10]!='')
       continue;
     worksheet3.getCell(`B${wcnt+5}`).value = i + 1;
     worksheet3.getCell(`B${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`C${wcnt+5}`).value = props.tradeList[k][i][0];
     worksheet3.getCell(`C${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`D${wcnt+5}`).value = props.tradeList[k][i][1];
     worksheet3.getCell(`D${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`E${wcnt+5}`).value = props.tradeList[k][i][2];
     worksheet3.getCell(`E${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`F${wcnt+5}`).value = props.tradeList[k][i][3];
     worksheet3.getCell(`F${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`G${wcnt+5}`).value = props.tradeList[k][i][6];
     worksheet3.getCell(`G${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`H${wcnt+5}`).value = props.tradeList[k][i][7];
     worksheet3.getCell(`H${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`I${wcnt+5}`).value = props.tradeList[k][i][8];
     worksheet3.getCell(`I${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`J${wcnt+5}`).value = props.tradeList[k][i][9]
     worksheet3.getCell(`J${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`K${wcnt+5}`).value = props.tradeList[k][i][10];
     worksheet3.getCell(`K${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`L${wcnt+5}`).value = props.tradeList[k][i][11];
     worksheet3.getCell(`L${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`M${wcnt+5}`).value = props.tradeList[k][i][12];
     worksheet3.getCell(`M${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`N${wcnt+5}`).value = props.tradeList[k][i][13];
     worksheet3.getCell(`N${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`O${wcnt+5}`).value = props.tradeList[k][i][14];
     worksheet3.getCell(`O${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`P${wcnt+5}`).value = props.tradeList[k][i][15]
     worksheet3.getCell(`P${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     worksheet3.getCell(`Q${wcnt+5}`).value = props.tradeList[k][i][16]
     worksheet3.getCell(`Q${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
     wcnt++;
   }
   wcnt++;
   worksheet3.getCell(`B${wcnt+5}`).fill={fgColor:{ argb: '2D5BFF' },type: 'pattern',pattern: 'solid',}
    worksheet3.getCell(`B${wcnt+5}`).value = 'Month: '+ k
    worksheet3.getCell(`B${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`C${wcnt+5}`).value =  'BookedQuantity';
    worksheet3.getCell(`C${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`D${wcnt+5}`).value =  props.tradeList[k].length-props.rollOverQuantity[k]
    worksheet3.getCell(`D${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`E${wcnt+5}`).value =  'BookedProfit'
    worksheet3.getCell(`E${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`F${wcnt+5}`).value = props.bookedMonthProfit[k]
    worksheet3.getCell(`F${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`I${wcnt+5}`).value = 'TotalBookedProfit'
    worksheet3.getCell(`I${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`J${wcnt+5}`).value = props.monthlyProfit[k]-props.monthlyLossExpiry[k]
    worksheet3.getCell(`J${wcnt+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    wcnt++;
    wcnt++;
 }
  //------------------------------------------------------------------------------------------------------------
  // Monthly Result
  const monthly = worksheet4.getCell('B2');
  worksheet4.mergeCells('B2:F2');
  monthly.value = 'Monthly Result';
  monthly.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '2D5BFF' }
  };
  worksheet4.getCell('B2').font = {
    color: { argb: 'FFFFFF' }
  }
  worksheet4.getCell('B2').alignment = { horizontal: 'center' }
  worksheet4.getCell('B3').value = 'Serial No.';
  worksheet4.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('C3').value = 'RollOverQuantity';
  worksheet4.getCell('C3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('D3').value = 'BookedProfit';
  worksheet4.getCell('D3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('E3').value = 'RollOverProfit';
  worksheet4.getCell('E3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('F3').value = 'NetProfit/Loss';
  worksheet4.getCell('F3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  for(let i=0;i<props.monthlyResult.length;i++)
  {
      worksheet4.getCell(`B${4+i}`).value = i + 1;
      worksheet4.getCell(`B${4+i}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet4.getCell(`C${4+i}`).value = props.monthlyResult[i][0];
      worksheet4.getCell(`C${4+i}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet4.getCell(`D${4+i}`).value = props.monthlyResult[i][1];
      worksheet4.getCell(`D${4+i}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet4.getCell(`E${4+i}`).value = props.monthlyResult[i][2];
      worksheet4.getCell(`E${4+i}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet4.getCell(`F${4+i}`).value = props.monthlyResult[i][3];
      worksheet4.getCell(`F${4+i}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    }

    //--------------------------------------------------------------------------------------------------------------------------
    //trail RollOver Result
    const rollOver = worksheet5.getCell('B3');
    worksheet5.mergeCells('B3:Q3');
    rollOver.value = 'Trailing RollOver';
    rollOver.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet5.getCell('B3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet5.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('B3').alignment = { horizontal: 'center' }
    
    worksheet5.getCell('B4').value = 'Serial No.';
    worksheet5.getCell('B4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('C4').value = 'Start Time';
    worksheet5.getCell('C4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('D4').value = 'Start Date';
    worksheet5.getCell('D4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('E4').value = 'End Time';
    worksheet5.getCell('E4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('F4').value = 'End Date';
    worksheet5.getCell('F4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('G4').value = 'Start Price';
    worksheet5.getCell('G4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('H4').value = 'End Price';
    worksheet5.getCell('H4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('I4').value = 'Old_Price';
    worksheet5.getCell('I4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('J4').value = 'RollOverPrice';
    worksheet5.getCell('J4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('K4').value = 'Profit';
    worksheet5.getCell('K4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('L4').value = 'RollOver';
    worksheet5.getCell('L4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('M4').value = 'Nifty_II';
    worksheet5.getCell('M4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('N4').value = 'Reason';
    worksheet5.getCell('N4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('O4').value = 'low';
    worksheet5.getCell('O4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('P4').value = 'high';
    worksheet5.getCell('P4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet5.getCell('Q4').value = 'target price';
    worksheet5.getCell('Q4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    let trailCount=0;
    for(let k=0;k<props.trailRollOver.length;k++){
    for (let i = 0; i < props.trailRollOver[k].length; i++) {
      worksheet5.getCell(`B${trailCount+5}`).value = i + 1;
      worksheet5.getCell(`B${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`C${trailCount+5}`).value = props.trailRollOver[k][i][0];
      worksheet5.getCell(`C${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`D${trailCount+5}`).value = props.trailRollOver[k][i][1];
      worksheet5.getCell(`D${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`E${trailCount+5}`).value = props.trailRollOver[k][i][2];
      worksheet5.getCell(`E${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`F${trailCount+5}`).value = props.trailRollOver[k][i][3];
      worksheet5.getCell(`F${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`G${trailCount+5}`).value = props.trailRollOver[k][i][6];
      worksheet5.getCell(`G${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`H${trailCount+5}`).value = props.trailRollOver[k][i][7];
      worksheet5.getCell(`H${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`I${trailCount+5}`).value = props.trailRollOver[k][i][8];
      worksheet5.getCell(`I${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`J${trailCount+5}`).value = props.trailRollOver[k][i][9]
      worksheet5.getCell(`J${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`K${trailCount+5}`).value = props.trailRollOver[k][i][10];
      worksheet5.getCell(`K${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`L${trailCount+5}`).value = props.trailRollOver[k][i][11];
      worksheet5.getCell(`L${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`M${trailCount+5}`).value = props.trailRollOver[k][i][12];
      worksheet5.getCell(`M${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`N${trailCount+5}`).value = props.trailRollOver[k][i][13];
      worksheet5.getCell(`N${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`O${trailCount+5}`).value = props.trailRollOver[k][i][14];
      worksheet5.getCell(`O${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`P${trailCount+5}`).value = props.trailRollOver[k][i][15]
      worksheet5.getCell(`P${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet5.getCell(`Q${trailCount+5}`).value = props.trailRollOver[k][i][16]
      worksheet5.getCell(`Q${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      trailCount++;
    }
    trailCount++;
    worksheet5.getCell(`B${trailCount+5}`).fill={fgColor:{ argb: '2D5BFF' },type: 'pattern',pattern: 'solid',}
    worksheet5.getCell(`B${trailCount+5}`).value = 'Month: '+ k
    worksheet5.getCell(`B${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet5.getCell(`C${trailCount+5}`).value =  'RollOverQuantity';
    worksheet5.getCell(`C${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet5.getCell(`D${trailCount+5}`).value =  props.trailRollOver[k].length
    worksheet5.getCell(`D${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet5.getCell(`E${trailCount+5}`).value =  'RollOverProfit/Loss'
    worksheet5.getCell(`E${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet5.getCell(`F${trailCount+5}`).value = props.trailRollOverSum[k]
    worksheet5.getCell(`F${trailCount+5}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    trailCount++;
    trailCount++;
  }

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(data, `series_trading.xlsx`)
    });
  };

  return (
    <div>
      <button className="bg-blue-600 text-white p-1 text-[16px] rounded font-serif" onClick={generateExcel1}>Download Excel</button>
    </div>
  );
};

export default Excel;
