import React, { useState } from "react";
import axios from "axios";
import { CONTACT } from "../../constant/constant";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from "./Navbar";
import FooterVersion2 from "./FooterVersion2";

const Contact = () => {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const onSubmit = () => {
    const data = { name, email, phone, message };
    console.log(data);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: CONTACT,
      headers: { 'Content-Type': 'application/json' },
      data
    };

    axios.request(config)
      .then(() => {
        toast.success('Successfully submitted the contact.');
        setName("");
        setEmail("");
        setPhone("");
        setMessage("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <ToastContainer />
      <Navbar/>
      <div className="bg-gradient-to-r from-blue-200 via-blue-300 to-blue-400 py-12">
        <div className="max-w-screen-md mx-auto bg-white rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">For any query/suggestion/issue</h2>
          <div className="grid grid-cols-1 gap-6">
            <div className="flex flex-col">
              <label htmlFor="name" className="text-gray-700 mb-2">Name</label>
              <input
                type="text"
                id="name"
                placeholder="Name"
                className="p-4 h-12 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="email" className="text-gray-700 mb-2">Email Address</label>
              <input
                type="email"
                id="email"
                placeholder="Email Address"
                className="p-4 h-12 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="phone" className="text-gray-700 mb-2">Mobile Number</label>
              <input
                type="text"
                id="phone"
                placeholder="Mobile Number"
                className="p-4 h-12 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="message" className="text-gray-700 mb-2">Message</label>
              <textarea
                id="message"
                placeholder="Message"
                rows="4"
                className="p-4 border border-gray-300 rounded-md focus:border-blue-500 focus:ring-2 focus:ring-blue-200 outline-none"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <button
              className="h-12 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition duration-200"
              onClick={onSubmit}
            >
              Send Message
            </button>
          </div>
        </div>
      </div>
      <FooterVersion2/>
    </>
  );
};

export default Contact;
