import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js'
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import 'chartjs-plugin-zoom';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

// Register the necessary components and plugins with Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  zoomPlugin
);

const Graph1 = (props) => {
  const [drawdown, setDrawdown] = useState([]);
  const [date, setDate] = useState([]);
  const [choice, setChoice] = useState(-1);

  const min = (a, b) => {
    if (a < b) return a;
    return b;
  }

  const formatDate = (date) => {
    return moment(date).format('D MMM, YYYY');
  };

  const data = {
    labels: date.map(date => formatDate(date)),
    datasets: [
      {
        label: '',
        data: drawdown,
        color: "black",
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
        pointRadius:0.1,
        segment: {
          borderColor: (ctx) => {
            return ctx.p1.parsed.y > 0 ? 'green' : 'red';
          },
        },
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          callback: function (value) {
            return this.getLabelForValue(value);
          }
        }
      },
      y: {
        ticks: {
          beginAtZero: false,
          callback: function (value) {
            return value >= 1000 ? value / 1000 + 'k' : value / 1000 + 'k';
          }
        }
      }
    },
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming with pinch gestures
          },
          mode: 'x', // Allow zooming only on the x-axis
        },
        pan: {
          enabled: true, // Enable panning
          mode: 'x', // Allow panning only on the x-axis
        },
      },
    },
  };

  const updateData = (cnt) => {
    let drawdownCount = props.drawdown.length;
    if (drawdownCount >= cnt * 20) {
      setDrawdown(props.drawdown.slice(drawdownCount - cnt * 20, drawdownCount));
      setDate(props.date.slice(drawdownCount - cnt * 20, drawdownCount));
    } else {
      setDrawdown(props.drawdown.slice(0, min(cnt * 20, props.drawdown.length)))
      setDate(props.date.slice(0, min(cnt * 20, props.date.length)));
    }
  }

  useEffect(() => {
    setDrawdown(props.drawdown);
    setDate(props.date);
  }, [])

  return (
    <div className="overflow-x-auto w-full">
      <div className='flex flex-row'>
        <div className='flex flex-row gap-2'>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 1 && props.drawdown.length > 20 ? '#C5C6C7' : '' }} onClick={() => {
            props.drawdown.length >= 20 && updateData(1); setChoice(1);
          }}>1M</div>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 2 && props.drawdown.length > 60 ? '#C5C6C7' : '' }} onClick={() => {
            props.drawdown.length >= 60 && updateData(3); setChoice(2);
          }}>3M</div>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 3 && props.drawdown.length > 120 ? '#C5C6C7' : '' }} onClick={() => {
            props.drawdown.length >= 120 && updateData(6); setChoice(3);
          }}>6M</div>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 4 && props.drawdown.length > 240 ? '#C5C6C7' : '' }} onClick={() => {
            props.drawdown.length >= 200 && updateData(12); setChoice(4);
          }}>1Y</div>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 5 ? '#C5C6C7' : '' }} onClick={() => {
            updateData(100); setChoice(5);
          }}>All</div>
        </div>
        <div className='flex flex-grow justify-end text-l text-blue-500'>
          {props?.date?.length > 0 && props?.date[0]} <ArrowRightAltIcon /> {props?.date?.length > 0 && props?.date[props?.date?.length - 1]}
        </div>
      </div>
      <Line data={data} options={options} height={60} />
    </div>
  );
};

export default Graph1;
