import React, { useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqItems = [
    { question: 'What is Fprognos Backtesting Software?', answer: 'Fprognos is a sophisticated backtesting platform designed to help traders evaluate and optimize trading strategies using historical data and advanced analytics.' },
    { question: 'How does Fprognos improve my trading strategy?', answer: 'Fprognos provides detailed metrics, scenario analysis, and real-time simulations to identify strengths and weaknesses in your strategies, enabling better decision-making.' },
    { question: 'Is Fprognos suitable for beginners?', answer: 'Yes, Fprognos is designed to be user-friendly. It offers an easy-to-navigate interface where you can select and adjust various parameters to fine-tune and optimize your trading strategies.' },
    { question: 'Can I use Fprognos with my existing trading platform?', answer: 'Yes, Fprognos is compatible with major trading platforms and offers API integration for seamless workflow integration.' },
    { question: 'Does Fprognos offer support for custom strategies?', answer: 'Absolutely. Fprognos allows users to create and test custom strategies using a flexible strategy builder.' },
    { question: 'How frequently is Fprognos updated?', answer: 'Fprognos is regularly updated with new features, improvements, and access to the latest data sources.' },
    { question: 'What kind of reports does Fprognos generate?', answer: 'Fprognos generates comprehensive reports with customizable metrics, including performance indicators like Sharpe ratio, drawdown, and win/loss ratios.' },
    { question: 'Is there a community or support system available?', answer: 'Yes, Fprognos offers community support and professional assistance to help you get the most out of the platform.' },
    { question: 'What makes Fprognos unique compared to other backtesting tools?', answer: 'Fprognos incorporates AI-powered insights and offers a custom strategy builder, providing unmatched flexibility and predictive analytics.' },
    { question: 'How can I get started with Fprognos?', answer: 'Simply sign up on our website, and you’ll have access to all the tools you need to start backtesting and optimizing your trading strategies.' },
    // Add more FAQs as needed
  ];
  

  return (
    <div className='mx-auto p-6 rounded-lg bg-white text-black'>
      <h2 className='text-3xl font-bold text-center mb-6'>Frequently Asked Questions</h2>
      {faqItems.map((item, index) => (
        <div key={index} className='border-b border-gray-200'>
          <div
            className='flex justify-between items-center p-4 cursor-pointer hover:bg-gray-100 transition duration-200 shadow-sm'
            onClick={() => toggleAnswer(index)}
          >
            <div className='text-xl font-medium'>{item.question}</div>
            <div>
              {activeIndex === index ? <FaChevronUp /> : <FaChevronDown />}
            </div>
          </div>
          {activeIndex === index && (
            <div className='p-4 text-gray-800 bg-gray-50  font-serif font-medium'>{item.answer}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Faq;
