import React, { useState } from 'react'
import Graph from '../../../Graph/Graph'
import Graph1 from '../../../Graph/Graph1'
import BarChart from '../../../Graph/BarChart'

const GraphResult = (props) => {
  const cumulative = props.cumulative;
  const profit = [1,2,3,4,5,6,7,8,9]
  const date = [1,2,3,4,5,6,7,8,9]
  const drawdown = props.drawdown;
  const [activeElement, setActiveElement] = useState(1);

  const handleToggle = (element) => {
    setActiveElement(element);
  };

  return (
    <>
      <div className="grid grid-cols-2 text-center mt-5 ml-2 mr-2 rounded-md justify-center mb-0 text-[#718EBF] overflow-auto bg-white">
        {/* <div className="cursor-pointer font-[500] text-xl p-1 border-b-2" style={{ borderColor: activeElement === 1 ? "blue" : "white",color: activeElement === 1 ? "#1814F3" : "#718EBF" }} onClick={() => handleToggle(1)}>Profit/Loss</div> */}
        <div className="cursor-pointer font-[500] text-xl p-1 border-b-2" style={{ borderColor: activeElement === 2 ? "blue" : "white",color: activeElement === 2 ? "#1814F3" : "#718EBF" }} onClick={() => handleToggle(2)}>Cumulative Graph</div>
        <div className="cursor-pointer font-[500] text-xl p-1 border-b-2" style={{ borderColor: activeElement === 3 ? "blue" : "white",color: activeElement === 3 ? "#1814F3" : "#718EBF"}} onClick={() => handleToggle(3)}>Maximum Drawdown Graph</div>
      </div>
      {/* Graph make */}
      {/* {activeElement === 1 && <div className="bg-white shadow rounded-lg mt-0 m-2 flex flex-col overflow-auto">
        <BarChart profit={profit} date={date} />
      </div>
      } */}
      {activeElement === 2 && <div className="bg-white shadow rounded-lg mt-0 m-2 flex flex-col overflow-auto">
        <Graph cumulative={cumulative} date={date} graph_name="Cumulative" />
      </div>}
      {activeElement === 3 && <div className="bg-white shadow rounded-lg mt-0 m-2 flex flex-col overflow-auto">
        <Graph1 drawdown={drawdown} date={date} graph_name="Drawdown" />
      </div>
      }
    </>
  )
}

export default GraphResult