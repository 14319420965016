import {useState} from 'react'

const Manage = () => {
  const [endDate,setEndDate]=useState("")
  return (
    <div className='flex flex-col items-center min-h-screen bg-gray-100 py-10'>
    <div className='w-full max-w-sm p-6 bg-white shadow-lg rounded-lg'>
      <div className='mb-4'>
        <label className='block text-gray-700 font-semibold mb-2'>Start Date</label>
        <input 
          type="date" 
          className='w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500' 
        />
      </div>
      <div className='mb-4'>
        <label className='block text-gray-700 font-semibold mb-2'>End Date</label>
        <input 
          type="date" 
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          className='w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500' 
        />
      </div>
      <div className='flex justify-end'>
        <button className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-all'>
          Submit
        </button>
      </div>
    </div>
  </div>
  )
}

export default Manage