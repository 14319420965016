

// we are not using but for Updation,We will use this method

import { create } from 'zustand';
import {devtools, persist} from 'zustand/middleware'

const backtestStore = (set) => ({
coin:0,

// For Updating coin
updateCoin:(coin) => { 
  set((state) => (
     { coin:coin }
  ))
},

})

// Now,We will make a above code as a store and perform action in another component to perform it.
const useBacktestStore = create(
    devtools(
        persist(backtestStore, {
            name: "backtest",
        })
    )
)


export default useBacktestStore;