import React, { useState } from 'react'

const Main = (props) => {
    const [showStats, setShowStats] = useState(false)
    return (
        <>
            <div className="grid grid-cols-2 w-full mr-2 bg-white border-4 border-blue-500 rounded-lg">
                <div className='flex text-center justify-center text-xl font-semibold rounded-t-md col-span-2  h-10 text-white bg-[#718EBF]'>Backtest Result</div>
                <div className='grid grid-cols-1 font-medium text-xl font-serif'>
                    <div className="grid grid-cols-2 bg-green-100 border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l text-l font-medium border-r-2 border-gray-100 " >Net Profit</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF] " style={{ color: parseFloat(props.totalProfit) >= 0 ? "green" : "red" }} >&#8377; {parseFloat(props.totalProfit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Max Profit</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">&#8377; {parseFloat(props.maxProfit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100 " >Max Loss</h1>
                        <h1 className="text-right pr-4 justify-center text-red-600 ">&#8377; {props.maxLoss == 999999999 ? 0 : parseFloat(props.maxLoss).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100 " >Win%(days)</h1>
                        <h1 className="text-right pr-4 justify-center  text-green-600 " >{props.investmentDay != 0 ? parseFloat((props.winningTrades * 100) / props.investmentDay).toFixed(0) : 0}%({props.winningTrades})</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Loss%(days)</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600" >{props.investmentDay != 0 ? parseFloat((props.losingTrades * 100) / props.investmentDay).toFixed(0) : 0}%({props.losingTrades})</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100" >Average Profit On Win Days</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600 " >&#8377; {parseFloat(props.avgProfit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Average Loss On Loss Days</h1>
                        <h1 className="text-right pr-4 justify-center text-red-600">&#8377; {-1 * parseFloat(props.avgLoss).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Risk Reward Ratio</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">{props.avgLoss != 0 ? (parseFloat(props.avgProfit) / parseFloat(props.avgLoss)).toFixed(2) : 'Infinity'}</h1>
                    </div>

                    <div className="grid grid-cols-2 border-white p-2 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-gray-100" > Maximum Win Streak</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600" >{props.maxStreak} Days</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Maximum Loss Streak</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">{props.lossStreak} Days</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >MDD Days(Recovery Days)</h1>
                        <h1 className="text-right pr-4 justify-center">{parseFloat(props?.mdd?.count).toFixed(0)}{`(${parseFloat(props?.mdd?.recovery)} Days`} {props?.mdd?.continue ? '- Running)' : ')'}<br /><span className="text-sm">({props?.mdd?.startDate} - {props?.mdd?.endDate})</span></h1>
                    </div>
                </div>
                <div className='grid grid-cols-1 font-medium text-xl font-serif'>
                <div className="grid grid-cols-2 border-white p-2 bg-green-100">
                        <h1 className="pl-2 text-[#232323] border-r-2 border-white" >Estimated Margin</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF]" >&#8377; {parseFloat(props?.margin).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Minimum Capital Required</h1>
                        <h1 className="text-right pr-4 justify-center">&#8377; {props.maximumDrawdown > 0 ? props?.margin : (props?.margin + -1 * props.maximumDrawdown).toFixed(0)}</h1>
                    </div>

                    <div className="grid grid-cols-2 bg-red-100 border-white p-2 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Maximum Drawdown</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF] text-l font-medium" style={{ color: props.maximumDrawdown > 0 ? "green" : "red" }} >{parseFloat(props.maximumDrawdown).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Risk Reward Ratio</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">{props.avgLoss != 0 ? (parseFloat(props.avgProfit) / parseFloat(props.avgLoss)).toFixed(2) : 'Infinity'}</h1>
                    </div>
                    <div className="grid grid-cols-2  border-white p-1 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Gap Up (*PDC)</h1>
                        <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.metrics?.gap?.upProfit).toFixed(0)}{`(${props?.metrics?.gap?.upProfitCnt})`}</span><br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.metrics?.gap?.upLoss).toFixed(0)}{`(${props?.metrics?.gap?.upLossCnt})`}</span> </h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Gap Down (*PDC)</h1>
                        <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.metrics?.gap?.downProfit).toFixed(0)}{`(${props?.metrics?.gap?.downProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.metrics?.gap?.downLoss).toFixed(0)}{`(${props?.metrics?.gap?.downLossCnt})`}</span> </h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Day Open  {'>'} PDH</h1>
                        <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.metrics?.dayOpen?.pdhProfit).toFixed(0)}{`(${props?.metrics?.dayOpen?.pdhProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.metrics?.dayOpen?.pdhLoss).toFixed(0)}{`(${props?.metrics?.dayOpen?.pdhLossCnt})`}</span></h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Day Open  {'<'} PDL</h1>

                        <h1 className="text-right pr-4 justify-center "><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.metrics?.dayOpen?.pdlProfit).toFixed(0)}{`(${props?.metrics?.dayOpen?.pdlProfitCnt})`}</span> <br /> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.metrics?.dayOpen?.pdlLoss).toFixed(0)}{`(${props?.metrics?.dayOpen?.pdlLossCnt})`}</span> </h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Vix</h1>
                        <h1 className="text-right pr-4 justify-center">
                            From 1 to 10(inclusive) : <span style={{ color: props?.metrics?.vix[0]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.metrics?.vix[0]?.profit).toFixed(0)}</span>{`(${props?.metrics?.vix[0]?.cnt})`}<br />
                            From 11 to 15(inclusive) : <span style={{ color: props?.metrics?.vix[1]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.metrics?.vix[1]?.profit).toFixed(0)}</span>{`(${props?.metrics?.vix[1]?.cnt})`}<br />
                            From 16 to 20(inclusive): <span style={{ color: props?.metrics?.vix[2]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.metrics?.vix[2]?.profit).toFixed(0)}</span>{`(${props?.metrics?.vix[2]?.cnt})`}<br />
                            Above than 20: <span style={{ color: props?.metrics?.vix[3]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.metrics?.vix[3]?.profit).toFixed(0)}</span>{`(${props?.metrics?.vix[3]?.cnt})`}</h1>
                    </div>

                </div>
            </div>

            {/* <div className='flex text-center justify-center mb-5'><div className='bg-white flex text-center justify-center p-2 w-48 cursor-pointer' onClick={() => { setShowStats(!showStats); }}>{showStats ? 'SHOW MORE STATS' : 'SHOW LESS STATS'}{showStats ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div></div> */}
        </>
    )
}

export default Main