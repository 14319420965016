import icon from './assets/icon.webp'

const WhyChoose = () => {
  return (
    <div className="py-16 px-4 md:px-8 lg:px-16 bg-white text-black">
      <div className="mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-black">Why Choose Us?</h2>
          <p className="text-lg text-gray-700">Discover the features that set us apart from other platforms.</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">

          <div className="flex flex-row gap-4">
            <img src={icon} className='w-[76px] h-[76px] mt-12'></img>
            <div className='flex flex-col'>
              <h3 className="text-2xl font-semibold mb-4">AI-Driven Backtesting</h3>
              <p className="text-gray-700 mb-4">
              Experience the power of AI-driven backtesting with Fprognos. Our platform allows you to simulate your trading strategies against historical data, providing detailed performance metrics that help you fine-tune your approach.
              Whether you’re testing time-based strategies or complex algorithms, Fprognos offers the accuracy and depth you need to succeed.
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <img src={icon} className='w-[76px] h-[76px] mt-12'></img>
            <div className='flex flex-col'>
              <h3 className="text-2xl font-semibold mb-4">Comprehensive Statistical Reports</h3>
              <p className="text-gray-700 mb-4">
              Gain a deep understanding of your trading strategies with our comprehensive statistical reports.
              Fprognos delivers detailed, trade-wise analysis that reveals how your strategies perform under various market conditions, helping you optimize for better results.              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <img src={icon} className='w-[76px] h-[76px] mt-12'></img>
            <div className='flex flex-col'>
              <h3 className="text-2xl font-semibold mb-4">Intuitive and Useful Charts</h3>
              <p className="text-gray-700 mb-4">
              Visualize your backtesting results with our intuitive charts. Our platform turns complex data into easy-to-understand visuals, enabling you to quickly assess the effectiveness of your strategies and make informed decisions.
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <img src={icon} className='w-[76px] h-[76px] mt-12'></img>
            <div className='flex flex-col'>
              <h3 className="text-2xl font-semibold mb-4">Data in Excel</h3>
              <p className="text-gray-700 mb-4">
              Fprognos makes it easy to export your backtesting data into Excel for further analysis and reporting. Our Excel integration ensures that you can manipulate, share, and utilize your data with the tools you’re already familiar with, making it simple to incorporate into your trading workflow.
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <img src={icon} className='w-[76px] h-[76px] mt-12'></img>
            <div className='flex flex-col'>
              <h3 className="text-2xl font-semibold mb-4">Client-Friendly Interface:</h3>
              <p className="text-gray-700 mb-4">
              Fprognos is designed with you in mind. Our client-friendly interface ensures that even the most complex tools and features are accessible and easy to use, whether you’re a seasoned professional or just starting in the world of trading.
              </p>
            </div>
          </div>

          <div className="flex flex-row gap-4">
            <img src={icon} className='w-[76px] h-[76px] mt-12'></img>
            <div className='flex flex-col'>
              <h3 className="text-2xl font-semibold mb-4">Supported by Microsoft Founders Hub</h3>
              <p className="text-gray-700 mb-4">
              Fprognos is proud to be supported by the Microsoft Founders Hub, a testament to our commitment to innovation and excellence. This partnership ensures that we continuously evolve and provide you with cutting-edge tools and features.
              </p>
            </div>
          </div>

          {/* <div className="flex flex-row gap-4">
            <img src={icon} className='w-[76px] h-[76px] mt-12'></img>
            <div className='flex flex-col'>
              <h3 className="text-2xl font-semibold mb-4">State-of-the-Art Technology</h3>
              <p className="text-gray-700 mb-4">
              Built with the latest technology, Fprognos delivers a seamless and powerful trading experience. Our platform is engineered to handle the demands of both retail and professional traders, providing you with the speed, reliability, and accuracy you need to excel.
              </p>
            </div>
          </div> */}

        </div>
      </div>
    </div>
  );
}

export default WhyChoose;
