
const IndividualLeg = (props) => {
    
  return (
    <div className="m-2 bg-white rounded-md shadow-md overflow-hidden">
                <div className="bg-[#718EBF] px-4 py-2 text-xl font-bold text-white">
                    Individual Leg Contributions
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-gray-50">
                        <thead className="bg-indigo-100">
                            <tr>
                                <th className="py-3 px-4 text-left text-sm font-semibold text-indigo-700">Leg</th>
                                <th className="py-3 px-4 text-left text-sm font-semibold text-indigo-700">Total Entries</th>
                                <th className="py-3 px-4 text-left text-sm font-semibold text-indigo-700">Profit</th>
                                <th className="py-3 px-4 text-left text-sm font-semibold text-indigo-700">Percentage</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.individualLegContribution && props.individualLegContribution.map((item, key) => (
                                <tr key={`individualLeg-${key}`} className="hover:bg-indigo-50">
                                    <td className="py-3 px-4 text-sm text-gray-900">L{key + 1}</td>
                                    <td className="py-3 px-4 text-sm text-gray-900">{item?.count}</td>
                                    <td className={`py-3 px-4 text-sm text-gray-900 ${item?.profit > 0 ? 'text-green-600' : 'text-red-600'} `}>{parseFloat(item?.profit).toFixed(2)}</td>
                                    <td className={`py-3 px-4 text-sm text-gray-900 ${item?.profit > 0 ? 'text-green-600' : 'text-red-600'} `}>{props.totalProfit!=0 && (parseFloat(item?.profit/props.totalProfit) * 100).toFixed(2)}%</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
  )
}

export default IndividualLeg