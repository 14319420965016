// Method to Find the Next Thursday
export const Next_Thursday = (given_date) => {
    const givenDate = new Date(given_date);
    let nextThursday = new Date(givenDate)
    const currentWeekDay = nextThursday.getDay();
    const daysUntilNextThursday = (4 - currentWeekDay + 7) % 7;
    nextThursday.setDate(nextThursday.getDate() + daysUntilNextThursday);
    const nextThursdayDay = nextThursday.getDate();
    const nextThursdayMonth = nextThursday.getMonth() + 1;
    const nextThursdayYear = nextThursday.getFullYear();
    const formattedDate = `${nextThursdayMonth}/${nextThursdayDay}/${nextThursdayYear}`;
    return formattedDate;
}

// sort the data on the basis of daywise
export const handleDays=(data)=> {
    let mon = [], tue = [], wed = [], thu = [], fri = [];
    data.map((item, key) => {
        if (item[1] === 'Monday') mon.push(item);
        else if (item[1] === 'Tuesday') tue.push(item)
        else if (item[1] === 'Wednesday') wed.push(item)
        else if (item[1] === 'Thursday') thu.push(item)
        else fri.push(item)
    })
    mon.sort(); tue.sort(); wed.sort(); thu.sort(); fri.sort();
    let result = [...mon, ...tue, ...wed, ...thu, ...fri]
    return result;
}

export const tradeOnlyFirstEntryMethod=(data,leg)=>{
    let index=-1;
    let time='15:30'
    let temporary=[...data];
    for(let i=0;i<leg;i++)
    {
        if(data[(12 + 6 * leg + i)]=='' || data[(12 + 6 * leg + i)]==0)
            continue;
        else if(data[(12 + 6 * leg + i)]<=time){
            time=data[(12 + 6 * leg + i)]
            index=i;
        }
    }
    if(index!=-1){
        for(let i=0;i<leg;i++)
            {
                if(index!=i){
                    temporary[(12 + 3 * leg + i)]=0
                    temporary[(12 + 4 * leg + i)]=0
                }
            }  
    }
    return {"index":index,"data":temporary}
}

export const tradeOnlyFirstEntryAtReEntryMethod=(data,leg)=>{
    let index=-1;
    let time='15:30'
    let temporary=[...data];
    for(let i=0;i<leg;i++)
    {
        if(data[i][0][8]=='' || data[i][0][8]==0)
            continue;
        else if(data[i][0][8]<=time){
            time=data[i][0][8]
            index=i;
        }
    }
    if(index!=-1){
        for(let i=0;i<leg;i++)
            {
                if(index!=i){
                    temporary[i]=new Array(temporary[i].length).fill([0,0,0,0,0,0,0,0])
                }
            }  
    }
    return {"index":index,"data":temporary}
}

export const daysFromToday=(givenDate)=> {
    const today = new Date();
    const targetDate = new Date(givenDate);
  
    // Set the time of both dates to the start of the day to ignore time differences
    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);
  
    const timeDifference = today - targetDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
  
    return daysDifference;
  }

// Function to format the date => 2024-04-16 to 16 feb,2024
export function formatDate(dateString) {
    // Create a new Date object from the input date string
    const date = new Date(dateString);
  
    // Options for formatting the date
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
  
    // Format the date using toLocaleDateString
    return date.toLocaleDateString('en-GB', options).replace(/ /g, ' ');
}

export function NoOfDaysBetweenTwoDate(d1,d2){
    // Define the two dates
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    // Calculate the difference in time (milliseconds)
    const timeDifference = date2 - date1;

    // Convert the time difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return parseFloat(daysDifference).toFixed(0);

}