import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import moment from 'moment';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

// Register the zoom plugin with Chart.js
Chart.register(zoomPlugin);

const min = (a, b) => {
  if (a < b) return a;
  return b;
}

const BarChart = (props) => {
  const [profit, setProfit] = useState([])
  const [date, setDate] = useState([]);
  const [choice, setChoice] = useState(-1);
  const [chartData, setChartData] = useState({
    labels: date,
    datasets: [
      {
        label: '',
        data: props.profit,
        backgroundColor: profit.map(value => value > 0 ? 'green' : 'red'),
        borderWidth: 0,
        borderRadius: 6,
        border: 0,
      },
    ],
  });

  const formatDate = (date) => {
    return moment(date).format('D MMM, YYYY');
  };

  const interval = 1; // Set interval
  const filteredData = profit && profit.filter((_, index) => index % interval === 0);
  const filteredLabels = date && date.filter((_, index) => index % interval === 0);


  useEffect(() => {

    setChartData({
      labels: filteredLabels && filteredLabels.map(date => formatDate(date)),
      datasets: [
        {
          label: '',
          data: profit,
          backgroundColor: profit.map(value => value > 0 ? 'green' : 'red'),
          borderRadius: 6,
          border: 0,
        },
      ],
    });
  }, [profit, date]);

  const options = {
    responsive: true,
    legend: {
      display: false, // Hide the legend
    },
    title: {
      display: false,
    },
    scales: {
      x: {
        grid: {
          drawBorder: false,
          drawTicks: false,
        },
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 25,

        },
        barPercentage: 0.5, // Adjust this value to control the width of the bars
        categoryPercentage: 0.2, // Adjust this value to control the space between bars
        // title: {
        //   display: true, // Display the title
        //   text: 'Date', // Title for the x-axis
        //   color: '#333', // Color of the title
        //   font: {
        //     size: 14, // Font size of the title
        //   },
        // },
      },
      y: {
        ticks: {
          callback: function (value, index, values) {
            return value >= 1000 ? value / 1000 + 'k' : value / 1000 + 'k';
          },
          beginAtZero: true,
          maxTicksLimit: 25,
        },
        // title: {
        //   display: true, // Display the title
        //   text: 'Profit/Loss', // Title for the x-axis
        //   color: '#333', // Color of the title
        //   font: {
        //     size: 14, // Font size of the title
        //   },
        // },

      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (tooltipItems) => {
            return 'Date: ' + tooltipItems[0].label;
          },
          label: (tooltipItem) => {
            return 'profit: ' + tooltipItem.raw.toFixed(0);
          },
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: 'x',
        },
      },
    },
  };
  const updateData = (cnt) => {
    let profitCount = props.profit.length;
    if (profitCount >= cnt * 20) {
      setProfit(props.profit.slice(profitCount - cnt * 20, profitCount));
      setDate(props.date.slice(profitCount - cnt * 20, profitCount));
    } else {
      setProfit(props.profit.slice(0, min(cnt * 20, props.profit.length)))
      setDate(props.date.slice(0, min(cnt * 20, props.date.length)));
    }

  }
  useEffect(() => {
    setProfit(props.profit);
    setDate(props.date);
  }, [props.profit])
  return (
    <div className='overflow-x-auto w-full'>
      <div className='flex flex-row'>
        <div className='flex flex-row gap-2'>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 1 && props.profit.length > 20 ? '#C5C6C7' : '' }} onClick={() => {
            props.profit.length >= 20 && updateData(1); setChoice(1);
          }}>1M</div>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 2 && props.profit.length > 60 ? '#C5C6C7' : '' }} onClick={() => {
            props.profit.length >= 60 && updateData(3); setChoice(2);
          }}>3M</div>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 3 && props.profit.length > 120 ? '#C5C6C7' : '' }} onClick={() => {
            props.profit.length >= 120 && updateData(6); setChoice(3);
          }}>6M</div>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 4 && props.profit.length > 240 ? '#C5C6C7' : '' }} onClick={() => {
            props.profit.length >= 200 && updateData(12); setChoice(4);
          }}>1Y</div>
          <div className='h-10 w-10 flex text-center justify-center pt-2 bg-gray-200 cursor-pointer' style={{ backgroundColor: choice == 5 ? '#C5C6C7' : '' }} onClick={() => {
            updateData(100); setChoice(5);
          }}>All</div>
        </div>
        <div className='flex flex-grow justify-end text-l text-blue-500'>
          {props?.date?.length > 0 && props?.date[0]} <ArrowRightAltIcon /> {props?.date?.length > 0 && props.date[props?.date?.length - 1]}
        </div>
      </div>
      <Bar data={chartData} options={options} height={60} />
    </div>
  );
};

export default BarChart;
