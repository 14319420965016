
const TermsAndConditions = () => {
  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h1 className="text-3xl font-bold mb-4">Fprognos Terms and Conditions</h1>
      <p className="mb-4">
        Welcome to Fprognos! These Terms and Conditions outline the rules and regulations for the use of Fprognos, a derivative back-testing tool.
      </p>

      <h2 className="text-2xl font-semibold mb-2">1. Acceptance of Terms</h2>
      <p className="mb-4">
        By accessing or using Fprognos, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use the service.
      </p>

      <h2 className="text-2xl font-semibold mb-2">2. Description of Service</h2>
      <p className="mb-4">
        Fprognos provides tools for back-testing derivatives and other financial instruments. The service allows users to input data, perform simulations, and analyze the potential performance of financial strategies.
      </p>

      <h2 className="text-2xl font-semibold mb-2">3. User Responsibilities</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>Account Security:</strong> Users are responsible for maintaining the confidentiality of their account information and are fully responsible for all activities that occur under their account.
        </li>
        <li className="mb-2">
          <strong>Data Accuracy:</strong> Users must ensure that all data entered into Fprognos is accurate and up-to-date.
        </li>
        <li>
          <strong>Compliance:</strong> Users must comply with all applicable laws and regulations when using Fprognos.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">4. Usage Restrictions</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>Prohibited Activities:</strong> Users are prohibited from using Fprognos to engage in any unlawful activities, or to distribute any content that is harmful, offensive, or violates any third-party rights.
        </li>
        <li>
          <strong>Intellectual Property:</strong> All content and tools provided by Fprognos are protected by intellectual property laws. Users may not reproduce, distribute, or create derivative works based on the content or tools without explicit permission.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">5. Payment and Subscription</h2>
      <ul className="list-disc list-inside mb-4">
        <li className="mb-2">
          <strong>Fees:</strong> Access to Fprognos may be subject to subscription fees, which will be clearly communicated to the user. Payment terms will be specified at the time of subscription.
        </li>
        <li>
          <strong>Renewal and Cancellation:</strong> Subscriptions may renew automatically unless canceled by the user in accordance with the cancellation policy.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mb-2">6. Limitation of Liability</h2>
      <p className="mb-4">
        Fprognos is provided "as is" and without warranties of any kind. Fprognos will not be liable for any damages resulting from the use or inability to use the service.
      </p>

      <h2 className="text-2xl font-semibold mb-2">7. Termination</h2>
      <p className="mb-4">
        Fprognos reserves the right to terminate or suspend access to the service at any time, without notice, for conduct that it deems to be a violation of these terms or harmful to the interests of other users or the company.
      </p>
    </div>
  );
};

export default TermsAndConditions;
