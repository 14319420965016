import React from 'react';
import vision from './vision.jpg';

const Main = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-6 bg-[#FDCB02] rounded-lg shadow-lg">
      {/* Image Section */}
      <div className="flex justify-center items-center">
        <img 
          src={vision} 
          alt="Vision" 
          className="rounded-lg shadow-md w-full h-auto object-cover max-h-96 lg:max-h-full" 
        />
      </div>
      
      {/* Text Section */}
      <div className="flex flex-col justify-center">
        <div className="text-4xl text-center lg:text-left font-extrabold text-indigo-600 mb-6">
          Our Vision
        </div>
        <p className="text-gray-800 leading-relaxed text-xl mb-6">
          At Fprognos, we envision a world where every trader is empowered to take control of their financial future.
          We believe that everyone should have access to the tools and knowledge necessary to make informed trading decisions, 
          and that money should never be a barrier to living life on your own terms.
          <br /><br />
          Life is precious, time is limited, and how we choose to spend it defines who we are.
          A life with options is a life with freedom, and Fprognos is here to help you achieve that freedom through smarter trading.
        </p>
        <div className="text-4xl text-center lg:text-left font-extrabold text-indigo-600 mb-6">
          Our Mission
        </div>
        <p className="text-gray-800 leading-relaxed text-xl">
          At Fprognos, our mission is to democratize access to advanced trading tools and education, empowering traders of all levels to achieve financial independence.
          We are committed to providing innovative, user-friendly platforms that enable informed decision-making, allowing our users to trade with confidence and precision.
          <br/><br/>
          By breaking down barriers to entry and fostering a community of continuous learning, we strive to help every trader unlock their full potential, navigate the complexities of the financial markets, and achieve lasting success.
        </p>
      </div>
    </div>
  );
}

export default Main;
