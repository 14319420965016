import { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import logo from './../../assets/logo12.webp'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import secureLocalStorage from "react-secure-storage";
import { LOGIN_URL } from "../../constant/constant";
import { Oval } from 'react-loader-spinner'
import { MdOutlineVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import useBacktestStore from "../../store/backtestStore";

const Login = (props) => {
    const [email, setemail] = useState("")
    const [password, setpassword] = useState("")
    const [rememberMe, setRememberMe] = useState(false);
    const [wait, setWait] = useState(false)
    const [visible, setVisible] = useState(false)
    const {coin,updateCoin}=useBacktestStore()

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handlesubmit = () => {
        if (!validateEmail(email)) {
            toast('Please Enter Valid Email');
            setWait(false);
            return;
        }
        const data = { "email": email.toLowerCase(), "password": password }
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: LOGIN_URL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        if (email !== "" && password !== "") {
            axios.request(config)
                .then((response) => {
                    const result = response?.data;
                    console.log("result at login", result);
                    secureLocalStorage.setItem('backtest_token', `backtest_${result?.token}`)
                    secureLocalStorage.setItem('backtest_email', result.email)
                    secureLocalStorage.setItem('backtest_name', result.name)
                    secureLocalStorage.setItem('backtest_id', result.id)
                    secureLocalStorage.setItem('backtest_role', result.role)
                    secureLocalStorage.setItem('backtest_coin', result.coin)
                    updateCoin(result.coin)
                    if (result?.profilePic != null) {
                        secureLocalStorage.setItem('profilePic', result?.profilePic);
                    }
                    secureLocalStorage.setItem('active', result.active)
                    if (rememberMe) {
                        secureLocalStorage.setItem('password', password);
                        localStorage.setItem('remember', "true");
                    } else {
                        secureLocalStorage.removeItem('password');
                        localStorage.setItem('remember', "false");
                    }
                    setemail("")
                    setpassword("")
                    setWait(false)
                    props.setLoginView(false);
                }
                )
                .catch((error) => {
                    if (error?.response?.data?.message) {
                        toast(error.response.data.message);
                        setWait(false);
                        return;
                    } else {
                        toast('Try Again Later');
                        setWait(false);
                        return;
                    }
                });
        } else {
            toast("Please enter both your email and password.");
            setWait(false);
            return;
        }
    }

    useEffect(() => {
        if (wait === true) {
            handlesubmit();
            setTimeout(() => {
                setWait(false);
            }, 10000);
        }
    }, [wait])

    useEffect(() => {
        const remember = localStorage.getItem('remember');
        if (remember === "true") {
            setRememberMe(true);
            const password12 = secureLocalStorage.getItem('password');
            if (password12) setpassword(password12);
            const email1 = secureLocalStorage.getItem('backtest_email');
            if (email1) setemail(email1);
        }
    }, [])

    return (
        <>
            <ToastContainer />
            <div className="grid grid-cols-1 bg-[#DAECF2]">
                <div className="flex flex-col m-10  p-5 bg-white rounded-lg shadow">
                    <div className="text-[#3F4547] text-3xl font-bold ml-5 lg:w-8/12" >
                        <img src={logo} alt="logo" className="h-24 w-24" ></img>
                    </div>
                    <div className="text-[#000000] text-3xl mt-5 ml-4" style={{ fontWeight: "700" }}> <h1>Account Login</h1>  </div>
                    <p className="text-[#8692A6] ml-4 mt-1 p-1 h-18">  If you have already registered, you can login with your username and password.</p>
                    <div className="font-semibold ml-5">  Hello! Lets get started.. </div>
                    <hr className="m-5"></hr>
                    <label className="ml-5 mt-1">Email</label>
                    <div className="text-center justify-center mr-4">
                        <input type="email" placeholder="Enter Email"
                            className="mt-1 outline-0 h-10 border border-[#1565D8] pl-4 ml-4 mr-4 rounded-lg text-[#494949] w-full"
                            value={email} onChange={(e) => { setemail(e.target.value); }}
                        />
                    </div>
                    <label className="mt-2 ml-5 text-[#494949]">Password</label>
                    {visible && <div className="flex flex-row border border-[#1565D8] ml-4 rounded-lg h-10" >
                        <input type="text" placeholder="Enter Your Password"
                            className="mr-5 outline-0  pl-4 w-full rounded-lg border-[#1565D8]" value={password} onChange={(e) => {
                                setpassword(e.target.value);
                            }} />
                        <MdOutlineVisibility sx={{ cursor: "pointer", marginLeft: "20px", marginRight: "10px", marginTop: "6px" }} onClick={() => { setVisible(false) }} />
                    </div>
                    }
                    {!visible && <div className="flex flex-row border border-[#1565D8] rounded-lg ml-4 h-10">
                        <input type="password" placeholder="Enter Your Password"
                            className="mr-5 outline-0   pl-4 w-full rounded-lg border-[#1565D8]" value={password}
                            onChange={(e) => { setpassword(e.target.value); }} />
                        <MdVisibilityOff sx={{ cursor: "pointer", marginLeft: "20px", marginRight: "10px", marginTop: "6px" }} onClick={() => { setVisible(true) }} />
                    </div>}
                    <div className="grid grid-cols-2 mt-6">
                        <div className="flex flex-row ml-5">
                            <input type="checkbox" checked={rememberMe} onChange={(e) => { setRememberMe(e.target.checked); }} />
                            <div className="text-[#3F4547]">Remember Me</div>
                        </div>
                        <Link to='/forget_password'><div className="text-[#2C73EB] text-right cursor-pointer mr-5">Forgot Password?</div></Link>
                    </div>

                    <div className="text-center mb-4">
                        <button
                            className={` text-white py-3 px-6 rounded-md flex justify-center text-center font-bold w-full
                            ${wait ? 'cursor-not-allowed bg-blue-400' : 'bg-[#2C73EB]'}`}
                            onClick={() => setWait(true)}
                            onKeyDown={(e) => { if (e.key === 'Enter') { setWait(true) } }}>
                            {!wait ? 'Log In' : <Oval height={24} width={24} color="white" ariaLabel="loading" secondaryColor="#3b82f6" strokeWidth={2} strokeWidthSecondary={2} />}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;

