import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Excel from './../../pdf/Excel'
import MonthlyProfit from './MonthlyProfit'
import WeekProfit from './WeekProfit'
import Main from './Main'
import GraphResult from "./GraphResult";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Delete from "@mui/icons-material/Delete";
import { tradeOnlyFirstEntryMethod,daysFromToday,formatDate,tradeOnlyFirstEntryAtReEntryMethod,NoOfDaysBetweenTwoDate } from "./Utility";
import LegProfit1 from "./LegProfit1";
import IndividualLegContribution from "./IndividualLegContribution";
import { FaDownload } from "react-icons/fa";

// balance={investment} startdate={startdate} enddate={enddate} legs={leg}
const Result = (props) => {
    let csv = props.csv;
    // Data required for calculation
    const legs = props.resultData.legs;
    const atm = props.resultData.atm;
    const startdate = props.resultData.startDate;
    const enddate = props.resultData.endDate;
    const lotarray = props.resultData.lotarray;
    const legarray = props.resultData.legarray;
    const inputData = props.resultData.data;
    const reEntryChoice = props.resultData.reEntryChoice;
    const legsReEntry = props.resultData.reEntry;
    const LotQuantity = props.LotQuantity;
    // Filteration
    const [filter, setFilter] = useState("Date")
    const [Variant, setVariant] = useState(["contained", "contained", "contained", "contained", "contained", "contained"])
    const [ExpiryResult, setExpiryResult] = useState()
    const [week, setWeek] = useState([0, 0, 0, 0, 0])
    const [month, setMonth] = useState({})
    // Main Profit
    const [totalProfit, setTotalProfit] = useState(0)
    const [winningTrades, setWinningTrades] = useState(0)
    const [losingTrades, setLosingTrades] = useState(0)
    const [cumulative, setCumulative] = useState([])
    const [date, setDate] = useState()
    const [profitArray, setProfitArray] = useState(0)
    const [maxProfit, setMaximumProfit] = useState(0)
    const [maxLoss, setMaximumLoss] = useState(0)
    const [NumberBox, setNumberBox] = useState([])
    const [data, setData] = useState([])
    const [indexNumber, setindexNumber] = useState(0)
    const [premiumPrice, setPremiumPrice] = useState(0.5)
    const [dayoption, setdayoption] = useState([1, 1, 1, 1, 1])
    const [activeColor, setActiveColor] = useState("0")
    const [maximumDrawdown, setMaximumDrawdown] = useState(0)
    const [investmentDay, setInvestmentDay] = useState(0)
    const [netProfit, setNetProfit] = useState([])
    const [investmentMonth, setInvestmentMonth] = useState(0)
    const [pageSize, setPageSize] = useState(25);
    const [drawdownArray, setDrawdownArray] = useState([]);
    const [expectancy, setExpectancy] = useState(0);
    const [maxStreak, setMaxStreak] = useState(0);
    const [lossStreak, setLossStreak] = useState(0);
    const [winningStreak,setWinningStreak] = useState([0,0,0,0])
    const [losingStreak,setLosingStreak] = useState([0,0,0,0])
    const [avgProfit, setAvgProfit] = useState(0);
    const [avgLoss, setAvgLoss] = useState(0);
    const [uniqueElements, setUniqueElements] = useState(new Set());
    const [expireyProfit, setExpireyProfit] = useState({})
    const [totalExpireyProfit, setTotalExpireyProfit] = useState({})
    // Main Result
    const [mainResult, setMainResult] = useState([]);
    const [restartAgain,setRestartAgain] = useState(0);
    const [weekResult,setWeekResult] = useState({});
    // Store Statistical result
    const [StatisticalResult, setStatisticalResult] = useState([])
    // Result of ReEntry
    const [reEntryResult, setreEntryResult] = useState([])   //Store ReEntry Result
    const [reEntryProfit, setReEntryProfit] = useState([])   //Store ReEntry Profit
    const [reEntryProfitLeg, setReEntryProfitLeg] = useState([])  // store profit on the basis of leg
    const [excelRentryResult, setExcelRentryResult] = useState([])  //Store Excel ReEntry Result  
    const [visibilityReEntry, setVisibilityReEntry] = useState(true);
    const [resultVisibility, setResultVisibility] = useState(false);
    // hide NA
    const [appearNA, setAppearNA] = useState(false);
    const [isNA,setIsNA]=useState({})  // only for reEntry
    // Only for Week and Monthly Result
    const [activeComponent, setActiveComponent] = useState("day");
    // slippage value
    const [slippageValue,setSlippageValue]=useState(0);
    // show start date
    const [resultStartDate,setResultStartDate]=useState('2022-01-03');
    const [resultEndDate,setResultEndDate]=useState('2022-01-03');
    // show vix result
    const [vixStart,setVixStart]=useState(1)
    const [vixExit,setVixExit]=useState(99)
    // add filter
    const [resultFilter,setResultFilter]=useState([])
    const [tempFilter,setTempFilter]=useState([])
    // show ROI
    const [showROI,setShowROI]=useState(false);
    // include trade or not
    const [includeTrade,setIncludeTrade]=useState({});
    // mdd
    const [mddDay,setMddDay]=useState(0);
    // trade only first entry
    const [tradeOnlyFirstEntry,setTradeOnlyFirstEntry]=useState(false);
    const [tradeOnlyFirstEntryHelper,setTradeOnlyFirstEntryHelper]=useState(false);
    // individual leg contribution
    const [individualLegContribution,setIndividualLegContribution]=useState([])
    // stats metrics
    const [metrics,setMetrices]=useState(null);
    // this component contain 
    //  a) Normal result that does not contain reEntry(result())
    //  b) reEntry Result  that contain reEntry  (Re_entry_result())
    //  c) Excel Result for  Normal Result
    //  d) Excel Result and final ReEntry Result for reEntry Result (temporary())
    //  e) handleDownload for download pdf that will take screeenshot of result
    //  f) handleTrade that will handle for paging in leg result

    // There is a method to download a pdf
    const handleDownload = () => {
        const pdfElement = document.getElementById("screenshot");
        html2canvas(pdfElement).then((canvas) => {
            const pdf = new jsPDF();
            pdf.addImage(canvas.toDataURL("image/png"),"PNG", 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight());
            props.strategyName ? pdf.save(`${props.strategyName}_fprognos.pdf`) : pdf.save(`backtest_fprognos.pdf`);
        });
    };
    // Store Result in Day Wise
    function handleDays() {
        let mon = [], tue = [], wed = [], thu = [], fri = [];
        data.map((item, key) => {
            if (item[3] === 'Monday') mon.push(item);
            else if (item[3] === 'Tuesday') tue.push(item)
            else if (item[3] === 'Wednesday') wed.push(item)
            else if (item[3] === 'Thursday') thu.push(item)
            else fri.push(item)
        })
        mon.sort(); tue.sort(); wed.sort(); thu.sort(); fri.sort();
        let result = [...mon, ...tue, ...wed, ...thu, ...fri]
        return result;
    }

    // Sorting the Data on the basis of Index
    const sorting = (index) => {
        const sortedData = [...data].sort((a, b) => {
            const itemA = a[index];
            const itemB = b[index];

            if (typeof itemA === 'string' && typeof itemB === 'string') {
                return itemA.localeCompare(itemB);
            } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                return itemA - itemB;
            } else {
                return 0; // No comparison possible, keep the order unchanged
            }
        });
        setData(sortedData);
        return sortedData;
    };
    // Method to Find the Next Thursday
    const Next_Thursday = (given_date) => {
        const givenDate = new Date(given_date);
        let nextThursday = new Date(givenDate)
        const currentWeekDay = nextThursday.getDay();
        const daysUntilNextThursday = (4 - currentWeekDay + 7) % 7;
        nextThursday.setDate(nextThursday.getDate() + daysUntilNextThursday);
        const nextThursdayDay = nextThursday.getDate();
        const nextThursdayMonth = nextThursday.getMonth() + 1;
        const nextThursdayYear = nextThursday.getFullYear();
        const formattedDate = `${nextThursdayMonth}/${nextThursdayDay}/${nextThursdayYear}`;
        return formattedDate;
    }

    // Filter Result on the basis of day,date and expiry
    const sortArrayOfArrays = () => {
        if (filter === "Day") {
            return handleDays();
        }
        return sorting(0)
    }

    const filterPass=(csv)=>{
        // Applying Filter on the basis of GapUpDown or PrevDayHL
        for(let k=0;k<resultFilter.length;k++){
            let value = ((csv[10]?.Open-csv[11]?.Close)/csv[10]?.Open)*100
            if(resultFilter[k].type=='GapUpDown'){
                if(resultFilter[k].sign1==">=")
                {
                    if((resultFilter[k].or && resultFilter[k].sign2==">="))
                    {
                        if(!(value>=resultFilter[k].value1 || value>=resultFilter[k].value2))
                            return false;
                    }else if(resultFilter[k].or && resultFilter[k].sign2=="<="){
                        if(!(value>=resultFilter[k].value1 || value<=resultFilter[k].value2))
                            return false;
                    }else if(!(value>=resultFilter[k].value1 && !resultFilter[k].or))
                            return false;
                }else{
                    if(resultFilter[k].or && resultFilter[k].sign2==">=")
                    {
                        if(!(value<=resultFilter[k].value1 || value>=resultFilter[k].value2))
                            return false;
                    }else if(resultFilter[k].or && resultFilter[k].sign2==">="){
                        if(!(value<=resultFilter[k].value1 || value>=resultFilter[k].value2))
                            return false;
                    }else if(!( value<=resultFilter[k].value1 && !resultFilter[k].value2))
                        return false;
                }
            }else 
            if(resultFilter[k]?.type=='PrevDayHL')
            {
                if(!(resultFilter[k]?.sign1==csv[csv.length-5]  || (resultFilter[k].or && resultFilter[k]?.sign2==csv[csv.length-5])))
                {
                    return false;
                }
            }
        }
        return true;
    }
    const totalMonthProfit = (monthlyResult,monthlyDrawdown,maximumDrawdown) => {
        let total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let month_profit = {}
        let total_profit = 0
        let sum = 0;
        Object.keys(monthlyResult).forEach(key => {
            let value = monthlyResult[key];
            for (let i = 0; i <= 12; i++) {
                total[i] += parseFloat(value[i]);
                for (let i = 1; i <= 12; i++) {
                    sum += parseFloat(value[i]);
                }
                month_profit[key] = sum;
                
                sum = 0;
            }
            total_profit+=parseFloat(month_profit[key]);
        });
        
        Object.keys(monthlyResult).forEach(key => {
            monthlyResult[key].push(month_profit[key]);
            monthlyResult[key].push(monthlyDrawdown[key]);
            monthlyResult[key].push(parseFloat((month_profit[key]/props.margin)*100).toFixed(0));
        })
        monthlyResult['Total']=total;
        monthlyResult['Total'].push(total_profit);
        monthlyResult['Total'].push(maximumDrawdown);
        monthlyResult['Total'].push(parseFloat((total_profit/props.margin)*100).toFixed(0));
        return monthlyResult;
    }
    const totalWeekProfit = (weekResult) => {
        let total = [0, 0, 0, 0, 0, 0]
        let week_profit = {}
        let sum = 0;
        Object.keys(weekResult).forEach(key => {
            let value = weekResult[key];
            for (let i = 0; i <= 5; i++) {
                total[i] += parseFloat(value[i]);
                for (let i = 1; i <= 5; i++) {
                    sum += parseFloat(value[i]);
                }
                week_profit[key] = sum;
                sum = 0;
            }
        });
        return total;
    }
    

    // Method to Find the Result for Normal and Not For Re-Entry
    let n = 0;
    const result = () => {
        // console.log(csv,'csv processed')
        setCumulative(Array(parseInt(csv.length)).fill(0))
        setDate(Array(parseInt(csv.length)).fill(""))
        setProfitArray(Array(parseInt(csv.length)).fill(0));
        setNetProfit(Array(parseInt(legs)).fill(0));
        let total_profit = 0, winningtrades = 0, losingtrades = 0, maxprofit = 0, maxloss = 0, barChartDateArray = [], previous = 0, cnt = 0, premiumAmount = 0;
        n = csv.length > 0 ? csv[0].length : 0
        let cumulative = new Array(csv.length);
        let profitArray = new Array(csv.length)
        let week1 = new Array(5).fill(0)   //For Storing profit on the basis of daywise
        let month1 = {}   //For Storing profit on the basis of monthwise
        let net_profit = new Array(csv.length)  //For Storing profit
        // expectancy
        let profitQuantity = 0, totalProfit = 0, totalLoss = 0, lossQuantity = 0;
        let maxStreak = 0, lossStreak = 0;
        let winStreakDict=[0,0,0,0,0];
        let lossStreakDict=[0,0,0];
        let prev = 1, count = 0;
        // drawdown
        let maxProfit = 0; // Initialize maxPrice with the first element of the array
        let MaximumDrawdown = 0;
        let monthlyDrawdown = {};
        let result = new Array(csv.length)
        // investmentDay
        let investmentDay = 0;
        // group by expiry
        const expirySet = new Set();
        // drawdown date
        let drawDownDate=csv.length>0 ? csv[0][0] :'';
        let weekResult={};
        let mddCount = 0,recoveryStartDate="",maxLoss=9999999,ans={"count":0,"startDate":"","endDate":"","recovery":0,"continue":false,"status":'Running',"recoveryDate":""};
        let individualLeg=new Array(inputData.leg).fill().map(() => ({"profit":0,"count":0}));
        let drawdownArray=[]
        let dayOpen={"pdhProfit":0,"pdhProfitCnt":0,"pdhLoss":0,"pdhLossCnt":0,"pdlProfit":0,"pdlLoss":0,"pdlProfitCnt":0,"pdlLossCnt":0}
        let gap={"upProfit":0,"upProfitCnt":0,"upLoss":0,"upLossCnt":0,"downProfit":0,"downProfitCnt":0,"downLoss":0,"downLossCnt":0}
        let vix = new Array(4).fill().map(() => ({ profit: 0, cnt: 0 }));
        for (var i = 0; i < csv.length; i++) {
            // Method to store the data
            let error = 0;
            // Applying Filter on the basis of vix , range of start date and end date
            if(csv[i][8]< vixStart || csv[i][8]>vixExit || csv[i][9]<vixStart || csv[i][9]>vixExit)
               continue;
            if(csv[i][0]<resultStartDate || csv[i][0]>resultEndDate)
                continue;
            // Applying the filter on the basis of GapUpDown and PrevDayHL
            if(!filterPass(csv[i])){
                continue;
            }
            // logic of trade only first entry
            let index=-1;
            let tradeOnlyFirstReEntryData=null;
            if(inputData.waitTrade && tradeOnlyFirstEntry){
                let temp=tradeOnlyFirstEntryMethod(csv[i],inputData?.leg);
                index=temp.index;
                tradeOnlyFirstReEntryData=temp.data;
            }
            // Formula to Calculate Sum of Profit at a particular Day
            let sum = 0;
            let flag = 0; // It indicate supremacy of strategy profit or stoploss
            for(let key=0;key<legarray.length;key++)
            {
                let current_sum=0;
                if(tradeOnlyFirstEntry && index!==-1 && index!=key)
                    continue;
                if(csv[i][(12 + 3 * legs + key)]==99999999999 || csv[i][(12 + 4 * legs + key)]==99999999999)
                {
                   continue;
                }
                else if(legarray[key][0]=='S')
                {  
                    if(csv[i][((12 + 5 * legs + key))]=='sstoploss')
                    {
                        sum = -1*parseFloat(props.strategyStoploss);
                        flag=1;
                    }else if(csv[i][((12 + 5 * legs + key))]=='smaxprofit')
                    {
                        sum = parseFloat(props.strategyProfit);
                        flag=1;
                    }else if (csv[i][csv[i].length-4] == 'Lock and Trail Profit Hit!' || csv[i][csv[i].length-4] == 'Trail Hit!' || csv[i][csv[i].length-4] == 'Lock Minimum Profit Hit!')
                    {
                        sum=parseFloat(csv[i][csv[i].length-3])
                    }
                    else if(flag==0 && csv[i][(12 + 3 * legs + key)]!='99999999999' && csv[i][(12 + 4 * legs + key)]!='99999999999'){
                        sum = parseFloat(sum) + parseFloat((parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)])-props.slippage*parseFloat(csv[i][(12 + 3 * legs + key)])*0.01) - (parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)])+props.slippage*parseFloat(csv[i][(12 + 4 * legs + key)])*0.01))) * lotarray[key] * LotQuantity) 
                    }
                    current_sum = parseFloat((parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)])-props.slippage*parseFloat(csv[i][(12 + 3 * legs + key)])*0.01) - (parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)])+props.slippage*parseFloat(csv[i][(12 + 4 * legs + key)])*0.01))) * lotarray[key] * LotQuantity)
                }else{
                    if(csv[i][((12 + 5 * legs + key))]==='sstoploss')
                    {
                        sum = -1*parseFloat(props.strategyStoploss);
                        flag=1;
                    }else if(csv[i][((12 + 5 * legs + key))]==='smaxprofit')
                    {
                        sum = parseFloat(props.strategyProfit);
                        flag=1;
                    }else if (csv[i][csv[i].length-4] == 'Lock and Trail Profit Hit!' || csv[i][csv[i].length-4] == 'Trail Hit!' || csv[i][csv[i].length-4] == 'Lock Minimum Profit Hit!')
                    {
                        sum=parseFloat(csv[i][csv[i].length-3])
                    }
                    else if(flag==0 && csv[i][(12 + 3 * legs + key)]!='99999999999' && csv[i][(12 + 4 * legs + key)]!='99999999999'){
                        sum=parseFloat(sum) + parseFloat((parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)])-props.slippage*parseFloat(csv[i][(12 + 4 * legs + key)])*0.01) - parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)])+props.slippage*parseFloat(csv[i][(12 + 3 * legs + key)])*0.01)) * lotarray[key] * LotQuantity)
                    }
                    current_sum=parseFloat((parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)])-props.slippage*parseFloat(csv[i][(12 + 4 * legs + key)])*0.01) - parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)])+props.slippage*parseFloat(csv[i][(12 + 3 * legs + key)])*0.01)) * lotarray[key] * LotQuantity)
                }

                individualLeg[key].profit+=parseFloat(current_sum);
                individualLeg[key].count+=1;
            }

            // legarray.map((value, key) => {
            //     return (
            //         sum += (parseFloat(csv[i][(12 + 3 * legs + key)]) != 99999999999 && parseFloat(csv[i][(12 + 4 * legs + key)]) != 99999999999) ?
            //          (parseFloat(value[0] == 'S' ? ((parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)])-props.slippage*parseFloat(csv[i][(12 + 3 * legs + key)])*0.01) - (parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)])))) * lotarray[key] * LotQuantity).toFixed(2) :
            //         ((parseFloat(parseFloat(csv[i][(12 + 4 * legs + key)])) - parseFloat(parseFloat(csv[i][(12 + 3 * legs + key)])+props.slippage*parseFloat(csv[i][(12 + 3 * legs + key)])*0.01)) * lotarray[key] * LotQuantity).toFixed(2))) : 0)
            // })
            
            // Hide the NA item
            for (let k = 0; k < legarray.length; k++) {
                if (parseFloat(csv[i][(12 + 3 * legs + k)]) == 99999999999 || parseFloat(csv[i][(12 + 4 * legs + k)]) == 99999999999) {
                    error += 1;
                }
            }
            if (appearNA && error > 0)
                continue;
            net_profit[cnt] = parseFloat(sum);
            if(tradeOnlyFirstEntry){
                data[cnt]=tradeOnlyFirstReEntryData;
            }else{
                data[cnt] = csv[i];
            }
            data[cnt][csv[i].length-3] = parseFloat(sum)
            // trade contain or not
            if(includeTrade.hasOwnProperty(csv[i][0])){
                cnt++;
                continue;
            }

            // Method to Sum of Profit DayWise
            if (dayoption[0] === 1 && csv[i][3] === 'Monday')
            {
                week1[0] += parseFloat(sum);
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][0]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][0]+=parseFloat(sum);
                }
            }
            else if (dayoption[1] === 1 && csv[i][3] === 'Tuesday')
            {
                week1[1] += parseFloat(sum);
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][1]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][1]+=parseFloat(sum);
                }

            }
            else if (dayoption[2] === 1 && csv[i][3] === 'Wednesday')
            {
                week1[2] += parseFloat(sum);
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][2]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][2]+=(sum);
                }
            }
            else if (dayoption[3] === 1 && csv[i][3] === 'Thursday')
            {
                week1[3] += parseFloat(sum);
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][3]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][3]+=parseFloat(sum);
                }

            }
            else if (dayoption[4] === 1 && csv[i][3] === 'Friday')
            {
                week1[4] += parseFloat(sum);
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][4]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][4]+=parseFloat(sum);
                }
            }
            else
                continue;
            
            // Method to find the MontlyWise Profit
            if (month1.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][parseInt(csv[i][0][5] + csv[i][0][6])] += parseFloat(sum)
            } else {
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(13).fill(0);
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][parseInt(csv[i][0][5] + csv[i][0][6])] += parseFloat(sum);
            }


            // Method to calculate total profit
            total_profit = week1[0] + week1[1] + week1[2] + week1[3] + week1[4];

            // Method to find the Cumulative Data
            cumulative[cnt] = previous + sum;
            previous = cumulative[cnt];
            
            // Method to find the Maximum Drawdown
            // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
            // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
            if (cumulative[cnt] > maxProfit) {
                // console.log(maxProfit,maxLoss,cumulative[cnt],drawDownDate,recoveryStartDate,csv[i][0],'tradeOnllllll')
                let dayCount=NoOfDaysBetweenTwoDate(drawDownDate,csv[i][0])
                if(ans.count<dayCount){
                    ans.count=dayCount;
                    ans.startDate=formatDate(drawDownDate);
                    ans.endDate=formatDate(csv[i][0]);
                    ans.recovery=NoOfDaysBetweenTwoDate(recoveryStartDate,csv[i][0],);
                    ans.continue=false;
                    ans.status='';
                    ans.recoveryDate=recoveryStartDate;
                }
                
                maxProfit = cumulative[cnt]; // Update maxPrice if a new peak is reached
                maxLoss=maxProfit;
                drawDownDate=csv[i][0];
                recoveryStartDate='';
            }

            if(cumulative[cnt] < maxLoss){
                recoveryStartDate = csv[i][0];
                maxLoss = cumulative[cnt];
            }

            
            
            const currentDrawdown = (cumulative[cnt] - maxProfit);
            drawdownArray.push(currentDrawdown);
                
            if (currentDrawdown < MaximumDrawdown) {
                MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
            }

            result[cnt] = MaximumDrawdown;

            // monthlyDrawdown
            if (monthlyDrawdown.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = MaximumDrawdown
            } else {
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(13).fill(0);
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = MaximumDrawdown;
            }

            // Method to find all date
            barChartDateArray[cnt] = csv[i][0]

            // Method to find the Total Premium Amount
            premiumAmount += sum * premiumPrice;

            // Method to find the Expectancy
            // Formula to Calculate Expectancy
            // Expectancy=(WinRate × AverageWin)−(LossRate × AverageLoss)
            if (net_profit[cnt] >= 0) {
                profitQuantity += 1;
                totalProfit += sum;
                // Calculate streak
                if (prev > 0) {
                    count += 1
                    prev = 1;  // it denotes positive series
                } else {
                    lossStreak = count > lossStreak ? count : lossStreak;
                    if(count<=3)
                    {
                        lossStreakDict[count-1]+=1;
                    }
                    count = 1;
                    prev = 1;
                }
            } else {
                lossQuantity += 1;
                totalLoss += sum;
                if (prev < 0) {
                    count += 1;
                    prev = -1;
                } else {
                    maxStreak = count > maxStreak ? count : maxStreak;
                    if(count<=4){
                        winStreakDict[count-1]+=1;
                    }
                    count = 1;
                    prev = -1;
                }
            }

            // Method to find the winning trades and lossing trades
            profitArray[cnt] = sum
            if (sum > 0) {
                winningtrades += 1;
            }
            else {
                losingtrades += 1;
            }
            if (sum > maxprofit) {
                maxprofit = sum;
            }
            if (sum < maxloss) {
                maxloss = sum;
            }

            // Count Number of InvestmentDay
            investmentDay += 1
            // Expiry
            let expirey_date = Next_Thursday(csv[i][0]);
            expirySet.add(expirey_date);

            // Day open profit
            if(csv[i][csv[i].length-5]=='High'){
                if(sum>0){dayOpen.pdhProfit+=sum;dayOpen.pdhProfitCnt+=1;}
                else {dayOpen.pdhLoss+=sum;dayOpen.pdhLossCnt+=1}
            }
            else if(csv[i][csv[i].length-5]=='Low'){
                if(sum>0){dayOpen.pdlProfit+=sum;dayOpen.pdlProfitCnt+=1;}
                else {dayOpen.pdlLoss+=sum;dayOpen.pdlLossCnt+=1}
            }

            // gap up profit
            if((csv[i][10]?.Open - csv[i][11]?.Close) > 0){
                if(sum>=0){gap.upProfit+=sum;gap.upProfitCnt+=1;}
                else {gap.upLoss+=sum;gap.upLossCnt+=1;}
            }
            else{
                if(sum>=0){gap.downProfit+=sum;gap.downProfitCnt+=1;}
                else {gap.downProfit+=sum;gap.downLossCnt+=1;}
            }

            // vix profit
            if(csv[i][8]<=10){ 
                vix[0].profit+=sum; vix[0].cnt+=1;
            }else if(csv[i][8]<=15){ vix[1].profit+=sum; vix[1].cnt+=1;}
            else if(csv[i][8]<=20){ vix[2].profit+=sum; vix[2].cnt+=1;}
            else{ vix[3].profit+=sum; vix[3].cnt+=1; }
            
            cnt++;
        }
        if(prev<0)
        {
            if(count<=3){ lossStreakDict[count-1]+=1;}
        }else{
            if(count<=5){ winStreakDict[count-1]+=1;}
        }
        // daywise profitabiliry
        weekResult['Total']=totalWeekProfit(weekResult);
        // monthwise profitability
        month1=totalMonthProfit(month1,monthlyDrawdown,MaximumDrawdown);
        // Calculate Expectancy
        let Expectancy = 0;
        totalLoss = -1 * totalLoss;
        if (totalLoss == 0) {
            Expectancy = 'Infinity';
        }
        else if (totalProfit == 0) {
            Expectancy = 0;
        }
        else {
            Expectancy = ((profitQuantity * totalProfit) / (totalLoss * cnt)) - lossQuantity / cnt;
        }
        // check again at every point
        let current_cnt=daysFromToday(drawDownDate);
        if(ans.count==0 || ans.count<=current_cnt){
            ans.count=daysFromToday(drawDownDate);
            ans.startDate=formatDate(drawDownDate);
            ans.endDate='Running';
            ans.recovery=daysFromToday(recoveryStartDate);
            ans.continue=true;
            ans.status='Running'
        }
        setExpectancy(Expectancy);
        setMaxStreak(maxStreak);
        setLossStreak(lossStreak);
        profitQuantity == 0 ? setAvgProfit(0) : setAvgProfit(totalProfit / profitQuantity);
        lossQuantity == 0 ? setAvgLoss(0) : setAvgLoss(totalLoss / lossQuantity);
        setCumulative(cumulative);
        setMaximumDrawdown(MaximumDrawdown);
        setDrawdownArray(result);
        data.splice(cnt, csv.length)
        setUniqueElements(expirySet);
        setWeek(week1)
        setMonth(month1)
        setTotalProfit(total_profit);
        setWinningTrades(winningtrades)
        setLosingTrades(losingtrades)
        setWinningStreak(winStreakDict);
        setLosingStreak(lossStreakDict);
        setMaximumProfit(maxprofit)
        setMaximumLoss(maxloss)
        setProfitArray(profitArray)
        const len = csv.length / 1 + csv.length % 2 > 0 ? 1 : 0;
        setDate(barChartDateArray)
        const st_date = new Date(startdate);
        const end_date = new Date(enddate);
        const investmentmonth = (end_date.getFullYear() - st_date.getFullYear()) * 12 + (end_date.getMonth() - st_date.getMonth());
        var timeDiff = end_date.getTime() - st_date.getTime();
        var weeksDiff = Math.floor(timeDiff / (1000 * 3600 * 24 * 7));
        setInvestmentDay(investmentDay);
        setInvestmentMonth(investmentmonth);
        setNetProfit(net_profit);
        setData(data);  //change csv to data;
        !reEntryChoice && setVisibilityReEntry(true);
        // week Result year wise
        setWeekResult(weekResult);
        setMddDay(ans);
        setMetrices({"vix":vix,"gap":gap,"dayOpen":dayOpen})
        setIndividualLegContribution(individualLeg);
    }

    const min = (a, b) => {
        if (a < b)
            return a;
        return b;
    }


    const finalReEntryResult = (reEntryProfitLeg) => {
        if(!reEntryProfitLeg)
            return;
        setCumulative(Array(parseInt(csv.length)).fill(0))
        setDate(Array(parseInt(csv.length)).fill(""))
        setProfitArray(Array(parseInt(csv.length)).fill(0));
        setNetProfit(Array(parseInt(legs)).fill(0));
        let total_profit = 0, winningtrades = 0, losingtrades = 0, maxprofit = 0, maxloss = 0, barChartDateArray = [], previous = 0, cnt = 0, premiumAmount = 0;
        n = csv.length > 0 ? csv[0].length : 0
        let cumulative = new Array(csv.length);
        let profitArray = new Array(csv.length)
        let week1 = new Array(5).fill(0)   //For Storing profit on the basis of daywise
        let weekResult = {};
        let month1 = {}   //For Storing profit on the basis of monthwise
        let net_profit = new Array(csv.length)  //For Storing profit
        // expectancy
        let profitQuantity = 0, totalProfit = 0, totalLoss = 0, lossQuantity = 0;
        let maxStreak = 0, lossStreak = 0;
        let winStreakDict=[0,0,0,0,0];
        let lossStreakDict=[0,0,0];
        let prev = 1, count = 0;
        // drawdown
        let maxProfit = 0; // Initialize maxPrice with the first element of the array
        let MaximumDrawdown = 0;
        let monthlyDrawdown={}
        let result = new Array(csv.length)
        // investmentDay
        let investmentDay = 0;
        // group by expiry
        const expirySet = new Set();
        // drawdown date
        let drawDownDate=csv.length>0 ? csv[0][0] :'';
        //mdd Day
        let mddCount = 0,recoveryStartDate="",maxLoss=9999999,ans={"count":0,"startDate":"","endDate":"","recovery":0,"continue":false,"status":'Running',"recoveryDate":""};
        let individualLeg=new Array(inputData.leg).fill().map(() => ({"profit":0,"count":0}));
        let drawdownArray=[]
        let dayOpen={"pdhProfit":0,"pdhProfitCnt":0,"pdhLoss":0,"pdhLossCnt":0,"pdlProfit":0,"pdlLoss":0,"pdlProfitCnt":0,"pdlLossCnt":0}
        let gap={"upProfit":0,"upProfitCnt":0,"upLoss":0,"upLossCnt":0,"downProfit":0,"downProfitCnt":0,"downLoss":0,"downLossCnt":0}
        let vix = new Array(4).fill().map(() => ({ profit: 0, cnt: 0 }));
        // only useful for trade only at first entry
        let reEntryResultAmend=[...reEntryResult];


        for (var i = 0; i < csv.length; i++) {
            // Formula to Calculate Sum of Profit at a particular Day
            // Applying Filter on the basis of vix , range of start date and end date
            if(csv[i][8]< vixStart || csv[i][8]>vixExit || csv[i][9]<vixStart || csv[9]>vixExit)
                continue;
            if(csv[i][0]<resultStartDate || csv[i][0]>resultEndDate)
                 continue;
            // Applying the filter on the basis of GapUpDown and PrevDayHL
            if(!filterPass(csv[i])){
                 continue;
            }
            if(appearNA && isNA.hasOwnProperty(csv[i][0]))
                continue;
            // logic of trade only first entry
            let index=-1;
            let sum = reEntryProfit[i];
            let tradeOnlyFirstReEntryData=null;
            if(inputData.waitTrade && tradeOnlyFirstEntry){
                let temp=tradeOnlyFirstEntryAtReEntryMethod(reEntryResult[i],inputData?.leg);
                reEntryResultAmend[i]=[...temp.data];
                index=temp.index;
            }
            
            for(let key=0;key<inputData.leg;key++)
            {
                if(tradeOnlyFirstEntry && index!==-1 && index==key)
                {
                    sum=reEntryProfitLeg[i][index];
                }
                individualLeg[key].profit=parseFloat(individualLeg[key]?.profit) + parseFloat(reEntryProfitLeg[i][key]);
                individualLeg[key].count+=1;
            }
            
            net_profit[cnt] = parseFloat(sum);
            data[cnt] = csv[i]
            data[cnt][csv[i].length-3] = parseFloat(sum)
            // trade contain or not
            if(includeTrade.hasOwnProperty(csv[i][0])){
                cnt++;
                continue;
            }

            // Method to Sum of Profit DayWise
            if (dayoption[0] === 1 && csv[i][3] === 'Monday')
            {
                week1[0] += sum;
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][0]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][0]+=parseFloat(sum);
                }
            }
            else if (dayoption[1] === 1 && csv[i][3] === 'Tuesday')
            {
                week1[1] += sum;
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][1]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][1]+=parseFloat(sum);
                }

            }
            else if (dayoption[2] === 1 && csv[i][3] === 'Wednesday')
            {
                week1[2] += sum;
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][2]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][2]+=parseFloat(sum);
                }

            }
            else if (dayoption[3] === 1 && csv[i][3] === 'Thursday')
            {
                week1[3] += sum;
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][3]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][3]+=parseFloat(sum);
                }

            }
            else if (dayoption[4] === 1 && csv[i][3] === 'Friday')
            {
                week1[4] += sum;
                if(weekResult.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]))
                {
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][4]+=parseFloat(sum);
                }else{
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(5).fill(0);
                    weekResult[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][4]+=parseFloat(sum);
                }
            }
            else
                continue;


            // Method to find the MontlyWise Profit
            if (month1.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][parseInt(csv[i][0][5] + csv[i][0][6])] += parseFloat(sum)
            } else {
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(13).fill(0);
                month1[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]][parseInt(csv[i][0][5] + csv[i][0][6])] += parseFloat(sum);
            }



            // Method to find the Cumulative Data
            cumulative[cnt] = previous + sum;
            previous = cumulative[cnt];

            // Method to find the Maximum Drawdown
            // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
            // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
            if (cumulative[cnt] > maxProfit) {
                // console.log(maxProfit,maxLoss,cumulative[cnt],drawDownDate,recoveryStartDate,csv[i][0],'tradeOnllllll')
                let dayCount=NoOfDaysBetweenTwoDate(drawDownDate,csv[i][0])
                if(ans.count<dayCount){
                    ans.count=dayCount;
                    ans.startDate=formatDate(drawDownDate);
                    ans.endDate=formatDate(csv[i][0]);
                    ans.recovery=NoOfDaysBetweenTwoDate(recoveryStartDate,csv[i][0],);
                    ans.continue=false;
                    ans.status='';
                    ans.recoveryDate=recoveryStartDate;
                }
                
                maxProfit = cumulative[cnt]; // Update maxPrice if a new peak is reached
                maxLoss=maxProfit;
                drawDownDate=csv[i][0];
                recoveryStartDate='';
            }

            if(cumulative[cnt] < maxLoss){
                recoveryStartDate = csv[i][0];
                maxLoss = cumulative[cnt];
            }

            const currentDrawdown = (cumulative[cnt] - maxProfit);

            if (currentDrawdown < MaximumDrawdown) {
                MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
            }

            result[cnt] = MaximumDrawdown;

            // monthlyDrawdown
            if (monthlyDrawdown.hasOwnProperty(csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3])) {
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = MaximumDrawdown
            } else {
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = new Array(13).fill(0);
                monthlyDrawdown[csv[i][0][0] + csv[i][0][1] + csv[i][0][2] + csv[i][0][3]] = MaximumDrawdown;
            }

            // Method to find all date
            barChartDateArray[cnt] = csv[i][0]

            // Method to find the Total Premium Amount
            premiumAmount += sum * premiumPrice;

            // Method to find the Expectancy
            // Formula to Calculate Expectancy
            // Expectancy=(WinRate × AverageWin)−(LossRate × AverageLoss)
            if (sum >= 0) {
                profitQuantity += 1;
                totalProfit += parseFloat(sum);
                // Calculate streak
                if (prev > 0) {
                    count += 1
                    prev = 1;
                } else {
                    lossStreak = count > lossStreak ? count : lossStreak;
                    if(count<=4){
                        lossStreakDict[count-1]+=1;
                    }
                    count = 1;
                    prev = 1;
                }
            } else {
                lossQuantity += 1;
                totalLoss += parseFloat(sum);
                if (prev < 0) {
                    count += 1;
                    prev = -1;
                } else {
                    maxStreak = count > maxStreak ? count : maxStreak;
                    if(count<=4){
                        winStreakDict[count-1]+=1;
                    }
                    count = 1;
                    prev = -1;
                }
            }

            // Method to find the winning trades and lossing trades
            profitArray[cnt] = parseFloat(sum)
            if (sum > 0) {
                winningtrades += 1;
            }
            else {
                losingtrades += 1;
            }
            if (sum > maxprofit) {
                maxprofit = sum;
            }
            if (sum < maxloss) {
                maxloss = sum;
            }

            // Count Number of InvestmentDay
            investmentDay += 1
            // Expiry
            let expirey_date = Next_Thursday(csv[i][0]);
            expirySet.add(expirey_date);

            // Day open profit
            if(csv[i][csv[i].length-5]=='High'){
                if(sum>0){dayOpen.pdhProfit+=sum;dayOpen.pdhProfitCnt+=1;}
                else {dayOpen.pdhLoss+=sum;dayOpen.pdhLossCnt+=1}
            }
            else if(csv[i][csv[i].length-5]=='Low'){
                if(sum>0){dayOpen.pdlProfit+=sum;dayOpen.pdlProfitCnt+=1;}
                else {dayOpen.pdlLoss+=sum;dayOpen.pdlLossCnt+=1}
            }

            // gap up profit
            if((csv[i][10]?.Open - csv[i][11]?.Close) > 0){
                if(sum>=0){gap.upProfit+=sum;gap.upProfitCnt+=1;}
                else {gap.upLoss+=sum;gap.upLossCnt+=1;}
            }
            else{
                if(sum>=0){gap.downProfit+=sum;gap.downProfitCnt+=1;}
                else {gap.downProfit+=sum;gap.downLossCnt+=1;}
            }

            // vix profit
            if(csv[i][8]<=10){ 
                vix[0].profit+=sum; vix[0].cnt+=1;
            }else if(csv[i][8]<=15){ vix[1].profit+=sum; vix[1].cnt+=1;}
            else if(csv[i][8]<=20){ vix[2].profit+=sum; vix[2].cnt+=1;}
            else{ vix[3].profit+=sum; vix[3].cnt+=1; }

            cnt++;
        }
        // counting of winning streak and losing streak
        if(prev<0)
        {
            if(count<=3){ lossStreakDict[count-1]+=1;}
        }else{
            if(count<=4){ winStreakDict[count-1]+=1;}
        }
        // daywise profitabiliry
        weekResult['Total']=totalWeekProfit(weekResult);
        // monthwise profitability
        month1=totalMonthProfit(month1,monthlyDrawdown,MaximumDrawdown);
        // Calculate Expectancy
        let Expectancy = 0;
        totalLoss = -1 * totalLoss;
        if (totalLoss == 0) {
            Expectancy = 'Infinity';
        }
        else if (totalProfit == 0) {
            Expectancy = 0;
        }
        else {
            Expectancy = ((profitQuantity * totalProfit) / (totalLoss * cnt)) - lossQuantity / cnt;
        }
        // Method to calculate total profit
        total_profit = parseFloat(week1[0]) + parseFloat(week1[1]) + parseFloat(week1[2]) + parseFloat(week1[3]) + parseFloat(week1[4]);
        if(tradeOnlyFirstEntry) setreEntryResult(reEntryResultAmend);  // trade only first entry change the result format
        setExpectancy(Expectancy);
        setMaxStreak(maxStreak);
        setLossStreak(lossStreak);
        profitQuantity == 0 ? setAvgProfit(0) : setAvgProfit(parseFloat(totalProfit) / parseFloat(profitQuantity));
        lossQuantity == 0 ? setAvgLoss(0) : setAvgLoss(parseFloat(totalLoss) / parseFloat(lossQuantity));
        setCumulative(cumulative);
        setMaximumDrawdown(MaximumDrawdown);
        setDrawdownArray(result);
        data.splice(cnt, csv.length)
        setUniqueElements(expirySet);
        setWeek(week1);
        setMonth(month1);
        setWeekResult(weekResult);
        setTotalProfit(total_profit);
        setWinningTrades(winningtrades)
        setLosingTrades(losingtrades)
        setWinningStreak(winStreakDict)
        setLosingStreak(lossStreakDict)
        setMaximumProfit(maxprofit)
        setMaximumLoss(maxloss)
        setProfitArray(profitArray)
        setDate(barChartDateArray)
        const st_date = new Date(startdate);
        const end_date = new Date(enddate);
        const investmentmonth = (end_date.getFullYear() - st_date.getFullYear()) * 12 + (end_date.getMonth() - st_date.getMonth());
        var timeDiff = end_date.getTime() - st_date.getTime();
        var weeksDiff = Math.floor(timeDiff / (1000 * 3600 * 24 * 7));
        setInvestmentDay(investmentDay);
        setInvestmentMonth(investmentmonth);
        setNetProfit(net_profit);
        setData(data);  //change csv to data;
        setVisibilityReEntry(true);
        // check again at every point
        let current_cnt=daysFromToday(drawDownDate);
        if(ans.count==0 || ans.count<=current_cnt){
            ans.count=daysFromToday(drawDownDate);
            ans.startDate=formatDate(drawDownDate);
            ans.endDate='Running';
            ans.recovery=recoveryStartDate==''?0:daysFromToday(recoveryStartDate);
            ans.continue=true;
            ans.status='Running'
        }
        setMddDay(ans);
        setMetrices({"vix":vix,"gap":gap,"dayOpen":dayOpen})
        setIndividualLegContribution(individualLeg);
    }

    // Method to find the Result on the Basis of Expiry
    const GroupByExpiry = () => {
        const data = [...uniqueElements]; // Assuming uniqueElements is defined somewhere
        const result = [];
        let temp = {};
        let expirey_profit = {}
        let total_expirey_profit = {}
        for (let k = 0; k < csv.length; k++) {
            let value = Next_Thursday(csv[k][0])
            temp[value] = temp[value] || [];
            temp[value].push(csv[k]);
            expirey_profit[value] = expirey_profit[value] || []
            total_expirey_profit[value] = total_expirey_profit[value] || 0
            let sum = 0;
            legarray.map((value, key) => {
                return (
                    sum += (parseFloat(csv[k][(12 + 3 * legs + key)]) != 99999999999 && parseFloat(csv[k][(12 + 4 * legs + key)]) != 99999999999) ? (parseFloat(value[0] == 'S' ? -1 * ((parseFloat(parseFloat(csv[k][(12 + 4 * legs + key)])+props.slippage*parseFloat(csv[k][(12 + 4 * legs + key)])*0.01) - parseFloat(parseFloat(csv[k][(12 + 3 * legs + key)])-props.slippage*parseFloat(csv[k][(12 + 3 * legs + key)])*0.01)) * lotarray[key] * LotQuantity).toFixed(2) : ((parseFloat(parseFloat(csv[k][(12 + 4 * legs + key)])+props.slippage*parseFloat(csv[k][(12 + 4 * legs + key)])*0.01) - parseFloat(parseFloat(csv[k][(12 + 3 * legs + key)])+props.slippage*parseFloat(csv[k][(12 + 3 * legs + key)])*0.01)) * lotarray[key] * LotQuantity).toFixed(2))) : 0)
            })
            expirey_profit[value].push(sum)
            total_expirey_profit[value] += sum;
        }
        result.push(temp);
        setTotalExpireyProfit(total_expirey_profit);
        setExpireyProfit(expirey_profit)
        setExpiryResult(result)
        return result;
    }
    // Method to Find the ReEntry Result
    const ReEntry_Result = () => {
        let ReEntryResult = props.re_entry_result;
        let n = ReEntryResult.length;
        let index = 0;
        let total_leg = inputData['leg'];

        let reEntry_profit = new Array(parseInt(props.csv.length)).fill(0);
        let reEntry_reason = new Array(total_leg).fill(0);
        let res = new Array(total_leg).fill(0);
        let week1={}
        let month1={}
        let isNA={}
        ReEntryResult.sort();
        for (let i = 0; i < ReEntryResult.length; i++) {
            if (ReEntryResult[i][3] > res[ReEntryResult[i][1]])
                res[ReEntryResult[i][1]] = ReEntryResult[i][3];
        }
        let result = [];
        let temp = [];
        const reEntryProfitLeg = new Array(props.csv.length).fill(0).map(() => new Array(total_leg).fill(0));
        // console.log(reEntryProfitLeg,'reEntry Profit Leg')
        for (let i = 0; i < total_leg; i++) {
            let tempArray = [];
            if(inputData.legsReEntry[i]){
            for (let x = 0; x <= inputData.reEntryValue[i]; x++) {
                tempArray.push([ReEntryResult[0][0], 0, 0, 0, 0, 0, 0, 0, 0]);
            }
            }else{
                tempArray.push([ReEntryResult[0][0], 0, 0, 0, 0, 0, 0, 0, 0]);
            }
            temp.push(tempArray);
        }

        temp[ReEntryResult[0][1]][ReEntryResult[0][3]] = ReEntryResult[0];
        let key=ReEntryResult[0][1]
        // for buy  case
        let normal =  -1*parseFloat((parseFloat(ReEntryResult[0][4])+props.slippage*parseFloat(ReEntryResult[0][4])*0.01 - (parseFloat(ReEntryResult[0][5])-props.slippage*(ReEntryResult[0][5])*0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
        // for sell case
        let normal1 =  parseFloat((parseFloat(ReEntryResult[0][4])-props.slippage*parseFloat(ReEntryResult[0][4])*0.01 - (parseFloat(ReEntryResult[0][5])+props.slippage*(ReEntryResult[0][5])*0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
        reEntry_profit[index]=0
        if(ReEntryResult[0][4]==99999999999 || ReEntryResult[0][5]==99999999999){
            reEntryProfit[index]=parseFloat(reEntry_profit[index])
            ReEntryResult[0][4]=99999999999;
            ReEntryResult[0][5]=99999999999;
            if(!isNA.hasOwnProperty(ReEntryResult[0][0])){
                isNA[ReEntryResult[0][0]]=1;
            } 
        }
        else
            reEntry_profit[index] = parseFloat(reEntry_profit[index]) + (ReEntryResult[0][4] != 99999999999 && ReEntryResult[0][5] != 99999999999) && legarray[ReEntryResult[0][1]][0]=='S' ? normal1 : normal;
        
        reEntryProfitLeg[index][ReEntryResult[0][1]]+=parseFloat(reEntry_profit[index]);
            // reEntry_profit[index] =((ReEntryResult[0][4]+props.slippage*ReEntryResult[0][4]*0.01 - ReEntryResult[0][5]-props.slippage*ReEntryResult[0][5]*0.01) * LotQuantity) ;
        if(ReEntryResult[0][6]!='stoploss' && ReEntryResult[0][6]!='maxprofit')
            reEntry_reason[index]=ReEntryResult[0][6]
        for (let i = 1; i < n; i++) {  
            if (ReEntryResult[i - 1][0] === ReEntryResult[i][0]) {
                temp[ReEntryResult[i][1]][ReEntryResult[i][3]] = ReEntryResult[i];
                if (JSON.stringify(ReEntryResult[i]) != JSON.stringify(ReEntryResult[i - 1])) {
                    // Method to calculate profit at reentry
                    let key=ReEntryResult[i][1]
                    let normal =  parseFloat(((parseFloat(ReEntryResult[i][4])+props.slippage*parseFloat(ReEntryResult[i][4])*0.01) - (parseFloat(ReEntryResult[i][5])-props.slippage*(ReEntryResult[i][5])*0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
                    let normal1 =  parseFloat(((parseFloat(ReEntryResult[i][4])-props.slippage*parseFloat(ReEntryResult[i][4])*0.01) - (parseFloat(ReEntryResult[i][5])+props.slippage*(ReEntryResult[i][5])*0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)

                    let sum = (ReEntryResult[i][4] != 99999999999 && ReEntryResult[i][5] != 99999999999) && legarray[ReEntryResult[i][1]][0]=='S'?normal1:-1*normal;
                    // reEntry_profit[index] += ((ReEntryResult[i][4] - ReEntryResult[i][5]) * LotQuantity);
                    if(ReEntryResult[i][4]==99999999999 || ReEntryResult[i][5]==99999999999){
                        sum=0
                        ReEntryResult[i][4]=99999999999
                        ReEntryResult[i][5]=99999999999
                        if(!isNA.hasOwnProperty(ReEntryResult[i][0])){
                            isNA[ReEntryResult[i][0]]=1
                        }  
                    }
                    reEntry_profit[index] = parseFloat(reEntry_profit[index]) + parseFloat(sum);
                    reEntryProfitLeg[index][parseInt(ReEntryResult[i][1])]+=parseFloat(sum)
                    if(ReEntryResult[i][6]!='stoploss' && ReEntryResult[i][6]!='maxprofit')
                        reEntry_reason[index]=ReEntryResult[i][6]
                    // month and week result
                    if (month1.hasOwnProperty(ReEntryResult[i][0][0] + ReEntryResult[i][0][1] + ReEntryResult[i][0][2] + ReEntryResult[i][0][3])) {
                        month1[ReEntryResult[i][0][0] + ReEntryResult[i][0][1] + ReEntryResult[i][0][2] + ReEntryResult[i][0][3]][parseInt(ReEntryResult[i][0][5] + ReEntryResult[i][0][6])] += parseFloat(sum)
                    } else {
                        month1[ReEntryResult[i][0][0] + ReEntryResult[i][0][1] + ReEntryResult[i][0][2] + ReEntryResult[i][0][3]] = new Array(13).fill(0);
                        month1[ReEntryResult[i][0][0] + ReEntryResult[i][0][1] + ReEntryResult[i][0][2] + ReEntryResult[i][0][3]][parseInt(ReEntryResult[i][0][5] + ReEntryResult[i][0][6])] += parseFloat(sum);
                    }
                }
            } else {
                result.push(temp);
                index += 1;
                reEntry_profit[index] = 0;
                temp = [];
                for (let i = 0; i < total_leg; i++) {
                    let tempArray = [];
                    if(inputData.legsReEntry[i]){
                        for (let x = 0; x <= inputData.reEntryValue[i]; x++) {
                            tempArray.push([ReEntryResult[i][0], 0, 0, 0, 0, 0, 0, 0, 0]);
                        }
                    }else{
                        tempArray.push([ReEntryResult[i][0], 0, 0, 0, 0, 0, 0, 0, 0]);
                    }
                    temp.push(tempArray);
                }
                temp[ReEntryResult[i][1]][parseInt(ReEntryResult[i][3])] = ReEntryResult[i];
                let key=ReEntryResult[i][1]
                
                let normal =  parseFloat(((parseFloat(ReEntryResult[i][4])+props.slippage*parseFloat(ReEntryResult[i][4])*0.01) - (parseFloat(ReEntryResult[i][5])-props.slippage*(ReEntryResult[i][5])*0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)
                let normal1 =  parseFloat(((parseFloat(ReEntryResult[i][4])-props.slippage*parseFloat(ReEntryResult[i][4])*0.01) - (parseFloat(ReEntryResult[i][5])+props.slippage*(ReEntryResult[i][5])*0.01)) * parseFloat(LotQuantity) * parseFloat(lotarray[key])).toFixed(2)

                let sum= (ReEntryResult[i][4] != 99999999999 && ReEntryResult[i][5] != 99999999999) && legarray[ReEntryResult[i][1]][0]=='S' ?normal1:-1*normal;   
                if(ReEntryResult[i][4]==99999999999 || ReEntryResult[i][5]==99999999999){
                    sum=0
                    ReEntryResult[i][4]=99999999999
                    ReEntryResult[i][5]=99999999999
                    if(!isNA.hasOwnProperty(ReEntryResult[i][0])){
                        isNA[ReEntryResult[i][0]]=1
                    } 
                }
                reEntry_profit[index] = parseFloat(reEntry_profit[index]) + parseFloat(sum);
                reEntryProfitLeg[index][parseInt(ReEntryResult[i][1])]+=parseFloat(sum)
                if(ReEntryResult[i][6]!='stoploss' && ReEntryResult[i][6]!='maxprofit')
                    reEntry_reason[index]=ReEntryResult[i][6]
                // weekly and monthy result
                // Method to find the MontlyWise Profit
                if (month1.hasOwnProperty(ReEntryResult[i][0][0] + ReEntryResult[i][0][1] + ReEntryResult[i][0][2] + ReEntryResult[i][0][3])) {
                    month1[ReEntryResult[i][0][0] + ReEntryResult[i][0][1] + ReEntryResult[i][0][2] + ReEntryResult[i][0][3]][parseInt(ReEntryResult[i][0][5] + ReEntryResult[i][0][6])] += parseFloat(sum);
                } else {
                    month1[ReEntryResult[i][0][0] + ReEntryResult[i][0][1] + ReEntryResult[i][0][2] + ReEntryResult[i][0][3]] = new Array(13).fill(0);
                    month1[ReEntryResult[i][0][0] + ReEntryResult[i][0][1] + ReEntryResult[i][0][2] + ReEntryResult[i][0][3]][parseInt(ReEntryResult[i][0][5] + ReEntryResult[i][0][6])] += parseFloat(sum);
                }

            }
            }
        if (temp.length > 0)
            result.push(temp);
        for(let i=0;i<reEntry_profit.length && i<props.csv.length;i++)
        {
            if(i<csv.length && csv[i] && csv[i][csv[i].length-4] && csv[i][csv[i].length-4]=='strategy maxprofit hit!')
                reEntry_profit[i]=parseFloat(props.strategyProfit);
            else if(i<csv.length && csv[i] && csv[i][csv[i].length-4] && csv[i][csv[i].length-4]=='strategy stoploss hit!')
                reEntry_profit[i]=-1*parseFloat(props.strategyStoploss);
            else if(i<csv.length && csv[i] && csv[i][csv[i].length-4] === 'Lock and Trail Profit Hit!' || csv[i][csv[i].length-4] === 'Trail Hit!' || csv[i][csv[i].length-4] === 'Lock Minimum Profit Hit!')
            {
                // Dependent on normal result
                if(parseFloat(csv[i][csv[i]?.length-3])){
                    reEntry_profit[i]=parseFloat(csv[i][csv[i]?.length-3])
                }
            }
        }
        // filteration
        let tempResult=[]
        let tempProfit=[]
        if(appearNA){
        for(let i=0;i<result.length;i++){
            if(!isNA.hasOwnProperty(result[i][0][0][0]))
            {
                tempResult.push(result[i]);
                tempProfit.push(reEntry_profit[i]);
            }
        }}
        setIsNA(isNA);
        if(appearNA){
            setreEntryResult(tempResult);
            setReEntryProfit(tempProfit);
        }else{
            setreEntryResult(result);
            setReEntryProfit(reEntry_profit);
        }
        setVisibilityReEntry(true);
        setReEntryProfitLeg(reEntryProfitLeg);
        finalReEntryResult(reEntryProfitLeg);
        // console.log(reEntryProfitLeg,'reEntry Profit Leg');
    }

    // Method to find the ReEntry Result for Excel
    const temporary = () => {
        if (reEntryResult.length == 0)
            return;
        if (reEntryChoice) {
            const temp = {};
            const mainResult = []; // Initialize mainResult array
            const column1 = ['EntryDate', 'Entry Time', 'Exit Day', 'Expiry Date', 'Profit/Loss', 'FuturePrice', 'MaxProfit', 'MaxLoss'];

            let temparray = []
            legarray && legarray.map((item1, key) => {
                reEntryResult[0] && reEntryResult[0][key] && reEntryResult[0][key].map((item, key1) => {
                    return (
                        temparray.push(`Re${key1} Leg${key + 1} ${lotarray[key]}Lot ${atm[key]}`)
                    )
                })
            })
            let column2 = temparray

            const mergedRes = [...column1, ...column2];
            mainResult.push(mergedRes);

            csv && csv.forEach((hello, index) => {
                const temparray = [];
                legarray && legarray.forEach((item1, key) => {
                    reEntryResult && reEntryResult[index] && reEntryResult[index][key] && reEntryResult[index][key].forEach((item2, key1) => {
                        let sum = legarray[key][0] === 'B' ? (parseFloat(parseFloat(item2[4])+props.slippage*parseFloat(item2[4])*0.01).toFixed(2) - parseFloat(parseFloat(item2[5])-props.slippage*parseFloat(item2[5])*0.01).toFixed(2)) : ((parseFloat(item2[5])+props.slippage*parseFloat(item2[5])*0.01).toFixed(2) - parseFloat(parseFloat(item2[4])-props.slippage*parseFloat(item2[4])*0.01 ).toFixed(2));

                        if (legarray[key][0] === 'B') {
                            temparray.push(
                                `${parseFloat(parseFloat(item2[4])+props.slippage*parseFloat(item2[4])*0.01).toFixed(2)} - ${parseFloat(parseFloat(item2[5])-props.slippage*parseFloat(item2[5])*0.01).toFixed(2)} = ${(sum * lotarray[key] * LotQuantity).toFixed(2)}   (${item2[2]})`
                            );
                        } else {
                            temparray.push(
                                `${parseFloat(parseFloat(item2[5])+props.slippage*parseFloat(item2[5])*0.01).toFixed(2)} - ${(parseFloat(item2[4])-props.slippage*parseFloat(item2[4])*0.01).toFixed(2)} = ${(sum * lotarray[key] * LotQuantity).toFixed(2)}   (${item2[2]})`
                            );
                        }
                    });
                });
                const newRow = [
                    hello[0],
                    hello[1],
                    hello[2],
                    hello[3],
                    hello[6],
                    parseFloat(hello[hello.length - 3]).toFixed(2),
                    `${parseFloat(parseFloat(hello[4])+props.slippage*parseFloat(hello[4])*0.01).toFixed(2)}-${parseFloat(parseFloat(hello[5])-props.slippage*parseFloat(hello[5])*0.01).toFixed(2)}=${parseFloat(parseFloat(hello[4])+props.slippage*parseFloat(hello[4])*0.01 - parseFloat(hello[5])-props.slippage*parseFloat(hello[5])*0.01).toFixed(2)}`,
                    parseFloat(hello[hello.length - 2]).toFixed(2),
                    parseFloat(hello[hello.length - 1]).toFixed(2),
                    hello[hello.length - 4],
                ];
                temp[index] = temparray;
                const mergedResult = [...newRow, ...temparray];
                mainResult.push(mergedResult);
            });
            setExcelRentryResult(mainResult);
            finalReEntryResult(reEntryProfitLeg);
        }
    }
    //Method to find the Excel Result    
    const ExcelResult = () => {
        const mainResult = []; // Initialize mainResult array
        const column1 = ['EntryDate', 'Entry Time', 'Exit Time', 'Day', 'Expiry', 'Profit/Loss', 'LotSize','IndiaVix', 'Spot Price','Future Price','Gap Up/Down','Prev Day H/L','MaxProfit', 'MaxLoss','Exit Reason'];

        let column2 = legarray.map((item, key) => {
            return (`Leg${key + 1} ${item} ${lotarray[key]}Lot ${atm[key]}`);
        });

        const mergedRes = [...column1, ...column2];
        mainResult.push(mergedRes);
        data.map((item, index) => {
            const legData = legarray.map((value, key) => {
                if (item[(12 + 4 * legs + key)] && item[(12 + 3 * legs + key)]) {
                    item[(12 + 4 * legs + key)] = parseFloat(item[(12 + 4 * legs + key)]);
                    item[(12 + 3 * legs + key)] = parseFloat(item[(12 + 3 * legs + key)]);
                    let sum = legarray[key][0] === 'B' ? ((item[(12 + 4 * legs + key)]+props.slippage*item[(12 + 4 * legs + key)]*0.01).toFixed(2) - (item[(12 + 3 * legs + key)]-props.slippage*item[(12 + 3 * legs + key)]*0.01).toFixed(2)) : ((item[(12 + 3 * legs + key)]+props.slippage*item[(12 + 3 * legs + key)]*0.01).toFixed(2) - (item[(12 + 4 * legs + key)]-props.slippage*item[(12 + 4 * legs + key)]*0.01).toFixed(2));
                    if (legarray[key][0] === 'B') {
                        return (
                            `${(item[(12 + 4 * legs + key)]+props.slippage*item[(12 + 4 * legs + key)]*0.01).toFixed(2)} - ${(item[(12 + 3 * legs + key)]-props.slippage*item[(12 + 3 * legs + key)]*0.01).toFixed(2)}=${(sum * lotarray[key] * LotQuantity).toFixed(2)}  `
                        );
                    } else {
                        return (
                            `${(item[(12 + 3 * legs + key)]+props.slippage*item[(12 + 3 * legs + key)]*0.01)} - ${(item[(12 + 4 * legs + key)]-props.slippage*item[(12 + 4 * legs + key)]*0.01).toFixed(2)}=${(sum * lotarray[key] * LotQuantity).toFixed(2)}  `
                        );
                    }
                }
            });
            const newRow = [
                item[0],
                item[1],
                item[2],
                item[3],
                item[12],
                parseFloat(item[item.length - 3]).toFixed(2),
                LotQuantity,
                `${parseFloat(item[8]).toFixed(2)}-${parseFloat(item[9]).toFixed(2)}=${parseFloat(item[8]-item[9]).toFixed(0)}`,
                `${parseFloat(item[6]).toFixed(2)}-${parseFloat(item[7]).toFixed(2)}=${parseFloat(item[6]-item[7]).toFixed(0)}`,
                `${parseFloat(item[4]).toFixed(2)}-${parseFloat(item[5]).toFixed(2)}=${parseFloat(item[4]-item[5]).toFixed(0)}`,
                parseFloat(item[11].Open-item[10].Close).toFixed(2),
                item[item.length - 5]=='High'?'Day Open > PDH':item[item.length - 5]=='Low'?'Day Open < PDL':'--',
                parseFloat(item[item.length - 2]).toFixed(0),
                parseFloat(item[item.length - 1]).toFixed(0),
                item[item.length-4]
            ];

            const mergedResult = [...newRow, ...legData];
            mainResult.push(mergedResult);
        });

        const statsResult = ['-----', totalProfit, maximumDrawdown, maxProfit, maxLoss, winningTrades, losingTrades, avgProfit.toFixed(2), avgLoss.toFixed(2), investmentDay, parseInt(expectancy).toFixed(2), maxStreak, lossStreak];

        setStatisticalResult(statsResult);
        setMainResult(mainResult);
    }

    const handleTrade = () => {
        setData(csv);
    }

    useEffect(() => {
        const array = sortArrayOfArrays();
        GroupByExpiry();
        setData(array);
    }, [filter])
    useEffect(() => {
        handleTrade();
    }, [indexNumber, pageSize])
    useEffect(() => {
        if (!reEntryChoice){
            result()
        }else{
        ReEntry_Result();
        }
    }, [premiumPrice,restartAgain, dayoption, props.resultCount, appearNA,resultStartDate,resultEndDate,resultFilter,includeTrade,tradeOnlyFirstEntry])
    useEffect(() => {
        ExcelResult();
    }, [props.csv, JSON.stringify(StatisticalResult)])
    useEffect(() => {
        temporary();
    }, [JSON.stringify(excelRentryResult), reEntryResult])

    useEffect(()=>{
    setResultStartDate(inputData.startdate);
    setResultEndDate(inputData.enddate);
    },[])

    return (
        <>
            {/* Box of Main Answer of Every Value */}
            <hr className="bg-white h-2 m-5"></hr>
            <hr className="bg-white h-2 m-5"></hr>
            {/* Actual Result in Description Format */}
            {visibilityReEntry &&
                <div id="screenshot">
                    <div className="flex md:flex-row flex-col gap-1">
                        <div className="flex flex-col  gap-2 bg-white border-4 rounded-lg border-blue-500 ml-2">
                            <div className="flex text-center justify-center text-xl font-semibold h-10 text-white bg-[#718EBF] rounded-t-md ml-1 mr-1">Metrices</div>

                            {/* START HIDE NA */}
                            <div className="flex text-center justify-center">
                                <div className="flex flex-row gap-2">
                                    <input type="checkbox" checked={appearNA} onChange={()=>{setAppearNA(!appearNA);}}/>
                                    <div className="font-semibold text-l">Hide NA Rows</div>
                                </div>
                            </div>
                            { inputData.waitTrade && <div className="flex text-center justify-center">
                                <div className="flex flex-row gap-2">
                                    <input type="checkbox" checked={tradeOnlyFirstEntry} onChange={()=>{setTradeOnlyFirstEntry(!tradeOnlyFirstEntry);}}/>
                                    <div className="font-semibold text-l">Trade Only First Entry</div>
                                </div>
                            </div>}
                            {/* END HIDE NA */}
                            
                            <div className=" bg-gradient-to-r from-blue-200 to-blue-500 m-2 rounded-lg p-2">
                                <div className="grid grid-cols-1 m-2 gap-1">
                                    <div className="grid grid-cols-2 gap-[1px]">
                                    <div className="text-l mt-1 text-black text-left">Slippages</div>
                                    <div className="flex flex-row gap-1">
                                    <input type="number" className="border-2 rounded text-l h-7 w-36 text-center text-black" value={slippageValue} onChange={(e) => { setSlippageValue(e.target.value); }}></input>
                                    <div className="bg-blue-400 text-white w-12 rounded-lg w flex text-center justify-center hover:bg-blue-600"><span className="cursor-pointer text-white" onClick={()=>{props.setSlippage(slippageValue);setRestartAgain(restartAgain+1)}}><RestartAltIcon /></span></div>
                                    </div>
                                    </div>
                                    <div className="grid grid-cols-2">
                                    <div className="text-l mt-1 text-black text-left">Grouping</div>
                                    <select value={filter} className="h-7 border-2 rounded border-solid w-36"
                                        onChange={(e) => { setFilter(e.target.value) }}>
                                        <option className="text-black" value="Expiry" >Group by Expiry</option>
                                        <option className="text-black" value="Date" > Group By Date </option>
                                        <option className="text-black" value="Day">Group By Day</option>
                                    </select>
                                    </div>
                                     {/* ------------------Vix---------------- */}
                                    <div className="grid grid-cols-2">
                                        <div className="">vix (1-99)</div>
                                        <div className="flex flex-row gap-1">
                                        <input type="number" value={vixStart} onChange={(e)=>{setVixStart(parseFloat(e.target.value))}} className="w-20 text-center border-[1px] rounded-lg"/>
                                        <div className="">to</div>
                                        <input type="number" value={vixExit} onChange={(e)=>{setVixExit(parseFloat(e.target.value))}}  className="w-20 text-center border-[1px] rounded-lg"/>
                                        <div className="bg-blue-400 text-white w-12 rounded-lg w flex text-center justify-center hover:bg-blue-600"><span className="cursor-pointer text-white" onClick={()=>{props.setSlippage(slippageValue);setRestartAgain(restartAgain+1)}}><RestartAltIcon /></span></div>
                                        </div>
                                    </div>
                                    {/* --------------Vix---------------- */} 
                                </div>
                            </div>
                            <div className="flex flex-col bg-gradient-to-r from-blue-200 to-blue-500 m-2 rounded-xl p-2">
                                <div className="flex flex-row m-2 gap-2">
                                    <div className="flex flex-row rounded-2xl border-[1px]">
                                        <div className="bg-blue-500 text-white w-10 text-center font-serif rounded-l-2xl">St</div>
                                        <input type="date" className="outline-0 flex text-center justify-center rounded-r-lg" value={resultStartDate} onChange={(e)=>{setResultStartDate(e.target.value)}}/>
                                    </div>
                                    <div className="flex flex-row rounded-2xl border-[1px]">
                                        <div className="bg-blue-500 text-white w-10 text-center font-serif rounded-l-2xl">En</div>
                                        <input type="date" className="outline-0 flex text-center justify-center rounded-r-lg" value={resultEndDate} onChange={(e)=>{setResultEndDate(e.target.value);}}/>
                                    </div>
                                    <div className="bg-blue-400 text-white w-12 rounded-lg w flex text-center justify-center hover:bg-blue-600"><span className="cursor-pointer text-white" onClick={()=>{setResultStartDate(inputData.startdate);setResultEndDate(inputData.enddate);}}><RestartAltIcon /></span></div>

                                </div>
                                {/* Download Result in the form of PDF or EXCEl. */}
                                <div className="grid grid-cols-2 ml-2 gap-1">
                                    <button className="bg-green-500 hover:bg-green-600 flex flex-row gap-2 text-center justify-center text-white p-1 rounded text-[16px]" onClick={props.handleDownloadPDF}> Download PDF <FaDownload className="mt-1"/></button>
                                    {reEntryChoice && <Excel vixStart={vixStart} vixEnd={vixExit} slippage={slippageValue} b={excelRentryResult} week={weekResult} month={month} e={reEntryProfit} f={inputData} strategyName={props.strategyName} StatisticalResult={StatisticalResult} name={props.name} />}
                                    {!reEntryChoice && <Excel vixStart={vixStart} vixEnd={vixExit} slippage={slippageValue} b={mainResult} week={weekResult} month={month} e={profitArray} f={inputData} strategyName={props.strategyName} StatisticalResult={StatisticalResult} name={props.name} />}
                                </div>
                                {/* ---------------------Day----------- */}
                                {/* Profit Week Day Wise */}
                                <div className="flex text-center justify-start pl-2 flex-grow-0">
                                    <div className="text-[16px] grid grid-cols-6 gap-1 mt-2">
                                        <button className=" text-white rounded border-[1px] p-1" 
                                            style={{
                                                backgroundColor:Variant[0] == "contained" ? "#2D5BFF" : "white",
                                                color:Variant[0] == "contained" ? "white" : "black"
                                            }}
                                            onClick={() => {
                                            const inputdata = [...Variant]; inputdata[0] = Variant[0] == "contained" ? "outlined" : "contained";
                                            setVariant(inputdata); const data = [...dayoption]; data[0] = 1 - data[0]; setdayoption(data)
                                        }}>Mon</button>
                                        <button className=" text-white rounded border-[1px] p-1"
                                         style={{
                                            backgroundColor:Variant[1] == "contained" ? "#2D5BFF" : "white",
                                            color:Variant[1] == "contained" ? "white" : "black"
                                        }}
                                         onClick={() => {
                                            const inputdata = [...Variant]; inputdata[1] = Variant[1] == "contained" ? "outlined" : "contained";
                                            setVariant(inputdata); const data = [...dayoption]; data[1] = 1 - data[1]; setdayoption(data)
                                        }}>Tue</button>
                                        <button className=" text-white rounded border-[1px] p-1"
                                           style={{
                                            backgroundColor:Variant[2] == "contained" ? "#2D5BFF" : "white",
                                            color:Variant[2] == "contained" ? "white" : "black"
                                            }}
                                            onClick={() => {
                                            const inputdata = [...Variant]; inputdata[2] = Variant[2] == "contained" ? "outlined" : "contained";
                                            setVariant(inputdata); const data = [...dayoption]; data[2] = 1 - data[2]; setdayoption(data);
                                        }}>Wed</button>
                                        <button className=" text-white rounded border-[1px] p-1"
                                        style={{
                                            backgroundColor:Variant[3] == "contained" ? "#2D5BFF" : "white",
                                            color:Variant[3] == "contained" ? "white" : "black"
                                        }}
                                        onClick={() => {
                                            const inputdata = [...Variant]; inputdata[3] = Variant[3] == "contained" ? "outlined" : "contained";
                                            setVariant(inputdata); const data = [...dayoption]; data[3] = 1 - data[3]; setdayoption(data);
                                        }}>Thu</button>
                                        <button className=" text-white rounded border-[1px] p-1"
                                        style={{
                                            backgroundColor:Variant[4] == "contained" ? "#2D5BFF" : "white",
                                            color:Variant[4] == "contained" ? "white" : "black"
                                        }}
                                        onClick={() => {
                                            const inputdata = [...Variant]; inputdata[4] = Variant[4] == "contained" ? "outlined" : "contained";
                                            setVariant(inputdata); const data = [...dayoption]; data[4] = 1 - data[4]; setdayoption(data);
                                        }}>Fri</button>
                                    </div>
                                </div>
                            </div>
                            {/* Add more filter */}
                            <div className="flex flex-col bg-gray-100 p-2 flex-grow m-2 rounded-lg gap-1">
                                {
                                    tempFilter.map((item,key)=>{
                                        return(
                                            <div className="flex flex-row gap-1 h-6 mt-1">
                                                <select className="border-[1px] rounded-lg border-blue-500" value={item?.type} onChange={(e)=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].type=e.target.value;inputdata[key].sign1=inputdata[key].type=='PrevDayHL'?'High':'>=';return inputdata;})}}>
                                                    <option value="GapUpDown">Gap Up/Down</option>
                                                    <option value="PrevDayHL">Prev Day H/L</option>
                                                </select>
                                                {item.type=="GapUpDown" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign1} onChange={(e)=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].sign1=e.target.value;return inputdata;})}}>
                                                    <option value=">=">{`>=`}</option>
                                                    <option value="<=">{`<=`}</option>
                                                </select>
                                                }
                                                {
                                                    item.type=="PrevDayHL" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign1} onChange={(e)=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].sign1=e.target.value;return inputdata;})}}>
                                                    <option value="High">Day Open {`>=`} PDH</option>
                                                    <option value="Low">Day Open {`<=`} PDL</option>
                                                    <option value="">PDH {`>=`} Day Open {`>=`} PDL</option>
                                                   </select>
                                                }
                                                {item.type=="GapUpDown" &&<div className="flex flex-row gap-1"><input type="number" placeholder="" className="border-[1px] rounded-lg border-blue-500 w-16 text-center" value={item.value1} onChange={(e)=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].value1=parseFloat(e.target.value); return inputdata;})}}/><div className="bg-blue-500 text-center w-10 rounded text-white">%</div></div>}
                                                {item.or && <Delete onClick={()=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].or=false;return inputdata;})}}/>}
                                                {!item.or && <div className="bg-blue-500 text-white w-16 rounded text-center cursor-pointer" onClick={()=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].or=true;return inputdata;})}}>+OR</div>}
                                                {item.or && <div className="">OR</div>}
                                                {item.or && item.type=="GapUpDown" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign2} onChange={(e)=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].sign2=e.target.value;return inputdata;})}}>
                                                    <option value=">=">{`>=`}</option>
                                                    <option value="<=">{`<=`}</option>
                                                </select>}
                                                {item.or && item.type=="PrevDayHL" && <select className="border-[1px] rounded-lg border-blue-500" value={item?.sign2} onChange={(e)=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].sign2=e.target.value;return inputdata;})}}>
                                                    <option value="High">Day Open {`>=`} PDH</option>
                                                    <option value="Low">Day Open {`<=`} PDL</option>
                                                    <option value="">PDH {`>=`} Day Open {`>=`} PDL</option>
                                                </select>}

                                                {item.or && item.type=="GapUpDown" && <div className="flex flex-row gap-1"><input type="number" placeholder="" className="border-[1px] rounded-lg border-blue-500 w-16 text-center" value={item.value2} onChange={(e)=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].value2=parseFloat(e.target.value); return inputdata;})}}/><div className="bg-blue-500 text-center w-10 rounded text-white">%</div></div>}
                                                {item.or && <Delete onClick={()=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata[key].or=false;return inputdata;})}}/>}
                                                <div className="bg-blue-500 text-white w-32 rounded text-center cursor-pointer" onClick={()=>{setTempFilter((prevResult)=>{const inputdata=[...prevResult];inputdata.splice(key,1);inputdata.length==0 && setResultFilter([]); return inputdata;})}}>Remove</div>
                                            </div>
                                            )
                                        })
                                    }
                                <div className="cursor-pointer pl-5 text-orange-700 w-36" onClick={()=>{setTempFilter([...tempFilter,{"type":"GapUpDown","sign1":">=","sign1":">=","value1":0,"value2":0,"or":false}]);}}><u>Add More Filter</u></div>
                                {tempFilter.length>0 && <div className="bg-blue-500 text-white text-center w-32 ml-5 rounded-lg cursor-pointer" onClick={()=>{setResultFilter(tempFilter);}}>Apply</div>}
                            </div>
                        </div>
                        
                        {/* Addd More Filter */}
                    <div className="flex flex-grow">
                        <Main lossStreak={lossStreak} maxStreak={maxStreak} expectancy={expectancy} totalProfit={totalProfit} investmentMonth={investmentMonth} investmentDay={investmentDay}
                        avgLoss={avgLoss} avgProfit={avgProfit} losingTrades={losingTrades} winningTrades={winningTrades} maxLoss={maxLoss} maxProfit={maxProfit} maximumDrawdown={maximumDrawdown} margin={props.margin} winningStreak={winningStreak} losingStreak={losingStreak} mdd={mddDay} metrics={metrics}/>
                    </div>
                    </div>
                    <div className="flex text-center justify-center gap-2"><input type="checkbox" checked={showROI} onChange={()=>{setShowROI(!showROI)}}/>Show ROI Percent</div>
                    <WeekProfit weekResult={weekResult} margin={props.margin} showROI={showROI}/>
                    <MonthlyProfit month={month} totalProfit={totalProfit} maximumDrawdown={maximumDrawdown} margin={props.margin} showROI={showROI}/>
                    <IndividualLegContribution totalProfit={totalProfit}  individualLegContribution={individualLegContribution}/>
                    {/* Graph make */}  
                    <GraphResult profit={netProfit} cumulative={cumulative} date={date} drawdown={drawdownArray} StatisticalResult={StatisticalResult} />
                    {/* <GraphChart profit={netProfit} cumulative={cumulative} date={date} drawdown={drawdownArray} StatisticalResult={StatisticalResult} startDate={inputData.startdate}/> */}
                    {/* Group By Expiry */}

                    {/* <LegProfit includeTrade={includeTrade} setIncludeTrade={setIncludeTrade} slippage={props.slippage} setSlippage={props.setSlippage} legs={legs} filter={filter} ExpiryResult={ExpiryResult} legarray={legarray} reEntryChoice={reEntryChoice} lotarray={lotarray} atm={atm} netProfit={netProfit} pageSize={pageSize} expireyProfit={expireyProfit} reEntryResult={reEntryResult}
                        setPageSize={setPageSize} NumberBox={NumberBox} activeColor={activeColor} setActiveColor={setActiveColor} setindexNumber={setindexNumber} sorting={sorting} data={data} totalExpireyProfit={totalExpireyProfit} reEntryProfit={reEntryProfit} LotQuantity={LotQuantity} inputData={inputData}
                        appearNA={appearNA} setAppearNA={setAppearNA} legsStoploss={props.legsStoploss} legsStoplossType={props.legsStoplossType} legsTargetProfit={props.legsTargetProfit} legsTargetProfitType={props.legsTargetProfitType} indexNumber={indexNumber}  individualLegContribution={individualLegContribution}/> */}
                    <LegProfit1 includeTrade={includeTrade} setIncludeTrade={setIncludeTrade} slippage={props.slippage} setSlippage={props.setSlippage} legs={legs} filter={filter} ExpiryResult={ExpiryResult} legarray={legarray} reEntryChoice={reEntryChoice} lotarray={lotarray} atm={atm} netProfit={netProfit} pageSize={pageSize} expireyProfit={expireyProfit} reEntryResult={reEntryResult}
                        setPageSize={setPageSize} NumberBox={NumberBox} activeColor={activeColor} setActiveColor={setActiveColor} setindexNumber={setindexNumber} sorting={sorting} data={data} totalExpireyProfit={totalExpireyProfit} reEntryProfit={reEntryProfit} LotQuantity={LotQuantity} inputData={inputData}
                        appearNA={appearNA} setAppearNA={setAppearNA} legsStoploss={props.legsStoploss} legsStoplossType={props.legsStoplossType} legsTargetProfit={props.legsTargetProfit} legsTargetProfitType={props.legsTargetProfitType} indexNumber={indexNumber}  individualLegContribution={individualLegContribution} totalProfit={totalProfit}/>
                    
                </div>
            }
        </>
    );
};

export default Result;
