import React, { useState } from 'react'
import Graph from '../../Graph/Graph'
import Graph1 from '../../Graph/Graph1'
import BarChart from '../../Graph/BarChart'

const GraphResult = (props) => {
  const cumulative = props.cumulative;
  const profit = props.profit;
  const date = props.date;
  const drawdown = props.drawdown;
  const [activeElement, setActiveElement] = useState(1);

  const handleToggle = (element) => {
    setActiveElement(element);
  };
  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-3 text-center mt-5 ml-2 mr-2 rounded-t-md justify-center mb-0 text-[#718EBF] overflow-auto bg-white">
        <div className="cursor-pointer font-[500] text-xl p-1 text-white" style={{ borderColor: activeElement === 1 ? "blue" : "white",backgroundColor: activeElement === 1 ? "#4BAAC8" : "#718EBF" }} onClick={() => handleToggle(1)}>Profit/Loss</div>
        <div className="cursor-pointer font-[500] text-xl p-1 text-white" style={{ borderColor: activeElement === 2 ? "blue" : "white",backgroundColor: activeElement === 2 ? "#4BAAC8" : "#718EBF" }} onClick={() => handleToggle(2)}>Cumulative Graph</div>
        <div className="cursor-pointer font-[500] text-xl p-1 text-white" style={{ borderColor: activeElement === 3 ? "blue" : "white",backgroundColor: activeElement === 3 ? "#4BAAC8" : "#718EBF"}} onClick={() => handleToggle(3)}>Drawdown Graph</div>
      </div>
      {/* Graph make */}
      {activeElement === 1 && <div className="bg-white shadow rounded-b-md mt-0 m-2 p-4 flex flex-col overflow-auto">
        <BarChart profit={profit} date={date} />
      </div>
      }
      {activeElement === 2 && <div className="bg-white shadow rounded-b-md mt-0 m-2 p-4 flex flex-col overflow-auto">
        <Graph cumulative={cumulative} date={date} graph_name="Cumulative" />
      </div>}
      {activeElement === 3 && <div className="bg-white shadow rounded-b-md mt-0 m-2 p-4 flex flex-col overflow-auto">
        <Graph1 drawdown={drawdown} date={date} graph_name="Drawdown" />
      </div>
      }
    </>
  )
}

export default GraphResult