import React from 'react'
import { FaCalendarWeek } from "react-icons/fa";

const WeekProfit = (props) => {
    return (
        <>
            <div className="flex flex-col m-2 bg-white rounded-md">
            <h3 className='bg-gradient-to-r from-[#718EBF] to-[#4A6DA3] flex text-xl text-center justify-start pl-5 text-white p-1'><FaCalendarWeek className='mt-1 mr-2'/> DAYWISE PROFIT</h3>
            <div className='grid grid-cols-6 border-[1px] border-t-blue-500 border-t-2 bg-[#EFEFEF] pl-4'>
                    <div className="p-2">Day</div>
                    <div className="p-2">Mon</div>
                    <div className="p-2">Tue</div>
                    <div className="p-2">Wed</div>
                    <div className="p-2">Thu</div>
                    <div className="p-2">Fri</div>
                </div>
                {
                    props.week && Object.entries(props.week).map(([key, value]) => (
                        <div className="grid grid-cols-6 border-[1px] text-center" style={{backgroundColor:key=='Total'?"#EFEFEF":""}} key={key}>
                            <div className="p-2 text-black" style={{ width: "80px" }}>{key}</div>
                            <div className="p-2" style={{ color: value[1] >= 0 ? "green" : "red", width: "80px" }}>{parseFloat(value[0]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[0]*100)/props.margin).toFixed(2)}%)`}</div>
                            <div className="p-2" style={{ color: value[1] >= 0 ? "green" : "red", width: "80px" }}>{parseFloat(value[1]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[1]*100)/props.margin).toFixed(2)}%)`}</div>
                            <div className="p-2" style={{ color: value[2] >= 0 ? "green" : "red", width: "80px" }}>{parseFloat(value[2]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[2]*100)/props.margin).toFixed(2)}%)`}</div>
                            <div className="p-2" style={{ color: value[3] >= 0 ? "green" : "red", width: "80px" }}>{parseFloat(value[3]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[3]*100)/props.margin).toFixed(2)}%)`}</div>
                            <div className="p-2" style={{ color: value[4] >= 0 ? "green" : "red", width: "80px" }}>{parseFloat(value[4]).toFixed(0)}<br/>{props.showROI&&`(${parseFloat((value[4]*100)/props.margin).toFixed(2)}%)`}</div>
                        </div>
                ))}
            </div>
        </>
    )
}

export default WeekProfit