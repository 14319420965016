import ellipse from './assets/Ellipse.webp';
import { FaArrowRight } from "react-icons/fa6";

const Backtest = () => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-[#FDCB02] md:h-[314px]">
            {/* Backtest */}
            <div className="flex flex-row gap-4 mt-10 justify-center text-black p-6 rounded-lg text-left transition-shadow duration-300">
                <img src={ellipse} className='h-[171px] w-[133px]'/>
                <div className='w-[383px]'>
                    <h2 className="text-3xl font-bold mb-4">Explore Backtest Now!</h2>
                    <p className="text-2xl mb-4">Dive into our backtest tool and explore your strategies.</p>
                    <div className='w-48 flex flex-row text-lg font-thin text-black bg-[#FFFFFF] rounded-md p-4 hover:bg-blue-400 hover:text-black transition-colors duration-300 gap-2'>
                    <a href="https://fprognos.com/backtest" target="_blank" className="">
                        Start Backtesting 
                    </a>
                    <FaArrowRight className='mt-1 font-thin'/>
                    </div>
                </div>
            </div>
            {/* Trading */}
            <div className="flex flex-row gap-4 mt-10 justify-center text-black p-6 rounded-lg text-left transition-shadow duration-300">
                <img src={ellipse} className='h-[171px] w-[133px]'/>
                <div className='w-[383px]'>
                    <h2 className="text-3xl font-bold mb-4">Explore Trading Platform!</h2>
                    <p className="text-2xl mb-4">Dive into trading platform and Run your strategy.</p>
                    <div className='w-48 flex flex-row text-lg font-thin text-black bg-[#FFFFFF] rounded-md p-4 hover:bg-blue-400 hover:text-black transition-colors duration-300 gap-2'>
                    <a href="https://fprognos.com/" target="_blank" className="">
                        Start Trading
                    </a>
                    <FaArrowRight className='mt-1 font-thin'/>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Backtest