import React from 'react';
import { saveAs } from 'file-saver';
const ExcelJS = require('exceljs');


const Excel = (props) => {

  const generateExcel1 = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet('Daily Result');
  
     // Define your columns
     const columns = [
      'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
      'K', 'L', 'M', 'N', 'O', 'P','Q'
    ];

    // Set width for each column
    columns.forEach(column => {
      worksheet1.getColumn(column).width = 15;
    });
   // Add border to the cell
     const borderStyle = {
      style: 'medium',
      color: { argb: '000000' } // Black color
    };
    // worksheet.getCell('A11').value = '2020';
    // worksheet.getCell('A12').value = 'Total';
    // worksheet.getCell('A9').alignment = { horizontal: 'center' }
    // worksheet.mergeCells('A9:M9');
    // worksheet.getCell('A9').fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: '#2D5BFF' }
    // };
    // worksheet3.getCell('A9').font = {
    //   size: 10, // Set font size to 16 (adjust as needed)
    //   name: 'serif', // Set font family (e.g., Arial)
    //   bold: true, // Set font weight to bold
    //   color: { argb: 'FFFFFF' }
    // };

    // Example: Applying a background color to a cell in worksheet1
    const cellA1 = worksheet1.getCell('A1');
    worksheet1.mergeCells('A1:G1');
    cellA1.value = 'FPROGNOS';
    cellA1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '#2D5BFF' }
    };
    worksheet1.getCell('A1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('A1').alignment = { horizontal: 'center' }
    worksheet1.getCell('A1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};


    const cellA2 = worksheet1.getCell('H1');
    worksheet1.mergeCells('H1:N1');
    cellA2.value = props.name;
    cellA2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('H1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('H1').alignment = { horizontal: 'center' }
    worksheet1.getCell('H1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};



    // Adding data to worksheet1
    const cellD1 = worksheet1.getCell('B3');
    worksheet1.mergeCells('B3:F3');
    cellD1.value = 'POSITION';
    cellD1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('B3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B3').alignment = { horizontal: 'center' }

    worksheet1.getCell('B4').value = 'START TIME';
    worksheet1.mergeCells('B4:C4');
    worksheet1.getCell('B4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D4').value = '09:16';
    worksheet1.mergeCells('D4:E4');
    worksheet1.getCell('D4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B6').value = 'END TIME';
    worksheet1.mergeCells('B6:C6');
    worksheet1.getCell('B6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D6').value = '03:20';
    worksheet1.mergeCells('D6:E6');
    worksheet1.getCell('D6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B5').alignment = { horizontal: 'center' }
    worksheet1.getCell('B5').value = 'START DATE';
    worksheet1.mergeCells('B5:C5');
    worksheet1.getCell('B5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D5').value = '01/03/2020';
    worksheet1.mergeCells('D5:E5');
    worksheet1.getCell('D5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B7').value = 'END DATE';
    worksheet1.mergeCells('B7:C7');
    worksheet1.getCell('B7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D7').value = '01/06/2020';
    worksheet1.mergeCells('D7:E7');
    worksheet1.getCell('D7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};


    const formattingOptions = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '#2D5BFF' }
    };

    const cellres = worksheet1.getCell('H3');
    worksheet1.mergeCells('H3:N3');
    cellres.value = 'STATISTICAL RESULT';
    cellres.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('H3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('H3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H3').alignment = { horizontal: 'center' }
    worksheet1.getCell('H5').value = 'Total Margin';
    worksheet1.mergeCells('H5:I5');
    worksheet1.getCell('H5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H6').value = 'Total Profit';
    worksheet1.mergeCells('H6:I6');
    worksheet1.getCell('H6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H7').value = 'Maximum Drawdown';
    worksheet1.mergeCells('H7:I7');
    worksheet1.getCell('H7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H8').value = 'Montly Max Profit';
    worksheet1.mergeCells('H8:I8');
    worksheet1.getCell('H8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H9').value = 'Monthly Min Profit';
    worksheet1.mergeCells('H9:I9');
    worksheet1.getCell('H9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H10').value = 'Investment Day';
    worksheet1.mergeCells('H10:I10');
    worksheet1.getCell('H10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('J5').value = props.margin;
    worksheet1.mergeCells('J5:K5');
    worksheet1.getCell('J5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J6').value = props.totalProfit;
    worksheet1.mergeCells('J6:K6');
    worksheet1.getCell('J6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J7').value = props.maximumDrawdown;
    worksheet1.mergeCells('J7:K7');
    worksheet1.getCell('J7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J8').value = props.maximumProfit;
    worksheet1.mergeCells('J8:K8');
    worksheet1.getCell('J8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J9').value = props.minimumProfit;
    worksheet1.mergeCells('J9:K9');
    worksheet1.getCell('J9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J10').value = props.investmentDay;
    worksheet1.mergeCells('J10:K10');
    worksheet1.getCell('J10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    // // Define an array of cell addresses where you want to apply the formatting
    const cellAddresses = ['B15', 'C15', 'D15', 'E15', 'F15','G15' ,'H15','I15', 'J15','K15', 'L15','M15', 'N15','O15','P15','Q15'];

    // Apply formatting to the specified cells
    cellAddresses.forEach(address => {
      const cell = worksheet1.getCell(address);
      cell.fill = formattingOptions;
      cell.font = {
        color: { argb: 'FFFFFF' }
      }
    });

    // Trade List Result is showing here...........
    // Adding data to worksheet1
    const trade = worksheet1.getCell('B14');
    worksheet1.mergeCells('B14:Q14');
    trade.value = 'TRADE LIST';
    trade.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('B14').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('B14').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B14').alignment = { horizontal: 'center' }
    
    worksheet1.getCell('B15').value = 'Serial No.';
    worksheet1.getCell('B15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('C15').value = 'Start Time';
    worksheet1.getCell('C15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D15').value = 'Start Date';
    worksheet1.getCell('D15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('E15').value = 'End Time';
    worksheet1.getCell('E15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('F15').value = 'End Date';
    worksheet1.getCell('F15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('G15').value = 'Start Price';
    worksheet1.getCell('G15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H15').value = 'End Price';
    worksheet1.getCell('H15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('I15').value = 'Old_Price';
    worksheet1.getCell('I15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J15').value = 'RollOverPrice';
    worksheet1.getCell('J15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('K15').value = 'Profit';
    worksheet1.getCell('K15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('L15').value = 'RollOver';
    worksheet1.getCell('L15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('M15').value = 'Nifty_II';
    worksheet1.getCell('M15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('N15').value = 'Reason';
    worksheet1.getCell('N15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('O15').value = 'low';
    worksheet1.getCell('O15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('P15').value = 'high';
    worksheet1.getCell('P15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('Q15').value = 'target price';
    worksheet1.getCell('Q15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    let cnt=0;
    for(let k=0;k<props.tradeList.length;k++){
    for (let i = 0; i < props.tradeList[k].length; i++) {
      worksheet1.getCell(`B${cnt+16}`).value = i + 1;
      worksheet1.getCell(`B${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`C${cnt+16}`).value = props.tradeList[k][i][0];
      worksheet1.getCell(`C${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`D${cnt+16}`).value = props.tradeList[k][i][1];
      worksheet1.getCell(`D${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`E${cnt+16}`).value = props.tradeList[k][i][2];
      worksheet1.getCell(`E${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`F${cnt+16}`).value = props.tradeList[k][i][3];
      worksheet1.getCell(`F${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`G${cnt+16}`).value = props.tradeList[k][i][4];
      worksheet1.getCell(`G${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`H${cnt+16}`).value = props.tradeList[k][i][5];
      worksheet1.getCell(`H${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`I${cnt+16}`).value = props.tradeList[k][i][6];
      worksheet1.getCell(`I${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`J${cnt+16}`).value = props.tradeList[k][i][7]
      worksheet1.getCell(`J${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`K${cnt+16}`).value = props.tradeList[k][i][8];
      worksheet1.getCell(`K${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`L${cnt+16}`).value = props.tradeList[k][i][9];
      worksheet1.getCell(`L${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`M${cnt+16}`).value = props.tradeList[k][i][10];
      worksheet1.getCell(`M${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`N${cnt+16}`).value = props.tradeList[k][i][11];
      worksheet1.getCell(`N${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`O${cnt+16}`).value = props.tradeList[k][i][12];
      worksheet1.getCell(`O${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`P${cnt+16}`).value = props.tradeList[k][i][13]
      worksheet1.getCell(`P${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`Q${cnt+16}`).value = props.tradeList[k][i][14]
      worksheet1.getCell(`Q${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      cnt++;
    }
  }
  

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(data, `series_trading.xlsx`)
    });
  };

  return (
    <div>
      <button className="bg-blue-600 text-white p-1 text-[16px] rounded font-serif" onClick={generateExcel1}>Download Excel</button>
    </div>
  );
};

export default Excel;
