import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import { IoMdAdd } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { IoMdHelpCircle } from "react-icons/io";
import { MdCurrencyRupee } from "react-icons/md";

const ProtectProfit = (props) => {
  const day = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
  const [activeIndex, setActiveIndex] = useState(3);
  const [ExpiryType, setExpiryType] = useState('Week');
  const [showMessage,setShowMessage]=useState('');

  const PrettoSlider = styled(Slider)({
    color: '#2563EB',
    height: 8,
    '& .MuiSlider-track': {
      border: 'none',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#2563EB',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&:before': { display: 'none' },
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  });

  const handleNumber = (event,update) => {
    const inputValue = event.target.value;
    // Regular expression to match numbers with optional decimal point
    const regex = /^(\d+\.?\d*|\.\d+)$/;
    if (regex.test(inputValue) || inputValue === '') {
      update(inputValue);
    }
  };

  const updateDay = (index) => {
    if (index == 0)
      return 'Friday';
    else if (index == 1)
      return 'Monday';
    else if (index == 2)
      return 'Tuesday'
    else if (index == 3)
      return 'Wednesday'
    else
      return 'Thursday'
  }
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)
  const [startIndex1, setStartIndex1] = useState(0)
  const [endIndex1, setEndIndex1] = useState(0);
  useEffect(() => {
    if (ExpiryType == 'Week') {
      props.setStartDay(4 - startIndex);
      props.setEndDay(4 - endIndex);
    } else {
      props.setStartDay(24 - startIndex);
      props.setEndDay(24 - endIndex);
    }
  }, [startIndex, endIndex, ExpiryType])
  useEffect(() => {
    if (ExpiryType == 'Week') {
      props.setStartDay(4 - startIndex1);
      props.setEndDay(4 - endIndex1);
    } else {
      props.setStartDay(24 - startIndex1);
      props.setEndDay(24 - endIndex1);
    }
  }, [startIndex1, endIndex1, ExpiryType])

  useEffect(() => {
    let cnt = 0;
    for (let i = 0; i < props.leg; i++) {
      if (props.legExpiryType[i] == 'Weekly')
        cnt++;
    }
    if (cnt > 0) {
      setExpiryType('Week');
      setStartIndex(0);
      setStartIndex1(0);
      setEndIndex(4)
      setEndIndex1(4)
    } else {
      setExpiryType('Month');
      setStartIndex(0);
      setStartIndex1(0);
      setEndIndex1(24)
      setEndIndex(24)
    }
  }, [props.legExpiryType])

  useEffect(()=>{
    if(parseInt(props.profitReaches)<500)
    {
      setShowMessage('profit Reaches must be greater than or equal to 500.!!')
    }
    if(parseInt(props.lockMinimumProfit)>(parseInt(props.profitReaches)>2000?parseInt(props.profitReaches)-1000:parseInt(props.profitReaches)/2))
    {
      setShowMessage(`Lock Minimum Profit should be maximum ${(parseInt(props.profitReaches)>2000?parseInt(props.profitReaches)-1000:props.profitReaches/2)}`)
    }
    if(parseInt(props.profitIncrease)<1000)
    {
      setShowMessage('Increase Profit value should be greater than or equal to 1000.!!')
    }
    if(parseInt(props.trailProfit)>parseInt(props.profitIncrease))
    {
      setShowMessage('Trail Profit should be less than Increase Profit.!!')
    }
    setTimeout(()=>{
      setShowMessage('');
    },4000)
    
  },[props.profitIncrease,props.profitReaches,props.lockMinimumProfit,props.trailProfit])

  return (
    <>
      <ToastContainer />
      <div>
        <div className="bg-[#EDF0F7] mt-2 mb-2 pb-2 ml-2 mr-2">
          {props.protectProfit && <div className="grid grid-cols-2"><div className="ml-5 mt-3 mr-4 text-xl  "><div className="flex flex-row">Protect&nbsp;Profit<div data-tooltip-id="my-tooltip" data-tooltip-content="This feature will set a minimum profit threshold and incrementally trail the profit by the specified amount."> <IoMdHelpCircle className='text-[#0096FF] text-2xl' /> </div></div></div> <div className="text-center justify-center ml-16">
            <div className="cursor-pointer flex justify-end text-right mr-5 mt-2 text-blue-600 font-bold" onClick={() => { props.setProtectProfit(false) }}><MdClose className='text-3xl font-semibold flex justify-end' /></div>
          </div></div>}
          {!props.protectProfit && <div className="flex text-center justify-center"><div className="text-center  p-2 cursor-pointer flex flex-row" onClick={() => { props.setProtectProfit(true); }}><IoMdAdd className='text-blue-500 font-semibold text-2xl' /> Protect Profit <div data-tooltip-id="my-tooltip" data-tooltip-content="This feature will set a minimum profit threshold and incrementally trail the profit by the specified amount."> <IoMdHelpCircle className='text-[#0096FF] text-2xl' /> </div></div></div>}
          <div className="ml-2 bg-white  mr-5  grid grid-cols-1">

            {props.protectProfit && <div className="flex flex-row ">
              <div className="bg-inherit text-black text-sm mt-2 flex flex-row flex-wrap gap-2">
                <div className='flex flex-row m-2 gap-2'><input type="checkbox" checked={activeIndex == 1} className="text-black  text-sm" onClick={() => { props.setProtectType('Lock Minimum Profit'); setActiveIndex(1); }} ></input><div className="text-blue-600">Lock&nbsp;Minimum&nbsp;Profit</div></div>
                <div className='flex flex-row m-2 gap-2'><input type="checkbox" checked={activeIndex == 2} className="text-black  text-sm" onClick={() => { props.setProtectType('Trail Profit'); setActiveIndex(2); }}></input><div className='text-blue-600'>Trail&nbsp;Profit</div></div>
                <div className='flex flex-row m-2 gap-2'><input type="checkbox" checked={activeIndex == 3} className="text-black  text-sm" onClick={() => { props.setProtectType('Lock & Trail Profit'); setActiveIndex(3); }}></input><div className='text-blue-600'>Lock&nbsp;&&nbsp;Trail&nbsp;Profit</div></div>
              </div>
            </div>
            }
          <Tooltip id="my-tooltip" style={{ width: '300px', borderRadius: '10px', backgroundColor: 'rgb(147 197 253)', color: 'black', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)' }} />
            {/* Handle Lock Minimum Profit Feature of Protect Profit */}
            {props.protectProfit && props.protectType === "Lock Minimum Profit" && (
              <div className="ml-3 col-span-5 grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-row">
                <div className="mt-3 min-w-fit ">If Profit Reached:</div>
                <div className='flex flex-col'>
                  <div className="flex flex-row mt-2 ml-5"><MdCurrencyRupee className='text-blue-500 text-3xl font-semibold' />
                    <input type="text" className="h-8 rounded-md text-center bg-[#DAECF2] outline-0 " value={props.profitReaches} onChange={(e) => { handleNumber(e,props.setProfitReaches); }} /></div>
                  <div className='text-xs ml-10 text-green-700'>*Minimum 500 rupee.</div>
                </div>
                <div className="mt-3 min-w-fit lg:ml-5 ">Lock Minimum Profit:</div>
                <div className='flex flex-col'>
                  <div className="flex flex-row mt-2 ml-5"><MdCurrencyRupee className='text-blue-500 text-3xl font-semibold' />
                    <input type="text" className="h-8 rounded-md text-center bg-[#DAECF2] outline-0 " value={props.lockMinimumProfit} onChange={(e) => { handleNumber(e,props.setLockMinimumProfit);}} /></div>
                  <div className='text-xs ml-10 text-green-700'>*maximum {props.profitReaches >= 2000 ? props.profitReaches - 1000 : props.profitReaches / 2} rupee</div>
                </div>
              </div>
            )}
            {/* Handle Trail Profit Feature of Protect Profit */}
            {props.protectProfit && props.protectType === "Trail Profit" && (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:flex lg:flex-row  ml-3">
                <div className="mt-3 min-w-fit ">For Every Increase In profit:</div>
                <div className='flex flex-col'>
                  <div className="flex flex-row mt-2 ml-5"><MdCurrencyRupee className='text-blue-500 text-3xl font-semibold' />
                    <input type="text" className="h-8 rounded-md text-center bg-[#DAECF2] outline-0  "
                      value={props.profitIncrease} onChange={(e) => { handleNumber(e,props.setProfitIncrease); }} /></div>
                  <div className='text-xs ml-10 text-green-700'>*minimum 500 rupee</div>
                </div>
                <div className="mt-3 min-w-fit  lg:ml-5">Profit Trail By:</div>
                <div className='flex flex-col'>
                  <div className="flex flex-row mt-2 ml-5"><MdCurrencyRupee className='text-blue-500 text-3xl font-semibold' />
                    <input type="text" className="h-8 rounded-md text-center bg-[#DAECF2] outline-0 "
                      value={props.trailProfit} onChange={(e) => { handleNumber(e,props.setTrailProfit); }} /></div>
                  <div className='text-xs ml-10 text-green-700'>*maximum {props.profitIncrease} rupee</div>
                </div>
              </div>
            )}
            {/*Handle Lock & Trail Profit Feature of Protect Profit  */}
            {props.protectProfit && props.protectType === "Lock & Trail Profit" && (
              <div className="grid grid-cols-1 lg:grid-cols-2 ">
                <div className="flex flex-row sm:grid sm:grid-cols-2">
                  <div className="flex flex-col">
                    <div className=" inline-flex">Profit Reaches:</div>
                    <div className="mt-8  flex flex-row">Lock Minimum Profit:</div></div>
                  <div className="flex flex-col">
                    <div className='flex flex-col'>
                      <div className="flex flex-row mt-2 sm:ml-5"><MdCurrencyRupee className='text-blue-500 text-3xl font-semibold' />
                        <input type="text" className="h-8 rounded-md text-center bg-[#DAECF2] outline-0 " value={props.profitReaches} onChange={(e) => { handleNumber(e,props.setProfitReaches); }} /></div>
                      <div className='text-xs ml-10 text-green-700'>*Minimum 500 rupee.</div>
                    </div>
                    <div className='flex flex-col'>
                      <div className="flex flex-row mt-2 sm:ml-5"><MdCurrencyRupee className='text-blue-500 text-3xl font-semibold' />
                        <input type="text" className="h-8 rounded-md text-center bg-[#DAECF2] outline-0 " value={props.lockMinimumProfit} onChange={(e) => { handleNumber(e,props.setLockMinimumProfit); }} /></div>
                      <div className='text-xs ml-10 text-green-700'>*maximum {props.profitReaches >= 2000 ? props.profitReaches - 1000 : props.profitReaches / 2} rupee</div>
                    </div>
                  </div>
                  </div>
                  <div className="flex flex-row sm:grid sm:grid-cols-2">
                    <div className="flex flex-col">
                      <div className="">For Every Increase In profit:</div>
                      <div className="mt-8 ">Profit Trail By:</div>
                    </div>
                    <div className="flex flex-col mt-2 lg:mt-0">
                      <div className='flex flex-col'>
                        <div className="flex flex-row mt-2 sm:ml-5"><MdCurrencyRupee className='text-blue-500 text-3xl font-semibold' />
                          <input type="text" className="h-8 rounded-md text-center bg-[#DAECF2] outline-0"
                            value={props.profitIncrease} onChange={(e) => { handleNumber(e,props.setProfitIncrease); }} />
                        </div>
                        <div className='text-xs ml-10 text-green-700'>*minimum 500 rupee</div>
                      </div>
                      <div className='flex flex-col'>
                        <div className="flex flex-row mt-2 sm:ml-5"><MdCurrencyRupee className='text-blue-500 text-3xl font-semibold' />
                          <input type="text" className="h-8 rounded-md text-center bg-[#DAECF2] outline-0"
                            value={props.trailProfit} onChange={(e) => { handleNumber(e,props.setTrailProfit); }} />
                        </div>
                        <div className='text-xs ml-10 text-green-700'>*maximum {props.profitIncrease} rupee</div>
                      </div>
                      </div>
                    </div>
                  </div>
                  
            )}

              <div className='text-red-500 text-xs text-center justify-center'>{showMessage}</div>
              </div>

          {/* Here,we are handling the start day and end day */}
            {props.intraday === "No" && ExpiryType == 'Week' &&

              <div className="mt-2 mb-2 bg-white p-3 grid grid-cols-1 sm:grid-cols-2">
                <div className="grid grid-cols-2 gap-1 mr-4 max-md:mb-1">
                  <div className="mt-3 ml-5  text-l">Start Day:</div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <PrettoSlider aria-label="Temperature" defaultValue={0} value={startIndex} onChange={(e) => { setStartIndex(e.target.value); }} valueLabelDisplay="auto" step={1} marks min={0} max={4} valueLabelFormat={(v) => (4 - v)} />
                    </div>
                    <div className=" font-sans text-blue-400">{4 - startIndex} trading days before weekly expiry</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-1  mr-4  max-md:mb-1 ">
                  <div className="mt-3 ml-5 text-l ">End Day:</div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <PrettoSlider aria-label="Temperature" defaultValue={24} value={endIndex} onChange={(e) => { setEndIndex(e.target.value); }} valueLabelDisplay="auto" step={1} marks min={0} max={4} valueLabelFormat={(v) => (4 - v)} />
                    </div>
                    <div className="font-sans text-blue-400">{4 - endIndex} trading days before weekly expiry</div>
                  </div>
                </div>
              </div>
            }
            {props.intraday === "No" && ExpiryType == 'Month' &&
              <div className="mt-2 mb-2 bg-white p-3 grid grid-cols-1 sm:grid-cols-2">
                <div className="grid grid-cols-2 gap-1 mr-4 max-md:mb-1">
                  <div className="mt-3 ml-5  text-l">Start Day:</div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <PrettoSlider value={startIndex1} onChange={(e) => { setStartIndex1(e.target.value); }} valueLabelDisplay="on" step={1} min={0} max={24} valueLabelFormat={(v) => (24 - v)} />
                    </div>
                    <div className=" text-blue-400">{24 - startIndex1} trading days before weekly expiry</div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-1  mr-4  max-md:mb-1 ">
                  <div className="mt-3 ml-5 text-l ">End Day:</div>
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <PrettoSlider value={endIndex1} onChange={(e) => { setEndIndex1(e.target.value); }} valueLabelDisplay="on" step={1} min={0} max={24} valueLabelFormat={(v) => (24 - v)} />
                    </div>
                    <div className=" text-blue-400">{24 - endIndex1} trading days before weekly expiry</div>
                  </div>
                </div>
              </div>
            }
          </div>

        </div>
        
      </>
      )
}

      export default ProtectProfit