import { useState, useEffect } from 'react';
import { FaQuoteLeft, FaStar, FaUser } from 'react-icons/fa'; // Replace with your icon library


const reviews = [
  {
    username: 'Shashank Gupta., Portfolio Manager',
    review: 'Fprognos transformed the way we approach strategy development. The backtesting feature is robust and has given us the confidence to execute our strategies with precision.',
    bgColor: 'bg-blue-100',
    iconColor: 'white'
  },
  {
    username: 'Prashant Singh.., Quantitative Analyst',
    review: "The insights gained from Fprognos' backtesting portal are unparalleled. It has significantly improved our decision-making process by allowing us to fine-tune strategies before going live.",
    bgColor: 'bg-red-100',
    iconColor: 'white'
  },
  {
    username: 'Rajneesh Raj., Head of Trading Operations',
    review: 'The ease of use and depth of analysis that Fprognos provides are unmatched. The backtesting results are detailed and accurate, helping us to avoid costly mistakes.',
    bgColor: 'bg-orange-100',
    iconColor: 'white'
  },
  {
    username: 'Rajat Jain., Financial Engineer',
    review: 'Fprognos has been a game-changer for our team. The ability to backtest various scenarios and parameters in one platform has streamlined our workflow and improved our performance.',
    bgColor: 'bg-blue-100',
    iconColor: 'white'
  },
  {
    username: 'Rajiv P., Algorithmic Trader',
    review: "We've tried other backtesting tools, but Fprognos stands out for its reliability and comprehensive data coverage. It's an indispensable tool in our trading arsenal.",
    bgColor: 'bg-red-100',
    iconColor: 'white'
  },
  {
    username: 'Rahul Singh., Chief Investment Officer',
    review: 'Fprognos’ backtesting portal has significantly reduced our time-to-market for new strategies. The accuracy and speed of the tests allow us to iterate quickly and effectively.',
    bgColor: 'bg-orange-100',
    iconColor: 'white'
  },
  // {
  //   username: 'Vivek B., Risk Management Specialist',
  //   review: 'The backtest results from Fprognos are not just numbers; they provide actionable insights that have led to tangible improvements in our trading strategies.',
  //   bgColor: 'bg-blue-100',
  //   iconColor: 'white'
  // },
];


const Review = () => {

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 2000); // Change every 3 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, [reviews.length]);


  return (
    <div className=''>
      <div className="py-12 px-4 md:px-8 lg:px-16 bg-[#FDCB02] text-black">
        {/* Customer Review Section */}
        <div className="flex text-center justify-center text-3xl font-serif m-5 font-bold ">Customer Reviews</div>

        <div className="relative w-full overflow-hidden">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-10">
            {reviews.map((review, index) => (
              <div key={index} className={`h-60 rounded-lg p-2 flex flex-col bg-white relative`}>

                <p className="font-serif text-black mt-4 text-center p-3">
                  <FaQuoteLeft className="inline text-gray-400" /> {review.review}
                </p>
                <div className='flex flex-col text-center justify-center'>
                  <div className="flex flex-row justify-center items-center space-x-4">
                    <FaUser
                      className="text-black"
                      style={{
                        fontSize: "60px",
                        borderRadius: "50%",
                        backgroundColor: review.iconColor,
                        padding: "10px",
                      }}
                    />

                  </div>
                  <div className='flex flex-col'>
                    <div className="flex justify-center items-center mt-2">
                      {[...Array(5)].map((_, i) => (
                        <FaStar key={i} className={`text-orange-500 ${i < review?.rating ? '' : 'opacity-25'}`} />
                      ))}</div>
                    <div className="text-lg font-semibold">{review.username}</div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;
