import background from './../../assets/home_page.png';
import logo from './../../assets/logo.png';

const Section1 = () => {
  return (
    <div
      className="relative flex items-center justify-center h-full bg-cover bg-center rounded-lg"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="absolute inset-0 bg-black opacity-40"></div>

      <div className="relative z-10 max-w-4xl text-center text-white px-6 lg:px-12 py-10">
        <div className='flex justify-center mb-6'>
          <img src={logo} alt="Logo" className='h-32' />
        </div>
        <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mb-4">
          Design Winning Strategies for Capital Market
        </h1>
        <h2 className="text-xl md:text-2xl lg:text-3xl font-medium text-gray-200 mb-8">
          Using AI-powered Platform
        </h2>
        <p className="text-base md:text-lg lg:text-xl text-gray-100 mb-12">
          Selected under Microsoft's Founder's Hub Program, we are here to help you access and utilize data-driven strategies to succeed in the Capital Market. Our platform is designed to make professional strategies accessible to everyone.
        </p>
        <a
          href="#learn-more"
          className="inline-block px-6 py-3 md:px-8 md:py-4 bg-blue-600 text-white rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-200"
        >
          Learn More
        </a>
      </div>
    </div>
  );
};

export default Section1;
