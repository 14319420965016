export const extractErrorMessage = (error)=> {
    if (error?.response?.data?.detail) {
      return error.response.data.detail;
    } else if (error?.response?.data?.message) {
      return error.response.data.message;
    } else if (error?.response?.data?.errors) {
      return error.response.data.errors.join(', ');
    } else if (typeof error?.response?.data === 'string') {
      return error.response.data;
    } else if (error?.message) {
      return error.message;
    } else {
      return 'An unknown error occurred';
    }
};