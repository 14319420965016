import React, { useState, useEffect } from 'react'
import Navbar from '../Layout/Navbar'
import { FaCheckCircle } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import { TRANSACTION_DETAIL } from '../../constant/constant';
import axios from 'axios'
import useBacktestStore from '../../store/backtestStore';
import secureLocalStorage from "react-secure-storage";

const SuccessPaymentPage = () => {

    const [successResponse, setSuccessResponse] = useState({});
    const [plan, setPlan] = useState({});
    const location = useLocation();
    const { coin, updateCoin } = useBacktestStore()
    const email = secureLocalStorage.getItem("email")

    const getSuccessDetail = () => {
        let response = {}
        const queryParams = new URLSearchParams(location.search);
        response['transactionid'] = queryParams.get('txnid')
        response['email'] = email
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: TRANSACTION_DETAIL,
            headers: {
                'Content-Type': 'application/json'
            },
            data: response
        };
        axios.request(config)
            .then((response) => {
                console.log(response.data)
                setSuccessResponse(response.data.payment);
                setPlan(response.data.payment.plan)
                updateCoin(response.data.user.coin)
            })
            .catch((error) => {
                console.log(error);
            });

    }
    useEffect(() => {
        getSuccessDetail();
    }, []);
    return (
        <div>
            <Navbar />

            <div className='flex justify-center items-center min-h-screen bg-gray-100 py-10'>
                <div className='shadow-lg border border-gray-200 text-left p-6 w-full max-w-lg rounded-lg bg-white transition-transform transform hover:scale-105'>
                    <div className='flex justify-center items-center mb-4'>
                        <FaCheckCircle sx={{ color: "green", fontSize: 60 }} />
                    </div>
                    <div className="text-center text-green-600 text-3xl font-bold mb-6">Payment Successful</div>

                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <div className='font-semibold text-gray-700'>Payment Type</div>
                        <div className='text-gray-900'>{successResponse?.paymentMode}</div>
                    </div>

                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <div className='font-semibold text-gray-700'>Email</div>
                        <div className='text-gray-900'>{successResponse?.userEmail}</div>
                    </div>

                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <div className='font-semibold text-gray-700'>Plan</div>
                        <div className='text-gray-900'>{plan?.name}</div>
                    </div>

                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <div className='font-semibold text-gray-700'>Price</div>
                        <div className='text-gray-900'>{successResponse?.amount}</div>
                    </div>

                    <div className='grid grid-cols-2 gap-4 mb-4'>
                        <div className='font-semibold text-gray-700'>Transaction ID</div>
                        <div className='text-gray-900'>{successResponse?.txId}</div>
                    </div>

                    <div className='flex justify-end'>
                        <a href='/' className='bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition-all transform hover:translate-y-1'>
                            DASHBOARD
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessPaymentPage