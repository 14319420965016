import Dashboard from './../Page/Layout/Home/assets/Dashboard.webp';

const Platform = () => {
  return (
    <div 
      className="p-8 bg-cover bg-center rounded-lg shadow-lg" 
      style={{ backgroundImage: `url(${Dashboard})` }}
    >
      {/* Title Section */}
      <div className="text-4xl text-center font-extrabold text-white mb-8 drop-shadow-md">
        Platform
      </div>

      {/* Grid Section for Subtitles */}
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4 text-black mb-8'>
        <div className='text-3xl text-center justify-center font-semibold bg-white bg-opacity-75 p-4 rounded-lg shadow-lg'>
          Trading Platform
        </div>
        <div className='text-3xl text-center justify-center font-semibold bg-white bg-opacity-75 p-4 rounded-lg shadow-lg'>
          Backtest Platform
        </div>
      </div>

      {/* Content Section */}
      <p className="text-white leading-relaxed text-lg bg-blue-900 bg-opacity-75 p-6 rounded-lg shadow-lg">
        Your portfolio should be a source of empowerment, giving you the freedom to live your life the way you want.
        At Fprognos, we are committed to providing a trading platform that puts you at the center of the experience. 
        Our platform is designed to remove the stress and complexity from trading, offering a data-driven approach that is grounded in sound logic 
        and executed flawlessly with state-of-the-art automation technology.
        Whether you’re backtesting strategies or executing trades in real-time, 
        Fprognos ensures that you have the tools you need to succeed without being held back by technology.
      </p>
    </div>
  );
}

export default Platform;
