import {useState} from 'react'
import Navbar from '../Layout/Navbar';
import FooterVersion2 from '../Layout/FooterVersion2';
import Future from './Future/Future';
import Option from './Option/Option';

const Series = () => {
  const [visible,setVisible]=useState(true);
  return (
    <div className='bg-[#DAECF2]'>
        <div className='fixed w-full top-0'><Navbar /></div>
        <div className='flex flex-row mt-20 justify-center text-center p-2 gap-4'>
            <input type="checkbox" checked={visible} onChange={()=>{setVisible(!visible)}}/>
            <div className=''>Future</div>
            <input type="checkbox" checked={!visible} onChange={()=>{setVisible(!visible)}}/>
            <div className=''>Future & Option Both</div>
        </div>
        {visible && <Future/>}
        {!visible && <Option/>}
        <FooterVersion2/>
    </div>
  )
}

export default Series