import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog } from '@mui/material';
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage';
import { ToastContainer, toast } from 'react-toastify';
// constant
import { SAVE_BUCKET_NAME, FETCH_BASKET_NAME } from '../../constant/constant';
// component
import Bucket from './Bucket';
import useBucketStore from '../../store/BucketStore';
import Dashboard from './Dashboard';
import FooterVersion2 from '../Layout/FooterVersion2';
import Navbar from './../Layout/Navbar';
// Icon
import { MdClose } from "react-icons/md";




const Home = () => {
    const navigate = useNavigate();
    const { basketList, updateBasketList } = useBucketStore();
    const { updateSidebarVisible } = useBucketStore()
    const { boxShow, updateBoxShow } = useBucketStore();
    const { updateActiveBasket,activeBasket } = useBucketStore();
    const { updateActiveBasketName,activeBasketName } = useBucketStore()
    const [bucketName, setbucketName] = useState("");
    const [showBucket, setShowBucket] = useState(false);
    const [bucketStartDate, setBucketStartDate] = useState("2022-01-03");
    const [bucketEndDate, setBucketEndDate] = useState("2022-02-03");

    const saveBucketName = () => {
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        } else {
            navigate('/backtest/login');
        }

        let data = JSON.stringify({ id: secureLocalStorage.getItem("backtest_id"), 'bucket_name': bucketName, 'strategy': [] });
        let config = { method: "post", maxBodyLength: Infinity, url: SAVE_BUCKET_NAME, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data, };
        axios
            .request(config)
            .then((response) => {
                console.log(response.data, 'save bucket name')
                updateBoxShow(false);
                updateActiveBasketName(bucketName);
                updateActiveBasket({ id: response?.data?.id, 'bucket_name': bucketName, 'strategy': [] });
                setbucketName('');
                fetchBasketName();
                toast.success('New Basket Added Successfully!!!')
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    updateBoxShow(false);
                    setbucketName('');
                    navigate('/backtest/login');
                } else if (error?.response?.data?.message) {
                    toast.error(error.response.data.message)
                    updateBoxShow(false);
                    setbucketName('');
                } else {
                    toast.error('Network Issue');
                    updateBoxShow(false);
                    setbucketName('');
                }
                console.log(error, 'error at save bucket name')

            });
    }
    const fetchBasketName = () => {
        const id = secureLocalStorage.getItem("backtest_id");
        let token = secureLocalStorage.getItem('backtest_token');

        // Remove the 'backtest_' prefix if it exists
        if (token && token.startsWith('backtest_')) {
            token = token.replace('backtest_', '');
        } else {
            navigate('/backtest/login');
        }
        let config = { method: "get", maxBodyLength: Infinity, url: `${FETCH_BASKET_NAME}/${id}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, };
        axios
            .request(config)
            .then((response) => {
                console.log(response?.data, 'fetch basket name')
                updateBasketList(response?.data?.result);
            })
            .catch((error) => {
                if (error?.response?.status === 401) {
                    navigate('/backtest/login');
                } else if (error?.response?.data?.message) {
                    toast.error(error.response.data.message)
                } else {
                    toast.error('Network Issue');
                }
                console.log(error, 'error at fetch Basket Name at home.jsx')
            });
    }
    function handleResize() {
        setShowBucket(false);
        updateSidebarVisible(false);
    }
    // Add an event listener when the component mounts
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        // Clean up the event listener when the component is unmounted
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    useEffect(()=>{
        fetchBasketName();
    },[])

    return (
        <>
            <ToastContainer />
            <div className='fixed w-full z-20'>
                <Navbar />
            </div>
            <div className="flex flex-row pt-5">
                <div className="bg-[#DAECF2] flex-grow overflow-y-auto overflow-x-hidden">
                    <div className="mt-[70px]">
                        {basketList.length > 0 && <div className="text-center justify-center text-[white] text-xl font-serif mt-5 mx-2 border-b-2 bg-blue-500 rounded-tl-lg rounded-tr-lg">List Of Instrument</div>}
                        {basketList.length > 0 && <div className=""><Bucket bucketStartDate={bucketStartDate} bucketEndDate={bucketEndDate} setBucketStartDate={setBucketStartDate} setBucketEndDate={setBucketEndDate} /></div>}
                        {basketList.length == 0 && (<Dashboard />)}
                    </div>
                </div>
            </div>
            <FooterVersion2 />
            {/* Dialog Box for add a new Basket */}
            <Dialog className="rounded-lg" onClose={(event, reason) => {
                if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                    updateBoxShow(false);
                }
            }} open={boxShow}>
                <div className="flex flex-row border-b-2 border-2 rounded bg-blue-500 text-white">
                    <div className="text-xl p-2 w-96 flex flex-row gap-2">Add Strats</div>
                    <div className="text-xl flex justify-end font-semibold p-2 cursor-pointer"><MdClose className='text-2xl font-semibold' onClick={() => { updateBoxShow(false); }} /></div>
                </div>

                <div className="flex flex-col text-center justify-center mt-1 p-6">
                    <div className="font-serif text-lg font-medium text-left p">Strats Name</div>
                    <input type="text" className="rounded-md outline-0 bg-[#ECF0FF] border-[1px] border-blue-500 h-8 justify-center p-2" placeholder="Strats Name (max 30 character)" value={bucketName} onChange={(e) => { setbucketName(e.target.value); }} />
                </div>

                <div className="flex justify-end gap-2 p-1 mt-2 bg-gray-300">
                    <button className="border-2 p-1 w-28 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { saveBucketName(); }}>Add Strats</button>
                    <button className="border-2 p-1 w-28 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { updateBoxShow(false); }}>Cancel</button>
                </div>
            </Dialog>
        </>
    )
}

export default Home