import WhyChoose from './WhyChoose';
import Faq from './Faq';
import Footer from './Footer';
import Plan from './Plan';
import Features from './Features';
import Backtest from './Backtest';
import Number from './Number';
import Review from './Review';
import Main from './Main';

const Index = () => {
  return (
    <>
      <div className='bg-white'>
        <Main />
        <Backtest />
        <WhyChoose />
        <Number />
        <Plan />
        <Faq />
        <Review/>
        <Features />
      
      <Footer />
      </div>
    </>
  );
}

export default Index;
