import React from 'react';
import { saveAs } from 'file-saver';
const ExcelJS = require('exceljs');


const Excel = (props) => {

  const generateExcel1 = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet('Result');
    const worksheet4 = workbook.addWorksheet('Monthly Result');
    const worksheet3 = workbook.addWorksheet('Circular Result');
    const worksheet2 = workbook.addWorksheet('Circular Concise');
  
     // Define your columns
     const columns = [
      'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J',
      'K', 'L', 'M', 'N', 'O', 'P','Q'
    ];

    // Set width for each column
    columns.forEach(column => {
      worksheet1.getColumn(column).width = 15;
      worksheet4.getColumn(column).width = 15;
      worksheet2.getColumn(column).width = 15;
      worksheet3.getColumn(column).width = 15;
    });
   // Add border to the cell
     const borderStyle = {
      style: 'medium',
      color: { argb: '000000' } // Black color
    };
    // worksheet.getCell('A11').value = '2020';
    // worksheet.getCell('A12').value = 'Total';
    // worksheet.getCell('A9').alignment = { horizontal: 'center' }
    // worksheet.mergeCells('A9:M9');
    // worksheet.getCell('A9').fill = {
    //   type: 'pattern',
    //   pattern: 'solid',
    //   fgColor: { argb: '#2D5BFF' }
    // };
    // worksheet3.getCell('A9').font = {
    //   size: 10, // Set font size to 16 (adjust as needed)
    //   name: 'serif', // Set font family (e.g., Arial)
    //   bold: true, // Set font weight to bold
    //   color: { argb: 'FFFFFF' }
    // };

    // Example: Applying a background color to a cell in worksheet1
    const cellA1 = worksheet1.getCell('A1');
    worksheet1.mergeCells('A1:G1');
    cellA1.value = 'FPROGNOS';
    cellA1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '#2D5BFF' }
    };
    worksheet1.getCell('A1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('A1').alignment = { horizontal: 'center' }
    worksheet1.getCell('A1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};


    const cellA2 = worksheet1.getCell('H1');
    worksheet1.mergeCells('H1:N1');
    cellA2.value = props.name;
    cellA2.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('H1').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('H1').alignment = { horizontal: 'center' }
    worksheet1.getCell('H1').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};



    // Adding data to worksheet1
    const cellD1 = worksheet1.getCell('B3');
    worksheet1.mergeCells('B3:E3');
    cellD1.value = 'POSITION';
    cellD1.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('B3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('B3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B3').alignment = { horizontal: 'center' }

    worksheet1.getCell('B4').value = 'START TIME';
    worksheet1.mergeCells('B4:C4');
    worksheet1.getCell('B4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D4').value = '09:16';
    worksheet1.mergeCells('D4:E4');
    worksheet1.getCell('D4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B6').value = 'END TIME';
    worksheet1.mergeCells('B6:C6');
    worksheet1.getCell('B6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D6').value = '03:20';
    worksheet1.mergeCells('D6:E6');
    worksheet1.getCell('D6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B5').value = 'START DATE';
    worksheet1.mergeCells('B5:C5');
    worksheet1.getCell('B5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D5').value = '01/03/2020';
    worksheet1.mergeCells('D5:E5');
    worksheet1.getCell('D5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B7').value = 'END DATE';
    worksheet1.mergeCells('B7:C7');
    worksheet1.getCell('B7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D7').value = '01/06/2020';
    worksheet1.mergeCells('D7:E7');
    worksheet1.getCell('D7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B8').value = 'Action';
    worksheet1.mergeCells('B8:C8');
    worksheet1.getCell('B8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D8').value = props.action;
    worksheet1.mergeCells('D8:E8');
    worksheet1.getCell('D8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B9').value = 'Symbol';
    worksheet1.mergeCells('B9:C9');
    worksheet1.getCell('B9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D9').value = props.symbol;
    worksheet1.mergeCells('D9:E9');
    worksheet1.getCell('D9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B10').value = 'Target Profit';
    worksheet1.mergeCells('B10:C10');
    worksheet1.getCell('B10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D10').value = props.targetProfit;
    worksheet1.mergeCells('D10:E10');
    worksheet1.getCell('D10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B11').value = 'Entry Difference';
    worksheet1.mergeCells('B11:C11');
    worksheet1.getCell('B11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D11').value = props.targetDiff;
    worksheet1.mergeCells('D11:E11');
    worksheet1.getCell('D11').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('B12').value = 'Lot';
    worksheet1.mergeCells('B12:C12');
    worksheet1.getCell('B12').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D12').value = props.lot;
    worksheet1.mergeCells('D12:E12');
    worksheet1.getCell('D12').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    const formattingOptions = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '#2D5BFF' }
    };

    const cellres = worksheet1.getCell('H3');
    worksheet1.mergeCells('H3:K3');
    cellres.value = 'STATISTICAL RESULT';
    cellres.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('H3').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('H3').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H3').alignment = { horizontal: 'center' }
    worksheet1.getCell('H5').value = 'Total Margin';
    worksheet1.mergeCells('H5:I5');
    worksheet1.getCell('H5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H6').value = 'Total Profit';
    worksheet1.mergeCells('H6:I6');
    worksheet1.getCell('H6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H7').value = 'Maximum Drawdown';
    worksheet1.mergeCells('H7:I7');
    worksheet1.getCell('H7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H8').value = 'Montly Max Profit';
    worksheet1.mergeCells('H8:I8');
    worksheet1.getCell('H8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H9').value = 'Monthly Min Profit';
    worksheet1.mergeCells('H9:I9');
    worksheet1.getCell('H9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H10').value = 'Investment Day';
    worksheet1.mergeCells('H10:I10');
    worksheet1.getCell('H10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};

    worksheet1.getCell('J5').value = props.margin;
    worksheet1.mergeCells('J5:K5');
    worksheet1.getCell('J5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J6').value = props.totalProfit;
    worksheet1.mergeCells('J6:K6');
    worksheet1.getCell('J6').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J7').value = props.maximumDrawdown;
    worksheet1.mergeCells('J7:K7');
    worksheet1.getCell('J7').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J8').value = props.maximumProfit;
    worksheet1.mergeCells('J8:K8');
    worksheet1.getCell('J8').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J9').value = props.minimumProfit;
    worksheet1.mergeCells('J9:K9');
    worksheet1.getCell('J9').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J10').value = props.investmentDay;
    worksheet1.mergeCells('J10:K10');
    worksheet1.getCell('J10').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    // // Define an array of cell addresses where you want to apply the formatting
    const cellAddresses = ['B15', 'C15', 'D15', 'E15', 'F15','G15' ,'H15','I15', 'J15','K15', 'L15','M15', 'N15','O15','P15','Q15'];

    // Apply formatting to the specified cells
    cellAddresses.forEach(address => {
      const cell = worksheet1.getCell(address);
      cell.fill = formattingOptions;
      cell.font = {
        color: { argb: 'FFFFFF' }
      }
    });

    // Trade List Result is showing here...........
    // Adding data to worksheet1
    const trade = worksheet1.getCell('B14');
    worksheet1.mergeCells('B14:Q14');
    trade.value = 'TRADE LIST';
    trade.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '2D5BFF' }
    };
    worksheet1.getCell('B14').font = {
      color: { argb: 'FFFFFF' }
    }
    worksheet1.getCell('B14').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('B14').alignment = { horizontal: 'center' }
    
    worksheet1.getCell('B15').value = 'Serial No.';
    worksheet1.getCell('B15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('C15').value = 'Start Time';
    worksheet1.getCell('C15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('D15').value = 'Start Date';
    worksheet1.getCell('D15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('E15').value = 'End Time';
    worksheet1.getCell('E15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('F15').value = 'End Date';
    worksheet1.getCell('F15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('G15').value = 'Weekday';
    worksheet1.getCell('G15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('H15').value = 'Standing Position';
    worksheet1.getCell('H15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('I15').value = 'Entry Price';
    worksheet1.getCell('I15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('J15').value = 'Exit Price';
    worksheet1.getCell('J15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('K15').value = 'Profit';
    worksheet1.getCell('K15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('L15').value = 'Monthly Expiry';
    worksheet1.getCell('L15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('M15').value = 'Reason';
    worksheet1.getCell('M15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('N15').value = 'Low';
    worksheet1.getCell('N15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('O15').value = 'High';
    worksheet1.getCell('O15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    worksheet1.getCell('P15').value = 'Target Price';
    worksheet1.getCell('P15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
    let cnt=0;
    for(let k=0;k<props.tradeList.length;k++){
    for (let i = 0; i < props.tradeList[k].length; i++) {
      worksheet1.getCell(`B${cnt+16}`).value = i + 1;
      worksheet1.getCell(`B${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`C${cnt+16}`).value = props.tradeList[k][i][0];
      worksheet1.getCell(`C${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`D${cnt+16}`).value = props.tradeList[k][i][1];
      worksheet1.getCell(`D${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`E${cnt+16}`).value = props.tradeList[k][i][2];
      worksheet1.getCell(`E${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`F${cnt+16}`).value = props.tradeList[k][i][3];
      worksheet1.getCell(`F${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`G${cnt+16}`).value = props.tradeList[k][i][4];
      worksheet1.getCell(`G${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`H${cnt+16}`).value = props.tradeList[k][i][5];
      worksheet1.getCell(`H${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`I${cnt+16}`).value = props.tradeList[k][i][7];
      worksheet1.getCell(`I${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`J${cnt+16}`).value = props.tradeList[k][i][9]
      worksheet1.getCell(`J${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`K${cnt+16}`).value = props.tradeList[k][i][10];
      worksheet1.getCell(`K${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`L${cnt+16}`).value = props.tradeList[k][i][13]
      worksheet1.getCell(`L${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`M${cnt+16}`).value = props.tradeList[k][i][14];
      worksheet1.getCell(`M${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`N${cnt+16}`).value = props.tradeList[k][i][15];
      worksheet1.getCell(`N${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`O${cnt+16}`).value = props.tradeList[k][i][16];
      worksheet1.getCell(`O${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      worksheet1.getCell(`P${cnt+16}`).value = props.tradeList[k][i][17];
      worksheet1.getCell(`P${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
      cnt++;
    }
    cnt++;
    worksheet1.getCell(`B${cnt+16}`).fill={fgColor:{ argb: '2D5BFF' },type: 'pattern',pattern: 'solid',}
    worksheet1.getCell(`B${cnt+16}`).value = 'Month: '+ k
    worksheet1.getCell(`B${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`E${cnt+16}`).value =  'BookedProfit'
    worksheet1.getCell(`E${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`F${cnt+16}`).value = props.bookedMonthProfit[k]
    worksheet1.getCell(`F${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`G${cnt+16}`).value = 'Expiry Loss'
    worksheet1.getCell(`G${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`H${cnt+16}`).value = props.monthlyLossExpiry[k]
    worksheet1.getCell(`H${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`I${cnt+16}`).value = 'NetProfit/Loss'
    worksheet1.getCell(`I${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet1.getCell(`J${cnt+16}`).value = props.monthlyProfit[k]
    worksheet1.getCell(`J${cnt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    cnt+=2;
  }

  //------------------------------------------------------------------------------------------------------------
  // Monthly Result
  const monthly = worksheet4.getCell('B14');
  worksheet4.mergeCells('B14:F14');
  monthly.value = 'Monthly Result';
  monthly.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '2D5BFF' }
  };
  worksheet4.getCell('B14').font = {
    color: { argb: 'FFFFFF' }
  }
  worksheet4.getCell('B15').value = 'Year.';
  worksheet4.getCell('B15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('C15').value = 'January';
  worksheet4.getCell('C15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('D15').value = 'February';
  worksheet4.getCell('D15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('E15').value = 'March';
  worksheet4.getCell('E15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('F15').value = 'April';
  worksheet4.getCell('F15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('G15').value = 'May';
  worksheet4.getCell('G15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('H15').value = 'June';
  worksheet4.getCell('H15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('I15').value = 'July';
  worksheet4.getCell('I15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('J15').value = 'August';
  worksheet4.getCell('J15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('K15').value = 'September';
  worksheet4.getCell('K15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('L15').value = 'October';
  worksheet4.getCell('L15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('M15').value = 'November';
  worksheet4.getCell('M15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('N15').value = 'December';
  worksheet4.getCell('N15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet4.getCell('O15').value = 'Total';
  worksheet4.getCell('O15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  let count=0;
  Object.entries(props.normalMonthResult).map(([key, value]) => {
    worksheet4.getCell(`B${count+16}`).value = key + 1;
    worksheet4.getCell(`B${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`C${count+16}`).value = value[1];
    worksheet4.getCell(`C${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`D${count+16}`).value = value[2];
    worksheet4.getCell(`D${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`E${count+16}`).value = value[3];
    worksheet4.getCell(`E${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`F${count+16}`).value = value[4];
    worksheet4.getCell(`F${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`G${count+16}`).value = value[5];
    worksheet4.getCell(`G${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`H${count+16}`).value = value[6];
    worksheet4.getCell(`H${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`I${count+16}`).value = value[7];
    worksheet4.getCell(`I${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`J${count+16}`).value = value[8]
    worksheet4.getCell(`J${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`K${count+16}`).value = value[9];
    worksheet4.getCell(`K${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`L${count+16}`).value = value[10]
    worksheet4.getCell(`L${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`M${count+16}`).value = value[11];
    worksheet4.getCell(`M${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`N${count+16}`).value = value[12];
    worksheet4.getCell(`N${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`O${count+16}`).value = value[13];
    worksheet4.getCell(`O${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet4.getCell(`P${count+16}`).value = value[14];
    worksheet4.getCell(`P${count+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    count++;
  })
  
  // worksheet3 -- monthlywise result
  const month = worksheet2.getCell('B4');
  worksheet2.mergeCells('B4:F4');
  month.value = 'Monthly Result';
  month.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '2D5BFF' }
  };
  worksheet2.getCell('B4').font = {
    color: { argb: 'FFFFFF' }
  }
  worksheet2.getCell('B4').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('B4').alignment = { horizontal: 'center' }

  worksheet2.getCell('B5').value = 'Serial No.';
  worksheet2.getCell('B5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('C5').value = 'Start Time';
  worksheet2.getCell('C5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('D5').value = 'Start Date';
  worksheet2.getCell('D5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('E5').value = 'End Time';
  worksheet2.getCell('E5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('F5').value = 'End Date';
  worksheet2.getCell('F5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('G5').value = 'Net Circular Pnl';
  worksheet2.getCell('G5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('H5').value = 'First Trade';
  worksheet2.getCell('H5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('I5').value = 'Last Trade';
  worksheet2.getCell('I5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('J5').value = 'Maximum Loss';
  worksheet2.getCell('J5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet2.getCell('K5').value = 'Max Standing Day';
  worksheet2.getCell('K5').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  
  let circularCount=0;
  for(let k=0;k<props.circularResult.length;k++){
    worksheet2.getCell(`B${circularCount+6}`).value = k + 1;
    worksheet2.getCell(`B${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`C${circularCount+6}`).value = props.circularResult[k][0][0];
    worksheet2.getCell(`C${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`D${circularCount+6}`).value = props.circularResult[k][0][1];
    worksheet2.getCell(`D${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`E${circularCount+6}`).value = props.circularResult[k][0][2];
    worksheet2.getCell(`E${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`F${circularCount+6}`).value = props.circularResult[k][0][3];
    worksheet2.getCell(`F${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`G${circularCount+6}`).value = props.circularProfit[k];
    worksheet2.getCell(`G${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`H${circularCount+6}`).value = props.circularProperty[k][2];
    worksheet2.getCell(`H${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`I${circularCount+6}`).value = props.circularProperty[k][1];
    worksheet2.getCell(`I${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`J${circularCount+6}`).value = props.circularProperty[k][2]
    worksheet2.getCell(`J${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet2.getCell(`K${circularCount+6}`).value = props.circularProperty[k][0];
    worksheet2.getCell(`K${circularCount+6}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    circularCount++;
  }

  // worksheet3 -- for circular result 
  const circular = worksheet3.getCell('B14');
  worksheet3.mergeCells('B14:Q14');
  circular.value = 'TRADE LIST';
  circular.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: '2D5BFF' }
  };
  worksheet3.getCell('B14').font = {
    color: { argb: 'FFFFFF' }
  }
  worksheet3.getCell('B14').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('B14').alignment = { horizontal: 'center' }
  
  worksheet3.getCell('B15').value = 'Serial No.';
  worksheet3.getCell('B15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('C15').value = 'Start Time';
  worksheet3.getCell('C15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('D15').value = 'Start Date';
  worksheet3.getCell('D15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('E15').value = 'End Time';
  worksheet3.getCell('E15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('F15').value = 'End Date';
  worksheet3.getCell('F15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('G15').value = 'Weekday';
  worksheet3.getCell('G15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('H15').value = 'Standing Position';
  worksheet3.getCell('H15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('I15').value = 'Entry Price';
  worksheet3.getCell('I15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('J15').value = 'Exit Price';
  worksheet3.getCell('J15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('K15').value = 'Profit';
  worksheet3.getCell('K15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('L15').value = 'Monthly Expiry';
  worksheet3.getCell('L15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('M15').value = 'Reason';
  worksheet3.getCell('M15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('N15').value = 'Low';
  worksheet3.getCell('N15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('O15').value = 'High';
  worksheet3.getCell('O15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  worksheet3.getCell('P15').value = 'Target Price';
  worksheet3.getCell('P15').border = { top: borderStyle, left: borderStyle, bottom: borderStyle, right: borderStyle};
  let cntt=0;
  for(let k=0;k<props.tradeList.length;k++){
  for (let i = 0; i < props.tradeList[k].length; i++) {
    worksheet3.getCell(`B${cntt+16}`).value = i + 1;
    worksheet3.getCell(`B${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`C${cntt+16}`).value = props.tradeList[k][i][0];
    worksheet3.getCell(`C${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`D${cntt+16}`).value = props.tradeList[k][i][1];
    worksheet3.getCell(`D${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`E${cntt+16}`).value = props.tradeList[k][i][2];
    worksheet3.getCell(`E${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`F${cntt+16}`).value = props.tradeList[k][i][3];
    worksheet3.getCell(`F${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`G${cntt+16}`).value = props.tradeList[k][i][4];
    worksheet3.getCell(`G${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`H${cntt+16}`).value = props.tradeList[k][i][5];
    worksheet3.getCell(`H${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`I${cntt+16}`).value = props.tradeList[k][i][7];
    worksheet3.getCell(`I${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`J${cntt+16}`).value = props.tradeList[k][i][8];
    worksheet3.getCell(`J${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`K${cntt+16}`).value = props.tradeList[k][i][9]
    worksheet3.getCell(`K${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`L${cntt+16}`).value = props.tradeList[k][i][10];
    worksheet3.getCell(`L${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`M${cntt+16}`).value = props.tradeList[k][i][13]
    worksheet3.getCell(`M${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`N${cntt+16}`).value = props.tradeList[k][i][14];
    worksheet3.getCell(`N${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`O${cntt+16}`).value = props.tradeList[k][i][15];
    worksheet3.getCell(`O${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`P${cntt+16}`).value = props.tradeList[k][i][16];
    worksheet3.getCell(`P${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    worksheet3.getCell(`Q${cntt+16}`).value = props.tradeList[k][i][17];
    worksheet3.getCell(`Q${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
    cntt++;
  }
  cntt++;
  worksheet3.getCell(`B${cntt+16}`).fill={fgColor:{ argb: '2D5BFF' },type: 'pattern',pattern: 'solid',}
  worksheet3.getCell(`B${cntt+16}`).value = 'Month: '+ k
  worksheet3.getCell(`B${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
  worksheet3.getCell(`E${cntt+16}`).value =  'BookedProfit'
  worksheet3.getCell(`E${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
  worksheet3.getCell(`F${cntt+16}`).value = props.bookedMonthProfit[k]
  worksheet3.getCell(`F${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
  worksheet3.getCell(`G${cntt+16}`).value = 'Expiry Loss'
  worksheet3.getCell(`G${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
  worksheet3.getCell(`H${cntt+16}`).value = props.monthlyLossExpiry[k]
  worksheet3.getCell(`H${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
  worksheet3.getCell(`I${cntt+16}`).value = 'NetProfit/Loss'
  worksheet3.getCell(`I${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
  worksheet3.getCell(`J${cntt+16}`).value = props.monthlyProfit[k]
  worksheet3.getCell(`J${cntt+16}`).border = {top: borderStyle,left: borderStyle,bottom: borderStyle,right: borderStyle};
  cntt+=2;
}

    // Save the workbook
    workbook.xlsx.writeBuffer().then((buffer) => {
      const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
      saveAs(data, `series_trading.xlsx`)
    });
  };

  return (
    <div>
      <button className="bg-blue-600 text-white p-1 text-[16px] rounded font-serif" onClick={generateExcel1}>Download Excel</button>
    </div>
  );
};

export default Excel;
