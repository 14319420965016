import React,{useState} from 'react'
import Navbar from '../Layout/Navbar'
import { MdNewReleases } from "react-icons/md";

const FailurePaymentPage = () => {
  const [reason,setReason]=useState("");
  return (
    <div>
        <Navbar/>
        <div className='flex justify-center items-center min-h-screen bg-gray-100'>
      <div className='rounded-lg w-full max-w-md shadow-lg shadow-red-600 bg-white p-6'>
        <div className="flex justify-center mb-4">
          <MdNewReleases sx={{ color: "red", fontSize: "60px" }} />
        </div>
        <div className="text-center text-red-600 text-2xl font-bold mb-4">Oh No, Your Payment Failed!</div>
        <div className='text-center text-blue-600 text-lg font-semibold mb-2'>Reason:</div>
        <div className="text-center text-gray-700 text-base font-medium mb-6">
          {reason || "Ensure that you've entered the correct card number, expiration date, CVV, and other required details."}
        </div>
        <div className='flex justify-center'>
          <a href='/' className='bg-green-500 text-white py-2 px-6 rounded-lg font-semibold border-2 border-green-500 hover:bg-green-600 transition-all'>
            Try Again
          </a>
        </div>
      </div>
    </div>
    </div>
  )
}

export default FailurePaymentPage