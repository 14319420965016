import React, {  useState } from 'react';
import { ANALYSIS_TRADING } from '../../../constant/constant';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { Tooltip } from 'react-tooltip';

const Gold = () => {
    const [startDate, setStartDate] = useState("2023-07-05");
    const [endDate, setEndDate] = useState("2024-02-18");
    const [startTime, setStartTime] = useState("00:01");
    const [endTime, setEndTime] = useState("23:59");
    const [rangeDate, setRangeDate] = useState(["2024-01-25","2024-01-26","2024-01-27","2024-01-28","2024-01-29","2024-01-30","2024-01-31"]);
    const [rangeTime, setRangeTime] = useState(["16:59"]);
    const [date,setDate]=useState('2024-02-18');
    const [time,setTime]=useState('23:59')
    const [option,setOption]=useState(1);
    // Result
    const [tradeList, setTradeList] = useState([])
    const [tradeList1,setTradeList1] = useState([])
    const [resultShow, setResultShow] = useState(false);
    const [dataType,setDataType]=useState("gold_data")
    const [expiryResult,setExpiryResult]=useState([])
    // page Size
    const [pageSize, setPageSize] = useState(1)
    const [currentIndex, setCurrentIndex] = useState(1)
    // loader
    const [loader, setloader] = useState(false);
    const [loader1, setloader1] = useState(false);
    // monthlyResult
    const [monthlyResult, setMonthlyResult] = useState([])
    const [showResult, setShowResult] = useState(1);
    const [check,setCheck]=useState(true);

    const calculateDifference = (arr) => {
        let result = [];
        arr[1].diff = '--';
        arr[1].diff1 = '--';
        result.push(arr[1]);
        let maxi = 0, mini = 0;
        for (let i = 2; i < arr.length; i++) {
            if (arr[i - 1].Date == arr[i].Date) {
                let diff = arr[i].open - arr[i - 1].close;
                let diff1 = arr[i].close - arr[i - 1].close;
                arr[i].diff = diff.toFixed(2);
                arr[i].diff1 = diff1.toFixed(2);
                result.push(arr[i]);
                if (diff > maxi)
                    maxi = diff;
                if (diff < mini)
                    mini = diff;
            } else {
                arr[i].diff = '--';
                arr[i].diff1 = '--';
                result.push(arr[i]);
            }
        }
        console.log(maxi, 'maximum')
        console.log(mini, 'minimum')
        setTradeList(result);
    }
    const dataMoreThanTwoDollar = (arr) => {
        let result = [];
        arr[1].diff = '--';
        arr[1].diff1 = '--';
        let maxi = 0, mini = 0;
        for (let i = 2; i < arr.length; i++) {
            if (arr[i - 1].Date == arr[i].Date) {
                let diff = arr[i].open - arr[i - 1].close;
                let diff1 = arr[i].close - arr[i - 1].close;
                arr[i].diff = diff.toFixed(2);
                arr[i].diff1 = diff1.toFixed(2);
                if(diff>2)
                {
                    result.push(arr[i-1]);
                    result.push(arr[i]);
                }
                if (diff > maxi)
                    maxi = diff;
                if (diff < mini)
                    mini = diff;
            }else{
            arr[i].diff = '--';
            arr[i].diff1 = '--';
            }
        }
        setTradeList1(result);
    }
    // Sorting the Data on the basis of Index
    const startSeriesTrading = (choice) => {
        setloader(true);
        setResultShow(false);
        const token = secureLocalStorage.getItem('token');
        const data = { "startDate": startDate, "endDate": endDate, "startTime": startTime, "endTime": endTime,"rangeDate":rangeDate,"rangeTime":rangeTime,"choice":choice,"dataType":dataType}
        let config = { method: "post", maxBodyLength: Infinity, url: ANALYSIS_TRADING, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
        axios(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                calculateDifference(response.data.result);
                setExpiryResult(response.data.expiry);
                dataMoreThanTwoDollar(response.data.result);
                setResultShow(true);
                setloader(false);
                setloader1(false);
            }).catch((error) => {
                console.log(error, 'error');
                setloader(false);
            })
    };
    return (
        <div className='bg-[#DAECF2] min-h-screen'>
            <div className='flex flex-col m-2'>
                <div className='grid sm:grid-cols-2 lg:grid-cols-4'>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>End Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Time: 1</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Time: 2</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endTime} onChange={(e) => { setEndTime(e.target.value); }}></input>
                    </div>
                </div>
                {!loader && <div className='bg-[#2D5BFF] h-8 rounded-md text-center text-white text-[16px] cursor-pointer' onClick={()=>{startSeriesTrading(1);}}>Start Series Trading</div>}
                {loader && <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}
                <div className='flex text-center justify-center'>OR</div>
                <div className='grid grid-cols-1 md:grid-cols-2'>
                <div className='flex flex-row bg-white p-2 m-2'>
                <div className='w-40 text-[18px]'>Start Date</div>
                    <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                </div>
                <div className='flex flex-row bg-white p-2 m-2'>
                    <div className='w-40 text-[18px]'>End Date</div>
                    <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2'>
                <div className='flex flex-row bg-white p-2 m-2 gap-2'>
                    <div className='w-40 text-[18px]'>Range Date</div>
                    <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={date} onChange={(e) => setDate(e.target.value)} />
                    <div className='rounded bg-blue-600 text-center text-white w-36' onClick={()=>{setRangeDate([...rangeDate,date])}}>Add</div>
                </div>
                <div className='flex flex-row bg-white p-2 m-2 gap-2'>
                    <div className='w-40 text-[18px]'>Time</div>
                    <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={time} onChange={(e) => setTime(e.target.value)} />
                    <div className='rounded bg-blue-600 text-center text-white w-36' onClick={()=>{setRangeTime([...rangeTime,time])}}>Add</div>
                </div>
                </div>
                <div className='grid grid-cols-1 md:grid-cols-2'>
                    <div className='flex flex-row bg-white p-2 m-2 gap-2'>{JSON.stringify(rangeDate)}</div>
                    <div className='flex flex-row bg-white p-2 m-2 gap-2'>{JSON.stringify(rangeTime)}</div>
                </div>
                {!loader1 && <div className='bg-[#2D5BFF] h-8 rounded-md text-center text-white text-[16px] cursor-pointer' onClick={()=>{startSeriesTrading(2);}}>Start Series Trading</div>}
                {loader1 && <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}
                <div className='flex flex-row gap-4 m-2'>
                    <div className='grid grid-cols-2 gap-4 w-full bg-white'>
                    <div className='text-[18px] pl-4'>DataType</div>
                    <select className='text-l w-full bg-[#ECF0FF] p-1 flex-grow outline-0' value={dataType} onChange={(e)=>{setDataType(e.target.value);}}>
                        <option value="gold_data">Unadjusted Data</option>
                        <option value="adjusted_gold_data">Adjusted Data</option>
                    </select>
                </div>
                </div>
                
                <div className='flex justify-start'>
                    <div className='flex flex-col p-1 text-left text-[12px]'>
                        <div className='text-[14px]'>*Choice 1 will give all data of time 1 and time 2 from start Date to End Date.</div>
                        <div className='text-[14px]'>*Choice 2 will give all date that show in Date and time.</div>
                        <div className='text-[14px]'>*<span className='font-[600]'>1. Unadjusted</span> - actual historic traded prices with no adjustments. 
                        <span className='font-[600] ml-4'>2. Adjusted</span> - prices adjusted for price jumps on contract roll date</div>
                        <div className='text-[14px]'>*For the GC contract,the roll occurs 2 business before the end of the month prior to the expiry month.So for example for the Dec 23 Contract,this rolled on Nov 28</div>
                        <div className='text-[14px]'>*Data is present from 31st January,2008 to 26th February,2024.</div>
                        <div className='text-[14px]'>*Range of Time is 18:00 to 16:59 </div>
                        <div className='text-[14px]'>*Profit and Price is in dollar.</div>
                    </div></div>
                <div className='h-[4px] bg-white'></div>
                
            </div>
            {resultShow && <div className='flex flex-col'>
                <div className='bg-white text-xl rounded-md m-2 text-center'>Series Trading Result</div>
                {/* Only For Result */}
                <div className='flex flex-row'>
                    <div className='ml-2 text-[18px] p-1'>Filter: </div>
                    <select className='bg-white outline-0 p-1' value={option} onChange={(e)=>{setOption(e.target.value);}}>
                        <option value={1}>Daily Data</option>
                        <option value={2}>Data More than 2 Dollar</option>
                        <option value={3}>Expiry Date</option>
                    </select>

                </div>
                {option==1 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-10 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px]'>S.No</div>
                        <div className='font-[600] text-[16px]'>TimeStamp&nbsp;</div>
                        <div className='font-[600] text-[16px]'>Open&nbsp;</div>
                        <div className='font-[600] text-[16px]'>High&nbsp;</div>
                        <div className='font-[600] text-[16px]'>Low</div>
                        <div className='font-[600] text-[16px]'>Close&nbsp;</div>
                        <div className='font-[600] text-[16px]'>Date</div>
                        <div className='font-[600] text-[16px]'>Time</div>
                        <div className='font-[600] text-[16px]'>Diff(Open(18:00)-Close(16:59))</div>
                        <div className='font-[600] text-[16px]'>Diff(close(18:00)-Close(16:59))</div>
                    </div>
                    {
                        tradeList.map((item, index) => {
                            return (
                                <div className='grid grid-cols-10 border-b-2 min-w-[1440px] p-2 text-center' key={index} style={{ backgroundColor: index % 2 == 1 ? '#F8F8F8' : 'white', color: item.diff > 10 ? 'red' : '' }}>
                                    <div className='text-[16px]'>{index + 1}</div>
                                    <div className='text-[16px]'>{item.timestamp}</div>
                                    <div className='text-[16px]'>${item.open}</div>
                                    <div className='text-[16px]'>${item.high}</div>
                                    <div className='text-[16px]'>${item.low}</div>
                                    <div className='text-[16px]'>${item.close}</div>
                                    <div className='text-[16px]'>{item.Date}</div>
                                    <div className='text-[16px'>{item.Time}</div>
                                    <div className='text-[16px' >{item.diff}</div>
                                    <div className='text-[16px'>{item.diff1}</div>
                                </div>
                            )
                        })
                    }
                </div>}

                {/* -------------------------------------------------------------------------------------- */}
                {/* Data Difference more than 2 dollar */}
                {option==2 && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-10 border-b-2 border-black min-w-[1440px] text-center p-2'>
                        <div className='font-[600] text-[16px]'>S.No</div>
                        <div className='font-[600] text-[16px]'>TimeStamp&nbsp;</div>
                        <div className='font-[600] text-[16px]'>Open&nbsp;</div>
                        <div className='font-[600] text-[16px]'>High&nbsp;</div>
                        <div className='font-[600] text-[16px]'>Low</div>
                        <div className='font-[600] text-[16px]'>Close&nbsp;</div>
                        <div className='font-[600] text-[16px]'>Date</div>
                        <div className='font-[600] text-[16px]'>Time</div>
                        <div className='font-[600] text-[16px]'>Diff(Open(18:00)-Close(16:59))</div>
                        <div className='font-[600] text-[16px]'>Diff(close(18:00)-Close(16:59))</div>
                    </div>
                    {
                        tradeList1.map((item, index) => {
                            return (
                                <div className='grid grid-cols-10 border-b-2 min-w-[1440px] p-2 text-center' key={index} style={{ backgroundColor: index % 2 == 1 ? '#F8F8F8' : 'white', color: item.diff > 10 ? 'red' : '' }}>
                                    <div className='text-[16px]'>{index + 1}</div>
                                    <div className='text-[16px]'>{item.timestamp}</div>
                                    <div className='text-[16px]'>${item.open}</div>
                                    <div className='text-[16px]'>${item.high}</div>
                                    <div className='text-[16px]'>${item.low}</div>
                                    <div className='text-[16px]'>${item.close}</div>
                                    <div className='text-[16px]'>{item.Date}</div>
                                    <div className='text-[16px'>{item.Time}</div>
                                    <div className='text-[16px' >{item.diff}</div>
                                    <div className='text-[16px'>{item.diff1}</div>
                                </div>
                            )
                        })
                    }
                </div>}
                {/* Expiry result */}
                {option==3 && <div >
                <div className='m-2 text-center text-xl font-[600]'>Expiry Result</div>
                <div className='grid grid-cols-6 gap-2 m-2'>
                    {
                        expiryResult.map((item,key)=>{
                            return(
                                <div className='bg-white text-center'>{item}</div>
                            )
                        })
                    }
                </div>
                </div>}

                {/* Page Size-> No. Of Items */}
                <div className="grid grid-cols-2" >
                    <div className="flex flex-row m-10">
                        <div className="font-[600] text-[16px]">Page Size</div>
                        <select className="ml-3 outline-0 rounded-md" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                            <option value={1}>1 month</option>
                            <option value={3}>3 month</option>
                            <option value={6}>6 month</option>
                            <option value={12}>12 month</option>
                        </select>
                    </div>
                    <div className="grid justify-end  mb-10 mt-10 mr-10">
                        <div className="flex flex-row gap-2">
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowLeft onClick={() => { setCurrentIndex(currentIndex - 1); }} /></div>
                            <div className="rounded h-[32px] w-[32px] font-[Ubuntu]  text-[16px] font-[700] text-center text-[#2D5BFF] justify-center border-[1px] border-[#ECF0FF] cursor-pointer active:bg-[#ECF0FF]">{currentIndex}</div>
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowRight onClick={() => { setCurrentIndex(currentIndex + 1); }} /></div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Tooltip id="my-tooltip" style={{ width: '300px', borderRadius: '10px', backgroundColor: 'rgb(147 197 253)', color: 'black', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)' }} />
        </div>
    );
};

export default Gold;
