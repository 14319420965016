import React from 'react';
import { FaChartLine, FaListUl, FaSearch, FaCogs, FaDownload, FaInfoCircle, FaHandsHelping } from 'react-icons/fa';
import useBucketStore from '../../store/BucketStore';
import result from './../../assets/5001093.jpg'
import view from './../../assets/projectmanager_4.jpg'
import { AiOutlineMonitor } from "react-icons/ai";
import Dashboard1 from './../Layout/Home/assets/Dashboard.webp';
import tradingImage from './../../assets/trading.jpg'
import { FaPlus } from 'react-icons/fa';


const Dashboard = () => {
    const { boxShow, updateBoxShow } = useBucketStore();
    return (
        <div className="min-h-screen">
            <div className="bg-[#DAECF2] rounded-lg" >

                {/* Header Section */}
                <div className="bg-white mx-auto p-8 rounded-lg shadow-md flex flex-row justify-between" style={{ backgroundImage: `url(${Dashboard1})` }}>
                    <div className='flex flex-col gap-4'>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #4facfe, #00f2fe)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #43e97b, #38f9d7)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #f7971e, #ffd200)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #f7971e, #ffd200)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #43e97b, #38f9d7)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #4facfe, #00f2fe)' }}></div>
                    </div>


                    <div className="text-center">
                        <h1 className="text-4xl font-extrabold text-white mb-6">
                            Fprognos Back-Testing Strategies Comparison
                        </h1>
                        <div className='flex flex-row justify-center items-center space-x-4'>
                            <AiOutlineMonitor className="text-6xl text-white w-[60px]" />
                            <div className='flex flex-col text-white'>
                                <div className="text-3xl font-serif">
                                    Start comparing strategies with ease
                                </div>
                                <div className="text-2xl font-serif">
                                    Effortlessly using <span className="text-white underline">StratsCompare!</span>
                                </div>
                            </div>
                        </div>


                        <button
                            className="mt-8 text-xl bg-gradient-to-r from-blue-500 to-blue-600 text-white font-semibold py-3 px-6 rounded-full hover:shadow-lg transform hover:scale-105 transition-transform duration-300 flex items-center mx-auto"
                            onClick={() => { updateBoxShow(true); }}
                        >
                            <FaPlus className="mr-2" />
                            Add Strats Compare
                        </button>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #4facfe, #00f2fe)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #43e97b, #38f9d7)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #f7971e, #ffd200)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #f7971e, #ffd200)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #43e97b, #38f9d7)' }}></div>
                        <div className='h-5 w-[120px] transient' style={{ background: 'radial-gradient(circle, #4facfe, #00f2fe)' }}></div>
                    </div>

                </div>


                {/* Content Section */}
                <div className="space-y-10 mt-10 p-6">

                    <section className="flex flex-col items-center text-center py-12">
                        <h2 className="text-4xl font-extrabold text-gray-800 mb-8 flex items-center justify-center">
                            <FaInfoCircle className="mr-3 text-blue-700" /> Overview of the Page:
                        </h2>
                        <div className="grid grid-cols-3 gap-4">
                            <p className="text-xl text-gray-800 mb-6">
                                <div className='flex text-center justify-center'><img src={tradingImage} className='h-44 w-44 rounded flex text-center justify-center' /></div>
                                <span className="font-semibold text-black">View and Compare Strategies:</span>
                                <p>Discover how you can effortlessly <span className="font-bold text-blue-700">view and compare multiple back-testing strategies</span> side by side. Our platform offers an intuitive interface that simplifies complex comparisons.</p>
                            </p>
                            <p className="text-xl text-gray-800 mb-6">
                                <div className='flex text-center justify-center'><img src={result} className='h-44 w-44 rounded' /></div>
                                <span className="font-semibold text-black">Analyze Performance Metrics:</span>
                                <p> Gain deep insights into the <span className="font-bold text-blue-700">performance metrics</span> of each strategy, and make data-driven decisions with detailed results and analytics.</p>
                            </p>
                            <p className="text-xl text-gray-800 mb-6">
                                <div className='flex text-center justify-center'><img src={view} className='h-44 w-44 rounded' /></div>
                                <span className="font-semibold text-black">Access Detailed Results:</span>
                                <p> Easily access <span className="font-bold text-blue-700">comprehensive results</span> for individual strategies. Our detailed breakdown ensures you have all the necessary information to evaluate each strategy thoroughly.</p>
                            </p>
                        </div>
                    </section>
                    {/* Features Section */}
                    <section className='p-6 bg-white'>
                    <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
                                    <FaCogs className="mr-2 text-blue-600" /> Features:
                                </h2>
                        <div className="grid md:grid-cols-2 gap-8">
                              
                                {[
                                    { icon: FaListUl, title: 'Multi-Strategy Comparison', items: ['Compare key performance indicators (KPIs) like returns, volatility, and Sharpe ratio.', 'Visualize trends to identify the most effective strategies.'] },
                                    { icon: FaChartLine, title: 'Interactive Graphs and Tables', items: ['Use interactive charts to compare historical performance.', 'Tables provide easy numerical comparisons.'] },
                                    { icon: FaSearch, title: 'Individual Strategy Analysis', items: ['Expand any strategy for detailed metrics and data.', 'View graphical performance trends and risk profiles.'] },
                                    { icon: FaCogs, title: 'Customizable Views', items: ['Filter strategies by criteria like date range or risk level.', 'Sort and rank strategies based on various metrics.'] },
                                    { icon: FaDownload, title: 'Download and Export Options', items: ['Export comparison results to Excel or PDF for offline analysis.', 'Download charts and tables for reporting purposes.'] },
                                ].map((feature, index) => (
                                    <div key={index}>
                                        <h3 className="text-xl font-bold text-blue-500 mb-2 flex items-center">
                                            <feature.icon className="mr-2" /> {feature.title}
                                        </h3>
                                        <ul className="list-disc list-inside text-gray-700 ml-5 text-lg">
                                            {feature.items.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}

                        </div>
                    </section>

                    {/* How to Use Section */}
                    <section className='bg-[#FDCB02] w-full p-6'>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
                            <FaHandsHelping className="mr-2 text-blue-600" /> How to Use:
                        </h2>
                        <div className="grid md:grid-cols-2 gap-8">

                            <div className="space-y-8">
                                {[
                                    { icon: FaListUl, title: 'Select Strategies', items: ['Choose the strategies you want to compare from the list.', 'Add or remove strategies to refine your comparison.'] },
                                    { icon: FaChartLine, title: 'Analyze Comparison', items: ['Review side-by-side metrics and visualizations.', 'Identify which strategies perform best under different conditions.'] },
                                    { icon: FaSearch, title: 'Dive Deeper', items: ['Click on a strategy for detailed results and insights.', 'Analyze individual metrics, risk assessments, and historical data.'] },
                                ].map((step, index) => (
                                    <div key={index}>
                                        <h3 className="text-xl font-bold text-blue-500 mb-2 flex items-center">
                                            <step.icon className="mr-2" /> {step.title}
                                        </h3>
                                        <ul className="list-disc list-inside text-gray-700 ml-5 text-lg">
                                            {step.items.map((item, i) => (
                                                <li key={i}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                            <div className="flex justify-center items-center h-auto">
                                <iframe
                                    src="https://www.youtube.com/embed/cCpzc1ezc1Y"
                                    title="Strategy Video"
                                    className="w-full h-full rounded-lg shadow-md"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        </div>

                    </section>

                    {/* Support Section */}
                    <section className='bg-blue-200 p-6'>
                        <h2 className="text-2xl font-semibold text-gray-800 mb-4 flex items-center">
                            <FaInfoCircle className="mr-2 text-blue-600" /> Support:
                        </h2>
                        <p className="text-lg text-gray-700 leading-relaxed mb-6">
                            Access help and documentation for detailed instructions on using the comparison tools. Contact support for any technical issues or questions.
                        </p>
                        <p className="text-lg text-gray-700 leading-relaxed">
                            Feel free to explore and utilize the features to make informed decisions and optimize your back-testing strategy analysis.
                        </p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
