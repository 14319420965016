import React, { useState, useEffect ,useRef} from 'react'
import { Dialog } from '@mui/material';
import axios from 'axios';
import FooterVersion2 from '../Layout/FooterVersion2';
import secureLocalStorage from 'react-secure-storage';
import Navbar from '../Layout/Navbar'
import ProfileUpload from './ProfileUpload'
import { PROFILE_DETAIL, UPDATE_PROFILE_DETAIL } from '../../constant/constant';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import TempProfile from './TempProfile'


const Profile = () => {
  const [name,setName]=useState('')
  const [address,setAddress]=useState('')
  const [broker,setBroker]=useState('')
  const [gender,setGender]=useState('Male')
  const [contact,setContact]=useState('')
  const [profile,setProfile]= useState(null);
  const [profilePic, setProfilePic] = useState(null)
  const [activeAccount, setActiveAccount] = useState(false);
  const [activeComponent, setActiveComponent] = useState("profile");
  const [editProfile, setEditProfile] = useState(false);
  const navigate = useNavigate();

  const updateProfile = () => {
    const data = { "name": name, "address": address, "broker": broker, "gender": gender, "contact": contact, "email": secureLocalStorage.getItem('email') }
    let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: UPDATE_PROFILE_DETAIL,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        alert("Profile Update Successfully")
        profileDetail();
      })
      .catch((error) => {
        toast(error.response.data.message);
        console.log(error);
      });
  }


  const profileDetail = () => {
    const id = secureLocalStorage.getItem('id');
    const data = { "id": id }
    // console.log(data);
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: PROFILE_DETAIL,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        setProfile(response.data.data);
        console.log(response.data.data)
        setName(response?.data?.data?.name);
        setGender(response?.data?.data?.gender);
        setBroker(response?.data?.data?.broker);
        setAddress(response?.data?.data?.address);
        setContact(response?.data?.data?.contact);
      })
      .catch((error) => {
        if(error?.response?.data?.message)
          toast(error?.response?.data?.message);
        else
          toast('Network Issue');
        console.log(error);
      });
  }
  useEffect(() => {
    profileDetail();
    const active = secureLocalStorage.getItem('active');
    setActiveAccount(active);

  }, [])
  return (
    <>
      <div className='bg-[#DAECF2]'>
        <div className="border-b-4 border-blue-500 fixed w-full z-10">
          <Navbar profilePic={profilePic} />
        </div>
        {!activeAccount && <div className="text-red-600 pt-12 z-0 text-center">**Your account is not active.It will be active within 24hrs.You have to re-login for access backtest tool.</div>}
        {activeAccount && <div className='pt-16'></div>}

        <div className='rounded mt-[40px] flex text-center justify-center'>
          <div className='flex flex-col bg-white rounded-3xl w-full m-2 md:w-10/12 lg:w-8/12 mb-10'>
            <div className='grid grid-cols-4 text-center '>
              <div className="cursor-pointer font-[600] text-[22px] p-2 text-black border-b-2" style={{ borderColor: activeComponent == "profile" ? "blue" : "white" }} onClick={() => { setActiveComponent("profile") }}>Profile</div>
              <div className='cursor-pointer font-[600] text-[22px] p-2 text-black border-b-2' style={{ borderColor: activeComponent == "plan" ? "blue" : "white" }} onClick={() => { setActiveComponent("plan"); }}>Plan Detail</div>
              <div className='cursor-pointer font-[600] text-[22px] p-2 text-black border-b-2' style={{ borderColor: activeComponent == "transaction" ? "blue" : "white" }} onClick={() => { setActiveComponent("transaction"); }}>Transaction</div>
              <div className='cursor-pointer font-[600] text-[22px] p-2 text-black border-b-2' style={{ borderColor: activeComponent == "livetrading" ? "blue" : "white" }} onClick={() => { setActiveComponent("livetrading"); }}>Trading Profile</div>
            </div>
            <div className="grid grid-cols-1 h-full">
              {activeComponent == "profile" && <div className="flex flex-col sm:flex-row bg-white rounded-lg  shadow" >
                <div className="flex text-center justify-center p-4">
                  <ProfileUpload setProfilePic={setProfilePic} />
                </div>
                <div className="p-4 text-left flex-grow">
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif" >Your Name  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.name} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif" >Email</label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1 " value={secureLocalStorage.getItem('email')} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif">Contact Number  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.contact} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif">Current Home Address  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.address} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif">Gender  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.gender} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-1 mt-3">
                    <label className="text-[16px] font-serif">Broker  </label>
                    <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] text-[#718EBF] leading-4 pl-1" value={profile?.broker} disabled={true}></input>
                  </div>
                  <div className="grid grid-cols-2 rounded mt-3">
                    <button style={{ backgroundColor: "#1814F3", color: "white", height: "40px", width: "190px", borderRadius: "15px", fontSize: "18px", lineHeight: "21.78px", fontWeight: "500" }} onClick={() => { setEditProfile(true); }}>Edit Profile</button>
                  </div>
                </div>
              </div>}
              {activeComponent == "plan" && <PlanDetail />}
              {activeComponent == "transaction" && <Transaction />}
              {activeComponent == "livetrading" && <TempProfile/>}
            </div>
          </div>
        </div>
      </div>

      <FooterVersion2 />
      {/* Confirmation to Delete saved Strategy */}
      <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl w-full" maxWidth="xl" minWidth="l" onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setEditProfile(false); }
      }} open={editProfile}>
        <div className="flex flex-row border-[1px] w-[680px]">
          <div className="text-[#232323] text-[22px] font-[600] p-2">Edit profile</div>
          <div className="text-[22px] font-[600] text-[#232323] flex-grow flex justify-end p-2 cursor-pointer"><CloseIcon onClick={() => { setEditProfile(false); }} /></div>
        </div>
        <div className="flex flex-col text-left m-2">
          <label className="text-[16px] font-serif" >Your Name*  </label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0  text-[#718EBF] leading-4 pl-1" value={name} onChange={(e)=>{setName(e.target.value);}}></input>
          <label className="text-[16px] font-serif" >Email*</label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1 " disabled={true} value={secureLocalStorage.getItem('email')} ></input>
          <label className="text-[16px] font-serif">Contact Number*  </label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1" value={contact} onChange={(e)=>{setContact(e.target.value);}} ></input>
          <label className="text-[16px] font-serif">Current Home Address  </label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1" value={address} onChange={(e)=>{setAddress(e.target.value);}} ></input>
          <label className="text-[16px] font-serif">Gender  </label>
          <select className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1" value={gender} onChange={(e)=>{setGender(e.target.value);}} >
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Others">Other</option>
          </select>
          <label className="text-[16px] font-serif">Broker  </label>
          <input className="flex-grow justify-end  border-[1px] border-[#DFEAF2] rounded text-[18px] outline-0 text-[#718EBF] leading-4 pl-1" value={broker} onChange={(e)=>{setBroker(e.target.value);}} ></input>
          <hr className="mt-2 bg-blue-400"></hr>
          <div className="flex justify-end gap-2 p-1">
            <button className="border-2 p-1 w-24 bg-[#2D5BFF] rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { updateProfile(); setEditProfile(false); }}>Save</button>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Profile

const PlanDetail = () => {
  return (
    <>
      <div className="bg-white rounded-lg shadow grid grid-cols-1 h-[450px]">
        <div className="shadow rounded m-10">
          <h1 className="p-2  font-serif bg-blue-300 rounded font-[600] text-[#333B69]">Running Plan Details</h1>
          <div className="grid grid-cols-1 text-left justify-center md:grid-cols-1 lg:grid-cols-1">
            <div className="grid grid-cols-1 sm:grid-cols-2 p-2 border-b-2">
              <h2 className="text-[16px] font-serif">Remaining Credit  </h2>
              <h2 className="  text-blue-700 text-l">-------------------</h2>
            </div>
            <div className=" grid grid-cols-1 sm:grid-cols-2 p-2 bg-blue-200">
              <h2 className="text-[16px] font-serif ">Expiry Date Of Plan  </h2>
              <h2 className="text-blue-700 text-l">-------------------</h2>

            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 p-2">
              <h2 className="text-[16px] font-serif">Total Coin Collected  </h2>
              <h2 className="text-blue-700 text-l">-------------------</h2>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 p-2 bg-blue-200">
              <h2 className="text-[16px] font-serif">Broker  </h2>
              <h2 className="text-blue-700 text-l">-------------------</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const Transaction = () => {
  return (
    <>
      {/* Added transaction history table */}
      <div className="bg-white  border-2 p-3 rounded-lg overflow-x-none overflow-y-auto justify-center h-[450px]">
        <h3 className="-mt-3 -ml-3 -mr-3 p-3 text-[#333B69] font-[600]">TRANSACTION HISTORY</h3>
        <hr></hr>
        <table className=" overflow-y-none overflow-x-auto w-full">
          <thead className="text-black text-center justify-center">
            <tr className='text-left'>
              <td className="font-medium font-serif text-[18px]">Transaction Id</td>
              <td className="font-medium font-serif text-[18px]">Package</td>
              <td className="font-medium font-serif text-[18px]">Date</td>
              <td className="font-medium font-serif text-[18px]">Time</td>
              <td className="font-medium font-serif text-[18px]">Validation</td>
            </tr>
          </thead>
          <tbody className='bg-blue-200'>
            <tr className='text-[12px]'>
              <td className="p-2 text-[14px]">4883UHSHJHKSUY8</td>
              <td className="p-2 text-[14px]">444</td>
              <td className="p-2 text-[14px]">18/11/2000</td>
              <td className="p-2 text-[14px]">05:30</td>
              <td className="p-2 text-[14px]">30 day</td>
            </tr>
          </tbody>
          <tbody>
            <tr className='text-[12px]'>
              <td className="p-2 text-[14px]">4883UHSHJHKSUY8</td>
              <td className="p-2 text-[14px]">444</td>
              <td className="p-2 text-[14px]">18/11/2000</td>
              <td className="p-2 text-[14px]">05:30</td>
              <td className="p-2 text-[14px]">60 day</td>
            </tr>
          </tbody>
          <tbody className='bg-blue-200'>
            <tr className='text-[12px]'>
              <td className="p-2 text-[14px]">4883UHSHJHKSUY8</td>
              <td className="p-2 text-[14px]">444</td>
              <td className="p-2 text-[14px]">18/11/2000</td>
              <td className="p-2 text-[14px]">05:30</td>
              <td className="p-2 text-[14px]">15 day</td>
            </tr>
          </tbody>
          <tbody>
            <tr className='text-[12px]'>
              <td className="p-2 text-[14px]">4883UHSHJHKSUY8</td>
              <td className="p-2 text-[14px]">444</td>
              <td className="p-2 text-[14px]">18/11/2000</td>
              <td className="p-2 text-[14px]">05:30</td>
              <td className="p-2 text-[14px]">30 day</td>
            </tr>
          </tbody>
          <tbody className='bg-blue-200'>
            <tr className='text-[12px]'>
              <td className="p-2 text-[14px]">4883UHSHJHKSUY8</td>
              <td className="p-2 text-[14px]">444</td>
              <td className="p-2 text-[14px]">18/11/2000</td>
              <td className="p-2 text-[14px]">05:30</td>
              <td className="p-2 text-[14px]">30 day</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  )
}
