import React, { useState } from 'react'

const Main = (props) => {
    return (
        <>
            <div className='flex flex-col w-full'>
            <div className='flex text-center justify-center text-xl font-semibold rounded-t-md col-span-2  h-10 text-white bg-[#718EBF]'>Backtest Result</div>
            <div className="grid grid-cols-1 md:grid-cols-2 w-full mr-2 bg-white">
                <div className='grid grid-cols-1 font-medium text-xl font-serif'>
                    <div className="grid grid-cols-2 border-white p-2 bg-green-100">
                        <h1 className="pl-2 text-[#232323] border-r-2 border-white" >Estimated Margin</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF]" >&#8377; {parseFloat(props?.margin).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Minimum Capital Required</h1>
                        <h1 className="text-right pr-4 justify-center">&#8377; {props.maximumDrawdown>0?props?.margin:(props?.margin + -1*props?.res?.maximumDrawdown).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-red-100 border-white p-2 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Maximum Drawdown</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF] text-l font-medium" style={{ color: props?.res?.maximumDrawdown > 0 ? "green" : "red" }} >{parseFloat(props?.res?.maximumDrawdown).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-green-100 border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l text-l font-medium border-r-2 border-gray-100 " >Total Profit</h1>
                        <h1 className="text-right pr-4 justify-center text-[#718EBF] " style={{ color: parseFloat(props?.res?.profit) >= 0 ? "green" : "red" }} >&#8377; {parseFloat(props?.res?.profit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Max Profit</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">&#8377; {parseFloat(props?.res?.maxProfit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100 " >Max Loss</h1>
                        <h1 className="text-right pr-4 justify-center text-red-600 ">&#8377; {props?.res?.maxLoss == 999999999 ? 0 : parseFloat(props?.res?.maxLoss).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Risk Reward Ratio</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">{props?.res?.avgLoss != 0 ? (parseFloat(props?.res?.avgProfit) / parseFloat(props?.res?.avgLoss)).toFixed(2) : 'Infinity'}</h1>
                    </div>
                    
                    <div className="grid grid-cols-2 border-white p-2 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-gray-100" > Maximum Win Streak</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600" >{props?.res?.maxStreak} Days</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Maximum Loss Streak</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600">{props?.res?.lossStreak} Days</h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >MDD Days(Recovery Days)</h1>
                        <h1 className="text-right pr-4 justify-center">{parseFloat(props?.res?.mdd?.count).toFixed(0)}{`(${parseFloat(props?.res?.mdd?.recovery).toFixed(0)} Days`} {props?.res?.mdd?.continue? '- Running)':')'}<br /><span className="text-sm">({props?.res?.mdd?.startDate} - {props?.res?.mdd?.endDate})</span></h1>
                    </div>
                </div>
                <div className='grid grid-cols-1 font-medium text-xl font-serif'>
                <div className="grid grid-cols-2 border-white p-2 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100 " >Win%(days)</h1>
                        <h1 className="text-right pr-4 justify-center  text-green-600 " >{props?.res?.investmentDay != 0 ? parseFloat((props?.res?.winTrade * 100) / props?.res?.investmentDay).toFixed(0) : 0}%({props?.res?.winTrade})</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Loss%(days)</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600" >{props?.res?.investmentDay != 0 ? parseFloat((props?.res?.lossTrade * 100) / props?.res?.investmentDay).toFixed(0) : 0}%({props?.res?.lossTrade})</h1>
                    </div>

                    <div className="grid grid-cols-2 border-white p-2 bg-green-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-gray-100" >Average Profit On Win Days</h1>
                        <h1 className="text-right pr-4 justify-center text-green-600 " >&#8377; {parseFloat(props?.res?.avgProfit).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-2 bg-red-100 border-2">
                        <h1 className="pl-2 text-[#232323]  text-l font-medium border-r-2 border-white" >Average Loss On Loss Days</h1>
                        <h1 className="text-right pr-4 justify-center text-red-600">&#8377; {-1 * parseFloat(props?.res?.avgLoss).toFixed(0)}</h1>
                    </div>
                    <div className="grid grid-cols-2  border-white p-1 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Gap Up (*pdc)</h1>
                        <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.res?.gap?.upProfit).toFixed(0)}{`(${props?.res?.gap?.upProfitCnt})`}</span><br/> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.res?.gap?.upLoss).toFixed(0)}{`(${props?.res?.gap?.upLossCnt})`}</span> </h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Gap Down (*pdc)</h1>
                        <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.res?.gap?.downProfit).toFixed(0)}{`(${props?.res?.gap?.downProfitCnt})`}</span> <br/> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.res?.gap?.downLoss).toFixed(0)}{`(${props?.res?.gap?.downLossCnt})`}</span> </h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-yellow-50 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Day Open  {'>'} PDH</h1>
                        <h1 className="text-right pr-4 justify-center"><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.res?.dayOpen?.pdhProfit).toFixed(0)}{`(${props?.res?.dayOpen?.pdhProfitCnt})`}</span> <br/> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.res?.dayOpen?.pdhLoss).toFixed(0)}{`(${props?.res?.dayOpen?.pdhLossCnt})`}</span></h1>
                    </div>
                    <div className="grid grid-cols-2 border-white p-1 bg-blue-100 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2" >Day Open  {'<'} PDL</h1>

                        <h1 className="text-right pr-4 justify-center "><span className='text-green-600'>Profit: &#8377;{parseFloat(props?.res?.dayOpen?.pdlProfit).toFixed(0)}{`(${props?.res?.dayOpen?.pdlProfitCnt})`}</span> <br/> <span className='text-red-600'>Loss: &#8377;{parseFloat(props?.res?.dayOpen?.pdlLoss).toFixed(0)}{`(${props?.res?.dayOpen?.pdlLossCnt})`}</span> </h1>
                    </div>
                    <div className="grid grid-cols-2 bg-white border-white p-1 border-2">
                        <h1 className="pl-2 text-[#232323] text-l font-medium border-r-2 border-white" >Vix</h1>
                        {props?.res?.vix && props?.res?.vix.length>2 && <h1 className="text-right pr-4 justify-center">From 1 to 10 : <span style={{ color: props?.res?.vix[0]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.res?.vix[0]?.profit).toFixed(0)}</span>{`(${props?.res?.vix[0]?.cnt})`}<br />
                        From 11 to 20 : <span style={{ color: props?.res?.vix[1]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.res?.vix[1]?.profit).toFixed(0)}</span>{`(${props?.res?.vix[1]?.cnt})`}<br />
                        Above than 20: <span style={{ color: props?.res?.vix[2]?.profit > 0 ? 'green' : 'red' }}>&#8377;{parseFloat(props?.res?.vix[2]?.profit).toFixed(0)}</span>{`(${props?.res?.vix[2]?.cnt})`}
                        </h1>}
                    </div>
                </div>
            </div>
            </div>

            {/* <div className='flex text-center justify-center mb-5'><div className='bg-white flex text-center justify-center p-2 w-48 cursor-pointer' onClick={() => { setShowStats(!showStats); }}>{showStats ? 'SHOW MORE STATS' : 'SHOW LESS STATS'}{showStats ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div></div> */}
        </>
    )
}

export default Main