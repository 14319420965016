import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Dialog } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { ToastContainer, toast } from 'react-toastify';
import Main from './Main';
// import component
import ProtectProfit from "./ProtectProfit";
import Leg from "./Leg";
import useBucketStore from '../../../store/BucketStore'
// import constant
import { ADD_STRATEGY_BUCKET, UPDATE_BASKET, PRE_DEFINED_STRATEGY } from "../../../constant/constant";
// icon
import ImportExportIcon from '@mui/icons-material/ImportExport';
import AddBox from "@mui/icons-material/AddBox";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import DateTime from "./DateTime";


const BucketBacktest = (props) => {

  const navigate = useNavigate();
  // Choose Option or Future
  const [segmentType, setSegmentType] = useState([]);
  const [segment, setSegment] = useState('option')
  // State for Main
  const [leg, setleg] = useState("1");
  const [intraday, setintraday] = useState("Yes");
  const [tradesymbol, settradesymbol] = useState("NIFTY");
  const [starttime, setStartTime] = useState("09:30");
  const [endtime, setEndTime] = useState("15:25");
  const [startdate, setstartdate] = useState("2022-01-03");
  const [enddate, setEndDate] = useState("2022-02-03");
  const [rate, setRate] = useState(0.5);
  const [expirytype, setexpirytype] = useState("Weekly");
  const [atmprice, setatmprice] = useState("spot")
  const [atm, setatm] = useState([]);
  const [atmtype, setatmtype] = useState("point");
  const [squareoff, setsquareoff] = useState("one");
  const [selectedCheckbox, setSelectedCheckbox] = useState("point");
  const [LotQuantity, setLotQuantity] = useState(50);
  const [point, setpoint] = useState([]);
  const [gap, setgap] = useState(50);
  // include leg or not for backtest
  const [includeLeg, setIncludeLeg] = useState([])


  // State for Strategy Profit
  const [stoploss, setstoploss] = useState(1);
  const [maxprofit, setmaxprofit] = useState(-1);
  // State for leg
  const [lotarray, setlotarray] = useState([]);
  const [lotarray1, setlotarray1] = useState([]);
  const [legarray, setlegarray] = useState([]);
  const [darray, setdarray] = useState([]);
  const [legSymbol, setLegsymbol] = useState([])
  const [legExpiryType, setLegExpiryType] = useState([])
  const [optionBuySell, setOptionBuySell] = useState([])
  const [optionCallPut, setOptionCallPut] = useState([])
  const [atmChoice, setAtmChoice] = useState([selectedCheckbox])
  // State for Positional
  const [startDay, setStartDay] = useState(0);
  const [endDay, setEndDay] = useState(4);
  // Variable for profit
  const [legsTargetProfitType, setLegsTargetProfitType] = useState([])
  const [legsTargetProfit, setLegsTargetProfit] = useState([])
  // Variable for Stoploss
  const [legsStoplossType, setLegsStoplossType] = useState([])
  const [legsStoploss, setLegsStoploss] = useState([])
  // State for reEntry
  const [reEntry, setReEntry] = useState(false);
  const [reEntryValue, setReEntryValue] = useState([])
  const [reEntryChoice, setReEntryChoice] = useState([])
  const [legsReEntry, setLegReEntry] = useState([false])
  const [reEntryType, setReEntryType] = useState([]);
  const [reExecuteTimes, setReExecuteTimes] = useState(1);
  // State for Wait Trade
  const [waitTrade, setwaitTrade] = useState(false);
  const [waitTradeType, setWaitTradeType] = useState([])
  const [waitTradeValue, setWaitTradeValue] = useState([])
  // State for Protect Profit
  const [protectProfit, setProtectProfit] = useState(false)
  const [protectType, setProtectType] = useState("Lock & Trail Profit");
  const [profitReaches, setProfitReaches] = useState("1000")
  const [lockMinimumProfit, setLockMinimumProfit] = useState("0")
  const [profitIncrease, setProfitIncrease] = useState("1000")
  const [trailProfit, setTrailProfit] = useState("300")
  // State for Move Sl to Cost
  const [slToCost, setSlToCost] = useState(false);
  const [disableMoveSlToCost, setDisableMoveSlToCost] = useState(false);
  // Variable for Closest Premium Choice
  const [closestPremiumValue, setClosestPremiumValue] = useState([])
  const [closestPremiumChoice, setClosestPremiumChoice] = useState([])
  // Variable for Trail Stoploss
  const [trailStoploss, setTrailStoploss] = useState([false]);
  const [trailStoplossProfit, setTrailStoplossProfit] = useState([])
  const [trailStoplossLoss, setTrailStoplossLoss] = useState([])
  const [trailStoplossType, setTrailStoplossType] = useState([])
  // Variable for Range Breakout
  const [showRangeBreakout, setShowRangeBreakout] = useState(false);
  const [rangeStartTime, setRangeStartTime] = useState("09:20")
  const [rangeEndTime, setRangeEndTime] = useState("09:30")
  const [startHour, setStartHour] = useState("09");
  const [endHour, setEndHour] = useState("15");
  const [startMinute, setStartMinute] = useState("30");
  const [endMinute, setEndMinute] = useState("20");
  const [rangeEndHour, setRangeEndHour] = useState("09");
  const [rangeEndMinute, setRangeEndMinute] = useState("40");
  // use zustand to Store management
  const { bucketStartDate, updateBucketStartDate } = useBucketStore();
  const { bucketEndDate, updateBucketEndDate } = useBucketStore();
  const { activeBasket, updateActiveBasket, addStrategiesOnActiveBasket } = useBucketStore();
  const { activeBasketName, updateActiveBasketName } = useBucketStore();
  // state for strategy
  const [visibleImportStrategy, setVisibleImportStrategy] = useState(false);
  const { savedstrategies, updateSavedStrategy } = useBucketStore();
  const [savedStrategyVisibility, setSavedStrategyVisibility] = useState(false);
  const [savedStrategyChoice, setSavedStrategyChoice] = useState(0);
  const [strategyName, setstrategyName] = useState("");
  // state for maintain result
  const { strategyMultiplier, include, updateStatsInclude, updateStatsMultiplier } = useBucketStore();
  // state for setting
  const [setting, setSetting] = useState(false);
  const BankniftyLotSize = useRef(40);
  const NiftyLotSize = useRef(50);
  const slippageList = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9]
  const inbuiltSlippage = useRef(1);
  const [settingDetail, setSettingDetail] = useState({ "bankniftylotsize": 15, "niftylotsize": 25, "slippage": 0 });
  // preDefined Strategy
  const [preDefinedStrategy, setPreDefinedStrategy] = useState([])

  // All the function of particular component
  // a)addleg(),copyleg() and delete leg()
  // b)addStrategyOnBasket()
  // c)updateStrategyOnBasket()
  // d)executeUpdateStrategyBucket()

  // set the lot size of banknify and nifty.Also set nifty and banknifty.
  const handleSetting = () => {
    const banknifty_lot_size = BankniftyLotSize.current.value;
    const nifty_lot_size = NiftyLotSize.current.value;
    const inbuilt_slippage = inbuiltSlippage.current.value;
    const settingData = { "banknifty": banknifty_lot_size, "nifty": nifty_lot_size, "slippage": inbuilt_slippage };
    console.log(settingData, 'settingData');
  }

  // method to add leg
  const addleg = () => {
    setleg(parseInt(leg) + 1);
    setSegmentType([...segmentType, segment])
    setlegarray([...legarray, "SC"]);
    setClosestPremiumChoice([...closestPremiumChoice, 'CP~'])
    selectedCheckbox == 'premium' ? setClosestPremiumValue([...closestPremiumValue, 100]) : setClosestPremiumValue([...closestPremiumValue, '5%SP']);
    setlotarray([...lotarray, 1]);
    setatm([...atm, "ATM"]);
    setpoint([...point, "true"]);
    setdarray([...darray, "100"]);
    setlotarray1([...lotarray1, 100]);
    setLegsTargetProfit([...legsTargetProfit, "-1"]);
    setLegsTargetProfitType([...legsTargetProfitType, "TP%"])
    setLegsStoploss([...legsStoploss, "-1"]);
    setLegsStoplossType([...legsStoplossType, "SL%"])
    setReEntryChoice([...reEntryChoice, "Re-Entry"])
    setReEntryValue([...reEntryValue, "1"])
    setLegReEntry([...legsReEntry, false])
    setLegsymbol([...legSymbol, tradesymbol])
    segment == 'future' ? setLegExpiryType([...legExpiryType, "Monthly"]) : setLegExpiryType([...legExpiryType, "Weekly"]);
    setOptionBuySell([...optionBuySell, "Buy"])
    setOptionCallPut([...optionCallPut, "Call"]);
    setAtmChoice([...atmChoice, selectedCheckbox])
    setWaitTradeType([...waitTradeType, 'Point ++'])
    setWaitTradeValue([...waitTradeValue, 0])
    setReEntryType([...reEntryType, 'SL'])
    setTrailStoploss([...trailStoploss, false]);
    setTrailStoplossLoss([...trailStoplossLoss, 0]);
    setTrailStoplossProfit([...trailStoplossProfit, 0]);
    setTrailStoplossType([...trailStoplossType, 'point']);
    setIncludeLeg([...includeLeg, true]);
  };

  // Method to copy leg of particular index
  const copyleg = (index) => {
    setleg(parseInt(leg) + 1);
    setSegmentType([...segmentType, segmentType[index]])
    setlegarray([...legarray, legarray[index]]);
    setlotarray([...lotarray, lotarray[index]]);
    setatm([...atm, atm[index]]);
    setpoint([...point, point[index]]);
    setdarray([...darray, darray[index]]);
    setlotarray1([...lotarray1, lotarray1[index]]);
    setLegsTargetProfit([...legsTargetProfit, legsTargetProfit[index]]);
    setLegsTargetProfitType([...legsTargetProfitType, legsTargetProfitType[index]])
    setLegsStoploss([...legsStoploss, legsStoploss[index]]);
    setLegsStoplossType([...legsStoplossType, legsStoplossType[index]])
    setReEntryChoice([...reEntryChoice, reEntryChoice[index]])
    setReEntryValue([...reEntryValue, reEntryValue[index]])
    setLegsymbol([...legSymbol, legSymbol[index]])
    setLegExpiryType([...legExpiryType, legExpiryType[index]])
    setOptionBuySell([...optionBuySell, optionBuySell[index]])
    setOptionCallPut([...optionCallPut, optionCallPut[index]]);
    setAtmChoice([...atmChoice, atmChoice[index]])
    setLegReEntry([...legsReEntry, legsReEntry[index]])
    setClosestPremiumChoice([...closestPremiumChoice, closestPremiumChoice[index]]);
    setClosestPremiumValue([...closestPremiumValue, closestPremiumValue[index]])
    setLegReEntry([...legsReEntry, false]);
    setWaitTradeType([...waitTradeType, 'Point ++']);
    setWaitTradeValue([...waitTradeValue, 0]);
    setReEntryType([...reEntryType, 'SL']);
    setTrailStoploss([...trailStoploss, false]);
    setTrailStoplossLoss([...trailStoplossLoss, 0]);
    setTrailStoplossProfit([...trailStoplossProfit, 0]);
    setTrailStoplossType([...trailStoplossType, 'point']);
    setIncludeLeg([...includeLeg, true]);
  };

  // Method to delete a particular leg
  const DeleteLeg = (index) => {
    setleg(parseInt(leg) - 1); // Update the leg count
    setSegmentType(segmentType.filter((_, i) => i !== index))
    // Create new arrays excluding the item at the specified index
    setSegmentType(segmentType.filter((_, i) => i !== index))
    setlegarray(legarray.filter((_, i) => i !== index));
    setlotarray(lotarray.filter((_, i) => i !== index));
    setatm(atm.filter((_, i) => i !== index));
    setpoint(point.filter((_, i) => i !== index));
    setdarray(darray.filter((_, i) => i !== index));
    setlotarray1(lotarray1.filter((_, i) => i !== index));
    setLegsTargetProfit(legsTargetProfit.filter((_, i) => i !== index));
    setLegsTargetProfitType(legsTargetProfitType.filter((_, i) => i !== index));
    setLegsStoploss(legsStoploss.filter((_, i) => i !== index));
    setLegsStoplossType(legsStoplossType.filter((_, i) => i !== index));
    setReEntryChoice(reEntryChoice.filter((_, i) => i !== index));
    setReEntryValue(reEntryValue.filter((_, i) => i !== index));
    setLegsymbol(legSymbol.filter((_, i) => i !== index));
    setLegExpiryType(legExpiryType.filter((_, i) => i !== index));
    setOptionBuySell(optionBuySell.filter((_, i) => i !== index));
    setOptionCallPut(optionCallPut.filter((_, i) => i !== index));
    setAtmChoice(atmChoice.filter((_, i) => i !== index));
    setLegReEntry(legsReEntry.filter((_, i) => i !== index));
    setClosestPremiumChoice(closestPremiumChoice.filter((_, i) => i !== index));
    setClosestPremiumValue(closestPremiumValue.filter((_, i) => i !== index));
    setWaitTradeType(waitTradeType.filter((_, i) => i !== index));
    setWaitTradeValue(waitTradeValue.filter((_, i) => i !== index));
    setReEntryType(reEntryType.filter((_, i) => i !== index));
    setTrailStoploss(trailStoploss.filter((_, i) => i !== index));
    setTrailStoplossLoss(trailStoplossLoss.filter((_, i) => i !== index));
    setTrailStoplossProfit(trailStoplossProfit.filter((_, i) => i !== index));
    setTrailStoplossType(trailStoplossType.filter((_, i) => i !== index));
    setIncludeLeg(includeLeg.filter((_, i) => i !== index));
  }


  const waittrade = () => {
    setWaitTradeType(Array(parseInt(leg)).fill("Point ++"));;
    setWaitTradeValue(Array(parseInt(leg)).fill(0));
  }


  // Method to check filled value is valid or not.
  const validation = () => {
    console.log(typeof (starttime), typeof (endtime), typeof (rangeStartTime), typeof (rangeEndTime), starttime, endtime, rangeEndTime, rangeStartTime)
    if (squareoff == 'all') {
      setReEntryValue(Array(leg).fill(reExecuteTimes));
      setReEntryChoice(Array(leg).fill("ReExecute"));
    }
    if (startdate > enddate) {
      toast.error('The start date must always be less than or equal to the end date.');
      return false;
    } else if (intraday == 'No' && endDay > startDay) {
      toast.error('Entry or Exit day is invalid at positional.')
      return false;
    }
    else if (showRangeBreakout && rangeStartTime > rangeEndTime) {
      toast.error('Range Start Time must be less than range End Time.!!')
      return false;
    } else if (protectProfit && profitReaches < 500) {
      toast.error('profit Reaches must be greater than or equal to 500.!!')
      return false;
    } else if (protectProfit && lockMinimumProfit > (profitReaches > 2000 ? profitReaches - 1000 : profitReaches / 2)) {
      toast.error(`Lock Minimum Profit should be maximum ${(profitReaches > 2000 ? profitReaches - 1000 : profitReaches / 2)}`)
      return false;
    } else if (protectProfit && profitIncrease < 1000) {
      toast.error('Increase Profit value should be greater than or equal to 1000.!!')
      return false;
    } else if (protectProfit && parseInt(trailProfit) > parseInt(profitIncrease)) {
      console.log(typeof (trailProfit), typeof (profitIncrease))
      toast.error('Trail Profit should be less than Increase Profit.!!')
      return false;
    } else {
      for (let i = 0; i < leg; i++) {
        if (legsStoploss[i] == '') {
          toast.error('please fill leg stoploss value!!.');
          return false;
        }
        if (legsTargetProfit[i] == '') {
          toast.error('please fill leg target profit value!!.');
          return false;
        }
      }
    }
    return true;
  }

  //  method to add strategy into basket
  const add_strategy_busket = () => {
    if (activeBasketName == '') {
      toast.error('Please Choose any Basket from List!!.')
      props.setVisible(false);
      return;
    } else if (strategyName == '') {
      toast.error('Please give a strategy name!!.')
      return;
    }
    else if (validation()) {
      let lotQuantity = []
      for (let i = 0; i < leg; i++) {
        if (tradesymbol === "NIFTY") {
          lotQuantity.push(lotarray[i] * settingDetail.niftylotsize)
        } else {
          lotQuantity.push(lotarray[i] * settingDetail.bankniftylotsize)
        }
      }
      let optionArray = []
      for (let i = 0; i < leg; i++) { optionArray.push(optionBuySell[i][0] + optionCallPut[i][0]); }
      // square off reexecute
      if (squareoff == 'all') {
        setReEntryValue(Array(leg).fill(reExecuteTimes));
        setReEntryChoice(Array(leg).fill("ReExecute"));
        setLegReEntry(Array(leg).fill(true));
      }
      let delta_array = []
      atm.map((item, key) => {
        let s1 = item.substr(3, item.length);
        if (s1 === "") s1 = "0";
        delta_array.push(s1);
        return item.substr(3, item.length);
      });


      const id = secureLocalStorage.getItem("id");
      const inputdata = {
        name: strategyName,
        array: {
          'leg': leg, 'segmentType': segmentType, 'lotarray1': lotQuantity, 'legarray': optionArray, 'darray': delta_array, 'intraday': intraday, 'tradesymbol': tradesymbol, 'gap': gap, 'stoploss': stoploss,
          'maxprofit': maxprofit, 'starttime': `${startHour}:${startMinute}`, 'endtime': `${endHour}:${endMinute}`, 'startdate': bucketStartDate, 'enddate': bucketEndDate,
          'startDay': startDay, 'endDay': endDay, 'atm': atm, 'atmprice': atmprice, 'squareoff': squareoff, 'waitTrade': waitTrade,
          'waitTradeType': waitTradeType, 'waitTradeValue': waitTradeValue, 'slToCost': slToCost, 'reEntry': reEntry, 'protectType': protectType, 'profitIncrease': profitIncrease, 'lockMinimumProfit': lockMinimumProfit,
          'profitReaches': profitReaches, 'trailProfit': trailProfit, 'legsStoplossType': legsStoplossType, 'legsStoploss': legsStoploss, 'legsTargetProfit': legsTargetProfit, 'legsTargetProfitType': legsTargetProfitType, 'reEntry': reEntry,
          'reEntryValue': reEntryValue, 'reEntryChoice': reEntryChoice, 'legSymbol': legSymbol, 'legExpiryType': legExpiryType, 'atmChoice': atmChoice, 'closestPremiumChoice': closestPremiumChoice, 'closestPremiumValue': closestPremiumValue,
          'legsReEntry': legsReEntry, 'protectProfit': protectProfit, 'reEntryType': reEntryType, "trailStoploss": trailStoploss, "trailStoplossLoss": trailStoplossLoss, "trailStoplossProfit": trailStoplossProfit, "trailStoplossType": trailStoplossType,
          "showRangeBreakout": showRangeBreakout, "rangeStartTime": `${startHour}:${startMinute}`, "rangeEndTime": `${rangeEndHour}:${rangeEndMinute}`, "lotarray": lotarray, "optionBuySell": optionBuySell, "optionCallPut": optionCallPut
        },
        dayoption: [1, 1, 1, 1, 1],
        variant: ['contained', 'contained', 'contained', 'contained', 'contained']
      };
      const token = secureLocalStorage.getItem('token');
      let config = { method: "post", url: `${ADD_STRATEGY_BUCKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: inputdata };
      axios
        .request(config)
        .then((response) => {
          props.setVisible(false);
          setstrategyName('');
          // update some state when we are adding strategy of active Basket
          addStrategiesOnActiveBasket(inputdata);
          console.log(inputdata, 'input data at strategy')
          props.setStrategyChecked([...props.strategyChecked, true]);
          props.setExecutedStrategy([...props.executedStrategy, 0]);
          props.setResult([...props.result, {}])
          props.setDayOption([...props.dayOption, [1, 1, 1, 1, 1]])
          updateStatsMultiplier([...strategyMultiplier, 1])
          updateStatsInclude([...include, true])
          toast.success('Strategy Added Successfully!!!');
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate('/login');
          } else if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('Network Issue');
          }
          console.log(error, 'error at add strategy')
        });
    }
  }

  // execute update strategy bucket
  const executeUpdateStrategyBucket = () => {
    if (activeBasketName == '') {
      toast.warn('Please choose any Basket');
      return;
    }
    const id = secureLocalStorage.getItem("id");
    const inputdata = {
      name: strategyName,
      array: {
        'leg': leg, 'segmentType': segmentType, 'lotarray1': lotarray1, 'legarray': legarray, 'darray': darray, 'intraday': intraday, 'tradesymbol': tradesymbol, 'gap': gap, 'stoploss': stoploss,
        'maxprofit': maxprofit, 'starttime': starttime, 'endtime': endtime, 'startdate': bucketStartDate, 'enddate': bucketEndDate,
        'startDay': startDay, 'endDay': endDay, 'atm': atm, 'atmprice': atmprice, 'squareoff': squareoff, 'waitTrade': waitTrade,
        'waitTradeType': waitTradeType, 'waitTradeValue': waitTradeValue, 'slToCost': slToCost, 'reEntry': reEntry, 'protectType': protectType, 'profitIncrease': profitIncrease, 'lockMinimumProfit': lockMinimumProfit,
        'profitReaches': profitReaches, 'trailProfit': trailProfit, 'legsStoplossType': legsStoplossType, 'legsStoploss': legsStoploss, 'legsTargetProfit': legsTargetProfit, 'legsTargetProfitType': legsTargetProfitType, 'reEntry': reEntry,
        'reEntryValue': reEntryValue, 'reEntryChoice': reEntryChoice, 'legSymbol': legSymbol, 'legExpiryType': legExpiryType, 'atmChoice': atmChoice, 'closestPremiumChoice': closestPremiumChoice, 'closestPremiumValue': closestPremiumValue,
        'legsReEntry': legsReEntry, 'protectProfit': protectProfit, 'reEntryType': reEntryType, "trailStoploss": trailStoploss, "trailStoplossLoss": trailStoplossLoss, "trailStoplossProfit": trailStoplossProfit, "trailStoplossType": trailStoplossType,
        "showRangeBreakout": showRangeBreakout, "rangeStartTime": rangeStartTime, "rangeEndTime": rangeEndTime, "lotarray": lotarray, "optionBuySell": optionBuySell, "optionCallPut": optionCallPut
      },
      dayoption: [1, 1, 1, 1, 1],
      variant: ['contained', 'contained', 'contained', 'contained', 'contained']
    };

    const index = props.updateIndex;
    const data1 = activeBasket;
    data1.strategy[index] = inputdata
    const token = secureLocalStorage.getItem('token')
    let config = { method: "post", url: `${UPDATE_BASKET}/${id}/${activeBasketName}`, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` }, data: data1.strategy };
    axios
      .request(config)
      .then((response) => {
        updateActiveBasket(data1);
        props.setVisible(false);
        props.setUpdate(false);
        setstrategyName('');
        toast.success('Strategy Updated Successfully!!!');
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          navigate('/login');
        } else if (error?.response?.data?.message) {
          toast.error(error.response.data.message)
        } else {
          toast.error('Network Issue');
        }
        console.log(error, 'error at update strategy bucket')
      });
  }

  // method to update the strategy of bucket
  const updateStrategyBucket = () => {
    if (props.update || props.copyStrategy || savedStrategyVisibility) {
      const data = props.updateStrategy.array;
      console.log(data, 'update strategy bucket')
      props.update && setstrategyName(props.updateStrategy.name);
      if (data) {
        setIncludeLeg(new Array(data.leg).fill(true))
        setleg(data.leg); setSegmentType(data.segmentType); setlotarray1(data.lotarray1); setlegarray(data.legarray); setdarray(data.darray); setintraday(data.intraday); settradesymbol(data.tradesymbol); setgap(data.gap);
        setstoploss(data.stoploss); setmaxprofit(data.maxprofit); setStartTime(data.starttime); setEndTime(data.endtime); setstartdate(data.startDate); setEndDate(data.endDate);
        setStartDay(data.startDay); setEndDay(data.endDay); setatm(data.atm); setatmprice(data.atmprice); setsquareoff(data.squareoff); setwaitTrade(data.waitTrade); setWaitTradeType(data.waitTradeType); setWaitTradeValue(data.waitTradeValue); setSlToCost(data.slToCost); setReEntry(data.reEntry);
        setProtectType(data.protectType); setProfitIncrease(data.profitIncrease); setLockMinimumProfit(data.lockMinimumProfit); setProfitReaches(data.profitReaches); setTrailProfit(data.trailProfit); setLegsStoplossType(data.legsStoplossType); setLegsStoploss(data.legsStoploss);
        setLegsTargetProfit(data.legsTargetProfit); setLegsTargetProfitType(data.legsTargetProfitType);
        setReEntry(data.reEntry); setReEntryValue(data.reEntryValue); setReEntryChoice(data.reEntryChoice); setOptionBuySell(data.optionBuySell); setOptionCallPut(data.optionCallPut); setLegsymbol(data.legSymbol); setLegExpiryType(data.legExpiryType); setAtmChoice(data.atmChoice);
        setClosestPremiumChoice(data.closestPremiumChoice); setClosestPremiumValue(data.closestPremiumValue); setlotarray(data.lotarray); setLegReEntry(data.legsReEntry); setProtectProfit(data.protectProfit); setSavedStrategyVisibility(false);
        setOptionBuySell(data.optionBuySell); setOptionCallPut(data.optionCallPut); setTrailStoploss(data.trailStoploss); setTrailStoplossLoss(data.trailStoplossLoss); setTrailStoplossProfit(data.trailStoplossProfit); setTrailStoplossType(data.trailStoplossType); setReEntryType(data.reEntryType);
      }
    }
  }
  useEffect(() => {
    waittrade();
  }, [])

  useEffect(() => {
    updateStrategyBucket();
  }, [props.update == true, savedStrategyVisibility == true])

  useEffect(() => {
    // find all saved strategies from an array
    const onGettingPreDefinedStrategy = () => {
      let data = JSON.stringify({ id: secureLocalStorage.getItem("id") });
      const token = secureLocalStorage.getItem('token');
      let config = { method: "get", maxBodyLength: Infinity, url: PRE_DEFINED_STRATEGY, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
      axios
        .request(config)
        .then((response) => {
          console.log('response', response.data)
          response?.data?.data && setPreDefinedStrategy(response?.data?.data);
          // console.log(savedstrategies);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate('/login');
          } else if (error?.response?.data?.message) {
            toast.error(error.response.data.message)
          } else {
            toast.error('Network Issue');
          }
          console.log(error, 'error at getting strategy')
        });
    };
    onGettingPreDefinedStrategy();
  }, [])

  return (
    <>
      <ToastContainer />
      <div className="flex flex-row text-sm">
        <div className={`bg-[#DAECF2]`} style={{ width: "100%" }}>
          <div className="grid grid-cols-2 items-center text-center -mb-32 mt-2 shadow-lg m-1 h-12 rounded-lg border border-gray-300 bg-gradient-to-r from-blue-500 to-indigo-600">
            <div className="text-left text-lg font-semibold ml-4 text-white">
              Add Strategies
            </div>
            <div className="flex justify-end items-center pr-4">
              <CloseIcon sx={{ cursor: "pointer", color: "white", fontSize: 24 }} onClick={() => { props.setVisible(false); }} />
            </div>
          </div>

          {/* <Main settradesymbol={settradesymbol} tradesymbol={tradesymbol} intraday={intraday} gap={gap} expirytype={expirytype} setgap={setgap} setexpirytype={setexpirytype} setintraday={setintraday}
            leg={leg} setleg={setleg} rate={rate} setRate={setRate} starttime={starttime} setStartTime={setStartTime} endtime={endtime} setEndTime={setEndTime} startdate={startdate} setstartdate={setstartdate}
            enddate={enddate} setEndDate={setEndDate} squareoff={squareoff} setsquareoff={setsquareoff} atmprice={atmprice} setatmprice={setatmprice}
            stoploss={stoploss} maxprofit={maxprofit} setstoploss={setstoploss} setmaxprofit={setmaxprofit} waitTrade={waitTrade} setWaitTradeType={setWaitTradeType} setWaitTradeValue={setWaitTradeValue} setwaitTrade={setwaitTrade} waitTradeType={waitTradeType}
            waitTradeValue={waitTradeValue} reEntry={reEntry} setReEntry={setReEntry} setLotQuantity={setLotQuantity} slToCost={slToCost} setSlToCost={setSlToCost} disableMoveSlToCost={disableMoveSlToCost} setDisableMoveSlToCost={setDisableMoveSlToCost} /> */}
          <Main  settradesymbol={settradesymbol} tradesymbol={tradesymbol} intraday={intraday} gap={gap} expirytype={expirytype} setgap={setgap} setexpirytype={setexpirytype} setintraday={setintraday}
            leg={leg} setleg={setleg} rate={rate} setRate={setRate} starttime={starttime} setStartTime={setStartTime} endtime={endtime} setEndTime={setEndTime} startdate={startdate} setstartdate={setstartdate}
            enddate={enddate} setEndDate={setEndDate} squareoff={squareoff} setsquareoff={setsquareoff} atmprice={atmprice} setatmprice={setatmprice}
            stoploss={stoploss} maxprofit={maxprofit} setstoploss={setstoploss} setmaxprofit={setmaxprofit} waitTrade={waitTrade} setWaitTradeType={setWaitTradeType} setWaitTradeValue={setWaitTradeValue} setwaitTrade={setwaitTrade} waitTradeType={waitTradeType}
            waitTradeValue={waitTradeValue} reEntry={reEntry} setReEntry={setReEntry} setLotQuantity={setLotQuantity} slToCost={slToCost} setSlToCost={setSlToCost} disableMoveSlToCost={disableMoveSlToCost} setDisableMoveSlToCost={setDisableMoveSlToCost}
            selectedCheckbox={selectedCheckbox} setSelectedCheckbox={setSelectedCheckbox} segment={segment} setSegment={setSegment} addleg={addleg} setSetting={setSetting}
            showRangeBreakout={showRangeBreakout} setShowRangeBreakout={setShowRangeBreakout} rangeStartTime={rangeStartTime} setRangeStartTime={setRangeStartTime}
            rangeEndTime={rangeEndTime} setRangeEndTime={setRangeEndTime} endDate={enddate} startDate={startdate} reExecuteTimes={reExecuteTimes} setReExecuteTimes={setReExecuteTimes}
            // range start time
            startHour={startHour} endHour={endHour} startMinute={startMinute} endMinute={endMinute} rangeEndHour={rangeEndHour} rangeEndMinute={rangeEndMinute} setStartHour={setStartHour}
            setEndHour={setEndHour} setStartMinute={setStartMinute} setEndMinute={setEndMinute} setRangeEndHour={setRangeEndHour} setRangeEndMinute={setRangeEndMinute} />

          <div className="mb-10"></div>
          {/* All Detail filled and handle in this component */}
          <Leg includeLeg={includeLeg} leg={leg} setleg={setleg} addleg={addleg} segmentType={segmentType} setSegmentType={setSegmentType} segment={segment} setSegment={setSegment} selectedCheckbox={selectedCheckbox} setSelectedCheckbox={setSelectedCheckbox} legarray={legarray} lotarray={lotarray} setlotarray={setlotarray} setlegarray={setlegarray} atm={atm} setatm={setatm} tradesymbol={tradesymbol}
            atmtype={atmtype} setatmtype={setatmtype} legsTargetProfitType={legsTargetProfitType} setLegsTargetProfitType={setLegsTargetProfitType}
            legsTargetProfit={legsTargetProfit} legsStoplossType={legsStoplossType} setLegsStoplossType={setLegsStoplossType} legsStoploss={legsStoploss} setLegsStoploss={setLegsStoploss} waitTradeType={waitTradeType} waitTrade={waitTrade} waitTradeValue={waitTradeValue} setWaitTradeValue={setWaitTradeValue} expirytype={expirytype}
            setLegsTargetProfit={setLegsTargetProfit} legSymbol={legSymbol} setLegsymbol={setLegsymbol} setLegExpiryType={setLegExpiryType} legExpiryType={legExpiryType}
            optionBuySell={optionBuySell} setOptionBuySell={setOptionBuySell} optionCallPut={optionCallPut} setOptionCallPut={setOptionCallPut} atmChoice={atmChoice} setAtmChoice={setAtmChoice} copyleg={copyleg} setWaitTradeType={setWaitTradeType}
            reEntryChoice={reEntryChoice} reEntry={reEntry} reEntryValue={reEntryValue} setReEntry={setReEntry} setReEntryChoice={setReEntryChoice} setReEntryValue={setReEntryValue} legsReEntry={legsReEntry} setLegReEntry={setLegReEntry} closestPremiumChoice={closestPremiumChoice}
            setClosestPremiumChoice={setClosestPremiumChoice} closestPremiumValue={closestPremiumValue} setClosestPremiumValue={setClosestPremiumValue} DeleteLeg={DeleteLeg} settradesymbol={settradesymbol} setLotQuantity={setLotQuantity} reEntryType={reEntryType} setReEntryType={setReEntryType} trailStoploss={trailStoploss} setTrailStoploss={setTrailStoploss} trailStoplossProfit={trailStoplossProfit}
            setTrailStoplossProfit={setTrailStoplossProfit} trailStoplossLoss={trailStoplossLoss} setTrailStoplossLoss={setTrailStoplossLoss} trailStoplossType={trailStoplossType} setTrailStoplossType={setTrailStoplossType} squareoff={squareoff} />

          <div className="border-2 m-1 rounded-lg bg-white">
            <div className="text-xl font-semibold text-[#333B69] m-2 text-left">Advanced Setting</div>
            {/* protect profit component to handle protect profit */}
            <ProtectProfit protectType={protectType} setProtectType={setProtectType} profitReaches={profitReaches} setProfitReaches={setProfitReaches} lockMinimumProfit={lockMinimumProfit} setLockMinimumProfit={setLockMinimumProfit} profitIncrease={profitIncrease} setProfitIncrease={setProfitIncrease} trailProfit={trailProfit} setTrailProfit={setTrailProfit}
              protectProfit={protectProfit} setProtectProfit={setProtectProfit} intraday={intraday}
              startDay={startDay} endDay={endDay} setStartDay={setStartDay} setEndDay={setEndDay} legExpiryType={legExpiryType} leg={leg} />
          </div>
          {/* Date Time */}
          <DateTime bucket={true} startHour={startHour} endHour={endHour} startMinute={startMinute} endMinute={endMinute} rangeEndHour={rangeEndHour} rangeEndMinute={rangeEndMinute} setStartHour={setStartHour}
          setEndHour={setEndHour} setStartMinute={setStartMinute} setEndMinute={setEndMinute} setRangeEndHour={setRangeEndHour} setRangeEndMinute={setRangeEndMinute}showRangeBreakout={showRangeBreakout} setShowRangeBreakout={setShowRangeBreakout} startTime={starttime} setStartTime={setStartTime} rangeEndTime={rangeEndTime} setRangeEndTime={setRangeEndTime} setEndDate={setEndDate} enddate={enddate} startdate={startdate} setstartdate={setstartdate} intraday={intraday} />


          <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={() => { setVisibleImportStrategy(false); }} open={visibleImportStrategy}>
            <div className="flex flex-row border-b-2">
              <div className="text-left text-xl p-4 w-64 text-[#232323]">List of Strategy</div>
              <div className="p-4 Text-[#232323]"><CancelIcon /></div>
            </div>
            <div className="flex flex-col p-2">
              {
                preDefinedStrategy.map((item, key) => {
                  return (
                    <div className=" flex flex-row w-full" key={`predefined-${key}`}>
                      <input type="checkbox" checked={key == savedStrategyChoice} onChange={() => { setSavedStrategyChoice(key); }} />
                      <div className="text-l text-black h-8 m-2 flex-grow flex justify-end rounded-xl" key={key}>{item.name}</div>
                    </div>
                  )
                })
              }
              <div className="flex justify-end gap-2 p-1">
                <button className="border-2 p-1 w-36 bg-blue-600 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { props.setUpdateStrategy(preDefinedStrategy[savedStrategyChoice]); setSavedStrategyVisibility(true); setVisibleImportStrategy(false); updateStrategyBucket(); setVisibleImportStrategy(false); }}>Add</button>
                <button className="p-1 w-36 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { setVisibleImportStrategy(false); }}>Cancel</button>
              </div>
            </div>
          </Dialog>
          {/* change default setting */}
          <Dialog className="border-2 border-t-8 border-blue-500 rounded-xl" onClose={(event, reason) => {
            if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') { setSetting(false); }
          }} open={setting}>
            <div className="flex flex-row border-b-2">
              <div className="text-[#232323] text-xl font-medium p-2 flex flex-grow"><SettingsIcon />Setting</div>
              <div className="text-xl text-[#232323] flex justify-end font-semibold p-2 cursor-pointer"><CloseIcon onClick={() => { setSetting(false); }} /></div>
            </div>
            <div className="flex flex-col mt-2 p-5">
              <div className="">Banknifty Lot Size</div>
              <select className="border-2 border-black rounded" ref={BankniftyLotSize} >
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={40}>40</option>
              </select>
              <div className="text-xs">*From 21 Jul'23 ,Banknifty Lot Size is considered 15</div>
              <div className="">Nifty Lot Size</div>
              <select className="border-2 border-black rounded" ref={NiftyLotSize}>
                <option value={50}>50</option>
                <option value={75}>75</option>
              </select>
              <div className="text-xs">* From 23 Jul'23, Nifty Lot Size is considered 50</div>
              <div className="">Inbuilt Slippages</div>
              <select className="border-2 border-black rounded mb-2" ref={inbuiltSlippage}>
                {
                  slippageList.map((item, key) => {
                    return (
                      <option value={item} key={`slippage-${key}`}>{item}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className="flex justify-end gap-2 mt-2 border-t-2 p-1">
              <button className="border-2 p-1 w-24 bg-blue-500 rounded-lg justify-center text-center text-white cursor-pointer" onClick={() => { setSetting(false); }}>Cancel</button>
              <button className="p-1 w-24 bg-white border-2 border-[#2D5BFF] rounded-lg justify-center text-center text-[#2D5BFF] cursor-pointer" onClick={() => { handleSetting(); setSetting(false); }}>Save</button>
            </div>
          </Dialog>
          <div className="grid grid-cols-1 mt-5 justify-center text-center mb-5  gap-4 sm:ml-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
            <div className="flex flex-col">
              <div className="grid grid-cols-2 text-center text-xl h-8 font-thin justify-center  rounded bg-blue-500 text-white sm:w-48">
                <div style={{ backgroundColor: intraday == "Yes" ? "rgb(37,99,235)" : "white", color: intraday == "Yes" ? "white" : "black" }} className="rounded-l h-8 border-solid  cursor-pointer" onClick={() => { setintraday("Yes"); }} >
                  Intraday
                </div>
                <div style={{ backgroundColor: intraday == "Yes" ? "white" : "rgb(37,99,235)", color: intraday == "Yes" ? "black" : "white" }} className="rounded-r h-8 border-solid  cursor-pointer" onClick={() => { setintraday("No"); }} >
                  Positional
                </div>
              </div>
            </div>
            <input type="text" className="border-2 rounded-md  border-blue-600 h-8 p-1" placeholder="Strategy Name" value={strategyName} onChange={(e) => { setstrategyName(e.target.value) }} />
            <div className="rounded border-solid justify-center pt-1 text-center text-l font-thin h-8 cursor-pointer bg-[#2D5BFF] text-white" onClick={() => { setSavedStrategyVisibility(true); setVisibleImportStrategy(true); }}>
              <ImportExportIcon />Import Saved Strategy
            </div>
            {
              !props.update && <div className="rounded border-solid p-1 text-center text-l h-8 font-thin cursor-pointer bg-[#2D5BFF] text-white sm:w-48" onClick={() => { add_strategy_busket(); }}>
                <AddBox />Add Strategy
              </div>
            }
            {
              props.update && <div className="rounded h-8 border-solid p-1 text-center text-l font-thin cursor-pointer bg-[#2D5BFF] text-white sm:w-48" onClick={() => { executeUpdateStrategyBucket(); }}>
                <SystemUpdateAltIcon />Update Strategy
              </div>
            }

            <div className="rounded h-8 border-solid text-center justify-center pt-1 text-l font-thin cursor-pointer bg-[#FF3030] text-white sm:w-48" onClick={() => { props.setVisible(false); props.setUpdate(false); }}>
              <CancelIcon />Cancel
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BucketBacktest;
