import React, { useEffect, useState } from 'react';
import { SERIES_OPTION_TRADING } from './../../../constant/constant';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import { IoMdHelpCircle } from "react-icons/io";
import { Tooltip } from 'react-tooltip';
import Excel from './Excel'
import {toast,ToastContainer} from 'react-toastify';


const ATM_LIST1=[-2000,-1900,-1800,-1700,-1600,-1500,-1400,-1300,-1200,-1100,-1000,-900,-800,-700,-600,-500,-400,-300,-200,-100,0]
const ATM_LIST2=[0,100,200,300,400,500,600,700,800,900,1000,1100,1200,1300,1400,1500,1600,1700,1800,1900,2000]


const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

const Option = () => {
    const [symbol, setSymbol] = useState('NIFTY');
    const [targetProfit, setTargetProfit] = useState(100);
    const [targetLoss, setTargetLoss] = useState(100);
    const [targetDiff, setTargetDiff] = useState(100);
    const [startDate, setStartDate] = useState("2022-01-03");
    const [endDate, setEndDate] = useState("2022-05-03");
    const [startTime, setStartTime] = useState("09:30");
    const [endTime, setEndTime] = useState("15:29");
    const [lotSize, setLotSize] = useState(1);
    const [action, setAction] = useState('BUY');
    const [expiryDay, setExpiryDay] = useState(0);
    const [option,setOption]=useState('CALL');
    const [tradeType,setTradeType]=useState('monthly');
    const [futureType,setFutureType]=useState('FUTURE_I');
    const [optionType,setOptionType]=useState('OPTION_I');
    const [atmBehaviour,setAtmBehaviour]=useState('Fixed');
    const [atmValue,setAtmValue]=useState(0);
    // Result
    const [tradeList, setTradeList] = useState([])
    const [totalPnl, setTotalPnl] = useState([])
    const [monthlyProfit, setMonthlyProfit] = useState([])
    const [investmentDay, setInvestmentDay] = useState([])
    const [maximumProfit, setMaximumProfit] = useState(0)
    const [minimumProfit, setMinimumProfit] = useState(0)
    const [margin, setMargin] = useState(0)
    const [maximumDrawdown, setMaximumDrawdown] = useState(0)
    const [resultShow, setResultShow] = useState(false);
    const [futurePrice, setFuturePrice] = useState([])
    const [futurePriceDatetime, setFuturePriceDatetime] = useState([])
    const [profitList, setProfitList] = useState()
    const [bookedMonthProfit, setBookedMonthProfit] = useState([])
    const [monthlyLossExpiry, setMontlyLossExpiry] = useState([])
    const [rollOverQuantity,setRollOverQuantity]=useState([]);
    const [expiry,setExpiry]=useState([])
    const [rollOverMonthResult,setRollOverMonthResult]=useState({})
    const [concise,setConcise]=useState(true);
    // page Size
    const [pageSize, setPageSize] = useState(1)
    const [currentIndex, setCurrentIndex] = useState(1)
    // loader
    const [loader, setloader] = useState(false);
    // monthlyResult
    const [monthlyResult,setMonthlyResult]=useState([])
    const [showResult,setShowResult]=useState(1);
    // Sorting the Data on the basis of Index
    const sorted = (index, order) => {
        const data = [];
        for (let i = 0; i < tradeList.length; i++) {
            const sortedData = tradeList[i].sort((a, b) => {
                const itemA = a[index];
                const itemB = b[index];
                if (order === 0) {
                    if (typeof itemA === 'string' && typeof itemB === 'string') {
                        return itemA.localeCompare(itemB);
                    } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                        return itemA - itemB;
                    } else {
                        return 0; // No comparison possible, keep the order unchanged
                    }
                } else {
                    if (typeof itemA === 'string' && typeof itemB === 'string') {
                        return itemB.localeCompare(itemA);
                    } else if (typeof itemA === 'number' && typeof itemB === 'number') {
                        return itemB - itemA;
                    } else {
                        return 0; // No comparison possible, keep the order unchanged
                    }
                }
            });
            data.push(sortedData);
        }
        console.log(data, 'data')
        setTradeList(data);
    };

    const calculateMaximumDrawdown = () => {
        let maxProfit = 0;
        let MaximumDrawdown = 0;
        let previous = 0
        let cumulative = new Array(bookedMonthProfit.length).fill(0);
        for (let i = 0; i < bookedMonthProfit.length; i++) {
            // console.log(bookedMonthProfit[i], 'bookedMonthProfit')
            // Method to find the Cumulative Data
            cumulative[i] = previous + bookedMonthProfit[i];
            previous = cumulative[i];
            // Method to find the Maximum Drawdown
            // Maximum Drawdown is the biggest loss observed in an investment from peak to trough
            // Formula of Drawdown:: MIN((B2-MAX($B$2-B2))/MAX($B$2-B2)
            if (bookedMonthProfit[i] > maxProfit)
                maxProfit = bookedMonthProfit[i];
            if (cumulative[i] > maxProfit) {
                maxProfit = cumulative[i]; // Update maxPrice if a new peak is reached
            }
            const currentDrawdown = (cumulative[i] - maxProfit);

            if (currentDrawdown < MaximumDrawdown) {
                MaximumDrawdown = currentDrawdown; // Update drawdown if a new minimum drawdown is reached
            }
        }
        // console.log(MaximumDrawdown, 'Maximum Duration')
        setMaximumDrawdown(MaximumDrawdown);
    }
    const calculationResult = (monthlyProfit) => {
        if(monthlyProfit.length>0){
        let min = 999999999999, max = 0;
        for (let i = 0; i < monthlyProfit.length; i++) {
            if (monthlyProfit[i] > max)
                max = monthlyProfit[i];
            if (monthlyProfit[i] < min)
                min = monthlyProfit[i];
        }
        setMinimumProfit(min);
        setMaximumProfit(max);
        }
    }

    const month_result=(monthlyProfit,monthlyLossExpiry,expiry,bookedMonthProfit)=>{
        let normal={}
        let rollOver={}
        for(let i=0;i<monthlyProfit.length;i++)
        {
            if (normal.hasOwnProperty(expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3])) {
                normal[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]][parseInt(expiry[i][4] + expiry[i][5])] = bookedMonthProfit[i];
                normal[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]][13] += parseInt(bookedMonthProfit[i]);
            } else {
                normal[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]] = new Array(14).fill(0);
                normal[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]][parseInt(expiry[i][4] + expiry[i][5])] = bookedMonthProfit[i];
                normal[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]][13] += parseInt(bookedMonthProfit[i]);
            }

            if (rollOver.hasOwnProperty(expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3])) {
                rollOver[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]][parseInt(expiry[i][4] + expiry[i][5])] = monthlyLossExpiry[i];
                rollOver[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]][13] += parseInt(monthlyLossExpiry[i]);
            } else {
                rollOver[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]] = new Array(14).fill(0);
                rollOver[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]][parseInt(expiry[i][4] + expiry[i][5])] = monthlyLossExpiry[i];
                rollOver[expiry[i][0] + expiry[i][1] + expiry[i][2] + expiry[i][3]][13] += parseInt(monthlyLossExpiry[i]);
            }
        //    console.log(i+1,rollOverQuantity[i],bookedMonthProfit[i],monthlyProfit[i],monthlyLossExpiry[i],'hello world')
        //    result.push([i+1,rollOverQuantity[i],bookedMonthProfit[i],monthlyProfit[i],monthlyLossExpiry[i]]);
        }
        setMonthlyResult(normal);
        setRollOverMonthResult(rollOver);
    }
    const startSeriesTrading = () => {
        setloader(true);
        const token = secureLocalStorage.getItem('token');
        if(endDate<startDate)
        {
            toast.warn("Please enter valid Start Date and End Date");
            setloader(false);
            return;
        }
        console.log(targetDiff,lotSize,endDate,startDate,'result');
        if(targetDiff==0 || targetDiff=='')
        {
            toast.warn('Target Difference must be greater than 0');
            setloader(false);
                        return;
        }
        if(lotSize==0)
        {
            toast.warn('Lot Size must be greater than 0');
            setloader(false);
            return;
        }
        const data = {
            "symbol": symbol, "targetProfit": targetProfit, "targetLoss": targetLoss, "targetDiff": targetDiff, "startDate": startDate, "endDate": endDate, "startTime": startTime, "endTime": endTime,
            "lotSize": lotSize, "action": action, "expiryDay": expiryDay,"futureType":futureType,"option":option,"optionType":optionType,"atmBehaviour":atmBehaviour,
            "atmValue":atmValue,"atmBehaviour":atmBehaviour,"tradeType":tradeType
        }
        let config = { method: "post", maxBodyLength: Infinity, url: SERIES_OPTION_TRADING, headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`, }, data: data, };
        axios(config)
            .then((response) => {
                console.log(JSON.stringify(response.data));
                setTotalPnl(response.data?.totalProfit);
                setTradeList(response.data?.result);
                setMonthlyProfit(response.data?.monthlyProfit);
                setInvestmentDay(response.data?.investmentDay);
                setMargin(response.data?.margin);
                setResultShow(true);
                month_result(response.data?.monthlyProfit,response.data?.monthlyLossExpiry,response.data?.expiry,response.data?.bookedMonthProfit)
                calculationResult(response.data?.monthlyProfit);
                setFuturePrice(response.data?.futurePrice);
                setFuturePriceDatetime(response.data?.futurePriceDatetime);
                setProfitList(response.data?.profitList);
                setBookedMonthProfit(response.data?.bookedMonthProfit);
                setMontlyLossExpiry(response.data?.monthlyLossExpiry);
                setRollOverQuantity(response.data?.rollOverQuantity);
                setExpiry(response.data.expiry)
                calculateMaximumDrawdown();
                setloader(false);
            }).catch((error) => {
                console.log(error, 'error');
                setloader(false);
            })
    };
//     useEffect(()=>{
//    month_result();
//     },[showResult])
    useEffect(()=>{
    if(action=="BUY"){
        setOption("PUT");
    }else{
        setOption("CALL");
    }
    },[action,option])

    return (
        <div className='bg-[#DAECF2] min-h-screen'>
            <ToastContainer/>
            <div className='flex flex-col m-2'>
                <div className='grid sm:grid-cols-2 lg:grid-cols-3'>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Target Profit</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={targetProfit} onChange={(e) => setTargetProfit(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Symbol</div>
                        <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={symbol} onChange={(e) => setSymbol(e.target.value)}>
                            <option value="NIFTY">NIFTY</option>
                            <option value="BANKNIFTY">BANKNIFTY</option>
                        </select>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Action</div>
                        <select value={action} className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' onChange={(e) => { setAction(e.target.value) }}>
                            <option value="BUY">BUY</option>
                            <option value="SELL">SELL</option>
                        </select>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Option</div>
                        <select value={option} className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' onChange={(e) => { setOption(e.target.value) }}>
                            <option value="CALL">CALL</option>
                            <option value="PUT">PUT</option>
                        </select>
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Lot</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={lotSize} onChange={(e) => setLotSize(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Entry Difference</div>
                        <input type="number" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={targetDiff} onChange={(e) => setTargetDiff(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>End Date</div>
                        <input type="date" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>Start Time</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                    </div>
                    <div className='flex flex-row bg-white p-2 m-2'>
                        <div className='w-40 text-[18px]'>RollOver</div>
                        <input type="time" className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={endTime} onChange={(e) => { setEndTime(e.target.value); }}></input>
                    </div>
                    <div className='flex flex-row bg-white p-1 m-2'>
                    <div className='w-40 text-[18px] flex flex-row'>ATM<div data-tooltip-id="my-tooltip" data-tooltip-content="future price decision."><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
                    <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={atmValue} onChange={(e)=>{setAtmValue(e.target.value);}}>
                        {
                          action=="BUY" && ATM_LIST1.map((item,key)=>{
                            return(
                            <option value={item}>{item}</option>
                            )
                          })
                        }
                        {
                        action=="SELL" && ATM_LIST2.map((item,key)=>{
                        return(
                        <option value={item}>{item}</option>
                        )
                        })
                        }
                    </select>
                    </div>
                    <div className='flex flex-row bg-white p-1 m-2'>
                    <div className='w-40 text-[18px] flex flex-row'>Trade Type<div data-tooltip-id="my-tooltip" data-tooltip-content="trade type."><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
                    <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={tradeType} onChange={(e)=>{setTradeType(e.target.value);}}>
                        <option value='monthly'>Monthly</option>
                    </select> 
                    </div>
                    <div className='flex flex-row bg-white p-1 m-2'>
                    <div className='w-40 text-[18px] flex flex-row'>Future Type<div data-tooltip-id="my-tooltip" data-tooltip-content="future price decision."><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
                    <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={futureType} onChange={(e)=>{setFutureType(e.target.value);}}>
                        <option value='FUTURE_I'>FUTURE_I</option>
                        <option value='FUTURE_II'>FUTURE_II</option>
                        <option value='FUTURE_III'>FUTURE_III</option>
                    </select>
                    </div>
                    <div className='flex flex-row bg-white p-1 m-2'>
                    <div className='w-40 text-[18px] flex flex-row'>Option Type<div data-tooltip-id="my-tooltip" data-tooltip-content="option price decision."><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
                    <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={optionType} onChange={(e)=>{setOptionType(e.target.value);}}>
                        <option value='OPTION_I'>OPTION_I</option>
                        <option value='OPTION_II'>OPTION_II</option>
                        <option value='OPTION_III'>OPTION_III</option>
                    </select>
                    </div>
                    <div className='flex flex-row bg-white p-1 m-2'>
                    <div className='w-40 text-[18px] flex flex-row'>ATM Behaviour<div data-tooltip-id="my-tooltip" data-tooltip-content="12000 --> ATM ,we will be take ATM-500 ---> 11500. here,meaning is that you want to fixed the future price for whole trade or it's variable on the basis of situation."><IoMdHelpCircle className="text-[#0096FF] text-2xl" /></div></div>
                    <select className='text-l bg-[#ECF0FF] p-1 flex-grow outline-0' value={atmBehaviour} onChange={(e)=>{setAtmBehaviour(e.target.value);}}>
                        <option value='Fixed'>Fixed</option>
                        <option value='Variable'>Variable</option>
                    </select>
                    </div>
                    
                </div>
                <div className='h-[4px] bg-white mb-1 mt-5'></div>
                {!loader && <div className='bg-[#2D5BFF] h-8 rounded-md text-center text-white text-[16px] cursor-pointer' onClick={startSeriesTrading}>Start F&O Series Trading</div>}
                {loader && <div className="bouncing-loader">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>}
            </div>
            <div className='flex flex-col gap-2'>
                <div className='text-sm font-medium text-red-600 ml-5'>**For Sell,We will take Call Option and OTM or ATM.</div>
                <div className='text-sm font-medium text-red-600 ml-5'>**For Buy,We will take Put Option and ITM or ATM.</div>
                <div className='text-sm font-medium text-red-600 ml-5'>**StrikePrice gap will be 100 here.</div>
            </div>
            {resultShow && <div className='flex flex-col'>
                <div className='bg-white text-xl rounded-md m-2 text-center'>Series Trading Result</div>
                {/* Only For Result */}
                <div className='grid grid-cols-2 m-2'>
                <div className='flex flex-row gap-2'>
                    <div className='text-[18px]'>Filter</div>
                    <select className='w-48 outline-0 text-[16px] bg-[#2D5BFF] text-white rounded' value={showResult} onChange={(e) => setShowResult(parseInt(e.target.value))}>
                    <option value={1}>Show Daily Result</option>
                    <option value={2}>Show RollOver Result</option>
                    </select>
                </div>
                <div className='flex justify-end gap-2'>
                    <div className='text-white bg-blue-600 rounded-lg text-center w-36 font-[500] text-[20px]' onClick={()=>{setConcise(!concise);}}>{concise?"concise":"detail"}</div>
                <Excel tradeList={tradeList} maximumDrawdown={maximumDrawdown} minimumProfit={minimumProfit} maximumProfit={maximumProfit} margin={margin} totalProfit={totalPnl} investmentDay={investmentDay}/>
                </div>
                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 m-2'>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Estimate Margin :</div>
                        <div className='font-semibold flex justify-end flex-grow'>{margin}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Total Profit :</div>
                        <div className='font-semibold flex justify-end flex-grow'>{totalPnl}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Total Trading Days :</div>
                        <div className='font-semibold flex justify-end flex-grow'>{investmentDay}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Maximum Monthly Profit:</div>
                        <div className='font-semibold flex justify-end flex-grow'>{maximumProfit}</div>
                    </div>
                    <div className='flex flex-row bg-white border-[1px] border-red-600 rounded-md p-2'>
                        <div className='text-l'>Minimum Monthly Profit:</div>
                        <div className='font-semibold flex justify-end flex-grow'>{minimumProfit}</div>
                    </div>
                </div>
                {
                    showResult == 1 && <div className='bg-white rounded-lg m-2 overflow-auto'>
                        <div className='flex flex-row justify-around gap-2 border-2 text-center  bg-blue-500 text-white min-w-[1560px]'>
                            <div className='text-[18px] text-[600] text-center w-32'>Years.</div>
                            {
                                month.map((item, key) => {
                                    return (
                                        <div className='text-[18px] text-[600] text-center w-32'>{item}</div>
                                    )
                                })
                            }
                            <div className='text-[18px] text-[600] text-center w-32'>Total.</div>
                        </div>
                        {
                            Object.entries(monthlyResult).map(([key, value]) => (
                                <div className=" bg-white flex flex-row justify-around text-center min-w-[1560px]" key={key}>
                                    <div className="p-2 text-black w-32">{key}</div>
                                    <div className="p-2 text-black w-32">{value[1].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[2].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[3].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[4].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[5].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[6].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[7].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[8].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[9].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[10].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[11].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[12].toFixed(2)}</div>
                                    <div className="p-2 text-black w-32">{value[13].toFixed(2)}</div>
                                </div>
                            ))
                        }
                    </div>
                }
                {showResult==1 && concise && 
                <div className='overflow-x-auto bg-white m-2 rounded-md'>
                    <div className=' md:grid md:grid-cols-6 flex flex-row text-center justify-center bg-blue-500 text-white border-b-2 text-l font-bold'>
                        <div className='font-[600] text-[16px]'>Month</div>
                        <div className='font-[600] text-[16px]'>RollOverQuantity</div>
                        <div className='font-[600] text-[16px]'>BookedProfit/Loss</div>
                        <div className='font-[600] text-[16px]'>RollOverProfit/Loss</div>
                        <div className='font-[600] text-[16px]'>NetMonthlyProfit/Loss</div>
                        <div className='font-[600] text-[16px]'>Expiry</div>
                    </div>
                    {
                        tradeList.map((list, key) => {
                            return (
                                    <div className='md:grid md:grid-cols-6 border-2 rounded-lg text-center justify-center  flex flex-row gap-3 p-1'>
                                        <div className=''>{key+1}</div>
                                        <div className=''>{rollOverQuantity[key]}</div>
                                        <div className=''> {bookedMonthProfit[key]} </div>
                                        <div className=''> {monthlyLossExpiry[key]}</div>
                                        <div className=''> {monthlyProfit[key]}</div>
                                        <div className=''> {list[0][13]}</div>
                                    </div>
                            )
                        })
                    }
                    </div>
                }
            
                {showResult==1 && !concise &&
                <div className='overflow-x-auto bg-white m-2 rounded-md'>
                <div className="rounded-lg overflow-x-auto font-serif overflow-auto p-2 min-w-[1400px]">
                    <div className=' flex flex-row text-center justify-center bg-blue-500 text-white border-b-2 text-l font-bold'>
                        <div className='font-[600] text-[16px] w-[40px]'>No.</div>
                        <div className='font-[600] text-[16px] w-[100px]'>St.time.</div>
                        <div className='font-[600] text-[16px] w-[100px]'>St.Date</div>
                        <div className='font-[600] text-[16px] w-[100px]'>Ex.time.</div>
                        <div className='font-[600] text-[16px] w-[100px]'>Ex.Date.</div>
                        <div className='font-[600] text-[16px] w-[100px]'>F.Price</div>
                        <div className='font-[600] text-[16px] w-[100px]'>F.Price</div>
                        <div className='font-[600] text-[16px] w-[100px]'>O.Price</div>
                        <div className='font-[600] text-[16px] w-[100px]'>O.Price</div>
                        <div className='font-[600] text-[16px] w-[100px]'>Roll</div>
                        <div className='font-[600] text-[16px] w-[100px]'>O.Profit</div>
                        <div className='font-[600] text-[16px] w-[100px]'>F.Profit</div>
                        <div className='font-[600] text-[16px] w-[100px]'>T.Profit</div>
                        <div className='font-[600] text-[16px] w-[100px]'>StrikePrice</div>
                        <div className='font-[600] text-[16px] w-[100px]'>T.Price</div>
                    </div>
                    <div className='flex flex-col text-center justify-center'>
                    {
                        tradeList.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div className='flex flex-row text-center justify-center border-b-2' key={index + key} style={{backgroundColor:item[16][0]=='R'?"lightblue":""}}>
                                                    <div className='text-[16px] w-[40px]' style={{backgroundColor:item[8]>0?'yellow':''}}>{index+1}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[0]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[1]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[2]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[3]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[4]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[5]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[6]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[7]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[8]}</div>
                                                    <div className='text-[16px] w-[100px]' style={{ color: item[9] > 0 ? "green" : "red" }}>{item[9]}</div>
                                                    <div className='text-[16px] w-[100px]' style={{ color: item[10] > 0 ? "green" : "red" }}>{item[10]}</div>
                                                    <div className='text-[16px] w-[100px]' style={{ color: item[11] > 0 ? "green" : "red" }}>{item[11]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[12]}</div>
                                                    {/* rollOver(14) , next_month_price(15) */}
                                                    <div className='text-[16px] w-[100px]'>{item[19]}</div>
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='m-2 border-2 rounded-lg  flex flex-row gap-3 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key+1}</div>
                                        <div className=''><span className='font-[600]'>RollOverQuantity :</span>{rollOverQuantity[key]}</div>
                                        <div className=''><span className='font-[600]'>Booked Profit/Loss:</span> {bookedMonthProfit[key]} </div>
                                        <div className=''><span className='font-[600]'>Net Monthly Profit/Loss:</span> {monthlyProfit[key]}</div>
                                        <div className=''><span className='font-[600]'>Roll Over Profit/Loss:</span> {monthlyLossExpiry[key]}</div>
                                        <div className=''><span className='font-[600]'>Expiry:</span> {list[0][13]}</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    </div>
                </div></div>}
            
                {
                    showResult == 2 && <div className='bg-white rounded-lg m-2 overflow-auto'>
                        <div className='flex flex-row justify-around gap-2 border-2 text-center  bg-blue-500 text-white min-w-[1560px]'>
                            <div className='text-[18px] text-[600] text-centerw-32'>Years.</div>
                            {
                                month.map((item, key) => {
                                    return (
                                        <div className='text-[18px] text-[600] text-center w-32'>{item}</div>
                                    )
                                })
                            }
                            <div className='text-[18px] text-[600] text-centerw-32'>Total.</div>
                        </div>
                        {
                            Object.entries(rollOverMonthResult).map(([key, value]) => (
                                <div className=" bg-white flex flex-row justify-around text-center min-w-[1560px]" key={key}>
                                    <div className="p-2 text-black text-center w-32">{key}</div>
                                    <div className="p-2 text-black text-center w-32">{value[1].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[2].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[3].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[4].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[5].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[6].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[7].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[8].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[9].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[10].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[11].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[12].toFixed(2)}</div>
                                    <div className="p-2 text-black text-center w-32">{value[13].toFixed(2)}</div>
                                </div>
                            ))
                        }
                    </div>
                }
                {/* Roll Over Detail Concise */}
                  {showResult==2 && concise && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='grid grid-cols-5 border-b-2 p-2 text-white  min-w-[1300px] bg-blue-500'>
                        <div className='font-[600] text-[16px] flex flex-row'>Month</div>
                        <div className='font-[600] text-[16px] flex flex-row'>RollOverQuantity&nbsp;</div>
                        <div className='font-[600] text-[16px] flex flex-row'>BookedProfit</div>
                        <div className='font-[600] text-[16px] flex flex-row'>RollOverProfit</div>
                        <div className='font-[600] text-[16px] flex flex-row'>NetMonthProfit</div>
                    </div>
                    {
                        tradeList.map((list, key) => {
                            return (
                                    <div className='grid grid-cols-5 p-1 border-2 min-w-[1300px] rounded-lg border-b-2 '>
                                        <div className=''>{key+1}</div>
                                        <div className=''>{rollOverQuantity[key]}</div>
                                        <div className=''> {bookedMonthProfit[key]} </div>
                                        <div className=''> {monthlyLossExpiry[key]}</div>
                                        <div className=''> {monthlyProfit[key]}</div>
                                    </div>
                            )
                        })
                    }
                </div>}
                {/* Roll Over Data: Detail */}
                {showResult==2 && !concise && <div className='flex flex-col bg-white m-2 rounded-lg overflow-auto'>
                    <div className='flex flex-row text-center justify-center bg-blue-500 text-white border-b-2 text-l font-bold'>
                        <div className='font-[600] text-[16px] w-[40px]'>No.</div>
                        <div className='font-[600] text-[16px] w-[100px]'>St.time.</div>
                        <div className='font-[600] text-[16px] w-[100px]'>St.Date</div>
                        <div className='font-[600] text-[16px] w-[100px]'>Ex.time.</div>
                        <div className='font-[600] text-[16px] w-[100px]'>Ex.Date.</div>
                        <div className='font-[600] text-[16px] w-[100px]'>F.Price</div>
                        <div className='font-[600] text-[16px] w-[100px]'>F.Price</div>
                        <div className='font-[600] text-[16px] w-[100px]'>O.Price</div>
                        <div className='font-[600] text-[16px] w-[100px]'>O.Price</div>
                        <div className='font-[600] text-[16px] w-[100px]'>Roll</div>
                        <div className='font-[600] text-[16px] w-[100px]'>O.Profit</div>
                        <div className='font-[600] text-[16px] w-[100px]'>F.Profit</div>
                        <div className='font-[600] text-[16px] w-[100px]'>T.Profit</div>
                        <div className='font-[600] text-[16px] w-[100px]'>StrikePrice</div>
                        <div className='font-[600] text-[16px] w-[100px]'>T.Price</div>
                    </div>
                    {
                        tradeList.map((list, key) => {
                            return (
                                <div>
                                    {
                                        list.map((item, index) => {
                                            return (
                                                <div>
                                                {item[10]!='' &&  <div className='flex flex-row text-center justify-center border-b-2' key={index + key} style={{backgroundColor:item[16][0]=='R'?"lightblue":""}}>
                                                    <div className='text-[16px] w-[40px]'>{index+1}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[0]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[1]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[2]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[3]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[4]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[5]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[6]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[7]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[8]}</div>
                                                    <div className='text-[16px] w-[100px]' style={{ color: item[9] > 0 ? "green" : "red" }}>{item[9]}</div>
                                                    <div className='text-[16px] w-[100px]' style={{ color: item[10] > 0 ? "green" : "red" }}>{item[10]}</div>
                                                    <div className='text-[16px] w-[100px]' style={{ color: item[11] > 0 ? "green" : "red" }}>{item[11]}</div>
                                                    <div className='text-[16px] w-[100px]'>{item[12]}</div>
                                                    {/* rollOver(14) , next_month_price(15) */}
                                                    <div className='text-[16px] w-[100px]'>{item[19]}</div>
                                                </div>}
                                                </div>
                                            )
                                        })
                                    }
                                    <div className='border-2 rounded-lg bg-blue-500 min-w-[1300px] text-white flex flex-row gap-5 p-2'>
                                        <div className=''><span className='font-[600]'>Month :</span>{key+1}</div>
                                        <div className=''><span className='font-[600]'>RollOverQuantity :</span>{rollOverQuantity[key]}</div>
                                        <div className=''><span className='font-[600]'>Booked Profit/Loss:</span> {bookedMonthProfit[key]} </div>
                                        <div className=''><span className='font-[600]'>Net Monthly Profit/Loss:</span> {monthlyProfit[key]}</div>
                                        <div className=''><span className='font-[600]'>Roll Over Profit/Loss:</span> {monthlyLossExpiry[key]}</div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>}
                {/* Page Size-> No. Of Items */}
                <div className="grid grid-cols-2" >
                    <div className="flex flex-row m-10">
                        <div className="font-[600] text-[16px]">Page Size</div>
                        <select className="ml-3 outline-0 rounded-md" value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                            <option value={1}>1 month</option>
                            <option value={3}>3 month</option>
                            <option value={6}>6 month</option>
                            <option value={12}>12 month</option>
                        </select>
                    </div>
                    <div className="grid justify-end  mb-10 mt-10 mr-10">
                        <div className="flex flex-row gap-2">
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowLeft onClick={() => { setCurrentIndex(currentIndex - 1); }} /></div>
                            <div className="rounded h-[32px] w-[32px] font-[Ubuntu]  text-[16px] font-[700] text-center text-[#2D5BFF] justify-center border-[1px] border-[#ECF0FF] cursor-pointer active:bg-[#ECF0FF]">{currentIndex}</div>
                            <div className='rounded-md border-2 border-white h-[32px] w-[32px] bg-[#2D5BFF] text-white'><FaArrowRight onClick={() => { setCurrentIndex(currentIndex + 1); }} /></div>
                        </div>
                    </div>
                </div>
            </div>
            }
            <Tooltip id="my-tooltip" style={{ width: '300px', borderRadius: '10px', backgroundColor: 'rgb(147 197 253)', color: 'black', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.1)' }} />
        </div>
    );
};

export default Option;
